import React, { useEffect } from "react";
import { useAppContext } from "../../../contextApi/AppContext";
import { Link, useParams } from "react-router-dom";
import { Card, Col, Container, Row, ListGroup, Button, Badge } from "react-bootstrap";
import moment from "moment";

const SubscriptionDetail = () => {
  const { id } = useParams();
  const { getSubsCourseDetail, subsCourseDetail, SubsCourseCancel } = useAppContext();

  useEffect(() => {
    getSubsCourseDetail(id);
    // eslint-disable-next-line
  }, [id]);

  const {
    current_plan_end,
    current_plan_start,
    subscription_history,
    product_plans,
    product_details,
    upcoming_meetings,
    _id,
    status,
    purchase_mode,
    amount
  } = subsCourseDetail;

  const handleCancelSubscription = (_id) => {
    const check = window.confirm("Are you sure you want to cancel this subscription?");
    if (check) {
      SubsCourseCancel(_id);
    }
  };
  // console.log("product_details",product_details)

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>My Enrollments</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li><Link to="/my-subscription">My Enrollment</Link></li>
                <li className="active">{product_details?.title}</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <Container className="mt-2">
        <Row>
          {/* Course Details Section */}
          <Col md={4}>
            <Card className="mb-4 shadow-sm">
              <Card.Img
                variant="top"
                src={product_details?.image || "https://via.placeholder.com/150"}
                alt={product_details?.title || "Course Image"}
              />
              <Card.Body>
                <Card.Title className="text-primary">{product_details?.title}</Card.Title>
                <Card.Text dangerouslySetInnerHTML={{ __html: product_details?.description || "No description available." }} />

                <Card.Text>
                  <strong>Purchase Mode:</strong> {purchase_mode || "N/A"}
                </Card.Text>
                {status === "Inactive" ? (
                  <Button variant="outline-danger" disabled className="w-100 mt-3" size="sm">
                    Subscription Canceled
                  </Button>
                ) : (
                  <Button variant="danger" className="w-100 mt-3" onClick={() => handleCancelSubscription(_id)} size="sm">
                    Cancel Subscription
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>

          {/* Subscription Details Section */}
          <Col md={8}>
          {purchase_mode === 'Subscription' && (
            <Card className="mb-4 shadow-sm">
              <Card.Body>
                <Card.Title className="text-dark">Subscription Details</Card.Title>
                <Row className="mt-3">
                  <Col md={6}>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <strong>Plan:</strong> {product_plans?.plan_name || "N/A"}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Price:</strong> ${product_plans?.plan_price || "N/A"}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Interval:</strong> {product_plans?.plan_interval || "N/A"}
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                  <Col md={6}>
                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <strong>Course duration:</strong> {product_plans?.plan_interval_count || "N/A"} {product_plans?.plan_interval}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>Start Date:</strong>{" "}
                        {current_plan_start ? moment(current_plan_start).format("LLL") : "N/A"}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <strong>End Date:</strong> {current_plan_end ? moment(current_plan_end).format("LLL") : "N/A"}
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

            {/* Subscription History Section */}
            {purchase_mode === 'Subscription' && (
              <Card className="mb-4 shadow-sm">
                <Card.Body>
                  <Card.Title className="text-dark">Subscription History</Card.Title>
                  <ListGroup variant="flush" className="mt-3">
                    {Array.isArray(subscription_history) && subscription_history.length > 0 ? (
                      subscription_history.map((historyItem) => (
                        <ListGroup.Item key={historyItem._id}>
                          <div className="d-flex justify-content-between">
                            <span>
                              {historyItem.amount} USD -{" "}
                              {moment(historyItem.current_plan_start).format("LL")} to{" "}
                              {moment(historyItem.current_plan_end).format("LL")}
                            </span>
                            <Badge
                              bg={
                                historyItem.payment_status === "Failed"
                                  ? "danger"
                                  : historyItem.payment_status === "Success"
                                    ? "success"
                                    : "secondary"
                              }
                            >
                              {historyItem.payment_status}
                            </Badge>
                          </div>
                        </ListGroup.Item>
                      ))
                    ) : (
                      <ListGroup.Item>No subscription history available.</ListGroup.Item>
                    )}
                  </ListGroup>
                </Card.Body>
              </Card>
              
            )}

            {/* Upcoming Meetings Section */}
            <Card className="shadow-sm">
              <Card.Body>
                {purchase_mode === "Paid" && (
                  <div className="d-flex justify-content-between align-items-center mb-3">
                      <div className="d-flex gap-2">
                        <strong>Course price :</strong>  {amount}
                      </div>
                      <div className="d-flex gap-2">
                        <strong>Purchased At :</strong>  {moment(subsCourseDetail?.createdAt).format("LL")}
                      </div>
                  </div>
                )}

                <Card.Title className="text-dark">Upcoming Meetings</Card.Title>
                <hr />
                {Array.isArray(upcoming_meetings) && upcoming_meetings.length > 0 ? (
                  upcoming_meetings.map((meeting, i) => (
                    <Card key={meeting._id} className={`my-0 ${upcoming_meetings?.length > (i + 1) && 'border-bottom'}`} style={{boxShadow: 'none', border: 'none', borderRadius: 0}}>
                      <Card.Body>
                        <Card.Title>{meeting.meeting_agenda}</Card.Title>
                        <Card.Text className="d-flex gap-3 justify-content-between flex-wrap">
                          <div><strong>Date:</strong> {moment(meeting.meetingAt).format("LL")}</div>
                          <div><strong>Time:</strong> {moment(meeting.meetingAt).format("LT")}</div>
                          <div><strong>Duration:</strong> {meeting.duration} minutes</div>                          
                        </Card.Text>
                        <div className="d-flex justify-content-end">
                          <a
                            href={meeting.meeting_join_url}
                            className="btn btn-primary btn-sm text-light"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Join Meeting
                          </a>
                        </div>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <p>No upcoming meetings available.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubscriptionDetail;