import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppContext } from "../contextApi/AppContext";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const TeacherPage = () => {
  const { getTeacherPage, subsList } = useAppContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const product_id = searchParams.get("_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (product_id) {
          await getTeacherPage(product_id);
        } else {
          toast.warning('Product Id not found');
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_id]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Course Plan Subscriptions</h1>
      <table className="table table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            <th scope="col">Plan</th>
            <th scope="col">Price</th>
            <th scope="col">Date of Purchase</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(subsList) && subsList.map((user, index) => (
            <tr key={user._id}>
              <th scope="row">{index + 1}</th>
              <td>{user.user_name}</td>
              <td>{user.purchase_mode}</td>
              <td>{user.amount}</td>
              <td>{formatDate(user.current_plan_start)}</td>
              <td>
                <span className={`badge ${user.status === "Active" ? "bg-success text-white" : "bg-danger text-white"}`}>
                  {user.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeacherPage;