import React from 'react'
import "./BannerAnimation.css";
import dzitalch3 from '../../assets/dzitalch3.png'

const DzitalChad = () => {


  const handleClick = () => {
    window.open("https://dzital.ch", "_blank");
  };
  return (
 

    <div className="container my-5 p-5" style={{ backgroundColor: '#b9efd5', borderRadius: '10px' }}>
    <div className="row align-items-center">
      <div className="col-md-7">
        <h5 className="fw-bold mb-4">Dzital <span style={{ fontWeight: 'normal' }}>Website maker</span></h5>
        <h1 className="fw-bold mb-3">Make an incredible Website <span style={{ color: '#055036' }}>in few minutes</span></h1>
        <p className="mb-4">Pre-designed by top talent. Just add your touch.</p>
        <button className="btn btn-dark btn-lg" onClick={handleClick}>Try Dzital.ch </button>
      </div>
      <div className="col-md-5">
      <img src={dzitalch3} alt="Logo Example 1" className="me-2" style={{maxWidth:'100%', borderRadius: '10px' }} />
      </div>
    </div>
  </div>
    
  )
}

export default DzitalChad