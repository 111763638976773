import React, { useEffect, useRef, useState } from "react";
import Ads from "../../components/comon/Ads";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import AccountSideBar from "./AccountSideBar";
import BreadCrum from "../../components/comon/BreadCrum";
import { useLoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { ImCross } from "react-icons/im";
import Select from "react-select";
import { City, Country, State } from "country-state-city";
import { MdArrowOutward } from "react-icons/md";
import cover_image from "../../assets/assets/images/register-bg.jpg";
import userIcon from "../../assets/assets/images/profile_image.jpg";
import { FaEdit } from "react-icons/fa";

const Account = () => {
  const {
    userData,
    setUserData,
    updateNewProfile,
    connectionList,
    getConnectionsList,
    receivedList,
    getreceivedList,
  } = useAppContext();
  // console.log(userData, "userData");

  useEffect(() => {
    connectionList();
    receivedList();
    // eslint-disable-next-line
  }, []);

  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState();
  const [address1, setAddress1] = useState();
  const searchBoxRef = useRef(null);

  const [isExpVisible, setIsExpVisible] = useState(false);
  const [isExpIndex, setIsExpIndex] = useState(-1);
  const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);

  const [isEduVisible, setIsEduVisible] = useState(false);
  const [isEduIndex, setIsEduIndex] = useState(-1);

  const [isSkillVisible, setIsSkillVisible] = useState(false);
  const [isSkillIndex, setIsSkillIndex] = useState(-1);

  const [isEditing, setIsEditing] = useState(false);

  const [isImageLoad, setIsImageLoad] = useState(false);
  const [isCoverLoad, setIsCoverLoad] = useState(false);

  const [aboutData, setaboutData] = useState({
    name: userData.name,
    about: userData.about,
    image: userData.image,
    address: userData.address,
    cover_photo: userData.cover_photo,
    city: userData.city,
    country: userData.country,
    state: userData.state,
    mobile: userData.mobile,
    pincode: userData.pincode,
  });

  const [isCompanyEditing, setCompanyIsEditing] = useState(false);
  const [companyDetails, setCompanyDetails] = useState({
    company_name: userData.company_name,
    company_address: userData.company_address,
    company_contact_number: userData.company_contact_number,
    company_email: userData.company_email,
    company_url: userData.company_url,
  });

  const handleCompanyEditClick = () => {
    setCompanyIsEditing(!isCompanyEditing);
  };

  const handleCompanyInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...companyDetails, [name]: value });
  };

  const [isBankEditing, setBankIsEditing] = useState(false);
  const [isAddressEditing, setAddresIsEditing] = useState(false);
  const [bankdetails, setBankDetails] = useState({
    bank_name: userData.bank_name,
    account_number: userData.account_number,
    beneficiary_name: userData.beneficiary_name,
    ifsc_code: userData.ifsc_code,
  });

  const handleBankEditClick = () => {
    setBankIsEditing(!isBankEditing);
  };

  const handleAddressEditClick = () => {
    setAddresIsEditing(!isAddressEditing);
  };

  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankdetails, [name]: value });
  };

  // console.log('user', userData.social_id  )
  const { address, education, experience, skills } = userData || {};

  let countryData = Country.getAllCountries();

  const existingCountryName = userData?.country;
  const existingStateName = userData?.state;
  const existingCityName = userData?.city;

  // Finding the existing country object in countryData
  const existingCountry = countryData.find(
    (country) => country.name === existingCountryName
  );

  const existingState = countryData.find(
    (country) => country.name === existingStateName
  );

  const existingCity = countryData.find(
    (country) => country.name === existingCityName
  );

  // State hooks for managing country, state, and city data
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [country, setCountry] = useState(existingCountry || countryData[0]);
  const [state, setState] = useState(existingState || null);
  const [city, setCity] = useState(existingCity || null);

  // Effect to update state data when country changes
  useEffect(() => {
    setStateData(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  // Effect to update city data when state changes
  useEffect(() => {
    setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
  }, [state]);

  // Effect to set the initial state when state data changes
  useEffect(() => {
    // stateData && setState(stateData[0]);
    if (stateData) {
      if (userData && userData.state) {
        const data = stateData.find((item) => item.name === userData.state);
        setState(data);
      } else {
        setState(stateData[0]);
      }
    }
  }, [stateData, userData]);

  // Effect to set the initial city when city data changes
  useEffect(() => {
    if (cityData) {
      if (userData && userData.city) {
        const data = cityData.find((item) => item.name === userData.city);
        setCity(data);
      } else {
        setCity(cityData[0]);
      }
    }
  }, [cityData, userData]);

  // Handler for country change
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = countryData.find(
      (country) => country.isoCode === selectedOption.value
    );
    setCountry(selectedCountry);
  };

  // Handler for state change
  const handleStateChange = (selectedOption) => {
    const selectedState = stateData.find(
      (state) => state.isoCode === selectedOption.value
    );
    setState(selectedState);
  };

  // Handler for city change
  const handleCityChange = (selectedOption) => {
    const selectedCity = cityData.find(
      (city) => city.name === selectedOption.value
    );
    setCity(selectedCity);
  };

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setUserData({ ...userData, ...aboutData });
    const dataToSend = {
      country: country.name || userData.country,
      state: state.name || userData.state,
      city: city.name || userData.city,
      address: aboutData.address,
      mobile: aboutData.mobile,
      pincode: aboutData.pincode,
    };
    updateNewProfile(dataToSend);
    // console.log(country.name + state.name + city.name, " address");
  };

  const handleUpdateProfile = () => {
    if (center?.lat && center?.lng && address1) {
      const dataToSend = {
        lat: center.lat,
        lng: center.lng,
        address: address1,
      };
      updateNewProfile(dataToSend);
    }
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const newCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const formatted_address = place.formatted_address;
      setAddress1(formatted_address);
      setCenter(newCenter);
      setPlaces(places);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setaboutData({ ...aboutData, [name]: value });
  };

  const handleFileChange = async (e) => {
    try {
      const { name, files } = e.target;
      const file = files[0];
      e.preventDefault();
      if (name === "image") {
        setIsImageLoad(true);
      } else {
        setIsCoverLoad(true);
      }
      const dataToSend = new FormData();
      dataToSend.append(name, file);
      await updateNewProfile(dataToSend);
      // console.log("Updated user data:", { ...userData, ...aboutData });
    } catch (e) {
    } finally {
      setIsImageLoad(false);
      setIsCoverLoad(false);
    }
  };

  const handleAboutSubmit = (e) => {
    e.preventDefault();
    const dataToSend = new FormData();
    dataToSend.append("name", aboutData.name);
    dataToSend.append("about", aboutData.about);
    if (aboutData.image) {
      dataToSend.append("image", aboutData.image);
    }
    if (aboutData.cover_photo) {
      dataToSend.append("cover_photo", aboutData.cover_photo);
    }

    // Pass the FormData object to the context function
    updateNewProfile(dataToSend);
    // console.log("Updated user data:", { ...userData, ...aboutData });
    setIsEditing(false);
  };

  const handleCompanySubmit = (e) => {
    e.preventDefault();
    setUserData({ ...userData, ...companyDetails });
    const dataToSend = {
      company_name: companyDetails.company_name,
      company_address: companyDetails.company_address,
      company_contact_number: companyDetails.company_contact_number,
      company_email: companyDetails.company_email,
      company_url: companyDetails.company_url,
    };
    updateNewProfile(dataToSend);
    // console.log("Updated company details:", companyDetails);
    setCompanyIsEditing(false);
  };

  const handlebankSubmit = (e) => {
    e.preventDefault();
    setUserData({ ...userData, ...bankdetails });
    const dataToSend = {
      bank_name: bankdetails.bank_name,
      account_number: bankdetails.account_number,
      beneficiary_name: bankdetails.beneficiary_name,
      ifsc_code: bankdetails.ifsc_code,
    };
    updateNewProfile(dataToSend);
    // console.log("Updated company details:", bankdetails);
    setCompanyIsEditing(false);
  };

  const handleEditChange = (e, index) => {
    const exp = [...userData.experience];
    exp[index] = { ...exp[index], [e.target.name]: e.target.value };

    // Check if the currently working checkbox was modified
    if (e.target.name === "currently_working") {
      const isWorking = e.target.checked;
      setIsCurrentlyWorking(isWorking);
      if (isWorking) {
        exp[index].present = true;
        delete exp[index].end_date; // Remove end_date if currently working
      } else {
        delete exp[index].present; // Remove present if not currently working
      }
    }

    setUserData({ ...userData, experience: exp });
  };

  const handleCheckboxChange = (e) => {
    const isWorking = e.target.checked;
    setIsCurrentlyWorking(isWorking);

    if (isWorking) {
      // Ensure end_date is cleared in the form if currently working is checked
      const exp = [...userData.experience];
      exp[isExpIndex].present = true;
      delete exp[isExpIndex].end_date;
      setUserData({ ...userData, experience: exp });
    }
  };

  const handleEduChange = (e, index) => {
    const edu = [...userData.education];
    edu[index] = { ...edu[index], [e.target.name]: e.target.value };
    setUserData({ ...userData, education: edu });
  };

  const handleSkillChange = (e, index) => {
    const skills1 = [...userData.skills];
    skills1[index] = e.target.value;
    setUserData({ ...userData, skills: skills1 });
  };

  const onExpCreate = (e, type) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries()); // Convert FormData to a plain object
    const newData = { ...userData };

    if (isCurrentlyWorking) {
      data.present = true;
      delete data.end_date; // Remove end_date if currently working
    }

    if (type === "experience") {
      newData.experience = [...newData.experience, data];
      // console.log(newData, "exp data");

      const formDataToSend = new FormData();
      newData.experience.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formDataToSend.append(`experience[${index}][${key}]`, item[key]);
        });
      });
      updateNewProfile(formDataToSend);
      setIsExpVisible(false);
      setIsCurrentlyWorking(false);
    }

    if (type === "education") {
      newData.education = [...newData.education, data];
      // console.log(newData, "edu data");

      // Prepare FormData for API call
      const formDataToSend = new FormData();
      newData.education.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formDataToSend.append(`education[${index}][${key}]`, item[key]);
        });
      });
      updateNewProfile(formDataToSend); // Pass FormData to context function
      setIsEduVisible(false);
    }

    if (type === "skills") {
      // Ensure data.skill is a string and split by commas to form an array
      const skills = data.skill.split(",").map((skill) => skill.trim());
      newData.skills = [...newData.skills, ...skills];
      // console.log(newData, "skill data");

      // Prepare FormData for API call
      const formDataToSend = new FormData();
      newData.skills.forEach((skill) => {
        formDataToSend.append("skills[]", skill);
      });
      updateNewProfile(formDataToSend); // Pass FormData to context function
      setIsSkillVisible(false);
    }
  };

  const onProfileUpdate = async (e, type) => {
    e.preventDefault();
    let dataToSend;
    const formData = new FormData();

    if (type === "experience") {
      dataToSend = userData.experience;
      // console.log(dataToSend, "experience");
      setIsExpIndex(-1);

      dataToSend.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`experience[${index}][${key}]`, item[key]);
        });
      });
    }

    if (type === "education") {
      dataToSend = userData.education;
      // console.log(dataToSend, "education");
      setIsEduIndex(-1);

      dataToSend.forEach((item, index) => {
        Object.keys(item).forEach((key) => {
          formData.append(`education[${index}][${key}]`, item[key]);
        });
      });
    }

    if (type === "skills") {
      dataToSend = userData.skills;
      // console.log(dataToSend, "skills");
      setIsSkillIndex(-1);

      dataToSend.forEach((skill, index) => {
        formData.append(`skills[${index}]`, skill);
      });
    }

    // Append the type of data being sent
    formData.append("type", type);

    updateNewProfile(formData);
    // context
  };

  return (
    <>
      <BreadCrum param={"Accounts"} />

      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* left part start */}
            <div className="col-md-3">
              {/* sidebar start */}
              <AccountSideBar
                getConnectionsList={getConnectionsList}
                getreceivedList={getreceivedList}
              />
              {/* sidebar end */}
            </div>
            {/* left part end */}
            {/* right part start */}
            <div className="col-md-7">
              {/************ new work start here ************/}
              {/* card start */}
              <div className="card mt-0 profileBx">
                <div className="profileBgBx position-relative">
                  <img
                    src={aboutData?.cover_photo || cover_image}
                    className="card-img-top"
                    alt="Profile Background"
                  />
                  <div
                    className="profile_edit_btn"
                    style={{ top: "10px", right: 10 }}
                  >
                    <input
                      type="file"
                      className="form-control"
                      id="cover_photo"
                      name="cover_photo"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      disabled={isCoverLoad}
                    />
                    <label
                      htmlFor="cover_photo"
                      style={{
                        cursor: "pointer",
                        transform: "translate(1px, -2px)",
                      }}
                    >
                      {isCoverLoad ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <FaEdit />
                      )}
                    </label>
                  </div>
                </div>
                {/* User profile edit */}
                <div className="card-body">
                  <div
                    className="userBx position-relative"
                    style={{ overflow: "unset" }}
                  >
                    <img
                      src={aboutData?.image || userIcon}
                      alt="User Profile"
                    />
                    <div
                      className="profile_edit_btn"
                      style={{ bottom: "18px", right: 0 }}
                    >
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="image"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        disabled={isImageLoad}
                      />
                      <label
                        htmlFor="image"
                        style={{
                          cursor: "pointer",
                          transform: "translate(1px, -2px)",
                        }}
                      >
                        {isImageLoad ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          <FaEdit />
                        )}
                      </label>
                    </div>
                  </div>
                  <div className="row justify-content-evenly">
                    <div className="col">
                      <h5 className="card-title">{aboutData?.name}</h5>
                    </div>
                    <div className="col" align="right">
                      <i
                        className={`fa fas fa-${
                          isEditing ? "xmark" : "edit"
                        } fs-5`}
                        style={{ cursor: "pointer" }}
                        onClick={handleEditClick}
                      ></i>
                    </div>
                  </div>
                  {isEditing ? (
                    <form onSubmit={handleAboutSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={aboutData.name}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="about" className="form-label">
                          About
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="about"
                          name="about"
                          value={aboutData.about}
                          onChange={handleInputChange}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary text-white"
                      >
                        Save
                      </button>
                    </form>
                  ) : (
                    <>
                      <p className="card-text mb-0">{aboutData.about}</p>
                      <p className="text-muted">
                        <i className="fa fa-map-marker"></i> {aboutData.city}{" "}
                        {""} {aboutData.country}
                      </p>
                      <div className="">
                        <Link
                          to="/user-connectionlist"
                          style={{ textDecoration: "none" }}
                        >
                          <strong>
                            {" "}
                            {getConnectionsList?.total_connections} {""}{" "}
                            Connections <MdArrowOutward />
                          </strong>
                        </Link>
                        <Link
                          to="/user-invitionlist"
                          style={{ textDecoration: "none" }}
                        >
                          {" "}
                          <strong>
                            {" "}
                            {getreceivedList?.total_pending_requests} {""}{" "}
                            invitations <MdArrowOutward />
                          </strong>
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {/* card end */}
              <div className=" mt-0">
                <div className="card mt-0">
                  <div className="card-body">
                    <div className="row justify-content-evenly mb-2">
                      <div className="col">
                        <h4>Address</h4>
                      </div>
                      <div className="col" align="right">
                        <i
                          className="fa fas fa-edit fs-5"
                          onClick={handleAddressEditClick}
                        ></i>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row mb-3">
                        <div className="col-md-4">
                          <label className="form-label">
                            Country ({aboutData?.country}){" "}
                          </label>
                          <Select
                            options={countryData.map((country) => ({
                              value: country.isoCode,
                              label: country.name,
                            }))}
                            onChange={handleCountryChange}
                            placeholder="Select Country"
                            isDisabled={!isAddressEditing}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="form-label">
                            State ({aboutData?.state})
                          </label>
                          <Select
                            options={stateData.map((state) => ({
                              value: state.isoCode,
                              label: state.name,
                            }))}
                            onChange={handleStateChange}
                            // value={
                            //   state
                            //     ? { value: state.isoCode, label: state.name }
                            //     : null
                            // }
                            placeholder="Select State"
                            isDisabled={!isAddressEditing}
                          />
                        </div>
                        <div className="col-md-4">
                          <label className="form-label">
                            City ({aboutData?.city})
                          </label>
                          <Select
                            options={cityData.map((city) => ({
                              value: city.name,
                              label: city.name,
                            }))}
                            onChange={handleCityChange}
                            // value={
                            //   city ? { value: city.name, label: city.name } : null
                            // }
                            placeholder="Select City"
                            isDisabled={!isAddressEditing}
                          />
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="mt-2 col-md-6">
                          <label htmlFor="name" className="form-label">
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            value={aboutData.mobile}
                            onChange={handleInputChange}
                            disabled={!isAddressEditing}
                          />
                        </div>
                        <div className="mt-2 col-md-6">
                          <label htmlFor="name" className="form-label">
                            Pin Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="pincode"
                            value={aboutData.pincode}
                            onChange={handleInputChange}
                            disabled={!isAddressEditing}
                          />
                        </div>
                        <div className="mt-2 col-md-12">
                          <label htmlFor="name" className="form-label">
                            Full Address
                          </label>
                          <textarea
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            value={aboutData.address}
                            onChange={handleInputChange}
                            disabled={!isAddressEditing}
                          />
                        </div>
                        <div className="col-md-3 mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary text-white"
                            disabled={!isAddressEditing}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* card start */}

              {/* card end */}

              {/* card start */}
              <div className="card mt-0 profileBx">
                <div className="card-body">
                  <div className="row justify-content-evenly mb-2">
                    <div className="col">
                      <h4>Experience</h4>
                    </div>
                    <div className="col" align="right">
                      <i
                        className={`fa fas fa-${
                          isExpVisible ? "xmark" : "plus"
                        } fs-5`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsExpVisible(!isExpVisible);
                          setIsExpIndex(-1);
                        }}
                      ></i>
                    </div>
                  </div>
                  {experience?.map((exp, index) => (
                    <div className="d-flex align-items-baseline" key={index}>
                      {isExpIndex === index ? (
                        <form
                          className="form-group px-2 mb-3"
                          style={{ width: "100%" }}
                          onSubmit={(e) => onProfileUpdate(e, "experience")}
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="company"
                            value={exp.company}
                            onChange={(e) => handleEditChange(e, index)}
                            placeholder="Company Name"
                          />
                          <div className="d-flex gap-2 align-items-center mt-2">
                            <label className="form-label">From </label>
                            <input
                              type="date"
                              name="start_date"
                              value={exp.start_date}
                              onChange={(e) => handleEditChange(e, index)}
                              className="form-control"
                            />
                            <label className="form-label">To </label>
                            <input
                              type="date"
                              name="end_date"
                              value={exp.end_date}
                              onChange={(e) => handleEditChange(e, index)}
                              className="form-control"
                              disabled={isCurrentlyWorking}
                              max={new Date().toISOString().split("T")[0]} // Prevent future dates
                            />
                            <div>
                              <input
                                type="checkbox"
                                name="currently_working"
                                checked={isCurrentlyWorking}
                                onChange={(e) => handleEditChange(e, index)}
                              />
                              <label className="form-label ms-1">
                                Currently Working
                              </label>
                            </div>
                            <div className="d-flex">
                              <button
                                type="submit"
                                className="btn btn-primary text-white mx-2"
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                  setIsExpIndex(-1);
                                  setIsCurrentlyWorking(false);
                                }}
                              >
                                <i className="fa fas fa-xmark" />
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <di v className="p-2">
                          <h5 className="mb-0">{exp.company}</h5>
                          <p>{`${new Date(
                            exp.start_date
                          ).toLocaleDateString()} - ${
                            exp.present
                              ? "Present"
                              : new Date(exp.end_date).toLocaleDateString()
                          }`}</p>
                        </di>
                      )}
                      {isExpIndex !== index && (
                        <i
                          className="fa fas fa-edit fs-5"
                          onClick={() => {
                            setIsExpIndex(index);
                            setIsExpVisible(false);
                            setIsCurrentlyWorking(!!exp.present);
                          }}
                        ></i>
                      )}
                    </div>
                  ))}
                  {isExpVisible && (
                    <div>
                      <form
                        className="form-group"
                        onSubmit={(e) => onExpCreate(e, "experience")}
                      >
                        <label className="form-label">Company Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="company"
                          placeholder="Company Name"
                        />
                        <div className="d-flex gap-2 align-items-center mt-3">
                          <label className="form-label">From </label>
                          <input
                            type="date"
                            name="start_date"
                            className="form-control"
                          />
                          <label className="form-label">To </label>
                          <input
                            type="date"
                            name="end_date"
                            className="form-control"
                            disabled={isCurrentlyWorking}
                            max={new Date().toISOString().split("T")[0]} // Prevent future dates
                          />
                          <div>
                            <input
                              type="checkbox"
                              name="currently_working"
                              checked={isCurrentlyWorking}
                              onChange={handleCheckboxChange}
                            />
                            <label className="form-label ms-1">
                              Currently Working
                            </label>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary text-white"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {/* card end */}

              {/* card start */}
              <div className="card mt-0 profileBx">
                <div className="card-body">
                  <div className="row justify-content-evenly mb-2">
                    <div className="col">
                      <h4>Education</h4>
                    </div>
                    <div className="col" align="right">
                      <i
                        className={`fa fas fa-${
                          isEduVisible ? "xmark" : "plus"
                        } fs-5`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsEduVisible(!isEduVisible);
                          setIsEduIndex(-1);
                        }}
                      ></i>
                    </div>
                  </div>
                  {education?.map((edu, index) => (
                    <>
                      <div className="d-flex align-items-baseline" key={index}>
                        {/* <div>
                          <img
                            src={educationLogo}
                            className="card-img-top rounded"
                            alt=""
                            style={{ width: "64px" }}
                          />
                        </div> */}
                        {isEduIndex === index ? (
                          <>
                            <form
                              className="form-group px-2 mb-3"
                              onSubmit={(e) => onProfileUpdate(e, "education")}
                              style={{ width: "100%" }}
                            >
                              <input
                                type="text"
                                className="form-control"
                                name="institute"
                                value={edu.institute}
                                onChange={(e) => handleEduChange(e, index)}
                                placeholder="institute Name"
                              />
                              <div className="d-flex gap-2 align-items-center mt-2">
                                <label className="form-label">From </label>
                                <input
                                  type="date"
                                  name="start_date"
                                  onChange={(e) => handleEduChange(e, index)}
                                  className="form-control"
                                />
                                <label className="form-label">To </label>
                                <input
                                  type="date"
                                  name="end_date"
                                  onChange={(e) => handleEduChange(e, index)}
                                  className="form-control"
                                />
                                <div className="d-flex">
                                  <button
                                    type="submit"
                                    className="btn btn-primary text-white mx-2"
                                  >
                                    Save
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                      setIsEduIndex(-1);
                                    }}
                                  >
                                    <i className="fa fas fa-xmark" />
                                  </button>
                                </div>
                              </div>
                            </form>
                          </>
                        ) : (
                          <>
                            <div className="p-2">
                              <h5 className="mb-0">{edu.institute}</h5>
                              <p>{`${new Date(
                                edu.start_date
                              ).toLocaleDateString()} - ${new Date(
                                edu.end_date
                              ).toLocaleDateString()}`}</p>
                            </div>
                          </>
                        )}
                        {isEduIndex !== index && (
                          <i
                            className="fa fas fa-edit fs-5"
                            onClick={() => {
                              setIsEduIndex(index);
                              setIsEduVisible(false);
                            }}
                          ></i>
                        )}
                      </div>
                    </>
                  ))}

                  {isEduVisible && (
                    <div>
                      <form
                        className="form-group"
                        onSubmit={(e) => onExpCreate(e, "education")}
                      >
                        <label className="form-label">Institute Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="institute"
                          placeholder="institute"
                        />
                        <div className="d-flex gap-2 align-items-center mt-3">
                          <label className="form-label">From </label>
                          <input
                            type="date"
                            name="start_date"
                            className="form-control"
                          />
                          <label className="form-label">To </label>
                          <input
                            type="date"
                            name="end_date"
                            className="form-control"
                          />
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary text-white"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {/* card end */}

              {/* card start */}
              <div className="card mt-0 profileBx">
                <div className="card-body">
                  <div className="row justify-content-evenly mb-2">
                    <div className="col">
                      <h4>Skills</h4>
                    </div>
                    <div className="col" align="right">
                      <i
                        className={`fa fas fa-${
                          isSkillVisible ? "xmark" : "plus"
                        } fs-5`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setIsSkillVisible(!isSkillVisible);
                          setIsSkillIndex(-1);
                        }}
                      ></i>
                    </div>
                  </div>
                  {skills?.map((skill, index) => (
                    <>
                      {isSkillIndex === index ? (
                        <div className="">
                          <div
                            className="card d-flex align-items-center justify-content-between"
                            style={{ padding: "16px", width: "100%" }}
                          >
                            <form
                              className="form-group"
                              style={{ width: "100%" }}
                              onSubmit={(e) => onProfileUpdate(e, "skills")}
                            >
                              <input
                                type="text"
                                className="form-control"
                                name="skills"
                                value={skill}
                                onChange={(e) => handleSkillChange(e, index)}
                                placeholder="skills"
                              />
                              <div className="mt-2 float-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary text-white mx-2 btn-sm"
                                >
                                  Save
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    setIsSkillIndex(-1);
                                  }}
                                >
                                  <i className="fa fas fa-xmark" />
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div
                            className="card d-flex align-items-center justify-content-between my-3"
                            style={{
                              flexDirection: "row",
                              padding: "10px 16px",
                            }}
                          >
                            <h5
                              className="mb-0"
                              style={{ marginRight: "10px", fontSize: "18px" }}
                            >
                              {skill}
                            </h5>
                            {isExpIndex !== index && (
                              <i
                                className="fa fas fa-edit fs-5"
                                onClick={() => {
                                  setIsSkillIndex(index);
                                  setIsSkillVisible(false);
                                }}
                              ></i>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  {isSkillVisible && (
                    <div>
                      <form
                        className="form-group"
                        onSubmit={(e) => onExpCreate(e, "skills")}
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="skill"
                          placeholder="Skill"
                        />
                        <div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-sm mt-2 float-end text-white"
                          >
                            Save
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {/* card end */}

              {/* card start */}
              <div className="card mt-0 profileBx">
                <div className="card-body">
                  <div className="row justify-content-evenly mb-2">
                    <div className="col">
                      <h4>Company Details</h4>
                    </div>
                    <div className="col" align="right">
                      {isCompanyEditing ? (
                        <ImCross
                          style={{
                            marginLeft: "9px",
                            fontSize: "1rem",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setCompanyIsEditing(false);
                          }}
                        />
                      ) : (
                        <i
                          className="fa fas fa-edit fs-5"
                          onClick={handleCompanyEditClick}
                          style={{ cursor: "pointer" }}
                        ></i>
                      )}
                    </div>
                  </div>
                  {isCompanyEditing ? (
                    <form onSubmit={handleCompanySubmit}>
                      <div className="mb-3">
                        <label htmlFor="company_name" className="form-label">
                          Company Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_name"
                          name="company_name"
                          value={companyDetails.company_name}
                          onChange={handleCompanyInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="company_address" className="form-label">
                          Company Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_address"
                          name="company_address"
                          value={companyDetails.company_address}
                          onChange={handleCompanyInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="company_contact_number"
                          className="form-label"
                        >
                          Contact Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_contact_number"
                          name="company_contact_number"
                          value={companyDetails.company_contact_number}
                          onChange={handleCompanyInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="company_email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="company_email"
                          name="company_email"
                          value={companyDetails.company_email}
                          onChange={handleCompanyInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="company_email" className="form-label">
                          Company site url
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_url"
                          name="company_url"
                          value={companyDetails.company_url}
                          onChange={handleCompanyInputChange}
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary text-white"
                      >
                        Save
                      </button>
                    </form>
                  ) : (
                    <div className="d-flex">
                      {/* <div>
                        <img
                          src={educationLogo}
                          className="card-img-top rounded"
                          alt="Company Logo"
                          style={{ width: "64px" }}
                        />
                      </div> */}
                      <div className="p-2 pt-0">
                        <h5 className="mb-1">{companyDetails.company_name}</h5>
                        {companyDetails.company_address && (
                          <p className="mb-1">
                            <i className="fa fa-map-marker"></i>{" "}
                            {companyDetails.company_address}
                          </p>
                        )}
                        {companyDetails.company_contact_number && (
                          <p className="mb-1">
                            <i className="fa fa-phone"></i>{" "}
                            {companyDetails.company_contact_number}
                          </p>
                        )}
                        {companyDetails.company_email && (
                          <p className="mb-1">
                            <i className="fa fa-envelope"></i>{" "}
                            {companyDetails.company_email}
                          </p>
                        )}
                        {companyDetails.company_url && (
                          <p className="mb-1">
                            <i className="fa fa-globe"></i>{" "}
                            {companyDetails.company_url}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="card mt-0 profileBx">
                <div className="card-body">
                  <div className="row justify-content-evenly mb-2">
                    <div className="col">
                      <h4>Bank Details</h4>
                    </div>
                    <div className="col" align="right">
                      <i
                        className="fa fas fa-edit fs-5"
                        onClick={handleBankEditClick}
                      ></i>
                    </div>
                  </div>

                  <form onSubmit={handlebankSubmit}>
                    <div className="mb-3">
                      <label htmlFor="company_name" className="form-label">
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="bank_name"
                        name="bank_name"
                        value={bankdetails.bank_name}
                        onChange={handleBankInputChange}
                        disabled={!isBankEditing}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="company_address" className="form-label">
                        IBAN
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="account_number"
                        name="account_number"
                        value={bankdetails.account_number}
                        onChange={handleBankInputChange}
                        disabled={!isBankEditing}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="company_contact_number"
                        className="form-label"
                      >
                        BIC
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="beneficiary_name"
                        name="beneficiary_name"
                        value={bankdetails.beneficiary_name}
                        onChange={handleBankInputChange}
                        disabled={!isBankEditing}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="company_email" className="form-label">
                        Bank Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="ifsc_code"
                        name="ifsc_code"
                        value={bankdetails.ifsc_code}
                        onChange={handleBankInputChange}
                        disabled={!isBankEditing}
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary text-white"
                      disabled={!isBankEditing}
                    >
                      Save
                    </button>
                  </form>
                </div>
              </div>

              {/* card end */}
              {/************ new work end here ************/}

              <div className="card mt-0 profileBx">
                <div className="card-body">
                  <div className="card-body">
                    <div className="row justify-content-evenly mb-2">
                      <div className="col">
                        <h4>Full Address : {address}</h4>
                      </div>
                    </div>
                    <StandaloneSearchBox
                      onLoad={(ref) => (searchBoxRef.current = ref)}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        type="text"
                        placeholder="Search for places"
                        className="form-control"
                      />
                    </StandaloneSearchBox>
                  </div>
                </div>
                <button
                  className="btn  mt-2"
                  style={{ background: "var(--theme-color)" }}
                  onClick={handleUpdateProfile}
                >
                  Update
                </button>
              </div>
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default Account;
