import React from "react";
import { AgoraVideoPlayer } from "agora-rtc-react";
import { Grid, Box } from "@mui/material";
import Controls from "./Controls";

const WebDashboard = (props) => {
  const { users, tracks, setStart } = props;

  return (
    <Box sx={{ maxWidth: "100%", height: "80vh", position: "relative", backgroundColor: "black" }}>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {/* Display remote user video in fullscreen */}
        {users && users[0] && users[0].videoTrack && (
          <Grid item xs={12} sx={{ height: "100%" }}>
            <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
              <AgoraVideoPlayer
                className="vid"
                videoTrack={users[0].videoTrack}
                key={users[0].uid}
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "black",
                }}
              />
            </Box>
          </Grid>
        )}

        {/* Overlay your own video in a smaller window */}
        {tracks && tracks[1] && (
          <Box
            sx={{
              position: "absolute",
              bottom: 16,
              right: 16,
              width: "150px",
              height: "150px",
              border: "2px solid white",
              backgroundColor: "black",
              zIndex: 10,
            }}
          >
            <AgoraVideoPlayer
              className="vid"
              videoTrack={tracks[1]}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        )}
      </Grid>

      {/* Controls Overlay */}
      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 20,
        }}
      >
        <Controls tracks={tracks} setStart={setStart} />
      </Box>
    </Box>
  );
};

export default WebDashboard;
