import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useAppContext } from "../contextApi/AppContext";
import { Link } from "react-router-dom";

const ConnectionList = () => {
  const {
    connectionList,
    getConnectionsList,
    listLoading,
    ConnectionRequestRemove,
  } = useAppContext();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const dataToSend = {
      keyword_search: searchTerm,
    };
    connectionList(dataToSend);
    // eslint-disable-next-line
  }, [searchTerm]);

  const handleRemove = (connectionId) => {
    const dataToSend = {
      id: connectionId,
    };
    ConnectionRequestRemove(dataToSend);
  };

  return (
    <div>
      <Container sx={{ p: 3 }}>
        <Paper elevation={3}>
          <Box p={2}>
            <Typography variant="h6">
              {getConnectionsList?.data?.length} Connections
            </Typography>

            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by name"
              sx={{ my: 2 }}
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />

            {listLoading.connectionLists ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                my={2}
              >
                <CircularProgress />
              </Box>
            ) : (
              getConnectionsList?.data?.map((connection, index) => (
                <React.Fragment key={index}>
                  <Box display="flex" alignItems="center" p={2}>
                    <Avatar
                      alt={connection.name}
                      src={connection.user_image || connection.avatar}
                      sx={{ width: 56, height: 56 }}
                    />
                    <Box ml={2} flexGrow={1}>
                      <Typography variant="subtitle1">
                        {connection.user_name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {connection.user_address}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Connected on :{" "}
                        {new Date(connection.createdAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleRemove(connection._id)}
                      sx={{ marginRight: "10px" }}
                    >
                      Remove
                    </Button>
                    <Link to={`/message?peerUser=${connection.senderId}`}>
                      <Button variant="outlined">Message</Button>
                    </Link>
                  </Box>
                  {index < getConnectionsList.data.length - 1 && <Divider />}
                </React.Fragment>
              ))
            )}
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default ConnectionList;
