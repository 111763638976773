import axios from "axios";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../contextApi/AppContext";
import Cookies from "js-cookie"

export const StripeResponse = () => {
  const { baseURL } = useAppContext();
  const location = useLocation();
  const {id} = useParams()
  const navigate = useNavigate();
  const token = Cookies.get("token1")

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const session = useMemo(() => params.get("session_id"), [params]);
  const type = useMemo(() => params.get("type"), [params]);

  const verifyCheckout = async () => {
    try {
      // console.log('Verifying payment with session:', session);
      const { data } = await axios.post(
        `${baseURL}/api/checkout/session/verify`,
        { id: session, userId: id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );

      if (data.status === 200) {
        toast.success(data.message);
        navigate(`/payment-success?order_id=${data?.data?.order_id}`, {replace: true})
      } else {
        toast.error(data.message);
        navigate("/", {replace: true});
      }
    } catch (error) {
      navigate("/", {replace: true});
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const verifyPayment = async () => {
    try {
      // console.log('Verif/ying payment with session:', session);
      const { data } = await axios.post(
        `${baseURL}/api/subscription/session/verify`,
        { id: session },
        {
          headers: {
            token: token,
          },
        }
      );

      if (data.status === 200) {
        toast.success(data.message);
        navigate(`/learn/welcome?courseId=${id}`, {replace: true})
      } else {
        toast.error(data.message);
        navigate("/", {replace: true});
      }
    } catch (error) {
      navigate("/", {replace: true});
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const verifyMembership = async () => {
    try {
      // console.log('Verifying payment with session:', session);
      const { data } = await axios.post(
        `${baseURL}/api/membership/session/verify`,
        { id: session },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        toast.success(data.message);
        navigate(`/payment-success?order_id=${data?.data?.order_id}`, {replace: true})
      } else {
        toast.error(data.message);
        navigate("/", {replace: true});
      }
    } catch (error) {
      navigate("/", {replace: true});
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    if (session && id) {
      if(type === 'membership'){
        verifyMembership();
      }
      else if(type === 'checkout'){
        verifyCheckout();
      }
      else{
        verifyPayment()
      }
    }
    return () => { };
    // eslint-disable-next-line
  }, [session, id, type]);

  return null; 
};