import React, { Fragment, useState } from "react";
import { Badge, Form, Table, Button, Modal } from "react-bootstrap";
import "./OrderTable.css";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../contextApi/AppContext";

const OrderTable = ({ orders, tabActive }) => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isProcessing, setIsProccessing] = useState(false);
  const { updateOrderStatus, sellerOrderList } = useAppContext();

  const handleClose = () => setShow(false);

  const handleShow = (order, item) => {
    setSelectedOrderId(order._id); // Set the order ID when edit is clicked
    setSelectedProductId(item.product_id); // Set the order ID when edit is clicked
    setStatus(order.order_status); // Optionally, set the current status as initial value
    setShow(true);
  };

  // console.log(orders, "orders");

  const onSubmit = async (e) => {
    e.preventDefault();
    const datatoSend = {
      orderId: selectedOrderId, // Use the selected order ID here
      product_id: selectedProductId, // Use the selected product
      status: status,
    };
    setIsProccessing(true);
    await updateOrderStatus(datatoSend);
    // console.log("Form submitted with status:", status);
    const dataToSend = {
      category_slug: tabActive,
    };
    await sellerOrderList(dataToSend);
    setIsProccessing(false);
    setShow(false);
  };

  return (
    <>
      <Table bordered hover responsive className="order-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Customer Details</th>
            <th>Product Details</th>
            <th>Quantity</th>
            <th>Total Price</th>
            <th>Purchase Date</th>
            <th>Order Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(orders.data) && orders.data.length > 0 ? (
            orders.data.map((order, index) => (
              <Fragment key={index}>
                {order?.items?.map((item, j) => {
                  const badgeVariant =
                    item?.status === "Completed"
                      ? "success"
                      : item?.status === "Pending"
                      ? "warning"
                      : "primary";

                  return (
                    <tr key={j}>
                      <td>{order?.order_id}</td>
                      <td
                        className="customer-details"
                        style={{ width: "100%", minWidth: "max-content" }}
                      >
                        <img
                          src={order?.customer_image}
                          alt={order?.customer_image}
                          className="customer-image"
                        />
                        <Link
                          to={order.customer?.url}
                          className="customer-name"
                        >
                          {order?.customer_name}
                        </Link>
                      </td>
                      <td className="text-truncate" style={{ maxWidth: 400 }}>
                        <img
                          src={item?.image}
                          alt={item.title}
                          className="product-image"
                        />
                        <Link
                          to={`/product/my-post/${item?.product_id}`}
                          className="product-name text-truncate"
                        >
                          {item?.title}
                        </Link>
                      </td>
                      <td>{item?.quantity}</td>
                      <td>{item?.total_price}</td>
                      <td>{new Date(order.order_date).toLocaleDateString()}</td>
                      <td>
                        <Badge bg={badgeVariant}>
                          {item?.status || "Pending"}
                        </Badge>
                      </td>
                      <td>
                        <FaEdit
                          style={{
                            fontSize: "20px",
                            color: "#168f9f",
                            cursor: "pointer",
                          }}
                          onClick={() => handleShow(order, item)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: "center", padding: "20px" }}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Order Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="orderStatusSelect">
              <Form.Label>Select status</Form.Label>
              <Form.Select
                name="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select...</option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
                <option value="On_Progress">On Progress</option>
                <option value="Delivered">Delivered</option>
                <option value="Cancelled">Cancelled</option>
              </Form.Select>
            </Form.Group>
            <Modal.Footer
              className="px-0 border-0"
              style={{ paddingBottom: 0 }}
            >
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={isProcessing}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" disabled={isProcessing}>
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderTable;
