import React, { useState } from "react";
import { useAppContext } from "../../contextApi/AppContext";
import { IoIosClose } from "react-icons/io";

const JobApply = ({ showModal, title, handleCloseModal, id }) => {
  const { userData, jobApply } = useAppContext();
  const { email: initialEmail, mobile: initialMobile, name: initialName } = userData || {};
  const [updatedName, setUpdatedName] = useState(initialName);
  const [updatedEmail, setUpdatedEmail] = useState(initialEmail);
  const [updatedMobile, setUpdatedMobile] = useState(initialMobile);
  const [resumeFile, setResumeFile] = useState(null);

  const handleNameChange = (event) => setUpdatedName(event.target.value);
  const handleEmailChange = (event) => setUpdatedEmail(event.target.value);
  const handleMobileChange = (event) => setUpdatedMobile(event.target.value);
  const handleFileChange = (event) => setResumeFile(event.target.files[0]);

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("name", updatedName);
    formData.append("email", updatedEmail);
    formData.append("mobile", updatedMobile);
    formData.append("file", resumeFile);
    formData.append("job_id", id);
    jobApply(formData);
    if (updatedName && updatedEmail && updatedMobile && resumeFile) {
      handleCloseModal();
    }
  };

  const handleBackdropClick = (event) => {
    if (event.target.id === "jobApplyModal") {
      handleCloseModal();
    }
  };

  return (
    <>
      {showModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          id="jobApplyModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          onClick={handleBackdropClick}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content shadow-lg border-0">
              <div className="modal-header bg-primary text-white">
                <h5 className="modal-title" id="exampleModalLabel">
                  Apply for {title}
                </h5>
                <button
                  type="button"
                  className="close-btn text-white  d-flex justify-content-end"
                  onClick={handleCloseModal}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "1.5rem",
                    padding: 0,
                  }}
                >
                  <IoIosClose size={28} />
                </button>
              </div>
              <div className="modal-body p-4">
                <div className="mb-3">
                  <label className="form-label fw-bold">Full Name</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="Full Name"
                    value={updatedName}
                    onChange={handleNameChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-bold">Email</label>
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    placeholder="Email Address"
                    value={updatedEmail}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-bold">Mobile Number</label>
                  <input
                    type="tel"
                    className="form-control form-control-sm"
                    placeholder="Mobile Number"
                    value={updatedMobile}
                    onChange={handleMobileChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label fw-bold">Upload CV/Resume</label>
                  <input
                    className="form-control form-control-sm"
                    id="formFileSm"
                    type="file"
                    onChange={handleFileChange}
                    required
                  />
                  <small className="text-muted mt-2 d-block">
                    Upload your CV/Resume or any other relevant file. Max file size 50 MB.
                  </small>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn btn-primary btn-sm px-4 text-white"
                  >
                    Send Application
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobApply;
