import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="container mt-4">
      <h1>Refund Policy</h1>
      <p>Welcome to Dzital.com! Your satisfaction is our priority, and we are committed to providing a seamless shopping experience. If you're not entirely satisfied with your purchase, our Refund Policy outlines the steps you can take to request a return or refund. Please review the policy below for details.</p>

      <h2>1. Returns</h2>
      <p>We accept returns within <strong>30 days</strong> of receiving your order. To be eligible for a return, your item must be unused, in the same condition you received it, and in the original packaging.</p>
      <p><strong>Items not eligible for return include:</strong></p>
      <ul>
        <li>Perishable goods (such as food, flowers, newspapers, or magazines)</li>
        <li>Personal care items (such as cosmetics and hygiene products)</li>
        <li>Gift cards and downloadable software</li>
        <li>Sale items (only regular-priced items may be refunded)</li>
      </ul>

      <h2>2. Refunds</h2>
      <p>Once we receive and inspect your return, we will notify you of the approval or rejection of your refund. Approved refunds will be processed, and a credit will automatically be applied to your original payment method within 5–10 business days.</p>
      <p><strong>Please note:</strong> Shipping costs are non-refundable. If you receive a refund, the cost of return shipping may be deducted from your refund.</p>

      <h2>3. Exchanges</h2>
      <p>We only replace items if they are defective or damaged. If you need to exchange an item for the same product, please email our support team at <a href="mailto:support@dzital.com">support@dzital.com</a> with your order details and a description of the issue.</p>

      <h2>4. Late or Missing Refunds</h2>
      <p>If you haven’t received a refund within 10 business days, please take these steps:</p>
      <ul>
        <li>Check your bank account and credit card statement.</li>
        <li>Contact your credit card company, as processing times can vary.</li>
        <li>If you’ve completed these steps and still have not received your refund, please contact us at <a href="mailto:support@dzital.com">support@dzital.com</a>.</li>
      </ul>

      <h2>5. Shipping Returns</h2>
      <p>To return your product, please mail it to:</p>
      <address>
        Dzital.com<br />
        [Insert Return Address Here]<br />
        [City, State, Zip Code]
      </address>
      <p>You are responsible for covering the shipping costs associated with returning your item. We recommend using a trackable shipping service or purchasing shipping insurance, as we cannot guarantee that we will receive your returned item.</p>

      <h2>6. Contact Us</h2>
      <p>If you have any questions about our Refund Policy, please contact our customer support team:</p>
      <p>Email: <a href="mailto:support@dzital.com">support@dzital.com</a><br />
      Phone: +1 (555) 123-4567</p>
    </div>
  );
};

export default RefundPolicy;
