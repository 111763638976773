import Ads from "../../../components/comon/Ads";
import AccountSideBar from "../AccountSideBar";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useAppContext } from "../../../contextApi/AppContext";
import { Pagination, Stack } from "@mui/material";
import { FaCheck } from "react-icons/fa";

export const MyJobs = () => {
  const { jobAppliedList, getJobAppliedList } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);

  const searchData = {
    page: currentPage,
    limit: 20,
  };

  useEffect(() => {
    getJobAppliedList(searchData);
    // eslint-disable-next-line
  }, [currentPage]);

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>My Jobs</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">My Jobs</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bg-light py-4">
        <article className="container-fluid">
          <article className="row">
            <Ads />
            <div className="col-md-3 mb-4">
              <AccountSideBar />
            </div>
            <div className="col-md-7 mb-4">
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h2 className="text-primary"> My Jobs</h2>
                  </div>
                  <p className="text-muted">
                    Here you can see all your job you applied.
                  </p>
                  <hr />
                  {/* Job applied table */}
                  <div className="">
                    {Array.isArray(jobAppliedList?.data) &&
                      jobAppliedList?.data?.map((doc, index) => {
                        const differenceInTime =
                          new Date() - new Date(doc?.createdAt);
                        const differenceInDays = Math.floor(
                          differenceInTime / (1000 * 3600 * 24)
                        );
                        let differenceInHour = 0;
                        if (differenceInDays === 0) {
                          differenceInHour = Math.floor(
                            differenceInTime / (1000 * 3600)
                          );
                        }
                        const badge_color =
                          doc.status === "Rejected"
                            ? "danger"
                            : doc.status === "Approved"
                            ? "success"
                            : "warning";
                        const Experience = doc?.attribute_values?.find(
                          (item) => item.attribute === "Experience"
                        );
                        const Salary = doc?.attribute_values?.find(
                          (item) => item.attribute === "Salary"
                        );
                        return (
                          <div className="jobeItem" key={index}>
                            <div className="card mb20">
                              <div
                                className="card-body"
                                style={{ height: "auto" }}
                              >
                                <div className="row">
                                  <div className="col-md-9 col-sm-12">
                                    <h5>{doc?.job_title}</h5>
                                    <p
                                      className="mb-2"
                                      style={{ fontSize: 16 }}
                                    >
                                      {" "}
                                      Employer :{" "}
                                      <strong>{doc?.job_posted_by}</strong>{" "}
                                    </p>
                                  </div>
                                  <Link
                                    to={`/seller-info/${doc?.job_posted_by_user_id}`}
                                    className="col-md-3 col-sm-12 logoBx"
                                  >
                                    <img
                                      src={
                                        doc?.company_logo ||
                                        "assets/images/g-logo.png"
                                      }
                                      width={44}
                                      className="mt5"
                                      alt=""
                                    />
                                  </Link>
                                </div>
                                <ul
                                  className="list-inline divider mb5 "
                                  style={{ fontSize: 14 }}
                                >
                                  <li>
                                    <i className="fa fa-briefcase" />
                                    {"  "} {Experience?.value || " N/A"}
                                  </li>
                                  <li>
                                    <i className="fa fa-inr" />{" "}
                                    {Salary?.value || "Not disclosed"}
                                  </li>
                                  <li>
                                    <i className="fa fa-map-marker" />{" "}
                                    {doc?.address}
                                  </li>
                                </ul>
                                <div className="row footBx mt-2">
                                  <div className="col-6 d-flex gap-2 align-items-center">
                                    <Badge
                                      bg={badge_color}
                                      style={{ fontSize: 12 }}
                                    >
                                      {doc?.status}
                                    </Badge>
                                    <div className="text-muted d-flex gap-1 align-items-center m-0">
                                      <span>
                                        {" "}
                                        Applied{" "}
                                        {differenceInDays === 0
                                          ? differenceInHour
                                          : differenceInDays}{" "}
                                        {differenceInDays > 1
                                          ? "days"
                                          : differenceInDays === 0 &&
                                            differenceInHour > 1
                                          ? "hours"
                                          : differenceInDays === 0 &&
                                            differenceInHour < 2
                                          ? "hour"
                                          : "day"}{" "}
                                        ago
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-6" align="right">
                                    <div className="d-flex align-items-center gap-2 justify-content-end">
                                      <Link
                                        to={`/message?peerUser=${doc?.job_posted_by_user_id}`}
                                        className="btn btn-sm btn-primary text-light"
                                      >
                                        Message
                                      </Link>
                                      <button
                                        className="btn btn-sm btn-outline-secondary d-flex align-items-center gap-1"
                                        disabled
                                      >
                                        CV Uploaded{" "}
                                        <FaCheck style={{ fontSize: 12 }} />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div align="center">
                    <Stack spacing={2}>
                      <Pagination
                        count={jobAppliedList?.totalPages}
                        page={currentPage}
                        onChange={(value, page) => {
                          setCurrentPage(page);
                        }}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        boundaryCount={2}
                        siblingCount={1}
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </div>
            <Ads />
          </article>
        </article>
      </section>
    </>
  );
};
