import React, { useEffect, useState } from "react";
import Ads from "../../../components/comon/Ads";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Image, Row, Spinner } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import SubscriptionPrice from "../../../components/modal/SubscriptionPrice";
import ReactQuill from "react-quill";
import { FaTrash } from "react-icons/fa6";
import { toast } from "react-toastify";

const PostUpdate = () => {
  const {
    getMyPostListDetail,
    myPostDetail,
    updateMyPost,
    userData,
    check,
    packagesList,
    getUserplan,
  } = useAppContext();

  const { id } = useParams();
  const [isImage, setIsImage] = useState(false);
  const [isEcom, setIsEcom] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [filePreviews, setFilePreviews] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [singleFile, setSingleFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [formState, setFormState] = useState({
    title: "",
    description: "",
    attribute_values: [],
    image: [] || "",
    product_price: "",
    property_type: "",
    price: "",
    stock: "",
    category_name: [],
  });

  // Store initial data in state
  useEffect(() => {
    if (myPostDetail) {
      setFormState({
        title: myPostDetail.title,
        description: myPostDetail.description,
        attribute_values: myPostDetail.attribute_values,
        image: myPostDetail.image,
        product_price: myPostDetail?.product_price,
        price: myPostDetail?.price,
        stock: myPostDetail?.stock,
        property_type: myPostDetail?.property_type,
      });
      // console.log("myPostDetail", myPostDetail);
      if (
        myPostDetail?.category_slug === "jobs" ||
        myPostDetail?.category_slug === "freelancer" ||
        myPostDetail?.category_slug === "lesson_courses"
      ) {
        setIsImage(true);
      } else {
        setFilePreviews(myPostDetail?.image || []);
        setIsImage(false);
      }
      if (
        myPostDetail?.category_slug === "goods_of_all_kinds" ||
        myPostDetail?.category_slug === "electronics" ||
        myPostDetail?.category_slug === "fashion_beauty"
      ) {
        setIsEcom(true);
      }
    }
  }, [myPostDetail]);

  useEffect(() => {
    if (id) {
      getMyPostListDetail(id);
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getUserplan();
    // eslint-disable-next-line
  }, []);

  const filteredPackages = packagesList?.filter((item) => item.type === "Zoom");

  const membership_id = userData?.membership_details?.find(
    (data) => data.membership_id === filteredPackages[0]?._id
  );

  const handleChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "attribute_value") {
      const updatedAttributeValues = [...formState.attribute_values];
      updatedAttributeValues[index].value = value;

      setFormState((prevState) => ({
        ...prevState,
        attribute_values: updatedAttributeValues,
      }));
    } else if (name === "image") {
      const selectedFiles = Array.from(e.target.files)[0];
      setSingleFile(selectedFiles);
      const newPreviews = URL.createObjectURL(selectedFiles);
      setFormState({ ...formState, image: newPreviews });
    } else {
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles1([...files1, ...selectedFiles]);
    const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file));
    setFilePreviews([...filePreviews, ...newPreviews]);
  };

  const handleRemoveFile = (indexToRemove) => {
    const newFiles = files1.filter((_, index) => index !== indexToRemove);
    const newPreviews = filePreviews.filter(
      (_, index) => index !== indexToRemove
    );
    setFiles1(newFiles);
    setFilePreviews(newPreviews);
  };
  //  have to check handle submit

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      title,
      description,
      image,
      attribute_values,
      product_price,
      price,
      stock,
      property_type,
    } = formState;

    const dataPost = new FormData();

    dataPost.append("title", title);
    dataPost.append("description", description);
    
    if(isEcom){
      dataPost.append("stock", stock);
    }

    if (product_price) {
      dataPost.append("product_price", product_price);
    }

    // Checking if category that contains single or multiple images on the basis of that sending the request
    if (isImage) {
      if (!image || image === "") {
        toast.info("Image is required");
        return;
      }
      if (singleFile) dataPost.append("image", singleFile);
    } else {
      if (filePreviews.length === 0) {
        toast.info("Image is required");
        return;
      }
      if (files1?.length > 0) {
        files1.forEach((file) => dataPost.append("image", file));
      }
      const existingFile =
        Array.isArray(filePreviews) && filePreviews.length > 0
          ? filePreviews?.filter((file) => file.includes("res.cloudinary.com"))
          : [];

      if (Array.isArray(existingFile) && existingFile.length > 0) {
        dataPost.append("image", existingFile?.join(","));
      }
    }

    if (price) {
      dataPost.append("price", price);
    }

    if (property_type) {
      dataPost.append("property_type", property_type);
    }

    attribute_values?.forEach((item, index) => {
      if (item.attribute !== "title" && item.attribute !== "description") {
        dataPost.append(
          `attributeData[${index}][attribute_id]`,
          item.attribute_id
        );
        dataPost.append(`attributeData[${index}][value]`, item.value);
      }
    });
    
    setIsProcessing(true);
    await updateMyPost(id, dataPost);
    setIsProcessing(false);
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>SELECTED CATEGORY</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link className="active" to="/my-post">
                    My Post
                  </Link>
                </li>
                <li className="active">Update Product</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody">
        <article className="container-fluid">
          <article className="row">
            <Ads />
            <div className="col-md-10">
              <div className="card">
                <div className="card card-header">
                  <h5 className="text-center">Update Product</h5>
                </div>
                <div className="card card-body border-0">
                  <form onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="mb20" style={{ maxWidth: "500px" }}>
                      <h6>Title</h6>
                      <input
                        type="text"
                        name="title"
                        id="title"
                        disabled={isProcessing}
                        value={formState.title}
                        onChange={handleChange}
                        className="form-control"
                      />
                    </div>
                    <div className="mb20">
                      <h6>Description</h6>
                      <ReactQuill
                        id="description"
                        value={formState.description}
                        onChange={(value) =>
                          setFormState({ ...formState, description: value })
                        }
                        theme="snow"
                        className="form-control"
                        placeholder="Enter a detailed description"
                        style={{
                          borderRadius: "8px",
                          height: "200px",
                          boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          overflow: "hidden",
                        }}
                      />
                    </div>
                    <div className="radioBx mb20">
                      <div className="row">
                        {Array.isArray(formState.attribute_values) &&
                          formState.attribute_values.map((item, index) => (
                            <div className="col-md-6 mt30" key={item._id}>
                              <label
                                style={{
                                  fontWeight: "bold",
                                  fontSize: "1em",
                                }}
                              >
                                {item.attribute}
                              </label>
                              <input
                                type="text"
                                name="attribute_value"
                                id={`attribute_value_${index}`}
                                disabled={isProcessing}
                                value={item.value}
                                onChange={(e) =>
                                  handleChange(e, index, item.attribute_id)
                                }
                                className="form-control mt-2"
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                        
                    {isEcom && (
                       <div className="App d-flex flex-column">
                       <h6>Stock</h6>
                       <div className="d-flex align-items-center mb-3">
                         <div className="form-group me-3 mb-0" style={{width: '100%'}}>
                           <input
                             type="number"
                             className="form-control"
                             min={0}
                             name="stock"
                             value={formState.stock}
                             onChange={handleChange}
                             placeholder="Enter stock"
                             required
                             disabled={
                               isProcessing
                             }
                           />
                         </div>
                       </div>
                     </div>
                    )}

                    <>
                      {myPostDetail.category_slug === "lesson_courses" ? (
                        <div className="App d-flex flex-column">
                          <h6>Course Price</h6>
                          <div className="d-flex align-items-center mb-3">
                            <div className="form-group me-3 mb-0">
                              <input
                                type="number"
                                className="form-control"
                                id="coursePriceAmount"
                                name="product_price"
                                value={formState.product_price}
                                onChange={handleChange}
                                placeholder="Enter amount"
                                required
                                disabled={
                                  myPostDetail?.product_plans ||
                                  check ||
                                  isProcessing
                                }
                              />
                            </div>
                            {membership_id?.membership_status && (
                              <button
                                className="btn btn-outline-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (myPostDetail?.product_plans === null) {
                                    setShowModal(true);
                                  }
                                }}
                                disabled={
                                  myPostDetail?.product_plans ||
                                  check ||
                                  isProcessing
                                }
                              >
                                {myPostDetail?.product_plans || check
                                  ? "Plan Selected"
                                  : "Add Subscription Plan"}
                              </button>
                            )}
                          </div>
                          <SubscriptionPrice
                            show={showModal}
                            handleClose={() => setShowModal(false)}
                            product_id={id}
                            category_id={myPostDetail.category_id}
                          />
                        </div>
                      ) : null}
                    </>

                    {!isImage ? (
                      <>
                        <>
                          <h6>Price</h6>
                          <div className="form-group me-3 mb-0">
                            <input
                              type="number"
                              className="form-control"
                              id="price"
                              name="price"
                              value={formState.price}
                              onChange={handleChange}
                              placeholder="Enter amount"
                              disabled={myPostDetail?.bid_now || isProcessing}
                              required
                            />
                          </div>
                        </>
                        <>
                          {myPostDetail.category_slug === "real_estate" && (
                            <div className="form-group pb-4 mt-3">
                              <label htmlFor="propertyType">
                                Property Type:
                              </label>
                              <select
                                id="propertyType"
                                name="property_type"
                                className="form-control mt-2"
                                value={formState.property_type}
                                disabled={isProcessing}
                                onChange={handleChange}
                                required
                              >
                                <option value="Buy">Buy</option>
                                <option value="Rent">Rent</option>
                                <option value="Lease">Lease</option>
                              </select>
                            </div>
                          )}
                        </>
                        <h5 className="m0 text-center mt50">UPDATE PHOTOS</h5>
                        <div className="row mt20">
                          {/* From here */}
                          <div className="">
                            <input
                              type="file"
                              className="form-control"
                              multiple
                              disabled={isProcessing}
                              onChange={handleFileChange}
                            />
                          </div>
                          <Row>
                            {filePreviews?.map((preview, index) => (
                              <Col key={index} xs={6} md={2} className="mt-4">
                                <div className="d-flex flex-column align-items-center position-relative">
                                  <Image
                                    src={preview || preview?.preview}
                                    alt={"Post"}
                                    style={{
                                      aspectRatio: 4 / 3,
                                      objectFit: "contain",
                                    }}
                                    thumbnail
                                    className="mb-2"
                                  />
                                  <Button
                                    variant="danger"
                                    className="position-absolute"
                                    disabled={isProcessing}
                                    style={{ top: 8, right: 8 }}
                                    size="sm"
                                    onClick={() => handleRemoveFile(index)}
                                  >
                                    <FaTrash />
                                  </Button>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </>
                    ) : (
                      <>
                        <h5 className="m30 text-center">UPLOAD file</h5>
                        <div className="row justify-content-center">
                          <div className="col-sm-3">
                            <div className="fileBx mt-2">
                              <input
                                type="file"
                                name="image"
                                id="img"
                                onChange={handleChange}
                                disabled={isProcessing}
                                style={{ display: "none" }}
                              />
                              <label htmlFor="img">
                                <img
                                  height={70}
                                  width={70}
                                  src={formState.image}
                                  alt=""
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <Button
                      type="submit"
                      className="text text-white mt30"
                      disabled={isProcessing}
                    >
                      {isProcessing && (
                        <Spinner size="sm" style={{ marginRight: "8px" }} />
                      )}
                      {isProcessing ? "Processing..." : "Update"}
                    </Button>
                  </form>
                </div>
              </div>
            </div>
            <Ads />
          </article>
        </article>
      </section>
    </>
  );
};

export default PostUpdate;