import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import PaymentModal from "./PaymentModal";
import { useAppContext } from "../../../../contextApi/AppContext";
import { toast } from "react-toastify";

const WalletPage = () => {
  const { userData, getPayoutRequestList, mainData, loading } = useAppContext();

  const [showModal, setShowModal] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const handleCloseModal = () => setShowModal(false);
  const walletBalance = userData?.wallet_amount;
  const user_id = userData?._id;

  const handleStatusChange = (status) => {
    setFilterStatus(status);
  };

  const requestedStatus = mainData.payOutList?.find((item) => item?.status);

  const handleShowModal = () => {
    if (requestedStatus?.status === "Requested")
      return toast.warning("You already have requested");
    setShowModal(true);
  };

  useEffect(() => {
    if (user_id) {
      getPayoutRequestList({ user_id, status: filterStatus });
    }
    // eslint-disable-next-line
  }, [user_id, filterStatus]);

  const getStatusClass = (status) => {
    switch (status) {
      case "Requested":
        return "text-black";
      case "Approved":
        return "text-success";
      case "Rejected":
        return "text-danger";
      default:
        return "";
    }
  };

  return (
    <div className="container mt-5">
      {loading ? (
        <div className="spinner-border text-info" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className="row">
          {/* Wallet Balance and Payment Request */}
          <div className="col-md-6 mb-4">
            <div className="card border-0 bg-primary text-white shadow-lg">
              <div className="card-body text-center">
                <h4 className="card-title mb-4">Wallet Balance</h4>
                <p className="display-3 mb-4">${walletBalance?.toFixed(2)}</p>
                <Button
                  variant="light"
                  size="lg"
                  className="rounded-pill"
                  onClick={handleShowModal}
                >
                  Request Payment
                </Button>
              </div>
            </div>
          </div>
          {/* Transaction History */}
          <div className="col-md-6">
            <div className="card border-0 shadow-sm">
              <div className="card-body p-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4 className="card-title mb-0">Transaction History</h4>
                  <DropdownButton
                    id="filterStatus"
                    title={
                      <>
                        <FaFilter className="me-2 text-white" />
                      </>
                    }
                    onSelect={handleStatusChange}
                    className="text-white btn-outline-secondary"
                  >
                    <Dropdown.Item eventKey="Requested">
                      Requested
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="Approved">Approved</Dropdown.Item>
                    <Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
                  </DropdownButton>
                </div>

                <div
                  className="transaction-list"
                  style={{ maxHeight: "250px", overflowY: "auto" }}
                >
                  <ul className="list-group list-group-flush">
                    {mainData?.payOutList?.length > 0 ? (
                      mainData.payOutList.map((transaction, index) => (
                        <li
                          key={transaction.id}
                          className={`list-group-item d-flex justify-content-between align-items-center ${
                            index % 2 === 0 ? "bg-light" : "bg-white"
                          } border-0`}
                        >
                          <div>
                            <h6
                              className={`mb-1 ${getStatusClass(
                                transaction.status
                              )}`}
                            >
                              {transaction.status}
                            </h6>
                            <small className="text-muted">
                              {transaction?.createdAt
                                ? new Date(
                                    transaction.createdAt
                                  ).toLocaleDateString("en-GB")
                                : "-"}
                            </small>
                          </div>
                          <span className={getStatusClass(transaction.status)}>
                            $ {transaction.requested_amount}
                          </span>
                        </li>
                      ))
                    ) : (
                      <li className="list-group-item text-center border-0">
                        No transactions found for this date
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <PaymentModal show={showModal} handleClose={handleCloseModal} />
    </div>
  );
};

export default WalletPage;
