import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import noPostar from "../../assets/no-poster.png";
import { FaBed, FaBath, FaRulerCombined } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useAppContext } from "../../contextApi/AppContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
const RealEstate = ({ item }) => {
  const {
    property,
    wishListAdd,
    wishListRemove,
    userData,
    sitelocation,
    getProperty,
    globalCurrency,
    currency_symbol,
  } = useAppContext();
  const [colorMap, setColorMap] = useState([]);

  useEffect(() => {
    const dataToSend = {
      addressName: sitelocation,
      currency: globalCurrency,
    };
    getProperty("", "", "", dataToSend);
    // eslint-disable-next-line
  }, [sitelocation, globalCurrency, currency_symbol]);

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };

  useEffect(() => {
    if (property && Array.isArray(property)) {
      let check = {};
      property.forEach((list) => {
        check[list.product_id] = list.isWishlist;
      });
      setColorMap(check);
    }
  }, [property]);

  // const formatPrice = (price) => {
  //   if (price >= 10000000) {
  //     // If price is 1 crore or more, show in crore
  //     return `${(price / 10000000).toFixed(2)} Cr`;
  //   } else if (price >= 100000) {
  //     // If price is 1 lakh or more, show in lakh
  //     return `${(price / 100000).toFixed(2)} L`;
  //   } else if (price >= 1000) {
  //     // If price is 1 thousand or more, show in thousand
  //     return `${(price / 1000).toFixed(2)} K`;
  //   } else {
  //     // Otherwise, show the price as is
  //     return `${price}`;
  //   }
  // };

  return (
    <section className=" py-4 bg-light">
      <article className="container">
        <div className="d-flex justify-content-between align-items-center mb-4 titelBx">
          <h3 className="mb-0">{item.title}</h3>
          <Link
            className="btn btn-primary rounded-pill px-4 text-white"
            to={`/${item.slug}?category_id=${item._id}`}
            onMouseEnter={(e) => (e.target.style.color = "var(--theme-color)")}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            style={{
              background: "linear-gradient(45deg, #007bff, #6610f2)",
              border: "none",
              fontSize: "0.95rem",
              fontWeight: "600",
            }}
          >
            View All
          </Link>
        </div>
        <div className="row">
          <Swiper
            spaceBetween={20}
            navigation={true}
            mousewheel={false}
            keyboard={true}
            loop={true}
            pagination={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
            className="mySwiper homeCategory"
          >
            {Array.isArray(property) &&
              property?.map((item) => {
                const bed = item?.attribute_values?.find(
                  (item) => item.attribute === "Number of Bedrooms"
                );
                const bath = item?.attribute_values?.find(
                  (item) => item.attribute === "Number of Bathrooms"
                );
                const sqft = item?.attribute_values?.find(
                  (item) => item.attribute === "Square Footage"
                );
                return (
                  <SwiperSlide key={item._id}>
                    <div className="card card-custom shadow-sm border-0 rounded-4">
                      <div
                        className="position-relative"
                        style={{ height: "200px" }}
                      >
                        <Link to={`/realstate-detail/${item._id}`}>
                          <img
                            src={item?.image[0] || noPostar}
                            className="card-img-top rounded-top-4"
                            alt=""
                            style={{ height: "100%", objectFit: "cover" }}
                          />
                        </Link>
                        <div
                          className="position-absolute top-0 end-0 m-2 bg-white p-1 rounded-circle"
                          onClick={
                            userData._id !== item._id
                              ? () => addWhishlist(item.product_id)
                              : null
                          }
                          style={{
                            cursor:
                              userData._id === item._id
                                ? "not-allowed"
                                : "pointer",
                            opacity: userData._id === item._id ? 0.5 : 1,
                          }}
                        >
                          <i
                            className={
                              colorMap[item.product_id]
                                ? "fa fa-heart text-danger fs-4"
                                : "fa fa-heart-o fs-4"
                            }
                          />
                        </div>
                        <div className="position-absolute bottom-0 start-0 m-2 text-light bg-dark px-2 rounded">
                          {item.property_type}
                        </div>
                      </div>
                      <Link
                        to={`/realstate-detail/${item._id}`}
                        className=" text-decoration-none"
                      >
                        <div className="card-body d-flex flex-column">
                          <h5 className="card-title text-truncate">
                            <p className=" text-dark m-0">{item.title}</p>
                          </h5>
                          <p className="card-text fw-bold text-primary m-0">
                            Price: {currency_symbol ? currency_symbol : "$"}{" "}
                            {item.converted_currency_price}
                          </p>
                          <p className="card-text text-muted small m-0 mt-1 text-truncate">
                            <strong>Posted By:</strong> {item.seller_name}
                          </p>
                          <div className="d-flex justify-content-between text-muted mt-2">
                            <div className="d-flex flex-column align-items-center text-center">
                              <FaBed className="text-primary mb-1" />
                              <span className="fw-bold">
                                {bed?.value ? `${bed.value} bed` : "NA"}
                              </span>
                            </div>
                            <div className="d-flex flex-column align-items-center text-center">
                              <FaBath className="text-info mb-1" />
                              <span className="fw-bold">
                                {bath?.value ? `${bath.value} bath` : "NA"}
                              </span>
                            </div>
                            <div className="d-flex flex-column align-items-center text-center">
                              <FaRulerCombined className="text-danger mb-1" />
                              <span className="fw-bold">
                                {sqft?.value ? `${sqft.value} sqft` : "NA"}
                              </span>
                            </div>
                          </div>
                          <p className="card-text text-muted small mt-2 text-truncate">
                            <FaLocationDot
                              style={{
                                marginTop: "-5px",
                                color: "var(--bs-info)",
                              }}
                              className="me-1"
                            />
                            <span>{item.address}</span>
                          </p>
                        </div>
                      </Link>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </article>
    </section>
  );
};

export default RealEstate;
