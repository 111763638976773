import React, { useState, useEffect } from "react";
import Ads from "./../components/comon/Ads";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import { Pagination } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Skeleton } from "../components/loading/Skeleton";
import NoData from "./noData/NoData";
import BreadCrum from "../components/comon/BreadCrum";
import FashionFilter from "../components/fashion-beauty/FashionFilter";
import FreelancerItem from "../components/freelancer/FreelancerItem";

const Freelancer = () => {
  const {
    freelancerList,
    getAttributeFilterList,
    totalItem,
    getFreelancer,
    listLoading,
    sitelocation,
    globalCurrency,
    currency_symbol,
  } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sub_id = searchParams.get("sub_category");
  const child_id = searchParams.get("child_category");
  const cat_id = searchParams.get("category_id");
  const search_value = searchParams.get("search");
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(totalItem?.freelancer / itemsPerPage);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();

  const searchData = {
    keyword_search: search_value || "",
    page: currentPage,
    limit: itemsPerPage,
    address: sitelocation,
    minPrice: minPrice,
    maxPrice: maxPrice,
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = { addressName: "", currency: globalCurrency };
      if (sub_id || child_id) {
        await getFreelancer(
          searchData,
          sub_id || child_id,
          filterData,
          dataToSend
        );
      } else {
        await getFreelancer(searchData, "", filterData, dataToSend);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [
    currentPage,
    sub_id,
    child_id,
    filterData,
    search_value,
    sitelocation,
    minPrice,
    maxPrice,
    globalCurrency,
    currency_symbol,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (sub_id) {
        await getAttributeFilterList({ sub: sub_id });
      }
      if (cat_id) {
        await getAttributeFilterList({ main: cat_id });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [sub_id, cat_id]);

  const updateFilter = (e, attr, value) => {
    const checked = e.target.checked;
    if (checked === true) {
      const existingFilterIndex = filterData.findIndex(
        (item) => item.attribute === attr
      );

      if (existingFilterIndex !== -1) {
        const updatedFilterData = [...filterData];
        updatedFilterData[existingFilterIndex].value.push(value);
        setFilterData(updatedFilterData);
      } else {
        const updatedFilterData = [
          ...filterData,
          {
            attribute: attr,
            value: [value],
          },
        ];
        setFilterData(updatedFilterData);
      }
    } else {
      const updatedFilterData = filterData
        .map((item) => {
          if (item.attribute === attr) {
            item.value = item.value.filter((v) => v !== value);
          }
          return item;
        })
        .filter((item) => item.value?.length > 0);
      setFilterData(updatedFilterData);
    }
  };

  return (
    <>
      <BreadCrum title={"FreeLancer List Page"} param={"FreeLancer"} />
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            <Ads />
            <FashionFilter
              onFilter={updateFilter}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
            />
            <div className="col-md-8">
              {listLoading.freelancer ? (
                Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton key={index} col={10} />
                ))
              ) : freelancerList?.length === 0 ? (
                <NoData
                  des={
                    "Please check the spelling or try searching for something else. Thank you!."
                  }
                />
              ) : (
                Array.isArray(freelancerList) &&
                freelancerList.map((item, index) => (
                  <FreelancerItem
                    key={index}
                    item={item}
                    currency_symbol={currency_symbol}
                  />
                ))
              )}
              {pageCount > 1 && (
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(_, value) => setCurrentPage(value)}
                    shape="rounded"
                    color="primary"
                  />
                </Stack>
              )}
            </div>
            <Ads />
          </article>
        </article>
      </section>
    </>
  );
};

export default Freelancer;
