// import { LuClock8 } from "react-icons/lu";
import { Link } from "react-router-dom";

const LessonItemRelated = ({ item }) => {
  // console.log(item.seller_details.name, "LessonItemRelated");

  return (
    <>
      <div className="col-md-3">
        <div className="lCItem">
          <div key={item._id}>
            <div className="card shadow-sm rounded">
              <div className="position-relative">
                <Link to={`/product/related-product/${item._id}`}>
                  <img
                    src={item.image}
                    className="card-img-top rounded-top"
                    alt={item.title}
                    style={{ height: "180px", objectFit: "cover" }}
                  />
                </Link>
              </div>
              <div className="card-body">
                
                <h5 className="card-title text-truncate ">
                  <Link
                    to={`/product/related-product/${item._id}`}
                    className="text-decoration-none"
                  >
                    {item.title}
                  </Link>
                </h5>
                <div className="d-flex justify-content-between align-items-center mt-2">
                  {/* <small className="text-muted m-0">
                    <LuClock8
                      style={{ marginTop: "-5px", color: "var(--bs-info)" }}
                      className="me-1"
                    />{" "}
                    {item.duration}
                  </small>
                  <small className="text-muted">
                    <i className="bi bi-people"></i> {item.learners} Learners
                  </small> */}
                </div>
                <div className="mt-2">
                  <small className="text-muted d-flex align-items-center">
                    <i
                      className="bi bi-person "
                      style={{ color: "var(--bs-primary)" }}
                    ></i>
                    <span>
                      <strong>Instructor:</strong> {item.seller_details.name}
                    </span>
                  </small>
                </div>
              </div>
              <div className="card-footer bg-transparent border-0 d-flex justify-content-between p-2">
                <Link
                  to={`/product/related-product/${item._id}`}
                  className="btn btn-outline-primary btn-sm px-3 py-2 rounded-pill"
                >
                  More Info
                </Link>
                <Link
                  to={`/product/related-product/${item._id}`}
                  className="btn btn-success btn-sm px-3 py-2 rounded-pill"
                >
                  Start Learning
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonItemRelated;
