import React, { useEffect, useState } from "react";
import useAgora from "./Hook";
import WebDashboard from './Dashboard.js';
import { useParams } from "react-router-dom";
import AgoraRTM from 'agora-rtm-sdk';

const Video = ({type}) => {
  const { channel, token1 } = useParams();
  const appId = "84aba9e26e4c4a94b15f98a38ad04c8c";
  const token = decodeURIComponent(token1);
  const [useClient, useMicrophoneAndCameraTracks] = useAgora();
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [incomingCall, setIncomingCall] = useState(null);
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const [rtmClient, setRtmClient] = useState(null);
  const [rtmChannel, setRtmChannel] = useState(null);

  useEffect(() => {
    let init = async (name) => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log("subscribe success");
        if(type === 'voicecall'){
          return null
        }else{
          if (mediaType === "video") {
            setUsers((prevUsers) => {
              return [...prevUsers, user];
            });
          }
        }
        if (mediaType === "audio") {
          user.audioTrack?.play();
        }
      });

      client.on("user-unpublished", (user, type) => {
        console.log("unpublished", user, type);
        if (type === "audio") {
          user.audioTrack?.stop();
        }
        if (type === "video") {
          setUsers((prevUsers) => {
            return prevUsers.filter((User) => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", (user) => {
        console.log("leaving", user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      await client.join(appId, name, token, null);
      if (tracks) await client.publish([tracks[0], tracks[1]]);
      setStart(true);
    };

    if (ready && tracks) {
      console.log("init ready");
      init(channel);
    }
  }, [channel, ready, tracks]);

  useEffect(() => {
    const initRtm = async () => {
      console.log("Initializing RTM");
      console.log("AgoraRTM object:", AgoraRTM);
      
      try {
        const rtmClient = AgoraRTM.createInstance(appId);
        console.log("RTM Client created:", rtmClient);
        
        setRtmClient(rtmClient);
        await rtmClient.login({ uid: channel, token });
        const rtmChannel = await rtmClient.createChannel(channel);
        setRtmChannel(rtmChannel);

        await rtmChannel.join();

        rtmChannel.on('ChannelMessage', (message, memberId) => {
          const msg = JSON.parse(message.text);
          if (msg.type === 'incoming_call') {
            setIncomingCall(msg.from);
            console.log("Incoming call from", msg.from);
          }
        });

        rtmClient.on('MessageFromPeer', ({ text }, peerId) => {
          const msg = JSON.parse(text);
          if (msg.type === 'incoming_call') {
            setIncomingCall(peerId);
            console.log('first,', peerId)
          }
        });
        
        console.log("RTM initialization completed");
      } catch (error) {
        console.error("Error initializing RTM:", error);
      }
    };

    initRtm();
  }, [channel, token]);

  const handleAcceptCall = async () => {
    setIncomingCall(null);
    await client.join(appId, channel, token, null);
    if (tracks) await client.publish([tracks[0], tracks[1]]);
    setStart(true);
  };

  const handleRejectCall = async () => {
    setIncomingCall(null);
    rtmClient.sendMessageToPeer(
      { text: JSON.stringify({ type: 'call_rejected' }) },
      incomingCall
    );
  };

  return (
    <div className="App">
      {incomingCall && (
        <div className="incoming-call">
          <p>Incoming call from {incomingCall}</p>
          <button onClick={handleAcceptCall}>Accept</button>
          <button onClick={handleRejectCall}>Reject</button>
        </div>
      )}
      {start && (
        <WebDashboard users={users} tracks={tracks} setStart={setStart} />
      )}
    </div>
  );
};

export default Video;
