import React, { useState, useEffect, useRef } from "react";
import "./chat.css";
import AC from "agora-chat";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { Spinner } from "react-bootstrap";
import EmojiPicker from "emoji-picker-react"; // Import Emoji Picker
import userIcon from "../../assets/assets/images/profile_image.jpg";

const App = () => {
  const {
    getMessageDetail,
    messageDetail,
    userData,
    getPeerUserDetail,
    peerUserData,
    createChatList,
  } = useAppContext();
  const appKey = "711117188#1300350";
  const [conn, setConn] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessages, setInputMessages] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [tokenExpiration, setTokenExpiration] = useState(0);
  const [receivedMessageIds, setReceivedMessageIds] = useState(new Set());
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // Emoji picker visibility state

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const peerUser = searchParams.get("peerUser");

  const chatHistoryRef = useRef(null);

  const handleLogin = async () => {
    const userId = userData._id;
    const token = messageDetail.data;

    if (!userId || !token || !conn) return;

    try {
      await conn.open({ user: userId, agoraToken: token });
      console.log("Login successful");
      setIsLoggedIn(true);
      setTokenExpiration(Date.now() + 3600000); // Token expires after 1 hour
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    const connection = new AC.connection({ appKey });
    connection.addEventHandler("connection&message", {
      onConnected: () => {
        console.log("Connect success!");
        fetchMessages(connection);
      },
      onDisconnected: () => {
        console.log("Logout success!");
        setIsLoggedIn(false);
      },
      onTextMessage: (message) => {
        if (!receivedMessageIds.has(message.id)) {
          setMessages((prevMessages) => {
            const updatedMessages = [message, ...prevMessages];
            return updatedMessages.sort((a, b) => a.time - b.time);
          });
          setReceivedMessageIds((prevIds) => new Set(prevIds).add(message.id));
          scrollToBottom(); // Scroll to bottom after receiving a new message
        }
      },
      onTokenWillExpire: () => {
        console.log("Token is about to expire");
      },
      onTokenExpired: () => {
        console.log("The token has expired");
        setIsLoggedIn(false);
        setTokenExpiration(0);
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    });

    setConn(connection);
    return () => connection && connection.close();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      messageDetail?.data &&
      userData?._id &&
      (!isLoggedIn || Date.now() > tokenExpiration)
    ) {
      handleLogin();
    }
    // eslint-disable-next-line
  }, [messageDetail, userData, conn, isLoggedIn, tokenExpiration]);

  const fetchMessages = async (connection) => {
    if (!peerUser) {
      console.error("peerUser is not defined");
      return;
    }
    setLoadingMessages(true);
    try {
      const data = await connection.getHistoryMessages({
        targetId: peerUser,
        chatType: "singleChat",
        pageSize: 20,
        searchDirection: "Last",
        searchOptions: { msgTypes: ["txt"] },
      });

      const sortedMessages = data.messages.sort((a, b) => a.time - b.time);
      setMessages(sortedMessages);
      scrollToBottom(); // Scroll to bottom after loading message history
    } catch (error) {
      console.error("Error fetching message history:", error);
    } finally {
      setLoadingMessages(false);
    }
  };

  useEffect(() => {
    if (conn?.isOpened() && peerUser) {
      fetchMessages(conn);
    }
    // eslint-disable-next-line
  }, [conn, peerUser]);

  const handleSendMessage = async () => {
    if (!conn?.isOpened()) {
      console.error("User is not logged in.");
      try {
        await handleLogin();
      } catch (error) {
        console.error("Re-login failed:", error);
        return;
      }
    }

    if (!peerUser || !inputMessages) {
      console.error("Peer ID and message are required.");
      return;
    }

    const option = {
      chatType: "singleChat",
      type: "txt",
      to: peerUser,
      msg: inputMessages,
      from: userData._id, // Ensure the sender's ID is included
    };

    const msg = AC.message.create(option);
    try {
      await conn.send(msg);
      console.log("Message sent successfully");
      setMessages((prevMessages) => {
        const updatedMessages = [msg, ...prevMessages];
        return updatedMessages.sort((a, b) => a.time - b.time);
      });
      setInputMessages(""); // Clear input field after sending
      scrollToBottom(); // Scroll to the bottom after sending a message
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  useEffect(() => {
    getMessageDetail();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (peerUser) {
      getPeerUserDetail(peerUser);
      createChatList(peerUser);
    }
    // eslint-disable-next-line
  }, [peerUser]);

  // Scroll to the bottom whenever a new message is added
  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      setTimeout(() => {
        chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
      }, 100); // Adjust the delay as needed
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const onEmojiClick = (emojiObject) => {
    setInputMessages(inputMessages + emojiObject.emoji); // Correctly append emoji
  };

  return (
    <div className="container mt40">
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="card chat-app">
            <div className="chat">
              {loadingMessages ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100vh" }}
                >
                  <Spinner animation="border" variant="info" />
                </div>
              ) : (
                <>
                  <div className="chat-header clearfix">
                    <div className="row">
                      <div className="col-lg-6 d-flex align-items-center gap-3 p-2 ">
                        <img
                          src={peerUserData.image || userIcon}
                          alt="Dzital"
                          className="rounded-circle"
                          height={40}
                        />
                        <div className="chat-about">
                          <h6 className="mb-0">{peerUserData.name}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="chat-history" ref={chatHistoryRef}>
                    <ul className="m-b-0">
                      {Array.isArray(messages) &&
                        messages?.map((msg, index) => (
                          <li
                            className={`clearfix ${
                              msg.from === userData._id
                                ? "my-message"
                                : "other-message"
                            }`}
                            key={msg.id} // Use `msg.id` for unique key
                          >
                            <div className="message-content">{msg.msg}</div>
                            <div className="message-data m-3">
                              <span className="message-data-time">
                                {new Date(msg.time).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              )}
              <div className="chat-message clearfix">
                <div className="input-group mb-0">
                  <input
                    type="text"
                    value={inputMessages}
                    onChange={(e) => setInputMessages(e.target.value)}
                    className="form-control"
                    onKeyDown={handleKeyPress}
                    placeholder="Enter text here..."
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    😊
                  </button>
                  <button className="btn btn-primary" onClick={handleSendMessage}>
                    Send
                  </button>
                </div>
                {showEmojiPicker && (
                  <div className="emoji-picker-container">
                    <EmojiPicker onEmojiClick={onEmojiClick} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
