import React, { useEffect } from "react";
import Ads from "../components/comon/Ads";
import AccountSideBar from "./auth/AccountSideBar";
import { Link } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import userIcon from "../assets/assets/images/profile_image.jpg";
import NoData from "./noData/NoData";

const MessageList = () => {
  const { getMessageList, mainData, userData } = useAppContext();

  useEffect(() => {
    getMessageList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className="pageBanner bg-light py-3">
        <article className="container">
          <aside className="row align-items-center">
            <div className="col-md-6">
              <h4 className="text-uppercase text-primary">Message List</h4>
            </div>
            <div className="col-md-6 text-end">
              <ul className="nav list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="/" className="text-decoration-none text-muted">
                    Home /
                  </Link>
                </li>
                <li className="list-inline-item text-primary">Message List</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bg-light py-4">
        <article className="container-fluid">
          <div className="row">
            <Ads />
            <div className="col-md-3 mb-4">
              <AccountSideBar />
            </div>
            <div className="col-md-7 mb-4">
              {mainData?.messageList?.length === 0 ? (
                <NoData />
              ) : (
                <div className="card border-0 shadow-sm">
                  <div className="card-body">
                    {Array.isArray(mainData?.messageList) &&
                      mainData?.messageList.map((user) => {
                        const peerUserId =
                          user.user1_id === userData._id
                            ? user.user2_id
                            : user.user1_id;

                        return (
                          <div
                            key={user._id}
                            className="d-flex align-items-center mb-3 p-3 bg-white rounded shadow-sm message-item"
                          >
                            <Link
                              to={`/message?peerUser=${peerUserId}`}
                              className="d-flex align-items-center text-decoration-none w-100"
                            >
                              <img
                                src={user?.user_image || userIcon}
                                alt={user?.user_name}
                                className="rounded-circle me-3"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  objectFit: "cover",
                                }}
                              />
                              <div className="flex-grow-1">
                                <h5 className="mb-0 text-primary">
                                  {user?.user_name}
                                </h5>
                                <small className="text-muted">
                                  Last message here...
                                </small>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
            <Ads />
          </div>
        </article>
      </section>
    </>
  );
};

export default MessageList;
