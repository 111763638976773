import React, { useState } from "react";
import Ads from "../../../components/comon/Ads";
import { Link } from "react-router-dom";
import { Dropdown, ProgressBar } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import AddProduct from "./AddProduct";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddPost = () => {
  const { serviceList, getSubServiceList, subServiceList, AddTitleDes } =
    useAppContext();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [inputData, setInputData] = useState({
    title: "",
    description: "",
  });
  const [hoveredSubService, setHoveredSubService] = useState(null);

  const handleInputChange = (name, value) => {
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const dataToSend = {
    title: inputData.title,
    description: inputData.description,
    category_id: formData.servicecategories,
    sub_category_id: formData.subservicecat,
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(step + 1);
    }
    if (step === 2) {
      if (
        inputData.title &&
        inputData.description &&
        formData.servicecategories &&
        formData.subservicecat
      ) {
        AddTitleDes(dataToSend);
        setStep(step + 1);
      } else {
        toast.warning("Please fill all the fields");
      }
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleSelectCategory = (serviceId, subServiceId, slug, item1) => {
    setFormData({
      ...formData,
      servicecategories: serviceId,
      subservicecat: subServiceId,
      category_slug: slug,
    });
    if (item1?.child_category?.length > 0) {
      setHoveredSubService(item1);
    } else {
      setHoveredSubService(null);
      handleNext();
    }
  };

  const handlechildcategory = (serviceId, subServiceId, slug, item2) => {
    setFormData({
      servicecategories: serviceId,
      subservicecat: subServiceId,
      category_slug: slug,
      child_category_id: item2?._id || "",
    });
    handleNext();
  };

  console.log(formData, "formData");

  const handleSubcat = (parId) => {
    getSubServiceList(parId);
  };

  console.log(subServiceList, "service list");
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="d-flex justify-content-between align-items-center dropdown-toggle"
    >
      {children}
    </Link>
  ));
  return (
    <>
      <section className="pageBanner">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4>Add Post</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Add Post</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="midBody bgtheme">
        <div className="container-fluid">
          <div className="row">
            <Ads />
            <div className="col-md-10">
              <div className="card mt-0">
                <div className="card-header">
                  <h5>
                    {step === 1 ? "CHOOSE A CATEGORY" : "INCLUDE SOME DETAILS"}
                  </h5>
                </div>
                <div
                  className="card-body border-0"
                  style={{ minHeight: "500px" }}
                >
                  <ProgressBar now={(step / 3) * 100} label={`Step ${step}`} />
                  {step === 1 && (
                    <div className="mt-5 d-flex postCategoryBx">
                      <div className="d-flex flex-row w-100">
                        {/* Service List */}
                        <div className="">
                          {Array.isArray(serviceList) &&
                            serviceList.map((item, index) => (
                              <Dropdown
                                drop="end"
                                key={index}
                                className="postCategoryBx"
                              >
                                {/* First Level Dropdown */}
                                <Dropdown.Toggle
                                  variant="light"
                                  id="dropdown-basic"
                                >
                                  <button
                                    type="button"
                                    onClick={() => handleSubcat(item._id)}
                                  >
                                    {item.title}
                                  </button>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {Array.isArray(subServiceList) &&
                                    subServiceList.map((item1) => (
                                      <>
                                        {item1.child_category?.length > 0 ? (
                                          <Dropdown drop="end">
                                            <Dropdown.Toggle as={CustomToggle}>
                                              <button
                                                onClick={() =>
                                                  handleSelectCategory(
                                                    item._id,
                                                    item1._id,
                                                    item.slug,
                                                    item1
                                                  )
                                                }
                                              >
                                                {item1.title}
                                              </button>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {item1.child_category?.map(
                                                (item2, i) => (
                                                  <Dropdown.Item key={i}>
                                                    <button
                                                      onClick={() =>
                                                        handlechildcategory(
                                                          item._id,
                                                          item1._id,
                                                          item.slug,
                                                          item2
                                                        )
                                                      }
                                                    >
                                                      {item2.title}
                                                    </button>
                                                  </Dropdown.Item>
                                                )
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        ) : (
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleSelectCategory(
                                                item._id,
                                                item1._id,
                                                item.slug,
                                                item1
                                              )
                                            }
                                          >
                                            {item1?.title}
                                          </Dropdown.Item>
                                        )}
                                      </>
                                    ))}

                                  {/* Second Level Dropdown */}
                                </Dropdown.Menu>
                              </Dropdown>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <div className="mb-4 mt-5">
                        <label htmlFor="title" className="form-label mb-1">
                          Title:
                        </label>
                        <input
                          type="text"
                          id="title"
                          name="title"
                          value={inputData.title}
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                          className="form-control"
                          placeholder="Enter a compelling title"
                          style={{
                            borderRadius: "8px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                          }}
                          pattern=".{15,50}"
                          title="Title must be between 15 and 50 characters"
                          required
                        />
                        <small className="form-text text-muted">
                          Provide a clear and engaging title for your content.
                        </small>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="description"
                          className="form-label mb-1"
                        >
                          Description:
                        </label>
                        <ReactQuill
                          id="description"
                          value={inputData.description}
                          onChange={(value) =>
                            handleInputChange("description", value)
                          }
                          theme="snow"
                          className="form-control"
                          placeholder="Enter a detailed description"
                          style={{
                            borderRadius: "8px",
                            height: "200px",
                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
                            overflow: "hidden",
                          }}
                        />
                        <small className="form-text text-muted">
                          Describe your content in detail to attract viewers.
                        </small>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-secondary me-3 px-4"
                          onClick={handlePrevious}
                          type="button"
                          style={{ borderRadius: "8px" }}
                        >
                          Previous
                        </button>
                        <button
                          className="btn btn-primary px-4 text-white"
                          onClick={handleNext}
                          type="button"
                          style={{ borderRadius: "8px" }}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  )}
                  {step === 3 && (
                    <div className="mt-5">
                      <div className="card-header">
                        <h5>INCLUDE SOME MORE DETAILS</h5>
                      </div>
                      <AddProduct idDetail={formData} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Ads />
          </div>
        </div>
      </section>
    </>
  );
};

export default AddPost;
