import { useEffect, useState } from "react";
import { Button, Dropdown, Form, Image, ListGroup, Spinner } from "react-bootstrap";
import { IoIosMore, IoIosSend } from "react-icons/io";
import { useAppContext } from "../../../contextApi/AppContext";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";

const ReplyCard = ({ reply, setReplyList, setRepCount, onCommentOpen }) => {
  const { userData, timeAgo, commentDelete } = useAppContext();

  const onCommentDelete = async () => {
    const confirm = await window.confirm(
      "Are you sure want to delete this comment"
    );
    if (!confirm) {
      return;
    }
    const data = await commentDelete(reply._id);
    if (data && data.status === 200) {
      toast.success("reply deleted successfully");
      setReplyList((prevReplies) =>
        prevReplies.filter((re) => re._id !== reply._id)
      );
      setRepCount((prevRepCount) => prevRepCount - 1);
    }
  };
  return (
    <ListGroup.Item key={reply._id} className="comment-item p-0 border-0 mb-3">
      <div className="d-flex gap-3">
        {reply.user_image ? (
          <Image
            src={reply.user_image}
            roundedCircle
            className="user-image mr-3"
          />
        ) : (
          <div className="d-grid" style={{ placeItems: 'center', width: 40, height: 40, borderRadius: '50%', background: '#80808020', fontWeight: 600, fontSize: 16 }}>{reply?.user_name?.slice(0, 1)}</div>
        )}
        <div className="comment-content">
          <div
            className="p-2"
            style={{ background: "#00000010", borderRadius: 8 }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <strong>{reply.user_name}</strong>{" "}
                <div className="text-muted small">
                  {timeAgo(reply?.createdAt)} ago
                </div>
              </div>
              {userData._id === reply.user_id && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="p-0 custom-dropdown-toggle"
                    id="dropdown-basic"
                  >
                    <button
                      style={{
                        background: "none",
                        outline: "none",
                        border: "none",
                        display: "flex",
                      }}
                    >
                      <IoIosMore style={{ fontSize: 20 }} />
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item role="button" onClick={() => onCommentOpen(reply, 'Reply', setReplyList)} >Edit Reply</Dropdown.Item>
                    <Dropdown.Item
                      className="d-flex justify-content-between align-items-center gap-2"
                      onClick={onCommentDelete}
                    >
                      {" "}
                      <span>Delete Reply</span>{" "}
                      <FaTrash style={{ color: "red" }} />{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* <div className="text-muted small">5d ago</div> */}
            </div>
            <div className="mt-2">{reply.content}</div>
          </div>
          <div className="mt-2 comment-actions">
            {/* <Button variant="link" className="p-0 comment-action ml-3">Reply</Button> */}
          </div>
        </div>
      </div>
    </ListGroup.Item>
  );
};

export const CommentCard = ({ comment, onComment, onCommentOpen }) => {
  const [replyVisible, setReplyVisible] = useState(false);
  const { getReplyList, userData, timeAgo, commentDelete, getCommentList } =
    useAppContext();
  const [replyList, setReplyList] = useState([]);
  const [rep_count, setRepCount] = useState(comment?.total_reply);
  const [isProcessing, setIsProcessing] = useState(false)
  const [replyData, setReplyData] = useState({
    totalPage: 1,
    currentPage: 0,
    isLoading: false,
  })
  const params = useParams();

  const fetchReply = async (page) => {
    setReplyData({ ...replyData, isLoading: true })
    const data = await getReplyList({
      post_id: comment?.post_id,
      comment_id: comment._id,
      page: page || replyData.currentPage + 1,
      limit: 8
    });
    if (data) {
      if(page === 1 || replyData.currentPage === 0){
        setReplyList(data?.docs);
      }else{
        setReplyList((prevReplies) => [...prevReplies,...data?.docs]);
      }
      setReplyData({
        currentPage: data?.data?.page || 1,
        totalPage: data?.data?.pages || 1,
        isLoading: false,
      });
    } else {
      setReplyData({ ...replyData, isLoading: false })
    }
  };

  useEffect(() => {
    if (replyList.length === 0 && replyVisible) {
      fetchReply();
    }
  }, [replyList, replyVisible]);

  const postComment = async (e) => {
    try {
      setIsProcessing(true)
      await onComment(e, "Reply", comment._id, setRepCount);
      await fetchReply(1);
      setIsProcessing(false)
    } catch (error) { }
  };

  const onCommentDelete = async () => {
    const confirm = await window.confirm(
      "Are you sure want to delete this comment"
    );
    if (!confirm) {
      return;
    }
    const data = await commentDelete(comment._id);
    if (data && data.status === 200) {
      toast.success("Comment deleted successfully");
      getCommentList({ post_id: params.id });
    }
  };

  return (
    <ListGroup.Item key={comment._id} className="comment-item p-0 pb-3 border-0">
      <div className="d-flex gap-3">
        {comment.user_image ? (
          <Image
            src={comment.user_image}
            roundedCircle
            className="user-image mr-3"
          />
        ) : (
          <div className="d-grid" style={{ placeItems: 'center', width: 40, height: 40, borderRadius: '50%', background: '#80808020', fontWeight: 600, fontSize: 16 }}>{comment?.user_name?.slice(0, 1)}</div>
        )}
        <div className="comment-content">
          <div
            className="p-2"
            style={{ background: "#00000010", borderRadius: 8 }}
          >
            <div className="d-flex justify-content-between">
              <div>
                <strong>{comment.user_name}</strong>{" "}
                <div className="text-muted small">
                  {timeAgo(comment?.createdAt)} ago
                </div>
              </div>
              {userData._id === comment.user_id && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="p-0 custom-dropdown-toggle"
                    id="dropdown-basic"
                  >
                    <button
                      style={{
                        background: "none",
                        outline: "none",
                        border: "none",
                        display: "flex",
                      }}
                    >
                      <IoIosMore style={{ fontSize: 20 }} />
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item role="button" onClick={() => onCommentOpen(comment, 'Comment')} >Edit Post</Dropdown.Item>
                    <Dropdown.Item
                      className="d-flex justify-content-between align-items-center gap-2"
                      onClick={onCommentDelete}
                    >
                      {" "}
                      <span>Delete Comment</span>{" "}
                      <FaTrash style={{ color: "red" }} />{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* <div className="text-muted small">5d ago</div> */}
            </div>
            <div className="mt-2">{comment.content}</div>
          </div>
          <div className="mt-2 comment-actions">
            {/* <Button variant="link" className="p-0 comment-action">Like</Button> */}
            <Button
              variant="link"
              className="p-0 comment-action ml-3"
              onClick={() => {
                setReplyVisible(!replyVisible);
              }}
            >
              {rep_count === 0 ? "" : rep_count}{" "}
              {rep_count > 1 ? "Replies" : "Reply"}
            </Button>
          </div>
          {replyVisible && (
            <Form
              className="mt-3 d-flex gap-2 align-items-center"
              onSubmit={(e) => postComment(e)}
            >
              <Form.Group controlId="commentInput" style={{ flex: 1 }}>
                <Form.Control
                  type="text"
                  placeholder="Add a reply..."
                  name="content"
                  required
                />
              </Form.Group>
              <Button
                type="submit"
                className="text-light"
                size="sm"
                disabled={isProcessing}
                style={{ aspectRatio: 1, borderRadius: "50%" }}
              >
                {isProcessing ? (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ): (
                  <IoIosSend style={{ fontSize: 20 }} />
                )}
              </Button>
            </Form>
          )}
        </div>
      </div>
      {replyVisible && (
        <ListGroup className="mt-3 " style={{ paddingLeft: 24 }}>
          <InfiniteScroll
            dataLength={replyList?.length} //This is important field to render the next data
            next={fetchReply}
            hasMore={replyData.currentPage < replyData.totalPage}
            loader={(
              <div className='d-grid' style={{ placeItems: 'center' }}>
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}
            endMessage={
              <p className="text-muted" style={{ textAlign: 'center', letterSpacing: "0.2px", fontSize: 12 }}>
                Reply ends
              </p>
            }
          >
            {Array.isArray(replyList) &&
              replyList?.map((reply, i) => (
                <ReplyCard
                  key={i}
                  reply={reply}
                  onCommentOpen={onCommentOpen}
                  setReplyList={setReplyList}
                  setRepCount={setRepCount}
                />
              ))}

          </InfiniteScroll>
        </ListGroup>
      )}
    </ListGroup.Item>
  );
};