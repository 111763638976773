import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card, Image, Popover, OverlayTrigger, Dropdown } from 'react-bootstrap';
import { FaEdit, FaShareAlt, FaTrash } from 'react-icons/fa';
import { IoIosMore } from 'react-icons/io';
import { SlLike } from "react-icons/sl";
import { MdOutlineModeComment } from 'react-icons/md';
import { useAppContext } from '../../../contextApi/AppContext';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Lightbox from 'yet-another-react-lightbox';
import { Download, Fullscreen, Thumbnails, Zoom } from 'yet-another-react-lightbox/plugins';

const PostCard = ({ post, onOpen }) => {
  const [reactionsVisible, setReactionsVisible] = useState(false);
  const [isExpand, setIsExpand] = useState(false)
  const { removeReaction, addReaction, userData, deletePost, getFeedList, timeAgo } = useAppContext()
  const [showSeeMore, setShowSeeMore] = useState(false);
  const linkRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false)
  const navigate = useNavigate()
  const [Liked, setLiked] = useState(post?.reaction?.type || null)
  const [total_like, setTotalLike] = useState(post?.total_reaction || 0)
  const [open, setOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  useEffect(() => {
    if (linkRef.current) {
      // console.log(linkRef.current.offsetHeight, 'linkRef.current.offsetHeight')
      setShowSeeMore(linkRef.current.offsetHeight >= 66 ? true : false);
    }
  }, [linkRef.current]);

  const handleImageClick = (index) => {
    setLightboxIndex(index);
    setCurrentIndex(index)
    setOpen(true);
  };

  const toggleReactions = async () => {
    if (Liked) {
      const res = await removeReaction(post._id)
      if (res && res.status === 200) {
        setLiked(null)
        setTotalLike(prev => prev - 1)
      }
    } else {
      setReactionsVisible(!reactionsVisible);
    }
  };

  const onReact = async (reaction) => {
    if (isProcessing === true) {
      return
    }
    let res = null
    setReactionsVisible(false)
    setIsProcessing(true)
    res = await addReaction({ type: reaction, post_id: post._id })
    setIsProcessing(false)
    if (res && res.status === 200) {
      setLiked(reaction)
      setTotalLike(prev => prev + 1)
    } else {
      toast.warn("Failed to add reaction")
    }
  }

  const sharePost = async () => {
    const shareData = {
      title: "Post | Dzital",
      text: post?.content?.length > 100 ? post?.content?.slice(0, 100) : post?.content,
      url: `https://dzital.com/#/post-detail/${post._id}`
    }
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log(err.message)
    }
  }

  const onPostDelete = async () => {
    const confirm = await window.confirm('Are you sure want to delete this post')
    if (!confirm) {
      return
    }
    const data = await deletePost(post._id)
    if (data && data.status === 200) {
      toast.success("Post deleted successfully")
      getFeedList({ page: 1, limit: 10 })
    }
  }

  const emojis = {
    love: '❤️',
    like: '👍',
    wow: '😮',
    haha: '😆',
    sad: '😔',
    angry: '😠',
  }

  const popover = (
    <Popover id="popover-basic" >
      <Popover.Body className='p-0' >
        <div className="reactions-dropdown">
          <span style={{ cursor: 'pointer' }} onClick={() => onReact('like')}>👍</span>
          <span style={{ cursor: 'pointer' }} onClick={() => onReact('love')}>❤️</span>
          <span style={{ cursor: 'pointer' }} onClick={() => onReact('haha')}>😆</span>
          <span style={{ cursor: 'pointer' }} onClick={() => onReact('wow')}>😮</span>
          <span style={{ cursor: 'pointer' }} onClick={() => onReact('sad')}>😔</span>
          <span style={{ cursor: 'pointer' }} onClick={() => onReact('angry')}>😠</span>
        </div>
      </Popover.Body>
    </Popover>
  )


  return (
    <Card className="post-card">
      <Card.Body className='p-2'>
        <div className="d-flex justify-content-between gap-3 mb-2">
          <Link to={`/seller-info/${post.user_id}`} className='d-flex align-items-center gap-3' style={{ all: 'unset', cursor: 'pointer' }}>
            {post.user_image ? (
              <Image src={post.user_image} roundedCircle className="profile-img" />
            ) : (
              <div className="d-grid" style={{ placeItems: 'center', width: 40, height: 40, borderRadius: '50%', background: '#80808020', fontWeight: 600, fontSize: 16 }}>{post?.user_name?.slice(0, 1)}</div>
            )}

            <div className="post-info">
              <h5>{post.user_name}</h5>
              <p className='text-muted small' style={{ letterSpacing: '0.5px' }}>{timeAgo(post?.createdAt)} ago</p>
            </div>
          </Link>
          {userData._id === post.user_id && (
            <Dropdown>
              <Dropdown.Toggle variant='light' className='p-0 custom-dropdown-toggle' id="dropdown-basic">
                <button style={{ background: 'none', outline: 'none', border: 'none', display: 'flex' }}><IoIosMore style={{ fontSize: 20 }} /></button>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item role='button' className='d-flex justify-content-between align-items-center' onClick={() => onOpen(post)}>
                  <span>Edit Post</span> <FaEdit />
                </Dropdown.Item>
                <Dropdown.Item className='d-flex justify-content-between align-items-center' onClick={onPostDelete} > <span>Delete Post</span> <FaTrash style={{ color: 'red' }} /> </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>

        <Link
          to={`/post-detail/${post._id}`}
          ref={linkRef}
          style={{
            display: 'block',
            overflow: 'hidden',
            cursor: 'pointer',
            color: 'unset',
            textDecoration: 'none'
          }}
        >
          <div style={{
            maxHeight: isExpand ? 'max-content' : '66px',
          }}>
            <h5 className='m-0 my-1'>{post?.title}</h5>
            <Card.Text style={{ lineHeight: 'normal' }} dangerouslySetInnerHTML={{ __html: post.content }} />
            <div className='d-flex align-items-center gap-2 flex-wrap mt-2'>
              {post?.keywords?.map((keyword, index) => (
                <span className='text-primary' key={index}>
                  #{keyword}
                </span>
              ))}
            </div>
          </div>
        </Link>

        {showSeeMore && (
          <p
            className='m-0'
            style={{
              fontWeight: 500,
              color: "#808080",
              textAlign: 'end',
              cursor: 'pointer'
            }}
            onClick={() => setIsExpand(!isExpand)}
          >
            {isExpand ? 'See less...' : '...See more'}
          </p>
        )}

        {post?.files?.length > 0 && (
          // <Link to={`/post-detail/${post._id}`}>
          //   <Image src={post?.files[0]} className="post-img" style={{ aspectRatio: 4 / 3, objectFit: 'cover' }} />
          // </Link>
          <>
            <div className=''>
              <Image src={post?.files[0]} className="post-img" style={{ aspectRatio: 4 / 3, objectFit: 'cover', flex: 1 }} onClick={() => handleImageClick(0)} />
              <div className="d-flex gap-2 mt-3" >
                {post?.files?.length > 1 && post?.files?.map((item, index) => (
                  <Fragment key={index}>
                    {index < 3 && (
                      <div
                        onClick={() => handleImageClick(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={item}
                          style={{
                            objectFit: "cover",
                            width: '100%',
                            maxWidth: '100px',
                            aspectRatio: 4 / 3,
                            borderRadius: 6,
                            border:
                              currentIndex === index
                                ? "2px solid gray"
                                : "1px solid gray",
                          }}
                          alt={`Thumbnail ${index}`}
                        />
                      </div>
                    )}
                    {index >= 3 && (
                      <div
                        onClick={() => handleImageClick(3)}
                        className='d-flex justify-content-center align-items-center flex-column '
                        style={{ cursor: "pointer", aspectRatio: 4 / 3, borderRadius: 6, background: '#000000', color: '#fff', placeItems: 'center', opacity: 0.8, maxWidth: '100px', width: '100%' }}
                      >
                        <h4 className='m-0'>+ {post?.files?.length - 3} </h4>
                        <span>More</span>
                      </div>
                    )}

                  </Fragment>
                ))}
              </div>
            </div>
            <Lightbox
              plugins={[Download, Fullscreen, Zoom, Thumbnails]}
              open={open}
              close={() => setOpen(false)}
              slides={post?.files?.map((src) => ({ src }))}
              index={lightboxIndex}
            />
          </>
        )}

        <div className='d-flex justify-content-between align-items-center mt-2'>
          {/* <Link to={'/'}></Link> */}
          <span style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => navigate(`/post/reactions/${post._id}`)}>{total_like > 0 ? `${total_like} likes` : ''}</span>
          <span style={{ fontSize: 12, cursor: 'pointer' }} onClick={() => navigate(`/post-detail/${post._id}`)}>{post?.total_comment > 0 ? `${post?.total_comment} comment` : ''}</span>
        </div>

        <hr className='my-2' />

        <div className="post-actions">
          <OverlayTrigger
            trigger="click"
            placement="top"
            overlay={popover}
            show={reactionsVisible}
            onToggle={toggleReactions}
            rootClose
          >
            <button className="p-action-btn">
              {Liked ? emojis[Liked] : <SlLike />}
              <span> {Liked || "Like"}</span>
            </button>
          </OverlayTrigger>

          <button className="p-action-btn" onClick={() => navigate(`/post-detail/${post._id}`)}>
            <MdOutlineModeComment />
            <span> Comment</span>
          </button>

          <button className="p-action-btn" onClick={sharePost}>
            <FaShareAlt />
            <span> Share</span>
          </button>
        </div>

      </Card.Body>
    </Card >
  );
};

export default PostCard;