
import { Container, Image, ListGroup, Navbar } from 'react-bootstrap'
import logo from "../../../assets/assets/images/logo.png";
import { useAppContext } from '../../../contextApi/AppContext';
import { Link, useNavigate } from 'react-router-dom'
import { Popover, OverlayTrigger, Button, Card } from 'react-bootstrap';
import { FaCaretDown, FaWallet } from 'react-icons/fa';
import { useState } from 'react';

export const SellerHeader = () => {
    const { userData, LogOut } = useAppContext()
    const [show, setShow] = useState(false);

    const handleToggle = () => setShow(!show);
    const handleClose = () => setShow(false)
    const Navigate = useNavigate()

    const popover = (
        <Popover id="popover-basic" style={{ minWidth: '300px' }}>
            <Popover.Body >
                <Card.Body className="text-center" >
                    <Image src={userData.image} roundedCircle width="70" height="70" />
                    <Card.Title className="mt-2">{userData.name}</Card.Title>
                    <Card.Text className="text-muted">{userData.email}</Card.Text>
                    <Button onClick={() => Navigate('/account')} variant="outline-primary" className="mb-2" style={{ width: '100%' }}>Go to profile</Button>
                    <hr />
                    <ListGroup variant="flush">
                        <ListGroup.Item action onClick={() => LogOut()}>Sign out</ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Popover.Body>
        </Popover>
    );

    return (
        <Navbar expand="sm" bg="light" className="border-bottom border-1">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} alt="logo" style={{ width: "100px" }} />
                </Navbar.Brand>
                {/* desktop search box end */}
                <ul className="navListInline d-flex align-items-center gap-3">
                    <li>
                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            overlay={popover}
                            show={show}
                            onToggle={handleToggle}
                            rootClose
                        >
                            <Button variant="light" onClick={handleToggle} className="d-flex align-items-center border-0">
                                <Image sizes='sm' src={userData.image} roundedCircle width="30" height="30" className="mr-2" />
                                <span className="mx-2">{userData.name}</span>
                                <FaCaretDown />
                            </Button>
                        </OverlayTrigger>
                    </li>
                    <li className='d-flex align-items-center gap-2'>
                        <FaWallet width={24} height={24} style={{color: "#168f9f"}} />
                        <span>{userData?.wallet_amount} Rs</span>
                    </li>
                </ul>
            </Container>
        </Navbar>
    )
}