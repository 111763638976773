import React, { useEffect, useState } from "react";
import Ads from "./../components/comon/Ads";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import { toast } from "react-toastify";
import JobApply from "../components/modal/JobApply";
import { Card, Button, Row, Col, Badge, ListGroup } from "react-bootstrap";
import { FaHome, FaRupeeSign } from "react-icons/fa";
import { FcDepartment } from "react-icons/fc";
import { MdOutlineWorkOutline } from "react-icons/md";
import { FaClock } from "react-icons/fa";
import { BsBriefcaseFill } from "react-icons/bs";
import { BsBook } from "react-icons/bs";
import { FaPen } from "react-icons/fa";
import { SiFireship } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { MdShare } from "react-icons/md";
import CallAction from "../components/comon/CallAction";
import defaultCompnayLogo from "../../src/assets/assets/images/g-logo.png";
import { HiCheck } from "react-icons/hi";
import { SlBadge } from "react-icons/sl";
const JobDetail = ({ initialDetail }) => {
  const {
    getJobListDetail,
    jobDetail,
    setJobDetail,
    getJobList,
    getReletedProduct,
    isLogIn,
    reletedList,
    userData,
    wishListAdd,
    getreviews,
  } = useAppContext();
  const { id } = useParams();
  const location1 = useLocation();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [visibleItems, setVisibleItems] = useState(2);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getJobListDetail(id);
    };
    if (!initialDetail && id) {
      fetchData();
    }
    if (!!initialDetail && !!initialDetail.data && !!initialDetail.data._id) {
      setJobDetail(initialDetail);
    }
    // eslint-disable-next-line
  }, [id, initialDetail]);

  useEffect(() => {
    const fetchData = async () => {
      await getJobList(id);
    };
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const {
    description,
    title,
    attribute_values,
    createdAt,
    category_id,
    product_id,
    sub_category_id,
    userId,
    _id,
    JobApplied,
    company_logo,
    image,
    category_slug,
    category_name,
    company_website_link,
  } = jobDetail.data || {};

  useEffect(() => {
    if (product_id) {
      getreviews(product_id);
    }
    // eslint-disable-next-line
  }, [product_id]);

  const getAttributeValue = (attributeName) => {
    if (!Array.isArray(attribute_values)) return null;
    const attribute = attribute_values.find(
      (item) => item.attribute === attributeName
    );
    if (attribute) {
      return attribute.value;
    } else {
      return null;
    }
  };
  const experience = getAttributeValue("Experience");
  const salary = getAttributeValue("Salary");
  const companyName = getAttributeValue("Company Name");
  const Experience = getAttributeValue("Experience");
  const Education = getAttributeValue("Education");
  const EmploymentType = getAttributeValue("Employment Type");
  const JobShift = getAttributeValue("Job Shift");
  const Workmode = getAttributeValue("Work mode");
  const CompanyName = getAttributeValue("Company Name");
  const AverageIncentives = getAttributeValue("Average Incentives");

  const formatDuration = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diff = now - createdDate;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 1) {
      return `${days} days`;
    } else {
      return `${hours} hours, ${minutes} minutes`;
    }
  };
  const formattedDuration = formatDuration(createdAt);

  useEffect(() => {
    if (product_id && category_id) {
      getReletedProduct(category_id, sub_category_id, product_id);
    }
    // eslint-disable-next-line
  }, [product_id, category_id, sub_category_id]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  const postWishlist = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    wishListAdd(jobDetail?.data.product_id);
  };

  const sharePost = async () => {
    const shareData = {
      title: "Dzital",
      url: window.location.href,
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error sharing the post:", err.message);
    }
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-12">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/${category_slug}?category_id=${category_id}`}>
                    {category_name}
                  </Link>
                </li>
                <li className="active">Detail Page Of {title}</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>

      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            <Ads />

            <Col md={6}>
              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={2} className="text-center">
                      <Link
                        to={`/seller-info/${jobDetail?.data?.seller_details?._id}`}
                      >
                        <img
                          src={company_logo || defaultCompnayLogo}
                          alt="Company Logo"
                          className="img-fluid rounded"
                        />
                      </Link>
                    </Col>
                    <Col xs={10}>
                      <h5 className="mb-1">{title} </h5>
                      <p className="text-muted mb-1">{companyName}</p>
                      <div className="d-flex align-items-center mb-1">
                        <FaHome className="me-2 text-muted" />
                        <p className="text-muted mb-0">{JobShift} Shift</p>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <FaRupeeSign className="me-2 text-muted" />
                        <p className="fw-bold mb-0">{salary}</p>
                      </div>
                      <small className="text-muted">
                        You can earn more incentive if you perform well
                      </small>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="text-center">
                    <Col xs={6}>
                      <p className="mb-1">
                        <strong>Fixed</strong>
                      </p>
                      <p className="mb-0">₹{salary} </p>
                    </Col>
                    <Col xs={6}>
                      <p className="mb-1">
                        <strong>Average Incentives</strong>
                      </p>
                      {AverageIncentives ? (
                        <p className="mb-0">₹ {AverageIncentives} </p>
                      ) : (
                        "N/A"
                      )}
                    </Col>
                    {/* <Col xs={6}>
                      <p className="mb-1">
                        <strong>Earning Potential</strong>
                      </p>
                      <p className="mb-0">₹87,000</p>
                    </Col> */}
                  </Row>
                  <div className="d-flex justify-content-center mt-3">
                    <Badge bg="success" className="me-2">
                      {EmploymentType}
                    </Badge>
                    <Badge bg="info" className="me-2">
                      {experience} Experience
                    </Badge>
                    <Badge bg="light" className="text-dark">
                      {Education}
                    </Badge>
                  </div>
                  <div className="row mt-3">
                    <div className="d-flex justify-content-between">
                      <p className="col-md-6 mt5 mb5 text16">
                        <b>Posted :</b> {formattedDuration} ago
                      </p>
                      {userData._id === userId ? (
                        " "
                      ) : (
                        <div className="">
                          {!isLogIn ? (
                            <>
                              <button
                                onClick={() => redirect("/message")}
                                className="btn btn-primary text-white"
                              >
                                Login to Apply
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                type="button"
                                className="btn btn-primary px-3 text-white"
                                onClick={handleShowModal}
                                disabled={JobApplied}
                              >
                                {JobApplied ? "Applied" : "Apply"}
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-primary px-3 text-white"
                                onClick={postWishlist}
                              >
                                {" "}
                                <i
                                  className="fa fa-bookmark-o "
                                  style={{ marginRight: "5px" }}
                                />
                                save
                              </button>{" "}
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="btn btn-primary px-3 text-white"
                                onClick={sharePost}
                              >
                                {" "}
                                <MdShare style={{ marginRight: "5px" }} />
                                Share
                              </button>
                              <JobApply
                                showModal={showModal}
                                title={title}
                                handleCloseModal={handleCloseModal}
                                id={_id}
                              />
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <div className="jobheighlight-bg">
                    <h4 className="card-title">Job Highlights</h4>
                    <div
                      style={{
                        width: "250px",
                        border: "1px solid , #00000085",
                      }}
                    ></div>
                    <Row className="mt-2">
                      <Col md={12}>
                        <p
                          className="d-flex"
                          style={{ alignItems: "center", gap: "5px" }}
                        >
                          <strong style={{ color: "#f3a009e8" }}>
                            <SiFireship />{" "}
                          </strong>{" "}
                          Urgently hiring
                        </p>
                        <p
                          className="d-flex"
                          style={{ alignItems: "center", gap: "5px" }}
                        >
                          <strong style={{ color: "#1564db" }}>
                            <IoIosPeople style={{ fontSize: "20px" }} />{" "}
                          </strong>{" "}
                          93 applicants
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>

              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <div className="accordion mt-4">
                    {/* item start */}
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button py-2"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          <h5 className="card-title">Job Description</h5>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                      >
                        <div className="accordion-body">
                          <div className="row clothDtl">
                            {Workmode && (
                              <p className="mt-2">
                                <strong style={{ color: "#1564db" }}>
                                  Job type -{" "}
                                </strong>{" "}
                                {Workmode}
                              </p>
                            )}
                            {description &&
                              (/<\/?[a-z][\s\S]*>/i.test(description) ? (
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: description,
                                  }}
                                />
                              ) : (
                                <p>
                                  <strong style={{ color: "#1564db" }}>
                                    Job Description -
                                  </strong>{" "}
                                  {description}
                                </p>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* item end */}
                  </div>
                </Card.Body>
              </Card>

              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <h4 className="card-title">Job Role</h4>
                  <div
                    style={{ width: "250px", border: "1px solid , #00000085" }}
                  ></div>
                  <Row className="mt-2">
                    <Col md={6}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <FcDepartment />{" "}
                        <strong style={{ color: "#1564db" }}>
                          {" "}
                          Department -{" "}
                        </strong>{" "}
                        Retail & eCommerce
                      </p>
                    </Col>
                    <Col md={6}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <MdOutlineWorkOutline style={{ color: "#1564db" }} />{" "}
                        <strong style={{ color: "#1564db" }}>Role</strong>{" "}
                        Retail Sales & Operations
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <SlBadge style={{ color: "#1564db" }} />{" "}
                        <strong style={{ color: "#1564db" }}>
                          Employment type -{" "}
                        </strong>{" "}
                        {EmploymentType}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <FaClock style={{ color: "#1564db" }} />
                        <strong style={{ color: "#1564db" }}>
                          Shift -{" "}
                        </strong>{" "}
                        {JobShift}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <h4 className="card-title">Job Requirements</h4>
                  <div
                    style={{ width: "250px", border: "1px solid , #00000085" }}
                  ></div>
                  <Row className="mt-2">
                    <Col md={6}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <BsBriefcaseFill style={{ color: "#1564db" }} />{" "}
                        <strong style={{ color: "#1564db" }}>
                          Experience -{" "}
                        </strong>{" "}
                        {Experience}
                      </p>
                    </Col>
                    <Col md={6}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <BsBook style={{ color: "#1564db" }} />{" "}
                        <strong style={{ color: "#1564db" }}>
                          Education -{" "}
                        </strong>{" "}
                        {Education}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <FaPen style={{ color: "#1564db", fontSize: "14px" }} />{" "}
                        <strong style={{ color: "#1564db" }}>Skills - </strong>{" "}
                        {jobDetail.data.skills?.map((item, index) => (
                          <span key={index}>
                            {item}
                            {index < jobDetail.data.skills.length - 1 && ","}
                          </span>
                        ))}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <div className="accordion mt-4">
                    {/* item start */}
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button py-2"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                        >
                          <h5 className="card-title">More Details</h5>
                        </button>
                      </h2>
                      <div id="collapseTwo" className="accordion-collapse collapse">
                        <div className="accordion-body">
                          <div className="row clothDtl">
                            {Array.isArray(attribute_values) &&
                              attribute_values.map((item) => (
                                <>
                                  <p
                                    key={item._id}
                                    className="d-flex"
                                    style={{ alignItems: "center", gap: "5px" }}
                                  >
                                    <strong style={{ color: "#1564db" }}>
                                      <HiCheck />{" "}
                                    </strong>{" "}
                                    <b style={{ color: "#1564db" }}>
                                      {item.attribute}{" "}
                                    </b>{" "}
                                    :<span> {item.value}</span>
                                  </p>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* item end */}
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <h4 className="card-title">About Company</h4>
                    <Link
                      className="btn btn-info ms-auto text-white rounded-pill px-4"
                      to={`/seller-info/${jobDetail?.data?.seller_details?._id}`}
                      style={{
                        background: "linear-gradient(45deg, #007bff, #6610f2)",
                        border: "none",
                        fontSize: "0.95rem",
                        fontWeight: "600",
                      }}
                    >
                      View Details
                    </Link>
                  </div>
                  <div
                    style={{ width: "250px", border: "1px solid #00000085" }}
                  ></div>
                  <Row className="mt-2">
                    <Col md={2}>
                      <i className="bi bi-building fs-1 text-primary"></i>
                    </Col>
                    <Col md={12}>
                      <p>
                        <strong style={{ color: "#1564db" }}>Name: </strong>
                        {CompanyName}
                      </p>
                      <p>
                        <strong style={{ color: "#1564db" }}>Address: </strong>
                        Salt Lake, Kolkata, Indi
                      </p>
                      <p>
                        <strong style={{ color: "#1564db" }}>Website: </strong>
                        {company_website_link}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>

            <Col
              md={4}
              className="sticky-top"
              style={{ height: "100%", zIndex: "1" }}
            >
              <Card className="mb-4 shadow-sm border-0 rounded-lg overflow-hidden">
                <img
                  src={image}
                  alt="Company Logo"
                  className="img-fluid rounded-top"
                  style={{ height: "35vh", objectFit: "cover", width: "100%" }}
                />
                <Card.Body className="p-4">
                  <Card.Title className="fw-bold text-dark mb-3">
                    {title}
                  </Card.Title>
                  <Card.Title className="fw-bold text-dark mb-3">
                    <CallAction peerUser={userId} />
                  </Card.Title>
                  <h6 className="fw-bold text-muted mb-3">Similar jobs</h6>
                  <ListGroup variant="flush">
                    {Array.isArray(reletedList) &&
                      reletedList.slice(0, visibleItems).map((item, index) => {
                        const Experience = item?.attribute_values?.find(
                          (item1) => item1.attribute === "Experience"
                        );
                        return (
                          <Link
                            className="text-decoration-none "
                            to={`/product/related-product/${item._id}`}
                            key={index}
                          >
                            <ListGroup.Item
                              className="p-4 rounded shadow-sm job-box mb-3"
                            >
                              <div className="d-flex align-items-center">
                                {/* Job Image */}
                                <img
                                  src={item.image}
                                  alt="Job Logo"
                                  className="me-3"
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                    border: "2px solid #dee2e6",
                                  }}
                                />
                                {/* Job Details */}
                                <div className="flex-grow-1">
                                  <h6 className="mb-1 fw-bold text-primary job-title">
                                    {item.title}
                                  </h6>
                                  <p className="mb-2 text-muted">
                                    {item.address}
                                  </p>
                                  {/* Additional details in grid layout */}
                                  <div className="row">
                                    {/* Job Type */}
                                    <div className="col-auto">
                                      <span className="badge bg-primary p-2 text-white">
                                        {Experience || "Job Type"}
                                      </span>
                                    </div>
                                    {/* Salary */}
                                    <div className="col-auto">
                                      <span className="badge bg-info p-2 text-white">
                                        Salary: {item.salary || "N/A"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ListGroup.Item>
                          </Link>
                        );
                      })}
                  </ListGroup>

                  {visibleItems < reletedList.length && (
                    <Button
                      variant="outline-secondary"
                      className="mt-3 w-100 rounded-pill"
                      onClick={showMoreItems}
                    >
                      Show more
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Ads />
          </article>
        </article>
      </section>
    </>
  );
};

export default JobDetail;
