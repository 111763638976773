import { useState } from "react";
import { Button, Modal, Form, Row, Col, Image } from "react-bootstrap"
import { FaPlus, FaTrash } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import { useAppContext } from "../../../contextApi/AppContext";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const CreatePostForm = ({ onHide, initialData, clearContent, isEdit }) => {
    const [content, setContent] = useState(initialData?.content || '');
    const [title, setTitle] = useState(initialData?.title || '');
    const [files, setFiles] = useState([]); // For backend submission
    const [filePreviews, setFilePreviews] = useState(initialData?.files || []); // For user preview
    const [keywords, setKeywords] = useState(initialData?.keywords || []);
    const [keywordInput, setKeywordInput] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { createFeedPost, getFeedList, updateFeedPost } = useAppContext()

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles([...files, ...selectedFiles]);
        const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file) );
        setFilePreviews([...filePreviews, ...newPreviews]);
        // console.log(newPreviews)
    };

    const handleRemoveFile = (indexToRemove) => {
        const newFiles = files.filter((_, index) => index !== indexToRemove);
        const newPreviews = filePreviews.filter((_, index) => index !== indexToRemove);
        setFiles(newFiles);
        setFilePreviews(newPreviews);
    };

    const handleKeywordChange = (e) => setKeywordInput(e.target.value);

    const handleAddKeyword = () => {
        if (keywordInput.trim()) {
            setKeywords([...keywords, keywordInput.trim()]);
            setKeywordInput(''); // Clear input after adding
        }
    };

    const handleRemoveKeyword = (keyword) => {
        setKeywords(keywords.filter((kw) => kw !== keyword));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formdata = new FormData()
        formdata.append('content', content)
        formdata.append('title', title)
        files.forEach((file) => formdata.append('files', file))
        if (keywords && keywords.length > 0) {
            formdata.append('keywords', keywords.join(','))
        }
        setIsSubmitting(true)
        let data = null
        if(isEdit){
            const existingFile = filePreviews.filter((file) =>  file.includes("res.cloudinary.com"))
            if(Array.isArray(existingFile) && existingFile.length > 0){
                formdata.append('existing_files', existingFile?.join(','))
            }
            data = await updateFeedPost(formdata, initialData?._id)   
        }else{
            data = await createFeedPost(formdata)   
        }
        if (data && data?.status === 200) {
            toast.success(data.message)
            getFeedList({ page: 1, limit: 20 })
            onHide()
            if (!isEdit) {
                clearContent('')
            }
        }
        setIsSubmitting(false)
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formText" className="mb-2">
                <Form.Label>Title</Form.Label>
                <Form.Control
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                    value={title}
                    required
                />
            </Form.Group>
            <Form.Group controlId="formContent" className="mb-2">
                <Form.Label>Content</Form.Label>
                <ReactQuill
                    id="description"
                    value={content}
                    onChange={(value) =>
                        setContent(value)
                    }
                    theme="snow"
                    className=""
                    placeholder="What's on your mind?"
                    style={{
                        borderRadius: "8px",
                    }}
                />
            </Form.Group>

            <Form.Group controlId="formFiles">
                <Form.Label>Attach Files</Form.Label>
                <Form.Control
                    type="file"
                    multiple
                    onChange={handleFileChange}
                />
            </Form.Group>
            <div className="mt-3">
                {filePreviews.length > 0 && (
                    <div>
                        <Row>
                            {filePreviews.map((preview, index) => (
                                <Col key={index} xs={6} md={4} className="">
                                    <div className="d-flex flex-column align-items-center position-relative">
                                        <Image
                                            src={preview || preview?.preview}
                                            alt={'Post'}
                                            style={{ aspectRatio: 4 / 3, objectFit: 'contain' }}
                                            thumbnail
                                            className="mb-2"
                                        />
                                        <Button
                                            variant="danger"
                                            className="position-absolute"
                                            style={{ top: 8, right: 8 }}
                                            size="sm"
                                            onClick={() => handleRemoveFile(index)}
                                        >
                                            <FaTrash />
                                        </Button>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                )}
            </div>

            <Row>
                <Col>
                    <Form.Group controlId="formKeywords">
                        <Form.Label>Keywords (Hashtags)</Form.Label>
                        <Form.Control
                            type="text"
                            value={keywordInput}
                            onChange={handleKeywordChange}
                            placeholder="Add a keyword and press enter"
                        />
                    </Form.Group>
                </Col>
                <Col xs="auto" className="d-flex align-items-end">
                    <Button variant="primary" className="text-light" onClick={handleAddKeyword}>
                        <FaPlus />
                    </Button>
                </Col>
            </Row>

            <div className="mt-3">
                {keywords.map((keyword, index) => (
                    <Button
                        key={index}
                        variant="secondary"
                        size="sm"
                        className="me-2 mb-2"
                        onClick={() => handleRemoveKeyword(keyword)}
                    >
                        #{keyword} <FaXmark style={{ fontSize: 20 }} />
                    </Button>
                ))}
            </div>
            <hr />
            <div className="d-flex justify-content-end gap-3">
                <Button variant="secondary" type="button" onClick={onHide} disabled={isSubmitting}>
                    Close
                </Button>
                <Button variant="primary" type="submit" className="text-light" disabled={isSubmitting}>
                    {isEdit ? 'Update' : 'Create'} Post
                </Button>

            </div>
        </Form>
    );
};

export const PostModal = ({ showModal, onHide, initialData, clearContent, isEdit }) => {
    return (
        <Modal
            show={showModal}
            size="lg"
            onHide={onHide}
            centered
            className="premium-modal"
        >
            <Modal.Header closeButton className="bg-light">
                <Modal.Title className="w-100 text-center text-dark">
                    {isEdit ? 'Edit Your Post' : 'Create New Post'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CreatePostForm onHide={onHide} initialData={initialData} clearContent={clearContent} isEdit={isEdit} />
            </Modal.Body>
        </Modal>
    )
}