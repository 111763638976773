import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Ads from "../../../components/comon/Ads";
import AccountSideBar from "../AccountSideBar";
import { useAppContext } from "../../../contextApi/AppContext";
import { Button } from "react-bootstrap";

const MyEnrollmentsStatic = () => {
  const { getSubscriptionList, subscriptionList } = useAppContext();

  useEffect(() => {
    getSubscriptionList();
    // eslint-disable-next-line
  }, []);

  const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) return description;
    return description.substring(0, maxLength) + "...";
  };

  const formatDateTime = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>My Enrollments</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">My Enrollments</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bg-light">
        <div className="container-fluid">
          <div className="row">
            {/* Sidebar */}
            <Ads />
            <div className="col-md-3 mb-4 mb-md-0">
              <AccountSideBar />
            </div>

            {/* Main Content */}
            <div className="col-md-7">
              <div className="card border-0 shadow-sm rounded">
                <div className="card-body">
                  <h2 className="mb-4">My Enrollments</h2>
                  <p className="text-muted mb-4">
                    Here you can see all your purchased courses.
                  </p>
                  <hr />

                  <div className="row">
                    {Array.isArray(subscriptionList) &&
                      subscriptionList.map((order) => (
                        <div key={order.id} className="col-12 mb-4">
                          <div className="card shadow-sm h-100 border-0 rounded-lg">
                            <div className="row g-0">
                              <div className="col-md-4">
                                {order?.product_details?.image && (
                                    <img
                                      src={order.product_details.image}
                                      className="card-img-top h-100 rounded-start"
                                      alt={order?.product_details?.title}
                                    />
                                  )}
                              </div>
                              <div className="col-md-8">
                                <div className="card-body d-flex flex-column">
                                  <h5 className="card-title">
                                    {order?.product_details?.title}
                                  </h5>
                                  <p className="card-text" dangerouslySetInnerHTML={{ __html: order?.product_details?.description }}
                                    style={{maxHeight: 50, overflow: 'hidden'}}
                                  />
                                  {order.upcoming_meetings && (
                                    <div className="mt-0">
                                      <strong>Upcoming Class:</strong>
                                      <div className="d-flex align-items-center mt-2">
                                        <Button
                                          onClick={() => {
                                            window.open(
                                              order.upcoming_meetings
                                                .meeting_join_url,
                                              "_blank"
                                            );
                                          }}
                                          variant="success"
                                          className="btn-sm"
                                        >
                                          Join
                                        </Button>
                                        <div className="ms-3">
                                          <p className="mb-1">
                                            Agenda:{" "}
                                            {
                                              order.upcoming_meetings
                                                .meeting_agenda
                                            }
                                          </p>
                                          <p className="mb-0">
                                            Time:{" "}
                                            {formatDateTime(
                                              order.upcoming_meetings.meetingAt
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <Link
                                    to={`/my-subscription/detail/${order._id}`}
                                    className="btn btn-primary btn-sm mt-3 align-self-start text-white"
                                  >
                                    START LEARNING
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Ads */}

            <Ads />
          </div>
        </div>
      </section>
    </>
  );
};

export default MyEnrollmentsStatic;