import React, { useEffect } from "react";
import Ads from "../../components/comon/Ads";
import { useAppContext } from "../../contextApi/AppContext";

const CustomerSupport = () => {
  const { getCmsList, mainData, loading } = useAppContext();
  const data = "customer-support";

  useEffect(() => {
    getCmsList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {/* <!-- page header part start here --> */}
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>Customer Support</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <a href="index.php">Home</a>
                </li>
                <li className="active">Customer Support</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      {/* <!-- page header part end here --> */}
      {/* <!-- page body part start --> */}
      <section className="midBody" style={{ backgroundColor: "#fff" }}>
        <article className="container-fluid">
          <article className="row">
            {/* <!-- ad box start --> */}
            <Ads />
            {/* <!-- ad box end --> */}
            {/* <!--*************** Body part start ***************--> */}

            {loading ? (
              <div className="col-md-10 d-flex justify-content-center ">
                <div className="spinner-grow text-info my-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-md-10">
                {/* <!-- about content part start --> */}
                <div>
                  <h3>Customer Support</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mainData?.aboutUs?.description,
                    }}
                  />
                </div>
                {/* <!-- about content part end --> */}
              </div>
            )}
            {/* <!--*************** Body part end ***************--> */}
            {/* <!-- ad box start --> */}
            <Ads />
            {/* <!-- ad box end --> */}
          </article>
        </article>
      </section>
      {/* <!-- page body part end --> */}
    </div>
  );
};

export default CustomerSupport;
