import React from "react";
import {useNavigate } from "react-router-dom";
import img from '../../assets/error-no-search-results_2353c5.png'

const NoData = ({des}) => {
  const navigate = useNavigate();

  const HandleBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <section className="py-3 py-md-2 min-vh-90 d-flex justify-content-center align-items-center">
        <div className="text-center">
          <h2 className="d-flex justify-content-center align-items-center gap-2 mb-4">
            <img src={img} alt="" />
            <i className="bi bi-exclamation-circle-fill text-danger display-4"></i>
          </h2>
          <h3 className="h2 mb-2">Sorry, no results found!</h3>
          <p className="mb-5">{des}</p>
          <button
            className="btn bsb-btn-5xl btn-dark bg-primary rounded-pill px-5 fs-6 m-0"
            onClick={HandleBack}
          >
            Go Back
          </button>
        </div>
      </section>
    </div>
  );
};

export default NoData;
