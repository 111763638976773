import { Button, Col, Container, Image, Row, Spinner } from "react-bootstrap"
import LeftSidebar from "./component/left_sidebar"
import { IoArrowBack } from "react-icons/io5"
import { RightSidebar, TrendingPostsSkeleton } from "./component/right_sidebar"
import RecentConnections from "./component/recent-connections"
import { useAppContext } from "../../contextApi/AppContext"
import { useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

export const PostReactions = () => {
    const { userData, getTrendingPost, trendingPosts, recentConnectionList, recentConnections, getReactionList, reactionData, reactionList, timeAgo } = useAppContext()

    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (params.id) {
            getReactionList({ post_id: params.id, page: 1, limit: 20 })
        }
    }, [params.id])

    useEffect(() => {
        if (trendingPosts.isLoading === false && trendingPosts.data === null) {
            getTrendingPost()
        }
    }, [trendingPosts])

    useEffect(() => {
        if (recentConnections.isLoading === false && recentConnections.data === null) {
            recentConnectionList()
        }
    }, [recentConnections])

    const users = [
        {
            name: "Susmita Jhunjhunwala Saha",
            connection: "1st",
            title: "UI/UX Designer",
            image: "https://via.placeholder.com/50", // replace with actual image source
        },
        {
            name: "Nagashree Sherugar",
            connection: "2nd",
            title: "Student at Bhandarkars' college of arts and science Kundapur.",
            image: "https://via.placeholder.com/50",
        },
        {
            name: "Niranjan Thangavel",
            connection: "3rd+",
            title: "Product Designer | User Experience, Design Systems",
            image: "https://via.placeholder.com/50",
        },
        {
            name: "Vijay Bir Lingwal",
            connection: "3rd+",
            title: "UI Designer @TekToad",
            image: "https://via.placeholder.com/50",
        },
        {
            name: "Tahira Begam Ahamad",
            connection: "3rd+",
            title: "Looking for UX Designer Role | Heuristic evaluation | Design system | User interface | Generative AI",
            image: "https://via.placeholder.com/50",
        },
        // Add more users as needed
    ];

    const emojis = {
        love: '❤️',
        like: '👍',
        wow: '😮',
        haha: '😆',
        sad: '😔',
        angry: '😠',
    }

    return (
        <Container fluid className="feed-container">
            <Row>
                <Col md={3} className="sidebar">
                    {/* Sidebar content */}
                    <LeftSidebar />
                </Col>
                <Col md={6} className="px-0">
                    <div className="d-flex align-items-center mt-3 px-2 gap-3">
                        <button style={{ all: 'unset', cursor: 'pointer' }} onClick={() => navigate(-1)}><IoArrowBack style={{ fontSize: 24 }} /></button>
                        <h4 className="m-0">Reactions</h4>
                    </div>
                    <div>
                        <div className="d-flex justify-content-between align-items-center p-3 pb-0">
                            <Button variant="secondary" size="sm">ALL {reactionData?.totalReactions}</Button>
                        </div>
                    </div>
                    <hr />
                    {reactionData.isLoading && (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    )}
                    <div className="d-flex flex-column gap-3 px-3 pb-3">
                        {reactionList?.map((user, index) => {
                            return (
                                <div key={index} className="d-flex justify-content-between align-items-center" >
                                    <Link style={{ all: 'unset', cursor: 'pointer' }} to={`/seller-info/${user.user_id}`} className="d-flex gap-3">
                                        <Image src={user.user_image} roundedCircle width="50" height="50" className="user-image mr-3" />
                                        <div className="comment-content">
                                            <div>
                                                <div className="d-flex justify-content-between" >
                                                    <div>
                                                        <strong>{user.user_name}</strong>  <div className="text-muted small">{timeAgo(user?.createdAt)} ago</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <span style={{ fontSize: 24 }}>{emojis[user.reaction]}</span>
                                </div>
                            )
                        })}
                    </div>
                </Col>
                <Col md={3} className="sidebar">
                    {/* Sidebar content */}
                    {recentConnections.isLoading ? (
                        <TrendingPostsSkeleton />
                    ) : (
                        <RecentConnections />
                    )}
                    {trendingPosts.isLoading ? (
                        <TrendingPostsSkeleton />
                    ) : (
                        <RightSidebar />
                    )}
                </Col>
            </Row>
        </Container>
    )
}