import React, { useEffect, useState } from "react";
import Ads from "../../../components/comon/Ads";
import { Link, useParams } from "react-router-dom";
import AccountSideBar from "../AccountSideBar";
import { useAppContext } from "../../../contextApi/AppContext";
import Stack from "@mui/material/Stack";
import { Pagination } from "@mui/material";
import { Skeleton3 } from "../../../components/loading/Skeleton";
import NoData from "../../noData/NoData";



const InquiryPage = () => {
    const { id} = useParams()
  const { mypost, getMyPost, totalItem, myPostRemove, listLoading ,inquiry,getinquiryList } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalItem?.post / itemsPerPage);

  const searchData = {
    page: currentPage,
    limit: itemsPerPage,
  };

  useEffect(() => {
     const dataToSend ={
        productId: id
     }
    getinquiryList(dataToSend)
  }, [currentPage]);

  const handlePageChange = (value) => {
    setCurrentPage(value);
  };

  // console.log(inquiry,'inquiry')

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>My Post Inquiry</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">My Post inquiry</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* sidebar start */}
            <div className="col-md-3">
              <AccountSideBar />
            </div>
            {/* sidebar end */}
            {/* right part start */}
            <div className="col-md-7">
              <div className="card border-0">
                <div className="card card-body border-0">
                  <h2 className="text24 mb-1">My Post Inquiry</h2>
                  <p className="text-muted mb-1">
                    Here you can see your post Inquiry.
                  </p>
                  <hr className="mt0" />
                  {/* item start */}
                  {listLoading.Post ? Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton3 key={index} />)) : inquiry.length === 0 ? (
                      <NoData des={'Add a post, to See your Post Thank you!.'} />
                    ) : (
                    Array.isArray(inquiry) && inquiry.map((item) => (
                        <div className="card mb-3 myPostItem" key={item._id}>
                        <div className="row g-0">
                          <div className="col-md-4">
                            <Link to={`/product/my-post/${item._id}`} className="imgBx">
                              <img
                                src={item?.user_image}
                                className="img-fluid rounded-start"
                                alt="Dzital"
                              />
                            </Link>
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-9">
                                  <h5 className="card-title mb-1">{item?.user_name}</h5>
                                </div>
                                <div className="col-md-3" align="right">
                                  <h5 className="m0">
                                    <b className="badge bg-warning text-dark">
                                      {/* Any badge content */}
                                    </b>
                                  </h5>
                                </div>
                              </div>
                              <p className="card-text mb-0">
                                <strong>Inquiry Details:</strong> {item?.requirements_details}
                              </p>
                              <p className="card-text mb-0">
                                <strong>Quantity:</strong> {item?.quantity}
                              </p>
                              <p className="card-text mb-0">
                                <strong>Address:</strong> {item?.user_address}
                              </p>
                              <p className="card-text mb-0">
                                <strong>Mobile:</strong> {item?.user_mobile}
                              </p>
                              <hr className="mb-2 mt-1" />
                              {/* <div className="d-flex justify-content-between">
                                <span className="text-muted">Seller ID: {item?.sellerId}</span>
                                <span className="text-muted">User ID: {item?.userId}</span>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  {Array.isArray(mypost) && mypost.length > 0 ? (
                    <div align="center">
                      <Stack spacing={2}>
                        <Pagination
                          count={pageCount}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          boundaryCount={2}
                          siblingCount={1}
                        />
                      </Stack>
                    </div>
                  )
                    : ''}

                  {/* item end */}
                </div>
              </div>
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>

    </>
  );
};



export default InquiryPage;