import React, { useEffect, useState } from "react";
import { useAppContext } from "../../contextApi/AppContext";
import { Link } from "react-router-dom";
import { SellerHeader } from "./component/seller-header";
import { LuBaggageClaim } from "react-icons/lu";
import { FaDollarSign, FaRegChartBar } from "react-icons/fa";
import OrderTable from "./component/order-table";
import { Badge } from "react-bootstrap";
import JobTable from "./component/JobTable";
import LessonTable from "./component/LessonTable";

const SellerPanel = () => {
  const {
    userData,
    serviceList,
    sellerJobList,
    sellerOrderList,
    sellerordersList,
    orderValuePanel,
    sellerTotalValue,
    courseList,
  } = useAppContext();
  const [tabActive, setTabActive] = useState("jobs");

  // console.log(serviceList,'service list')

  // console.log(sellerordersList, 'seller orders list')

  useEffect(() => {
    orderValuePanel();
    courseList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const dataToSend = {
      category_slug: tabActive,
    };
    // console.log(dataToSend,'tabActive')
    sellerOrderList(dataToSend);
    // eslint-disable-next-line
  }, [tabActive]);

  // const onSearch = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData(e.target);
  //   const data = Object.fromEntries(formData);
  //   navigate(`/seller-pannel?q=${data.search}`);
  // };

  const updateTab = (i) => {
    if (tabActive !== i) {
      setTabActive(i);
    }
  };

  if (!userData || userData.user_type !== "seller") {
    return (
      <div className="text-center py-5">
        <img
          src="https://img1a.flixcart.com/www/linchpin/fk-cp-zion/img/error-500_f9bbb4.png"
          alt="Error 500"
          className="img-fluid"
          style={{ maxWidth: "450px" }}
        />
        <div className="mt-3 mb-4" style={{ fontSize: "1.3em" }}>
          Unfortunately, the page you are looking for has been moved or deleted
        </div>
        <Link to="/" className="btn btn-primary text-white">
          Go To Home Page
        </Link>
      </div>
    );
  }

  return (
    <main>
      <SellerHeader />
      <section className="midBody bgtheme px-5">
        <div className="d-flex align-items-center flex-wrap gap-3">
          <div
            className="card bg-light p-3 mt-0"
            style={{ flex: 1, borderRadius: "10px" }}
          >
            {" "}
            <div className="d-flex w-100">
              <div className="flex-grow-1 ">
                <p
                  className="mb-0 flex flex-column"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  <p className="text-primary m-0">
                    {sellerTotalValue.data?.total_orders}{" "}
                  </p>
                  <p
                    className="text-secondary m-0"
                    style={{ fontSize: "16px" }}
                  >
                    Total Order
                  </p>
                </p>
              </div>
              <div className="seller-card-icon">
                <LuBaggageClaim style={{ fontSize: 24, color: "#fff" }} />
              </div>
            </div>
          </div>
          <div
            className="card bg-light p-3 mt-0"
            style={{ flex: 1, borderRadius: "10px" }}
          >
            {" "}
            <div className="d-flex w-100">
              <div className="flex-grow-1 ">
                <p
                  className="mb-0 flex flex-column"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  <p className="text-primary m-0">
                    {" "}
                    {sellerTotalValue.data?.total_amount}
                  </p>
                  <p
                    className="text-secondary m-0"
                    style={{ fontSize: "16px" }}
                  >
                    Total Sales Amount
                  </p>
                </p>
              </div>
              <div className="seller-card-icon">
                <FaRegChartBar style={{ fontSize: 24, color: "#fff" }} />
              </div>
            </div>
          </div>

          <div
            className="card bg-light p-3 mt-0"
            style={{ flex: 1, borderRadius: "10px" }}
          >
            {" "}
            <div className="d-flex w-100">
              <div className="flex-grow-1 ">
                <p
                  className="mb-0 flex flex-column"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                >
                  <p className="text-primary m-0">
                    {" "}
                    {sellerTotalValue.data?.seller_waller_amount}
                  </p>
                  <p
                    className="text-secondary m-0"
                    style={{ fontSize: "16px" }}
                  >
                    Wallet Amount
                  </p>
                </p>
              </div>
              <div className="seller-card-icon">
                <FaDollarSign style={{ fontSize: 24, color: "#fff" }} />
              </div>
            </div>
          </div>
        </div>
        <div
          className="p-3"
          style={{ background: "#fff", borderRadius: "12px" }}
        >
          <ul className="nav nav-pills gap-3 custom-tab scrollbar">
            {serviceList
              ?.filter(
                (item) =>
                  item.title !== "Freelancer" && item.title !== "Real Estate"
              )
              .map((item, i) => (
                <li
                  className="nav-item"
                  style={{ minWidth: "max-content" }}
                  key={i}
                >
                  <button
                    className={`btn ${
                      item.slug === tabActive
                        ? "btn-primary text-light"
                        : "btn-light"
                    }`}
                    onClick={() => {
                      updateTab(item.slug);
                    }}
                  >
                    {item.title}
                    {tabActive === item.slug && (
                      <Badge
                        bg={"light"}
                        style={{
                          color: item.slug === tabActive ? "#000" : "#fff",
                          marginLeft: "6px",
                        }}
                      >
                        {tabActive === "jobs" && sellerJobList?.total}
                        {tabActive === "electronics" && sellerordersList?.total}
                      </Badge>
                    )}
                  </button>
                </li>
              ))}
          </ul>
          <div className="flex-wrap d-flex align-items-center justify-content-between py-1">
            {/* <form
              className="input-group"
              onSubmit={onSearch}
              style={{ maxWidth: "350px" }}
            >
              <input
                type="text"
                name="search"
                className="form-control custom-input"
                placeholder="Search..."
              />
              <div className="input-group-append">
                <button
                  className="btn btn-primary custom-search-button"
                  type="submit"
                  id="button-addon2"
                >
                  <i className="fa fa-search" style={{ color: "#fff" }}></i>
                </button>
              </div>
            </form> */}
            {tabActive !== 0 && (
              <div className="d-flex gap-2">
                <select
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Select status</option>
                  <option value="Pending">Pending</option>
                  <option value="In_Transit">In Transit</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            )}
          </div>
          {tabActive === "jobs" && <JobTable />}
          {tabActive === "fashion_beauty" && (
            <OrderTable orders={sellerordersList} tabActive={tabActive} />
          )}
          {tabActive === "lesson_courses" && (
            <LessonTable orders={sellerordersList} tabActive={tabActive} />
          )}
          {tabActive === "electronics" && (
            <OrderTable orders={sellerordersList} tabActive={tabActive} />
          )}
          {tabActive === "goods_of_all_kinds" && (
            <OrderTable orders={sellerordersList} tabActive={tabActive} />
          )}
          {/* {tabActive !== 0 && <OrderTable orders={orders} />} */}
        </div>{" "}
      </section>
    </main>
  );
};

export default SellerPanel;
