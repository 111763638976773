import React, { useState } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import { useAppContext } from "../../../../contextApi/AppContext";

const PaymentModal = ({ show, handleClose }) => {
  const { userData, updateBank, createPayoutRequest } = useAppContext();
  const [amount, setAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState(false);

  const [bank_name, setBankName] = useState(userData?.bank_name);
  const [account_number, setAccountNumber] = useState(userData?.account_number);
  const [ifsc_code, setIfsc] = useState(userData?.ifsc_code);
  const [beneficiary_name, setHolderName] = useState(
    userData?.beneficiary_name
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (amount && remark) {
      createPayoutRequest({ amount, remarks: remark });
    }
    setAmount("");
    setRemark("");
    handleClose();
  };

  const handleSaveBankDetails = () => {
    updateBank(bank_name, account_number, ifsc_code, beneficiary_name);
    setEditBankDetails(false);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formAmount">
            <Form.Label>
              Amount ({userData?.country === "India" ? "INR" : "USD"})
            </Form.Label>
            <InputGroup>
              <InputGroup.Text>
                {userData?.country === "India" ? "₹" : "$"}
              </InputGroup.Text>
              <Form.Control
                type="number"
                placeholder={`Enter amount in ${
                  userData?.country === "India" ? "INR" : "USD"
                }`}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                required
              />
            </InputGroup>
          </Form.Group>

          <Form.Group controlId="formRemark" className="mt-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter remark"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
              required
            />
          </Form.Group>

          <Button
            variant="link"
            onClick={() => setShowBankDetails(!showBankDetails)}
            className="mt-3"
          >
            {showBankDetails ? "Hide Bank Details" : "Show Bank Details"}
          </Button>

          {showBankDetails && (
            <div className="mt-3 p-3 border rounded">
              <h5>Bank Details for {userData?.country}</h5>

              <Form.Group controlId="formBankName" className="mt-3">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="text"
                  value={userData?.country}
                  disabled
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBankName" className="mt-3">
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter bank name"
                  value={bank_name}
                  onChange={(e) => setBankName(e.target.value)}
                  disabled={!editBankDetails}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formAccountNumber" className="mt-3">
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter account number"
                  value={account_number}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  disabled={!editBankDetails}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formIban" className="mt-3">
                <Form.Label>IFSC Code / Bank id</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter IFSC Code / Bank id"
                  value={ifsc_code}
                  onChange={(e) => setIfsc(e.target.value)}
                  disabled={!editBankDetails}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBic" className="mt-3">
                <Form.Label>Beneficiary Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Beneficiary Name"
                  value={beneficiary_name}
                  onChange={(e) => setHolderName(e.target.value)}
                  disabled={!editBankDetails}
                  required
                />
              </Form.Group>
              <Button
                variant="outline-primary"
                className="mt-3"
                onClick={
                  editBankDetails
                    ? handleSaveBankDetails
                    : () => setEditBankDetails(true)
                }
              >
                {editBankDetails ? "Save Bank Details" : "Update Bank Details"}
              </Button>
              {editBankDetails && (
                <Button
                  variant="outline-primary"
                  className="mt-3 TEX ml5"
                  onClick={() => setEditBankDetails(!editBankDetails)}
                >
                  Close
                </Button>
              )}
            </div>
          )}
          {!showBankDetails && (
            <Modal.Footer>
              {!editBankDetails && (
                <>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    className="text-white"
                    variant="primary"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </Modal.Footer>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
