import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import { Link } from "react-router-dom";

const RecentConnections = () => {
  const { recentConnections, timeAgo } = useAppContext();

  return (
    <Card className="mb-3">
      <Card.Header className="right-sidebar-header">
        Recent Connections
      </Card.Header>
      <ListGroup variant="flush" className="right-sidebar-list">
        {recentConnections?.data?.map((connection, index) => (
          <ListGroup.Item key={index} className="">
            <Link
              to={`/seller-info/${connection.user_id}`}
              className="right-sidebar-list-item d-flex align-items-center"
              style={{ textDecoration: "none", color: "#000", padding: 0 }}
            >
              <img
                src={connection.user_image}
                alt={connection.user_name}
                className="rounded-circle me-3"
                style={{ width: "50px", height: "50px" }}
              />
              <div className="flex-grow-1">
                <div className="right-sidebar-news-title">
                  {connection.user_name}
                </div>
                <small className="right-sidebar-news-details text-muted">
                  {timeAgo(connection.connectedAt)} ago •{" "}
                  {connection.connectionCount} connections
                </small>
              </div>
            </Link>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Card.Footer className="right-sidebar-footer">
        <Link to="/user-connectionlist" className="right-sidebar-link">
          View all connections
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default RecentConnections;
