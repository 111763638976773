import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const BlogMagazine = () => {
  return (
    <div className="container py-5">
      <header className="text-center mb-5">
        <h1 className="display-4">Welcome to the Dzital Blog Magazine</h1>
        <p className="lead">
          Your hub for the latest digital trends, insightful tech reviews, and
          smart shopping advice.
        </p>
      </header>

      <section className="featured-articles mb-5">
        <h2 className="text-primary mb-4">Featured Articles</h2>

        <div className="row">
          <div className="col-md-4 mb-4">
            <article className="card h-100">
              <div className="card-body">
                <h3 className="card-title">Top Tech Gadgets of 2024</h3>
                <p className="card-text">
                  Discover the must-have gadgets that are defining the tech
                  scene this year. From smart home innovations to the latest in
                  wearable tech, get ready to explore the future.
                </p>
                <Link
                  to="#"
                  className="btn btn-primary text-white"
                >
                  Read More
                </Link>
              </div>
            </article>
          </div>

          <div className="col-md-4 mb-4">
            <article className="card h-100">
              <div className="card-body">
                <h3 className="card-title">
                  Guide to Online Shopping: 10 Tips to Find the Best Deals
                </h3>
                <p className="card-text">
                  Online shopping can be a maze. Here’s how to navigate it like
                  a pro and grab the best deals without compromising on quality.
                </p>
                <Link
                  to="#"
                  className="btn btn-primary text-white"
                >
                  Read More
                </Link>
              </div>
            </article>
          </div>

          <div className="col-md-4 mb-4">
            <article className="card h-100">
              <div className="card-body">
                <h3 className="card-title">
                  Smartphones to Watch Out for in 2024
                </h3>
                <p className="card-text">
                  With new models launching every month, here's our guide to the
                  most anticipated smartphones and what they bring to the table.
                </p>
                <Link
                  to="#"
                  className="btn btn-primary text-white"
                >
                  Read More
                </Link>
              </div>
            </article>
          </div>
        </div>
      </section>

      <section className="categories mb-5">
        <h2 className="text-primary mb-4">Explore Our Categories</h2>

        <div className="row">
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <h4 className="card-title">Tech Reviews</h4>
                <p className="card-text">
                  In-depth reviews of the latest tech products. Find out what’s
                  worth buying and what to skip.
                </p>
                <Link
                  to="#"
                  className="btn btn-outline-primary "
                >
                  Explore Reviews
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <h4 className="card-title">Shopping Guides</h4>
                <p className="card-text">
                  Your go-to resource for smart shopping tips, whether you’re
                  looking for gadgets, home appliances, or lifestyle products.
                </p>
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                >
                  View Guides
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-body text-center">
                <h4 className="card-title">Digital Trends</h4>
                <p className="card-text">
                  Stay updated on the latest trends shaping the digital world,
                  from AI advancements to blockchain technology.
                </p>
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                >
                  Discover Trends
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="latest-posts mb-5">
        <h2 className="text-primary mb-4">Latest Posts</h2>
        <ul className="list-group">
          <li className="list-group-item">
            <Link to="#">
              Latest Gadget Releases
            </Link>{" "}
            - A roundup of this month's hottest tech products.
          </li>
          <li className="list-group-item">
            <Link to="#">
              E-commerce Tips for First-Time Shoppers
            </Link>{" "}
            - How to shop smart and get the best deals online.
          </li>
          <li className="list-group-item">
            <Link to="#">Cybersecurity Basics</Link> -
            Protect yourself online with these essential tips.
          </li>
          <li className="list-group-item">
            <Link to="#">
              Top Eco-Friendly Gadgets
            </Link>{" "}
            - Discover tech that's as kind to the environment as it is
            innovative.
          </li>
          <li className="list-group-item">
            <Link to="#">
              AI in Retail: The Future of Shopping
            </Link>{" "}
            - How artificial intelligence is transforming the retail experience.
          </li>
        </ul>
      </section>

      <footer className="text-center">
        <p className="text-muted">
          Stay tuned for more updates, trends, and reviews on Dzital Blog
          Magazine. Follow us for the latest in tech and e-commerce insights!
        </p>
      </footer>
    </div>
  );
};

export default BlogMagazine;
