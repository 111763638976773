import jobs from "../../assets/assets/images/jobs.jpg";
import freelancer from "../../assets/assets/images/freelancer.jpg";
import realEstate from "../../assets/assets/images/real-estate.jpg";
import lessonsCourses from "../../assets/assets/images/lessons-courses.jpg";
import fashionBeauty from "../../assets/assets/images/fashion-beauty.jpg";
import electronics from "../../assets/assets/images/electronics.jpg";
import goodsOfAllKinds from "../../assets/assets/images/goods-of-all-kinds.jpg";
import buildYourWebsite from "../../assets/assets/images/build-your-website.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";

const CategoryBanner = () => {
  const { serviceList } = useAppContext();

  const job =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "jobs");
  const freeLancer =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "freelancer");
  const course =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "lesson_courses");
  const realstate =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "real_estate");
  const fashion =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "fashion_beauty");
  const elect =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "electronics");
  const goodsProduct =
    Array.isArray(serviceList) &&
    serviceList?.find((item) => item?.slug === "goods_of_all_kinds");
  return (
    <>
      <section className="bg-info bg-opacity-10">
        <article className="container pt-5 pb-5">
          <h3 className="mb-4 titelBx">Popular Category</h3>
          <Swiper
            spaceBetween={20}
            navigation={true}
            mousewheel={false}
            keyboard={true}
            loop={true}
            pagination={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
            className="mySwiper homeCategory"
          >
            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${job?.slug}?category_id=${job?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#0e9a83" }}
              >
                <h5>{job?.title}</h5>
                <img src={jobs} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${freeLancer?.slug}?category_id=${freeLancer?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#f4851c" }}
              >
                <h5>{freeLancer?.title}</h5>
                <img src={freelancer} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${realstate?.slug}?category_id=${realstate?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#004566" }}
              >
                <h5>{realstate?.title}</h5>
                <img src={realEstate} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${course?.slug}?category_id=${course?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#ca3839" }}
              >
                <h5>{course?.title}</h5>
                <img src={lessonsCourses} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${fashion?.slug}?category_id=${fashion?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#442a6b" }}
              >
                <h5>{fashion?.title}</h5>
                <img src={fashionBeauty} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${elect?.slug}?category_id=${elect?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#fead38" }}
              >
                <h5>{elect?.title}</h5>
                <img src={electronics} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to={`${goodsProduct?.slug}?category_id=${goodsProduct?._id}`}
                className="hcItem"
                style={{ backgroundColor: "#2369fd" }}
              >
                <h5>{goodsProduct?.title}</h5>
                <img src={goodsOfAllKinds} alt="" />
              </Link>
            </SwiperSlide>
            {/* item end */}

            {/* item start */}
            <SwiperSlide>
              <Link
                to="./"
                className="hcItem"
                style={{ backgroundColor: "#c111cc" }}
              >
                <h5>Build Your Website</h5>
                <img src={buildYourWebsite} alt="Website" />
              </Link>
            </SwiperSlide>
            {/* item end */}
          </Swiper>
        </article>
      </section>
    </>
  );
};

export default CategoryBanner;
