import React from "react";
import "./BannerAnimation.css";
import { Typewriter } from "react-simple-typewriter";
const BannerAnimation = () => {
  const words = [
    "Freelancer",
    "Electronics",
    "Job",
    "Real Estate",
    "Lesson & Courses" ,
    "Fashion & Beauty",
    "Goods of all Kind",
  ];

  return (
    <div className="container banners-container d-flex flex-column justify-content-center align-items-center mb-5 mt-5">
      <h1 className="banner-heading">
        {` `}
        <span>Are you in business of </span>
        <span style={{ color: "rgb(136 238 251)" }}>
          <Typewriter
            words={words}
            loop={Infinity}
            cursor
            cursorStyle="_"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </span>
      </h1>
      <button className="banners-button">Join Dzital & Grow More</button>
    </div>
  );
};

export default BannerAnimation;