import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppContext } from "../../../contextApi/AppContext";
import { useParams } from "react-router-dom";

const ForgotPassword = () => {
  const { getNewPassword } = useAppContext();
  const { id, token} = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      getNewPassword({id, token, password: newPassword});
    } else {
      alert("Passwords do not match. Please try again.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow-lg border-0 rounded-lg">
            <div className="card-body p-5">
              <h3 className="card-title text-center mb-4 text-primary">
                Set New Password
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-4">
                  <label
                    htmlFor="newPassword"
                    className="form-label text-secondary"
                  >
                    New Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    id="newPassword"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    style={{ borderColor: "#ced4da", borderRadius: "0.25rem" }}
                  />
                </div>
                <div className="form-group mb-4">
                  <label
                    htmlFor="confirmPassword"
                    className="form-label text-secondary"
                  >
                    Confirm New Password
                  </label>
                  <input
                    type="password"
                    className="form-control form-control-lg"
                    id="confirmPassword"
                    placeholder="Confirm new password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    style={{ borderColor: "#ced4da", borderRadius: "0.25rem" }}
                  />
                </div>
                <button
                  type="submit"
                  className="text-white btn btn-primary btn-block py-1 px-3"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
