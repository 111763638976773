import React, { useEffect } from "react";
import Ads from "../../components/comon/Ads";
import { Link, useParams } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { Card, Table, Col } from "react-bootstrap";
import "./SellerDetails.css";
import { IoSchoolSharp } from "react-icons/io5";
import { FaBriefcase } from "react-icons/fa";
import { MdCorporateFare } from "react-icons/md";
import Rating from "@mui/material/Rating";
import { FaLightbulb } from "react-icons/fa";
import coverImage from "../../assets/cover_image.jpg";
const SellerDetails = () => {
  const { id } = useParams();

  const { getsellerinfo, sellerinfo, sendConnection, userData } =
    useAppContext();

  useEffect(() => {
    const dataToSend = {
      sellerId: id,
      userId: userData._id,
    };
    getsellerinfo(dataToSend);
    // eslint-disable-next-line
  }, [id]);

  const handleConnection = () => {
    const dataToSend = {
      receiverId: sellerinfo._id,
    };
    sendConnection(dataToSend);
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-12">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Detail Page Of Seller </li>
              </ul>
            </div>
          </aside>
        </article>
      </section>

      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}

            <Col md={10} className="mx-auto">
              <div className="card mt-0 profileBx">
                <div className="profileBgBx">
                  <img
                    src={sellerinfo?.cover_photo || coverImage}
                    className="card-img-top"
                    alt="..."
                  />
                </div>
                <div className="card-body">
                  <div className="userBx">
                    <img src={sellerinfo?.image} alt="" />
                  </div>
                  <div className="row justify-content-evenly">
                    <div className="col">
                      <h5 classname="card-title">{sellerinfo?.name}</h5>
                      {sellerinfo.mobile && (
                        <>
                          <strong>Mobile:</strong> {sellerinfo?.mobile}
                        </>
                      )}
                      <br />
                      {sellerinfo.address && (
                        <>
                          <strong>Address:</strong> {sellerinfo?.address}
                        </>
                      )}

                      {sellerinfo.bio && (
                        <>
                          <strong>Bio:</strong> {sellerinfo?.bio}
                        </>
                      )}
                    </div>
                  </div>
                  {sellerinfo?.city &&
                    sellerinfo?.state &&
                    sellerinfo?.country && (
                      <>
                        <p classname="text-muted">
                          <i className="fa fa-map-marker"></i>{" "}
                          {sellerinfo?.city}, {sellerinfo?.state},{" "}
                          {sellerinfo?.country}
                        </p>
                      </>
                    )}
                  {sellerinfo?.isConnected === true &&
                  sellerinfo.isPending === false ? (
                    <button className="btn btn-success text-white">
                      Connected
                    </button>
                  ) : sellerinfo?.isConnected === false &&
                    sellerinfo.isPending === false ? (
                    <button
                      className="btn btn-primary text-white"
                      onClick={handleConnection}
                    >
                      Send Connection
                    </button>
                  ) : (
                    <button className="btn btn-primary text-white">
                      Request Sent
                    </button>
                  )}
                </div>
              </div>

              <div className="row">
                {sellerinfo?.education?.length ? (
                  <div className="col-md-6">
                    <div className="card mb-3">
                      <Card.Header className="d-flex justify-content-between align-items-center card-header text-primary mb-3">
                        <b>Education Details</b>
                        <IoSchoolSharp className="ms-auto" />
                      </Card.Header>
                      {sellerinfo?.education?.map((edu, index) => (
                        <div
                          className="card-body bg-secondary text-white rounded mx-2 mb-3"
                          key={index}
                        >
                          <b>Institute Name</b> : {""}
                          <span className="">{edu?.institute}</span>
                          <span className="float-end">
                            <i className="fa fa-calendar"></i>{" "}
                            {new Date(edu.start_date).toLocaleDateString()} To{" "}
                            {new Date(edu.end_date).toLocaleDateString()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
                {sellerinfo?.experience?.length ? (
                  <div className="col-md-6">
                    <div className="card mb-3">
                      <Card.Header className="d-flex justify-content-between align-items-center card-header text-primary mb-3">
                        <b>Experience Details</b> <FaBriefcase />
                      </Card.Header>
                      {sellerinfo?.experience?.map((exp) => (
                        <div className="card-body bg-secondary text-white rounded mx-2 mb-3">
                          <div className="">
                            <strong> Company Name : </strong> {exp?.company}
                            <span className="float-end">
                              <i className="fa fa-calendar"></i>{" "}
                              {new Date(exp.start_date).toLocaleDateString()} To{" "}
                              {new Date(exp.end_date).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="row">
                {sellerinfo.company_name ? (
                  <div className="col-md-6">
                    <div className="card mb-3">
                      <Card.Header className="d-flex justify-content-between align-items-center card-header text-primary">
                        <b>Company Details</b> <MdCorporateFare />
                      </Card.Header>
                      <ul className="list-group round0 mb-0">
                        <li className="list-group-item">
                          <b>Company Name </b> : {sellerinfo?.company_name}
                        </li>
                        <li className="list-group-item">
                          {" "}
                          <b>Company Email</b> : {sellerinfo?.company_email}
                        </li>
                        <li className="list-group-item">
                          {" "}
                          <b>Company Address</b> : {sellerinfo?.company_address}
                        </li>
                        <li className="list-group-item">
                          {" "}
                          <b>Company Contact Number</b> :{" "}
                          {sellerinfo?.company_contact_number}{" "}
                        </li>
                        <li className="list-group-item">
                          {" "}
                          <b>Company Url</b> : {sellerinfo?.company_url}
                        </li>
                      </ul>
                    </div>
                  </div>
                ) : null}
                {sellerinfo?.skills?.length ? (
                  <div className="col-md-6">
                    <div className="card mb-3">
                      <Card.Header className="card-header d-flex justify-content-between align-items-center text-primary">
                        <b>Skills</b> <FaLightbulb />
                      </Card.Header>
                      <div className="card-body d-flex flex-wrap">
                        {sellerinfo.skills?.map((skill, index) => (
                          <div className="col-md-4">
                            <div className="badge bg-secondary d-block p-2 m-2">
                              <h5
                                className="mb-0"
                                style={{ marginRight: "10px" }}
                              >
                                {skill}
                              </h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              {/* {sellerinfo && (
                <Card className="mb-4 shadow-sm">
                  <Row noGutters>
                    <Col md={2} className="text-center">
                      <Card.Img
                        variant="top"
                        style={{ width: "120px", height: "120px" }}
                        src={sellerinfo?.image}
                        alt={sellerinfo.name}
                        className="rounded-circle seller-image"
                      />
                    </Col>
                    <Col md={10}>
                      <Card.Body>
                        <Card.Title>{sellerinfo?.name}</Card.Title>
                        <Card.Subtitle className="mb-2">
                          <strong>Email:</strong> {sellerinfo?.email}
                        </Card.Subtitle>
                        <Card.Text>
                          <strong>Mobile:</strong> {sellerinfo?.mobile} <br />
                          <strong>Address:</strong> {sellerinfo?.address} <br />
                          <strong>Bio:</strong> {sellerinfo?.bio}
                        </Card.Text>
                        {sellerinfo?.isConnected === true ? (
                          <button className="btn btn-primary text-white">
                            Send Message
                          </button>
                        ) : sellerinfo?.isPending === true ? (
                          <button className="btn btn-primary text-white">
                            Request Sent
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary text-white"
                            onClick={handleConnection}
                          >
                            Send Connection
                          </button>
                        )}
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              )} */}

              {sellerinfo?.seller_own_products &&
                sellerinfo?.seller_own_products?.length > 0 && (
                  <Card className="shadow-sm">
                    <Card.Body>
                      <Card.Title className="card-title-header">
                        Seller's Products List
                      </Card.Title>
                      <Table
                        responsive
                        bordered
                        hover
                        className="mt-3"
                        style={{
                          backgroundColor: "#f8f9fa",
                          borderRadius: "10px",
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <thead
                          style={{ backgroundColor: "#343a40", color: "white" }}
                        >
                          <tr>
                            <th
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              Image
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              Title
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              Rating
                            </th>
                            <th
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              Posted On
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {sellerinfo.seller_own_products.map((product) => (
                            <tr key={product?._id}>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                <Link
                                  to={`/product/${product?.category_slug}/${product?._id}`}
                                >
                                  <img
                                    src={product?.image}
                                    className="img-thumbnail"
                                    style={{
                                      maxWidth: "150px",
                                      maxHeight: "150px",
                                      objectFit: "cover",
                                      borderRadius: "10px",
                                    }}
                                    alt={product.title}
                                  />
                                </Link>
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                {product?.title}
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={product.ratings}
                                  precision={0.5}
                                  readOnly
                                />
                              </td>
                              <td
                                style={{
                                  verticalAlign: "middle",
                                  textAlign: "center",
                                }}
                              >
                                {new Date(
                                  product.createdAt
                                ).toLocaleDateString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                )}
            </Col>

            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default SellerDetails;
