import React, { useEffect, useState } from 'react';

const Timer = ({ startDate, endDate }) => {
  const [remainingTime, setRemainingTime] = useState({});
  const [isBiddingOpen, setIsBiddingOpen] = useState(false);
  const [isBidComing, setIsBidComing] = useState(false);

  useEffect(() => {
    const calculateRemainingTime = () => {
      const currentDate = new Date();
      const startDate1 = new Date(startDate);
      const endDate1 = new Date(endDate);

      const isBiddingTime = currentDate >= startDate1 && currentDate < endDate1;
      setIsBiddingOpen(isBiddingTime);

      const bidComming = currentDate < startDate1;
      setIsBidComing(bidComming);

      const timeDifference = isBiddingTime ? endDate1 - currentDate : startDate1 - currentDate;

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setRemainingTime({
        days: String(days).padStart(2, '0'),
        hours: String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0'),
        seconds: String(seconds).padStart(2, '0'),
      });
    };

    // Initial calculation
    calculateRemainingTime();

    // Update every second
    const interval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [startDate, endDate]);

  return (
    <div>
      {isBiddingOpen && (
        <p style={styles.timerText}>
          {remainingTime.days} days {remainingTime.hours}h {remainingTime.minutes}m {remainingTime.seconds}s
        </p>
      )}
      {isBidComing && (
        <p style={styles.timerText}>
          {remainingTime.days} days {remainingTime.hours}h {remainingTime.minutes}m {remainingTime.seconds}s
        </p>
      )}
    </div>
  );
};

const styles = {
  timerText: {
    fontSize: '14px',
    margin: "0px",
    fontWeight: 'bold',
  },
};

export default Timer;