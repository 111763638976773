import React, { useState, useEffect, useRef } from "react";
import { Badge, Button, Form, FormControl, InputGroup } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import Select from "react-select";
import { City, Country, State } from "country-state-city";
import { toast } from "react-toastify";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// eslint-disable-next-line
import {
  useLoadScript,
  StandaloneSearchBox,
  Autocomplete,
} from "@react-google-maps/api";
// eslint-disable-next-line
const libraries = ["places"];

const AddProduct = ({ idDetail }) => {
  // console.log(idDetail, "idDetail");
  const {
    AddRealEstate,
    AddJob,
    AddFashionProduct,
    getAttributeList,
    attributeList,
    productId,
    AddFreelancer,
    AddElectronics,
    AddCourse,
    AddGoodsProduct,
    titleDes,
    loading,getCurrencyList , currency
  } = useAppContext();

  const [isImage, setIsImage] = useState(false);
  const [formData, setFormData] = useState({});
  const [formData1, setFormData1] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [amount, setAmount] = useState();
  const [propertyType, setPropertyType] = useState("");
  const [Productprice, setProductPrice] = useState();
  const [numProjects, setNumProjects] = useState("");
  const [perhourCharge, setPerHourCharge] = useState("");
  const [company_website_link, setcompany_website_link] = useState("");
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [stock, setStock] = useState();
  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState("");
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  useEffect(()=>{
    getCurrencyList()
  },[])

// console.log(currency,'currency')

useEffect(() => {
  if (currency.data && currency.data.length) {
      const options = currency.data?.map((item) => ({
        value: { name: item.currency_name, symbol: item.currency_symbol },
        label: `${item.currency_name} (${item.currency_symbol || item.code}) - ${item.country}`,
      }));
      setCurrencyOptions(options);
  }
}, [currency]);


  // Handle select change
  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    // console.log("Selected currency:", selectedOption.value.name);
  };

  let countryData = Country.getAllCountries();

  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [country, setCountry] = useState(countryData[0]);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  useEffect(() => {
    setStateData(State.getStatesOfCountry(country?.isoCode));
  }, [country]);

  // Effect to update city data when state changes
  useEffect(() => {
    setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
  }, [state]);

  // Handler for country change
  const handleCountryChange = (selectedOption) => {
    const selectedCountry = countryData.find(
      (country) => country.isoCode === selectedOption.value
    );
    setCountry(selectedCountry);
  };

  // Handler for state change
  const handleStateChange = (selectedOption) => {
    const selectedState = stateData.find(
      (state) => state.isoCode === selectedOption.value
    );
    setState(selectedState);
  };

  // Handler for city change
  const handleCityChange = (selectedOption) => {
    const selectedCity = cityData.find(
      (city) => city.name === selectedOption.value
    );
    setCity(selectedCity);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && skill.trim()) {
      addSkill();
      addLearn();
      e.preventDefault();
    }
  };

  const addSkill = () => {
    const newSkills = skill
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s && !skills.includes(s));

    if (newSkills.length) {
      setSkills([...skills, ...newSkills]);
      setSkill("");
    }
  };

  const removeSkill = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  const [learns, setLearns] = useState([]);
  const [learn, setLearn] = useState("");

  const handleKeyDownLearn = (e) => {
    // console.log("Key pressed:", e.key); // Debugging log
    if (e.key === "Enter" && learn.trim()) {
      e.preventDefault(); // Prevent default form submission
      addLearn();
    }
  };

  const addLearn = () => {
    const newLearns = learn
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s && !learns.includes(s));

    if (newLearns.length) {
      setLearns([...learns, ...newLearns]);
      setLearn(""); // Clear input after adding
    }
  };

  const removeWhatYouLearn = (index) => {
    setLearns(learns.filter((_, i) => i !== index));
  };
  const [imageInputs, setImageInputs] = useState([
    { id: Date.now(), file: "" },
  ]);

  const handlePhotoChange = (index, event) => {
    const newInputs = [...imageInputs];
    newInputs[index].file = event.target.files[0];
    setImageInputs(newInputs);

    // Automatically add a new input if it's the last one in the list and less than 10 images
    if (index === imageInputs.length - 1 && imageInputs.length < 10) {
      setImageInputs([
        ...imageInputs,
        { id: Date.now(), file: "" }, // Use Date.now() to generate a unique id
      ]);
    }
  };

  const handleRemoveImage = (index) => {
    const newInputs = imageInputs.filter((_, i) => i !== index);
    setImageInputs(newInputs);

    // Ensure the option to upload more images appears if less than 10 images are present
    if (
      newInputs.length < 10 &&
      !newInputs.some((input) => input.file === "")
    ) {
      setImageInputs([...newInputs, { id: Date.now(), file: "" }]);
    }
  };

  const sizes = ["S", "M", "L", "XL", "2XL", "Free"];

  const handleSizeClick = (size) => {
    setSelectedSizes((prevSizes) => {
      if (prevSizes.includes(size)) {
        return prevSizes.filter((s) => s !== size);
      } else {
        return [...prevSizes, size];
      }
    });
  };

  const handlePropertyChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleRadioChange = (value) => {
    setSelectedOption(value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const [uploadData, setUploadData] = useState({
    image1: null,
    image2: null,
  });

  const handleImageChange = (e, imageKey) => {
    const file = e.target.files[0];
    setUploadData((prevState) => ({
      ...prevState,
      [imageKey]: file,
    }));
  };
  // eslint-disable-next-line
  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState();
  const [address, setAddress] = useState();
  const searchBoxRef = useRef(null);

  const [bidData, setBidData] = useState({
    bid_start_price: "",
    bid_entry: "",
    bid_increament_value: "",
    bid_start_date: new Date(),
    bid_end_date: new Date(),
  });

  // console.log("formData", formData);
  const handleChange = (name, value, attId) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setFormData1((prevData) => ({ ...prevData, [attId]: value }));
  };

  useEffect(() => {
    const newEndDate = new Date(bidData.bid_start_date);
    newEndDate.setHours(newEndDate.getHours() + 1);
    setBidData({ ...bidData, bid_end_date: newEndDate });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const payloadData = {
      category_id: idDetail?.servicecategories || "",
      sub_category_id: idDetail?.subservicecat || "",
    };

    const fetchattributlist = async () => {
      await getAttributeList(payloadData);
    };
    fetchattributlist();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      idDetail?.category_slug?.includes("freelancer") ||
      idDetail?.category_slug?.includes("lesson_courses") ||
      idDetail?.category_slug?.includes("jobs")
    ) {
      setIsImage(true);
    } else {
      setIsImage(false);
    }
  }, [idDetail?.category_slug]);

  const onSubmit = async () => {
    if (isChecked) {
      if (
        !bidData.bid_start_price ||
        !bidData.bid_increament_value ||
        !bidData.bid_start_date ||
        !bidData.bid_end_date
      ) {
        toast.warning("Please fill all bid related fields.");
        return;
      }
      if (bidData.bid_start_date >= bidData.bid_end_date) {
        toast.warning("Bid end date should be greater than bid start date.");
        return;
      }
    }

    const data1 = {
      title: titleDes?.title || "",
      description: titleDes?.description || "",
      // location,

      product_id: productId,
      category_id: idDetail.servicecategories || "",
      sub_category_id: idDetail.child_category_id
        ? idDetail.child_category_id
        : idDetail.subservicecat,
    };

    const dataPost = new FormData();
    dataPost.append("country", country.name || "");
    dataPost.append("state", state.name || "");
    dataPost.append("city", city.name || "");
    dataPost.append("lat", center?.lat || 0);
    dataPost.append("lng", center?.lng || 0);
    if (selectedCurrency){
      dataPost.append("currency", selectedCurrency.value.name || "");
    }
    if (amount && Number(amount)) {
      dataPost.append("product_price", amount || 0);
    }

    if (stock && Number(stock)) {
      dataPost.append("stock", stock || 0);
    }

    if (Productprice) {
      dataPost.append("price", Productprice || 0);
    }

    if (selectedSizes) {
      selectedSizes.forEach((size, index) => {
        dataPost.append(`size[${index}]`, size);
      });
    }

    if (selectedOption) {
      dataPost.append("purchase_mode", selectedOption || "");
    }

    if (numProjects) {
      dataPost.append("projects", numProjects || 0);
    }

    if (perhourCharge) {
      dataPost.append("per_hour_charges", perhourCharge || 0);
    }

    if (company_website_link) {
      dataPost.append("company_website_link", company_website_link || "");
    }

    if (skills) {
      skills.forEach((skill, index) => {
        dataPost.append(`skills[${index}]`, skill);
      });
    }

    if (learns) {
      learns.forEach((learn, index) => {
        dataPost.append(`what_you_will_learn[${index}]`, learn);
      });
    }

    if (address) {
      dataPost.append("address", address || "");
    }

    if (uploadData?.image1) {
      dataPost.append("image", uploadData?.image1 || "");
    }

    if (uploadData?.image2) {
      dataPost.append("company_logo", uploadData?.image2 || "");
    }

    if (propertyType) {
      dataPost.append("property_type", propertyType || "");
    }

    Object.keys(data1).forEach((key) => {
      dataPost.append(key, data1[key]);
    });

    Object.entries(formData1).forEach(([key, value], index) => {
      dataPost.append(`attributeData[${index}][attribute_id]`, key);
      dataPost.append(`attributeData[${index}][value]`, value);
    });

    if (isChecked === true) {
      dataPost.append("bid_now", true);
      dataPost.append("bid_start_price", bidData.bid_start_price || "");
      // dataPost.append('bid_entry', bidData.bid_entry || '');
      dataPost.append(
        "bid_increament_value",
        bidData.bid_increament_value || ""
      );
      const formattedStartDate = bidData.bid_start_date
        ? bidData.bid_start_date.toISOString()
        : "";
      dataPost.append("bid_start_date", formattedStartDate);

      const formattedEndDate = bidData.bid_end_date
        ? bidData.bid_end_date.toISOString()
        : "";
      dataPost.append("bid_end_date", formattedEndDate);
    }

    if (isImage === false) {
      imageInputs.forEach((imageInput, index) => {
        if (imageInput.file) {
          dataPost.append(`image[${index}]`, imageInput.file);
        }
      });
    } else {
      dataPost.append("image", formData.image || "");
    }

    // eslint-disable-next-line
    {
      switch (attributeList[0]?.category_name) {
        case "Goods of all kinds":
          return AddGoodsProduct(dataPost);
        case "Fashion & Beauty":
          return AddFashionProduct(dataPost);
        case "Lessons & Courses":
          return AddCourse(dataPost);
        case "Jobs":
          return AddJob(dataPost);
        case "Freelancer":
          return AddFreelancer(dataPost);
        case "Real Estate":
          return AddRealEstate(dataPost);
        case "Electronics":
          return AddElectronics(dataPost);
        default:
          return null;
      }
    }
  };

  const handleType = (dataType) => {
    switch (dataType) {
      case "Salary Expectation":
        return "number";
      case "Typing Speed":
        return "number";
      case "Salary to":
        return "number";
      case "Salary from":
        return "number";
      case "Salary":
        return "number";
      case "Land Area":
        return "number";
      case "Price Range":
        return "number";
      case "Number of Rooms":
        return "number";
      case "Number of Bathrooms":
        return "number";
      case "Year Built":
        return "number";
      case "Square Footage":
        return "number";
      case "Rent":
        return "number";
      case "Quizzes":
        return "number";
      case "Lessons":
        return "number";
      case "Videos":
        return "number";
      case "Hours":
        return "number";
      case "Course Duration":
        return "number";
      case "Duration":
        return "number";
      case "Pack of":
        return "number";
      case "Price":
        return "number";
      case "  Model Number":
        return "number";
      case "Release Year":
        return "number";
      case "Number of Pieces":
        return "number";
      default:
        return "text";
    }
  };

  const handelEnablebid = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    setBidData({ ...bidData, bid_start_date: date });
  };

  const handleEndDateChange = (date) => {
    if (date > bidData.bid_start_date) {
      setBidData({ ...bidData, bid_end_date: date });
    } else {
      toast.warning("End date must be after start date");
    }
  };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const newCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const formatted_address = place.formatted_address;
      setAddress(formatted_address);
      setCenter(newCenter);
      setPlaces(places);
    }
  };

  return (
    <section className="midBody">
      <article className="container-fluid">
        <article className="row">
          {/* right part start */}
          <div className="col-md-12">
            <div className="card">
              <div className="card-body border-0">
                <form onSubmit={onSubmit} encType="multipart/form-data">
                  {Array.isArray(attributeList) &&
                    attributeList.map((item) => (
                      <div key={item._id} className="radioBx mb20">
                        <h6>{item.attribute}</h6>
                        <>
                          {item.options.length > 0 ? (
                            Array.isArray(item.options) &&
                            item?.options?.map((item1, index) => (
                              <React.Fragment key={item1._id}>
                                <input
                                  key={index}
                                  type="radio"
                                  className="btn-check"
                                  name={item.attribute}
                                  id={`radio_${item.attribute}_${item1._id}`}
                                  value={item1.option}
                                  onChange={(e) =>
                                    handleChange(
                                      item.attribute,
                                      e.target.value,
                                      item._id
                                    )
                                  }
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor={`radio_${item.attribute}_${item1._id}`}
                                >
                                  {" "}
                                  {item1.option}{" "}
                                </label>
                              </React.Fragment>
                            ))
                          ) : (
                            <div className="row">
                              <div key={item._id} className="">
                                <input
                                  type={handleType(item.attribute)}
                                  name={item.attribute}
                                  className="form-control"
                                  onChange={(e) =>
                                    handleChange(
                                      item.attribute,
                                      e.target.value,
                                      item._id
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    ))}
                  {/* Property Type */}
                  {idDetail?.category_slug === "real_estate" && (
                    <div className="form-group pb-4">
                      <label htmlFor="propertyType">Property Type:</label>
                      <select
                        id="propertyType"
                        name="propertyType"
                        className="form-control mt-2"
                        value={propertyType}
                        onChange={handlePropertyChange}
                      >
                        <option value="">Select an option</option>
                        <option value="Buy">Buy</option>
                        <option value="Rent">Rent</option>
                        <option value="Lease">Lease</option>
                      </select>
                    </div>
                  )}

                  {/* per hour charder for freelancer */}
                  {idDetail?.category_slug === "freelancer" && (
                    <>
                      <div className="form-group mt20">
                        <label htmlFor="numProjects" className="form-label">
                          Number of Projects Completion
                        </label>
                        <input
                          required
                          type="number"
                          id="numProjects"
                          className="form-control"
                          value={numProjects}
                          onChange={(e) => setNumProjects(e.target.value)}
                          placeholder="Enter number of projects"
                        />
                      </div>
                      <div className="form-group mt20">
                        <label htmlFor="numProjects" className="form-label">
                          Per Hour Charges
                        </label>
                        <input
                          type="number"
                          id="perHourCharges"
                          className="form-control"
                          value={perhourCharge}
                          onChange={(e) => setPerHourCharge(e.target.value)}
                          placeholder="Enter number of projects"
                          required
                        />
                      </div>
                    </>
                  )}

                  {/* skills, company_website_link  for jobs and freelancer */}
                  {(idDetail?.category_slug === "freelancer" ||
                    idDetail?.category_slug === "jobs") && (
                    <>
                      <div className="form-group mt20">
                        <label
                          htmlFor="company_website_link"
                          className="form-label"
                        >
                          {idDetail?.category_slug === "freelancer"
                            ? "Your Website Link"
                            : "Company Website Link"}
                        </label>
                        <input
                          type="text"
                          id="company_website_link"
                          className="form-control"
                          value={company_website_link}
                          onChange={(e) =>
                            setcompany_website_link(e.target.value)
                          }
                          placeholder="Enter Company Website Link Here"
                          required
                        />
                      </div>

                      <div className="form-group mt20">
                        <label htmlFor="skills" className="form-label">
                          Skills
                        </label>
                        <InputGroup>
                          <FormControl
                            type="text"
                            id="skills"
                            className="form-control"
                            value={skill}
                            onChange={(e) => setSkill(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Enter a skill and press Enter"
                          />
                        </InputGroup>
                        <div className="mt-3">
                          {skills.map((skill, index) => (
                            <Badge
                              key={index}
                              pill
                              bg="primary"
                              className="me-2 mb-2 p-2"
                              style={{ fontSize: "1rem" }}
                            >
                              {skill}
                              <Button
                                variant="link"
                                className="p-0 ms-2"
                                style={{ color: "#fff" }}
                                onClick={() => removeSkill(index)}
                              >
                                &times;
                              </Button>
                            </Badge>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {/* cloth sizes */}
                  {idDetail?.category_slug === "fashion_beauty" && (
                    <div className="form-group">
                      <div className="size-selector">
                        <h5 className="mb-3">Size</h5>
                        <div className="btn-group" role="group">
                          {sizes.map((size) => (
                            <button
                              key={size}
                              type="button"
                              className={`btn ${
                                selectedSizes.includes(size)
                                  ? "btn-info"
                                  : "btn-outline-info"
                              } mx-1 px-3`}
                              style={{ borderRadius: "20px" }}
                              onClick={() => handleSizeClick(size)}
                            >
                              {size}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}

                    {/* stock section added for categories fashion, elect, alltypeProduct*/}
                  <div className="mt-2 mb-2">
                    {(idDetail?.category_slug === "fashion_beauty" ||
                      idDetail?.category_slug === "electronics" ||
                      idDetail?.category_slug === "goods_of_all_kinds") && (
                      <div>
                        <label htmlFor="stock">Stock:</label>
                        <input
                          type="number"
                          className="form-control mt-2 mb-2"
                          id="stock"
                          placeholder="Enter product stock"
                          value={stock}
                          onChange={(e) => setStock(e.target.value)} // Update stock state
                        />
                      </div>
                    )}
                  </div>

                  {/* country added for all category here  */}
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <label className="form-label">Country</label>
                      <Select
                        options={countryData.map((country) => ({
                          value: country.isoCode,
                          label: country.name,
                        }))}
                        onChange={handleCountryChange}
                        placeholder="Select Country"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">State</label>
                      <Select
                        options={stateData.map((state) => ({
                          value: state.isoCode,
                          label: state.name,
                        }))}
                        onChange={handleStateChange}
                        // value={
                        //   state
                        //     ? { value: state.isoCode, label: state.name }
                        //     : null
                        // }
                        placeholder="Select State"
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">City</label>
                      <Select
                        options={cityData.map((city) => ({
                          value: city.name,
                          label: city.name,
                        }))}
                        onChange={handleCityChange}
                        // value={
                        //   city ? { value: city.name, label: city.name } : null
                        // }
                        placeholder="Select City"
                      />
                    </div>
                  </div>

                  <div> 
                  
                  <Form>
                    <Form.Group controlId="currencySelect">
                      <Form.Label>Search and select currency</Form.Label>
                      <Select
                        value={selectedCurrency}
                        onChange={handleCurrencyChange}
                        options={currencyOptions}
                        isSearchable={true}
                        placeholder="Select a currency..."
                      />
                    </Form.Group>
                  </Form>

                  </div>

                  {/* location added for all category here */}
                  <div className="mb-3 mt20">
                    <label htmlFor="search-location" className="form-label">
                      Search Location
                    </label>
                    <StandaloneSearchBox
                      onLoad={(ref) => (searchBoxRef.current = ref)}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        type="text"
                        placeholder="Search for places"
                        className="form-control"
                        required
                      />
                    </StandaloneSearchBox>
                  </div>

                  {isImage === false ? (
                    <>
                      {/* product price for only for categories */}
                      <div className="form-group mb50">
                        <label htmlFor="priceInput">Product Price</label>
                        <input
                          type="number"
                          className="form-control"
                          id="priceInput"
                          placeholder="Enter price"
                          value={Productprice}
                          onChange={(e) => setProductPrice(e.target.value)}
                        />
                      </div>

                      {/* bid added only for goodsProduct here */}
                      {idDetail?.category_slug === "goods_of_all_kinds" && (
                        <>
                          <div className="col-sm-3 m50">
                            <label htmlFor="bid">
                              <h5
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "6px",
                                  color: "#77ced9",
                                }}
                              >
                                Enable Bid
                              </h5>
                            </label>
                            <input
                              type="checkbox"
                              name="bid"
                              onChange={handelEnablebid}
                            />
                          </div>

                          {isChecked && (
                            <>
                              <div className="row mb50">
                                <div className="col-sm-3">
                                  <label htmlFor="bid_start_price">
                                    Bid Start Price
                                  </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="bid_start_price"
                                    onChange={(e) =>
                                      setBidData({
                                        ...bidData,
                                        bid_start_price: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label htmlFor="bid_increament_value">
                                    Bid Increament Value
                                  </label>
                                  <input
                                    className="form-control"
                                    type="number"
                                    name="bid_increament_value"
                                    onChange={(e) =>
                                      setBidData({
                                        ...bidData,
                                        bid_increament_value: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label htmlFor="bid_start_date">
                                    Bid Start Date
                                  </label>
                                  <Datetime
                                    value={bidData.bid_start_date}
                                    onChange={handleStartDateChange}
                                    name="bid_start_date"
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label htmlFor="bid_end_date">
                                    Bid End Date
                                  </label>
                                  <Datetime
                                    value={bidData.bid_end_date}
                                    onChange={handleEndDateChange}
                                    name="bid_end_date"
                                    inputProps={{ readOnly: true }} // Make input field read-only to prevent manual input
                                    isValidDate={(currentDate) =>
                                      currentDate.isAfter(
                                        bidData.bid_start_date
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {/* add mulitiple images for four categories */}
                      <h5 className="m0 text-center">UPLOAD PHOTOS </h5>
                      <div className="row mt20">
                        <div className="row">
                          {imageInputs.map((input, index) => (
                            <div
                              className="col-sm-3 position-relative"
                              key={input.id}
                            >
                              <div className="fileBx mt-2">
                                <input
                                  type="file"
                                  name={`photo-${input.id}`}
                                  id={`photo-${input.id}`}
                                  style={{ display: "none" }}
                                  onChange={(e) => handlePhotoChange(index, e)}
                                  accept="image/*"
                                />
                                <label htmlFor={`photo-${input.id}`}>
                                  {input.file ? (
                                    <img
                                      height={70}
                                      width={150}
                                      src={URL.createObjectURL(input.file)}
                                      alt={`Uploaded Preview ${index + 1}`}
                                    />
                                  ) : (
                                    "Upload Img"
                                  )}
                                </label>
                                {input.file && (
                                  <button
                                    type="button"
                                    className="position-absolute top-0 end-0 btn-close p-2"
                                    aria-label="Remove"
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    <span aria-hidden="true"></span>
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                          {imageInputs.length < 10 &&
                            !imageInputs.some((input) => input.file === "") && (
                              <div className="col-sm-3 position-relative">
                                <div className="fileBx mt-2">
                                  <input
                                    type="file"
                                    name={`photo-${imageInputs.length + 1}`}
                                    id={`photo-${imageInputs.length + 1}`}
                                    style={{ display: "none" }}
                                    onChange={(e) =>
                                      handlePhotoChange(imageInputs.length, e)
                                    }
                                    accept="image/*"
                                  />
                                  <label
                                    htmlFor={`photo-${imageInputs.length + 1}`}
                                  >
                                    Upload Img
                                  </label>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* course price only for lesson and course */}
                      {idDetail.category_slug === "lesson_courses" && (
                        <>
                          <div className="form-group mt20">
                            <label htmlFor="learn" className="form-label">
                              What You Will Learn
                            </label>
                            <InputGroup>
                              <FormControl
                                type="text"
                                id="learn"
                                className="form-control"
                                value={learn}
                                onChange={(e) => setLearn(e.target.value)}
                                onKeyDown={handleKeyDownLearn}
                                placeholder="Enter What You Will Learn and press enter"
                              />
                            </InputGroup>
                            <div className="mt-3">
                              {learns.map((learnItem, index) => (
                                <Badge
                                  key={index}
                                  pill
                                  bg="primary"
                                  className="me-2 mb-2 p-2"
                                  style={{ fontSize: "1rem" }}
                                >
                                  {learnItem}
                                  <Button
                                    variant="link"
                                    className="p-0 ms-2"
                                    style={{ color: "#fff" }}
                                    onClick={() => removeWhatYouLearn(index)}
                                  >
                                    &times;
                                  </Button>
                                </Badge>
                              ))}
                            </div>
                          </div>
                          <>
                            <h6>Course Price</h6>
                            <div className="d-flex">
                              <div className="form-check ">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="coursePrice"
                                  id="radio_coursePrice_free"
                                  value="Free"
                                  onChange={() => handleRadioChange("Free")}
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor="radio_coursePrice_free"
                                >
                                  Free
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  type="radio"
                                  className="btn-check"
                                  name="coursePrice"
                                  id="radio_coursePrice_paid"
                                  value="Paid"
                                  onChange={() => handleRadioChange("Paid")}
                                  required
                                />
                                <label
                                  className="btn btn-outline-primary"
                                  htmlFor="radio_coursePrice_paid"
                                >
                                  Paid
                                </label>
                              </div>
                            </div>
                            {selectedOption === "Paid" && (
                              <div className="form-group me-3 mt20 ">
                                <label htmlFor="coursePriceAmount">
                                  Amount
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="coursePriceAmount"
                                  value={amount}
                                  onChange={handleAmountChange}
                                  placeholder="Enter amount"
                                  required
                                />
                              </div>
                            )}
                          </>
                        </>
                      )}

                      {/* one image upload for job, freelancer, courses */}
                      <div className="mt30">
                        <h5 className="m-0">Upload file</h5>
                        <div className="row" style={{ maxWidth: "700px" }}>
                          <div className="col-sm-3">
                            <div className="fileBx mt-2">
                              <input
                                type="file"
                                name="image1"
                                id="image1"
                                onChange={(e) => handleImageChange(e, "image1")}
                                style={{ display: "none" }}
                                required
                              />
                              <label htmlFor="image1">
                                {uploadData.image1 ? (
                                  <img
                                    height={70}
                                    width={100}
                                    src={URL.createObjectURL(uploadData.image1)}
                                    alt=""
                                  />
                                ) : (
                                  "Upload Img"
                                )}
                              </label>
                            </div>
                          </div>

                          {/* job Logo */}
                          {idDetail?.category_slug === "jobs" && (
                            <>
                              <h5 className="mt30">Upload logo</h5>
                              <div className="col-sm-3">
                                <div className="fileBx mt-2">
                                  <input
                                    type="file"
                                    name="uploadfile2"
                                    id="img2"
                                    onChange={(e) =>
                                      handleImageChange(e, "image2")
                                    }
                                    style={{ display: "none" }}
                                    required
                                  />
                                  <label htmlFor="img2">
                                    {uploadData.image2 ? (
                                      <img
                                        height={70}
                                        width={100}
                                        src={URL.createObjectURL(
                                          uploadData.image2
                                        )}
                                        alt=""
                                      />
                                    ) : (
                                      "Choose Logo"
                                    )}
                                  </label>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="text-end mt-5">
                    <Button type="submit" className="text-white">
                      {loading ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </article>
      </article>
    </section>
  );
};

export default AddProduct;