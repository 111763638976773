import React, { useEffect, useState } from "react";
import { Table, Container, Row, Col, Card, Alert, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAppContext } from "../../../../contextApi/AppContext";
import NoData from "../../../noData/NoData";

const StatementPage = () => {
  const { mainData, getpaymentList, userData } = useAppContext();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const user_id = userData?._id;

  useEffect(() => {
    if (user_id) {
      getpaymentList({ user_id });
    }
    // eslint-disable-next-line
  }, [user_id]);

  useEffect(() => {
    if (mainData?.paymentList) {
      filterData();
    }
    // eslint-disable-next-line
  }, [startDate, endDate, mainData?.paymentList]);

  const filterData = () => {
    if (!mainData?.paymentList) {
      setFilteredData([]);
      return;
    }

    if (!startDate || !endDate) {
      setFilteredData(mainData.paymentList);
      return;
    }

    const filtered = mainData.paymentList.filter((statement) => {
      const statementDate = new Date(statement.createdAt);
      return statementDate >= startDate && statementDate <= endDate;
    });
    setFilteredData(filtered);
  };

  const handleDateChange = (setter) => (e) => {
    const date = e.target.value ? new Date(e.target.value) : null;
    setter(date);
  };

  // console.log(mainData?.paymentList?.length);

  return (
    <Container className="mt-5">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center">Account Statement</h1>
          <p className="text-center text-muted">
            Overview of your recent transactions
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate ? startDate.toISOString().substr(0, 10) : ""}
              onChange={handleDateChange(setStartDate)}
            />
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate ? endDate.toISOString().substr(0, 10) : ""}
              onChange={handleDateChange(setEndDate)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h5>Statement Details</h5>
            </Card.Header>
            <Card.Body>
              <Alert variant="info">
                This statement shows your credits, debits, total amounts, and
                closing balances for the selected period.
              </Alert>
              {filteredData.length === 0 ? (
                <NoData des="No payment records found" />
              ) : (
                <Table
                  responsive="sm"
                  striped
                  bordered
                  hover
                  className="text-center"
                >
                  <thead className="table-dark">
                    <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Amount (₹)</th>
                      <th>Closing Balance (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((statement, index) => {
                      const date = new Date(statement.createdAt);
                      const formattedDate = date.toLocaleDateString();
                      const formattedTime = date.toLocaleTimeString();
                      const isCredit = statement.credit > 0;
                      const type = isCredit ? "Credit" : "Debit";
                      const amount = isCredit
                        ? statement.credit
                        : statement.debit;

                      return (
                        <tr key={index}>
                          <td>{`${formattedDate} ${formattedTime}`}</td>
                          <td>
                            <div
                              className={`text-${
                                isCredit ? "success" : "danger"
                              }`}
                            >
                              <strong>{type}</strong>
                            </div>
                          </td>
                          <td
                            className={`text-${
                              isCredit ? "success" : "danger"
                            }`}
                          >
                            {isCredit ? `+${amount}` : `-${amount}`}
                          </td>
                          <td>{statement.closing_amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StatementPage;
