import React from "react";
import add from './../../assets/assets/images/Ad.jpg'

const Ads = () => {
  return (
    <>
      <div className="col-md-1 adBx">
        <div className="sticky-top">
          <img src={add} alt="" />
        </div>
      </div>
    </>
  );
};

export default Ads;
