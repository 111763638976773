import React from 'react'
import communication from "../../assets/assets/images/communication-icon.svg";
import realEstate from "../../assets/assets/images/real-estate-icon.svg";
import jobs from "../../assets/assets/images/jobs-icon.svg";
import lessonsCourses from "../../assets/assets/images/lessons-courses-icon.svg";

const HomeInformation = () => {
  return (
    <>
        {/* information part work start here */}
        <section>
            <article className='container pt-5 pb-5'>
                <h3 className='mb-5 titelBx'>Empower Your Future:<br />Connect, Explore, Advance, and Learn</h3>
                <aside className='row'>
                    {/* item start */}
                    <div className='col-md-3 col-sm-6 col-12'>
                        <img src={communication} style={{width:'54px'}} alt="" />
                        <h5 className='mt-2'>Expand Your Professional Horizons with Unlimited Connections</h5>
                        <p>Join over 300 professional communities to grow your network efficiently. Our advanced tools and 24/7 support ensure you connect with the right people to advance your career or business.</p>
                    </div>
                    {/* item end */}
                    {/* item start */}
                    <div className='col-md-3 col-sm-6 col-12'>
                        <img src={realEstate} style={{width:'54px'}} alt="" />
                        <h5 className='mt-2'>Discover Your Dream Property with Tailored Real Estate Solutions</h5>
                        <p>Browse over 500 properties, from luxury estates to budget homes, with transparent pricing and precise search filters. Get 24/7 expert support throughout your real estate journey.</p>
                    </div>
                    {/* item end */}
                    {/* item start */}
                    <div className='col-md-3 col-sm-6 col-12'>
                        <img src={jobs} style={{width:'54px'}} alt="" />
                        <h5 className='mt-2'>Unlock a World of Career Possibilities</h5>
                        <p>Explore over 200 job categories with clear, detailed listings. Tailored filters match you with the right jobs, and 24/7 support provides the tools you need for career success.</p>
                    </div>
                    {/* item end */}
                    {/* item start */}
                    <div className='col-md-3 col-sm-6 col-12'>
                        <img src={lessonsCourses} style={{width:'54px'}} alt="" />
                        <h5 className='mt-2'>Elevate Your Skills with Customized Learning Paths</h5>
                        <p>Choose from over 100 courses to learn new skills or deepen existing ones. Flexible learning and 24/7 support ensure a smooth educational experience.</p>
                    </div>
                    {/* item end */}
                </aside>
            </article>
        </section>
        {/* information part work end here */}
    </>
  )
}

export default HomeInformation