import React, { useState, useEffect } from "react";
import BreadCrum from "../components/comon/BreadCrum";
import Ads from "../components/comon/Ads";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import Electronics from "../components/productsitems/Electronics";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Skeleton2 } from "../components/loading/Skeleton";
import NoData from "./noData/NoData";
import FashionFilter from "../components/fashion-beauty/FashionFilter";

const ElectronicsList = () => {
  const {
    electronicsList,
    getAttributeFilterList,
    totalItem,
    getElectronicsProduct,
    listLoading,
    sitelocation,
    globalCurrency,
    currency_symbol,
  } = useAppContext();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(totalItem?.Electronics / itemsPerPage);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cat_id = searchParams.get("category_id");
  const sub_id = searchParams.get("sub_category");
  const child_id = searchParams.get("child_category");
  const search_value = searchParams.get("search");
  const [filterData, setFilterData] = useState([]);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();

  const searchData = {
    keyword_search: search_value,
    page: currentPage,
    limit: itemsPerPage,
    address: sitelocation,
    minPrice: minPrice,
    maxPrice: maxPrice,
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = { addressName: "", currency: globalCurrency };
      if (sub_id || child_id) {
        await getElectronicsProduct(
          searchData,
          sub_id || child_id,
          filterData,
          dataToSend
        );
      } else {
        await getElectronicsProduct(searchData, "", filterData, dataToSend);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [
    search_value,
    currentPage,
    sub_id,
    child_id,
    filterData,
    sitelocation,
    minPrice,
    maxPrice,
    globalCurrency,
    currency_symbol,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (sub_id) {
        await getAttributeFilterList({ sub: sub_id });
      }
      if (cat_id) {
        await getAttributeFilterList({ main: cat_id });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [search_value, sub_id, cat_id]);

  const updateFilter = (e, attr, value) => {
    const checked = e.target.checked;
    if (checked === true) {
      const existingFilterIndex = filterData.findIndex(
        (item) => item.attribute === attr
      );

      if (existingFilterIndex !== -1) {
        const updatedFilterData = [...filterData];
        updatedFilterData[existingFilterIndex].value.push(value);
        setFilterData(updatedFilterData);
      } else {
        const updatedFilterData = [
          ...filterData,
          {
            attribute: attr,
            value: [value],
          },
        ];
        setFilterData(updatedFilterData);
      }
    } else {
      const updatedFilterData = filterData
        .map((item) => {
          if (item.attribute === attr) {
            item.value = item.value.filter((v) => v !== value);
          }
          return item;
        })
        .filter((item) => item.value.length > 0);
      setFilterData(updatedFilterData);
    }
  };
  return (
    <>
      <BreadCrum title={"Electronics List Page"} param={"Electronics"} />
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* left part start */}
            <FashionFilter
              onFilter={updateFilter}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
            />
            {/* left part end */}
            {/* right part start */}
            <div className="col-md-8">
              <div className="row ">
                {/* item start */}
                {listLoading.Electronics ? (
                  Array.from({ length: 6 }).map((item, index) => (
                    <Skeleton2 key={index} col={4} />
                  ))
                ) : electronicsList.length === 0 ? (
                  <NoData
                    des={
                      "Please check the spelling or try searching for something else. Thank you!"
                    }
                  />
                ) : (
                  Array.isArray(electronicsList) &&
                  electronicsList.map((item, index) => (
                    <Electronics
                      key={index}
                      item={item}
                      currency_symbol={currency_symbol}
                    />
                  ))
                )}
                {/* item end */}
              </div>
              {/* pagenation start */}
              <div align="center">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    siblingCount={0}
                    boundaryCount={0}
                  />
                </Stack>
              </div>
              {/* pagenation end */}
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default ElectronicsList;
