import React, { useEffect, useState } from "react";
import Ads from "./../components/comon/Ads";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import CallAction from "../components/comon/CallAction";
import { FaHeart, FaRegHeart, FaShareAlt, FaTrash } from "react-icons/fa";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { toast } from "react-toastify";

const RealstateDetail = ({ initialDetail }) => {
  const { id } = useParams();
  const {
    getPropertyDetail,
    propertyDetail,
    setPropertyDetail,
    wishListAdd,
    wishListRemove,
    reletedList,
    getReletedProduct,
    userData,
    productrating,
    getreviews,
    reviews,
    reviewDelete,
    isLogIn,
    globalCurrency,
    currency_symbol,
  } = useAppContext();

  const [color, setColor] = useState(false);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [open, setOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const location1 = useLocation();
  const navigate = useNavigate();

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("Please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  // Function to handle opening the lightbox with the selected image
  const openLightbox = (index) => setOpenIndex(index);

  // Function to handle closing the lightbox
  const closeLightbox = () => setOpenIndex(null);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    // console.log('New rating set:', rating);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
    setPreviewImages(files?.map((file) => URL.createObjectURL(file)));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("productId", product_id);
    formData.append("rating", rating);
    formData.append("review", review);
    images.forEach((image, index) => {
      formData.append(`attachments[${index}]`, image);
    });

    productrating(formData);

    setRating(0);
    setReview("");
    setImages([]);
    setPreviewImages([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        id: id,
        currency: globalCurrency,
      };
      await getPropertyDetail(dataToSend);
    };
    if (!initialDetail && id) {
      fetchData();
    }
    if (!!initialDetail && !!initialDetail.data && !!initialDetail.data._id) {
      setPropertyDetail(initialDetail);
    }
    // eslint-disable-next-line
  }, [id, initialDetail, globalCurrency, currency_symbol]);

  const {
    description,
    image,
    title,
    product_id,
    attribute_values,
    isWishlist,
    category_id,
    category_name,
    category_slug,
    sub_category_id,
    userId,
    lat,
    lng,
    address,
    createdAt,
    converted_currency_price,
  } = propertyDetail.data || {};

  const [isExpanded, setIsExpanded] = useState(false);
  const wordLimit = 60;
  const truncatedDescription =
    description?.split(" ").slice(0, wordLimit).join(" ") + "...";

  const numberOfBedrooms = attribute_values?.find(
    (attribute) => attribute.attribute === "Number of Bedrooms"
  );

  // Display the value of the attribute
  const bedroomsValue = numberOfBedrooms
    ? numberOfBedrooms.value
    : "Not specified";

  const numberOfbathrooms = attribute_values?.find(
    (attribute) => attribute.attribute === "Number of Bathrooms"
  );

  // Display the value of the attribute
  const bathroomValue = numberOfbathrooms
    ? numberOfbathrooms.value
    : "Not specified";

  const SquareFootage = attribute_values?.find(
    (attribute) => attribute.attribute === "Square Footage"
  );

  // Display the value of the attribute
  const SquareFootageValue = SquareFootage
    ? SquareFootage.value
    : "Not specified";

  const YearBuilt = attribute_values?.find(
    (attribute) => attribute.attribute === "Year Built"
  );

  // Display the value of the attribute
  const YearBuiltValue = YearBuilt ? YearBuilt.value : "Not specified";

  const Amenities = attribute_values?.find(
    (attribute) => attribute.attribute === "Amenities"
  );

  // Display the value of the attribute
  const AmenitiesValue = Amenities ? Amenities.value : "Not specified";

  const ParkingSpaces = attribute_values?.find(
    (attribute) => attribute.attribute === "Parking Spaces"
  );

  // Display the value of the attribute
  const ParkingSpacesValue = ParkingSpaces
    ? ParkingSpaces.value
    : "Not specified";

  const FlooringType = attribute_values?.find(
    (attribute) => attribute.attribute === "Flooring Type"
  );

  // Display the value of the attribute
  const FlooringValue = FlooringType ? FlooringType.value : "Not specified";

  const Furnished = attribute_values?.find(
    (attribute) => attribute.attribute === "Furnished"
  );

  // Display the value of the attribute
  const FurnishedValue = Furnished ? Furnished.value : "Not specified";

  const Security = attribute_values?.find(
    (attribute) => attribute.attribute === "Security Features"
  );

  // Display the value of the attribute
  const SecurityValue = Security ? Security.value : "Not specified";

  const Renovations = attribute_values?.find(
    (attribute) => attribute.attribute === "Renovations/Upgrades"
  );

  // Display the value of the attribute
  const RenovationsValue = Renovations ? Renovations.value : "Not specified";

  const Nearby = attribute_values?.find(
    (attribute) => attribute.attribute === "Nearby Facilities"
  );

  // Display the value of the attribute
  const NearbyValue = Nearby ? Nearby.value : "Not specified";

  const PetPolicy = attribute_values?.find(
    (attribute) => attribute.attribute === "Pet Policy"
  );

  // Display the value of the attribute
  const PetPolicyValue = PetPolicy ? PetPolicy.value : "Not specified";

  const ViewType = attribute_values?.find(
    (attribute) => attribute.attribute === "View Type"
  );

  // Display the value of the attribute
  const ViewTypeValue = ViewType ? ViewType.value : "Not specified";

  const Accessibility = attribute_values?.find(
    (attribute) => attribute.attribute === "Accessibility "
  );

  // Display the value of the attribute
  const AccessibilityValue = Accessibility
    ? Accessibility.value
    : "Not specified";

  const NearestSchools = attribute_values?.find(
    (attribute) => attribute.attribute === "Nearest Schools"
  );

  // Display the value of the attribute
  const NearestSchoolsValue = NearestSchools
    ? NearestSchools.value
    : "Not specified";

  const NearestHospitals = attribute_values?.find(
    (attribute) => attribute.attribute === "Nearest Hospitals/Clinics"
  );

  // Display the value of the attribute
  const NearestHospitalsValue = NearestHospitals
    ? NearestHospitals.value
    : "Not specified";

  const NearbyShoppingCenters = attribute_values?.find(
    (attribute) => attribute.attribute === "Nearby Shopping Centers"
  );

  // Display the value of the attribute
  const NearbyShoppingCentersValue = NearbyShoppingCenters
    ? NearbyShoppingCenters.value
    : "Not specified";

  const Tranactiontype = attribute_values?.find(
    (attribute) => attribute.attribute === "Transaction Type"
  );

  // Display the value of the attribute
  const TranactiontypeValue = Tranactiontype
    ? Tranactiontype.value
    : "Not specified";

  useEffect(() => {
    if (product_id) {
      getreviews(product_id);
    }
    // eslint-disable-next-line
  }, [product_id]);

  useEffect(() => {
    if (isWishlist !== undefined) {
      setColor(isWishlist);
    }
  }, [isWishlist]);

  const addWhishlist = (id) => {
    if (!color) {
      wishListAdd(id);
      setColor(true);
    } else {
      wishListRemove(id);
      setColor(false);
    }
  };

  useEffect(() => {
    if (product_id && category_id) {
      getReletedProduct(category_id, sub_category_id, product_id);
    }
    // eslint-disable-next-line
  }, [product_id, category_id, sub_category_id]);

  const date = new Date(createdAt);

  // Extract day, month, and year
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  // Format as DD MM YYYY
  const formattedDate = `${day}/${month}/${year}`;

  const handleDelete = (id) => {
    const deleteConfirm = window.confirm(
      "Are you sure you want to delete this Review?"
    );
    if (deleteConfirm) {
      const dataToSend = {
        product_id: product_id,
        reviewId: id,
      };
      reviewDelete(dataToSend);
    }
  };

  // near by facility
  const [expandedNBF, setExpandedNBF] = useState(false);
  const maxWords = 20;
  const truncatedValue =
    NearbyValue?.split(" ").slice(0, maxWords).join(" ") + "...";

  // Renovations/Upgrades
  const [expandedRU, setExpandedRU] = useState(false);
  const truncatedValueRU =
    RenovationsValue?.split(" ").slice(0, maxWords).join(" ") + "..";

  // Pet Policy
  const [expandedPP, setExpandedPP] = useState(false);
  const truncatedValuePP =
    PetPolicyValue?.split(" ").slice(0, maxWords).join(" ") + "..";

  // Accessibility
  const [expandedAccessibility, setExpandedAccessibility] = useState(false);
  const truncatedValueAccessibility =
    Accessibility?.split(" ").slice(0, maxWords).join(" ") + "..";

  // Nearest Schools
  const [expandedNS, setExpandedNS] = useState(false);
  const truncatedValueNS =
    NearestSchoolsValue?.split(" ").slice(0, maxWords).join(" ") + "..";

  // Nearest Hospitals/Clinics
  const [expandedNH, setExpandedNH] = useState(false);
  const truncatedValueNH =
    NearestHospitalsValue?.split(" ").slice(0, maxWords).join(" ") + "..";

  // Nearby Shopping Centers
  const [expandedNSC, setExpandedNSC] = useState(false);
  const truncatedValueNSC =
    NearbyShoppingCentersValue?.split(" ").slice(0, maxWords).join(" ") + "...";

  // security
  const [expandedSecurity, setExpandedSecurity] = useState(false);

  const sharePost = async () => {
    const shareData = {
      title: "Dzital",
      url: window.location.href,
    };
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error sharing the post:", err.message);
    }
  };

  return (
    <>
      {/* page banner work start */}
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-12">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/${category_slug}?category_id=${category_id}`}>
                    {category_name}
                  </Link>
                </li>
                <li className="active"> Detail Page Of {title}</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      {/* page banner work end */}

      {/* page body work start */}
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            <Ads />
            {propertyDetail.isLoad && (
              <div className="col-md-10 d-flex justify-content-center ">
                <div className="spinner-grow text-info my-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            {!propertyDetail.isLoad && (
              //************* main area start *************//
              <div className="col-md-10">
                <div className="row">
                  {/* left part start */}
                  <div className="col-md-8">
                    {/* new work start here */}
                    <div className="card propertyInfo">
                      <div className="card-header">
                        <div className="d-flex justify-content-between">
                          <div>
                            <h3> {title} </h3>
                            <div
                              className="propertyPrice"
                              style={{
                                backgroundColor: "#f7f9fc",
                              }}
                            >
                              <h5
                                style={{
                                  color: "#198754",
                                  fontWeight: "800",
                                  fontSize: "1.2rem",
                                  margin: 0,
                                }}
                              >
                                Property Price:{" "}
                                {currency_symbol ? currency_symbol : "$"}{" "}
                                {converted_currency_price}
                              </h5>
                            </div>
                          </div>
                          <ul className="list-group list-group-horizontal list-inline">
                            <li className="list-group-item border-0 px-1">
                              <div className="iconWish">
                                <FaShareAlt
                                  className="text20 text-primary Cursor"
                                  onClick={sharePost}
                                />
                              </div>
                            </li>
                            <li className="list-group-item border-0 px-1">
                              {userData._id === userId ? (
                                ""
                              ) : (
                                <div
                                  className="iconWish"
                                  onClick={() => addWhishlist(product_id)}
                                >
                                  {color ? (
                                    <FaHeart className="text-danger text20" />
                                  ) : (
                                    <FaRegHeart className="text20" />
                                  )}
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="collageImgBx">
                              {image?.map((img, index) => (
                                <img
                                  key={index}
                                  src={img}
                                  alt={title}
                                  className="img-fluid"
                                  onClick={() => setOpen(true)}
                                />
                              ))}

                              <Lightbox
                                plugins={[
                                  Download,
                                  Fullscreen,
                                  Zoom,
                                  Thumbnails,
                                ]}
                                open={open}
                                close={() => setOpen(false)}
                                slides={image?.map((img) => ({ src: img }))}
                              />
                            </div>
                          </div>
                          <div className="col-md-8">
                            <nav className="navbar bg-danger bg-opacity-10 rounded-2">
                              <ul className="list-inline">
                                <li>
                                  <i className="fa-solid fa-bed"></i>{" "}
                                  {bedroomsValue}{" "}
                                  {bedroomsValue === 1 ? "Bed" : "Beds"}
                                </li>
                                <li>
                                  <i className="fa-solid fa-bath"></i>
                                  {bathroomValue}{" "}
                                  {bathroomValue === 1 ? "Bath" : "Baths"}{" "}
                                </li>
                                <li>
                                  <i className="fa-solid fa-window-restore"></i>{" "}
                                  1Balcony{" "}
                                </li>
                                <li>
                                  <i className="fa-solid fa-chair"></i>{" "}
                                  {FurnishedValue}
                                </li>
                              </ul>
                            </nav>
                            <div className="row mt-2">
                              <div className="col">
                                <ul className="list-unstyled">
                                  <li className="mb-3">
                                    <b>Square Footage : </b>{" "}
                                    {SquareFootageValue}
                                  </li>
                                  <li className="mb-3">
                                    <b>Year Built : </b> {YearBuiltValue}
                                  </li>
                                  <li className="mb-3">
                                    <b>View Type : </b> {ViewTypeValue}
                                  </li>
                                </ul>
                              </div>
                              <div className="col">
                                <ul className="list-unstyled">
                                  <li className="mb-3">
                                    <b>Parking Spaces : </b>{" "}
                                    {ParkingSpacesValue}
                                  </li>
                                  <li className="mb-3">
                                    <b>Floor type : </b> {FlooringValue}
                                  </li>
                                  <li className="mb-3">
                                    <b>Transaction Type : </b>{" "}
                                    {TranactiontypeValue}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer">
                        {userData._id === userId ? (
                          ""
                        ) : (
                          <CallAction
                            peerUser={userId}
                            realestateId={1}
                            details={propertyDetail?.data}
                            productId={product_id}
                          />
                        )}
                      </div>
                    </div>

                    <div className="card">
                      <div className="card-body">
                        <h2>More Details</h2>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: isExpanded
                                ? description
                                : truncatedDescription,
                            }}
                          />
                          {description?.split(" ").length > wordLimit && (
                            <span
                              onClick={() => setIsExpanded(!isExpanded)}
                              style={{ color: "blue", cursor: "pointer" }}
                            >
                              {isExpanded ? " Read Less" : " Read More"}
                            </span>
                          )}
                        </p>

                        <table className="table table-bordered table-striped width100">
                          <tbody>
                            <tr>
                              <td>
                                <b>Aminities : </b> {AmenitiesValue}
                              </td>
                              <td>
                                <b>Nearby Facilities: </b>
                                {expandedNBF ? NearbyValue : truncatedValue}
                                {NearbyValue?.split(" ").length > maxWords && (
                                  <span
                                    onClick={() => setExpandedNBF(!expandedNBF)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedNBF ? " Read Less" : " Read More"}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Accessibility: </b>
                                {expandedAccessibility
                                  ? AccessibilityValue
                                  : truncatedValueAccessibility}
                                {AccessibilityValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() =>
                                      setExpandedAccessibility(
                                        !expandedAccessibility
                                      )
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedAccessibility
                                      ? " Read Less"
                                      : " Read More"}
                                  </span>
                                )}
                              </td>
                              <td>
                                <b>Nearest Schools:</b>
                                {expandedNS
                                  ? NearestSchoolsValue
                                  : truncatedValueNS}
                                {NearestSchoolsValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() => setExpandedNS(!expandedNS)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedNS ? " Read Less" : " Read More"}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Nearby Shopping Centers:</b>
                                {expandedNSC
                                  ? NearbyShoppingCentersValue
                                  : truncatedValueNSC}
                                {NearbyShoppingCentersValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() => setExpandedNSC(!expandedNSC)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedNSC ? " Read Less" : " Read More"}
                                  </span>
                                )}
                              </td>
                              <td>
                                <b>Pet Policy:</b>
                                {expandedPP ? PetPolicyValue : truncatedValuePP}
                                {PetPolicyValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() => setExpandedPP(!expandedPP)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedPP ? " Read Less" : " Read More"}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Renovations/Upgrades:</b>
                                {expandedRU
                                  ? RenovationsValue
                                  : truncatedValueRU}
                                {RenovationsValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() => setExpandedRU(!expandedRU)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedRU ? " Read Less" : " Read More"}
                                  </span>
                                )}
                              </td>
                              <td>
                                <b>Nearest Hospitals/Clinics:</b>
                                {expandedNH
                                  ? NearestHospitalsValue
                                  : truncatedValueNH}
                                {NearestHospitalsValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() => setExpandedNH(!expandedNH)}
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedNH ? " Read Less" : " Read More"}
                                  </span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Security :</b>
                                {expandedSecurity
                                  ? SecurityValue
                                  : truncatedValueNH}
                                {SecurityValue?.split(" ").length >
                                  maxWords && (
                                  <span
                                    onClick={() =>
                                      setExpandedSecurity(!expandedSecurity)
                                    }
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    {expandedSecurity
                                      ? " Read Less"
                                      : " Read More"}
                                  </span>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* view review part start */}

                    {/* new work end here */}
                  </div>
                  {/* left part end */}

                  {/* right part start */}
                  <div className="col-md-4">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Seller Information</h5>
                        <Link
                          to={`/seller-info/${propertyDetail?.data.seller_details?._id}`}
                        >
                          {" "}
                          <button className="btn btn-outline-primary btn-sm">
                            {" "}
                            View Details{" "}
                          </button>{" "}
                        </Link>
                        <Link
                          to={`/seller-info/${propertyDetail?.data.seller_details?._id}`}
                        >
                          <img
                            src={propertyDetail?.data?.seller_details?.image}
                            alt="Seller"
                            className="rounded-circle"
                            style={{ width: "40px", height: "40px" }}
                          />
                        </Link>
                      </div>
                      <div className="card-body">
                        <p>
                          <strong>Seller Name:</strong>{" "}
                          <span>
                            {propertyDetail?.data.seller_details?.name}
                          </span>
                        </p>
                        <p>
                          <strong>Mobile:</strong>{" "}
                          <span>
                            {propertyDetail?.data.seller_details?.mobile}
                          </span>
                        </p>
                        <p>
                          <strong>Email:</strong>{" "}
                          <span>
                            {propertyDetail?.data.seller_details?.email}
                          </span>
                        </p>
                        <p>
                          <strong>Address:</strong>{" "}
                          <span>
                            {propertyDetail?.data.seller_details?.address}
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* location */}
                    <div className="card card-sm mb15">
                      <div className="card-body p8">
                        <h5 className="mb0 text16 theme-text">
                          <i className="fa fa-calendar" /> <b>Posted On</b>{" "}
                          {formattedDate}
                        </h5>
                        <p className="text-muted mb5">
                          <b>Location :</b> {address}
                        </p>
                        <div>
                          <iframe
                            title={title}
                            allowFullScreen
                            height="350"
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyAbznYqeVfwfeh0DQdKFF4tFAq276hU6cY&center=${lat},${lng}2&zoom=15`}
                            style={{
                              border: "0",
                            }}
                            width="100%"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="mb-0">Rate this product:</h5>
                      </div>
                      <div className="card-body">
                        <form onSubmit={handleSubmit} className="mt-4">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="rating mt-4">
                                {[...Array(5)]?.map((star, index) => (
                                  <button
                                    key={index}
                                    type="button"
                                    className={`btn btn-link ${
                                      index < rating ? "text-warning" : ""
                                    }`}
                                    onClick={() =>
                                      handleRatingChange(index + 1)
                                    }
                                  >
                                    <i className="fas fa-star"></i>
                                  </button>
                                ))}
                              </div>
                            </div>
                            <div className="col-md-7">
                              <label htmlFor="images">Upload Images:</label>
                              <input
                                type="file"
                                id="images"
                                className="form-control"
                                multiple
                                onChange={handleImageChange}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label htmlFor="review">Review:</label>
                            <textarea
                              id="review"
                              className="form-control"
                              value={review}
                              onChange={handleReviewChange}
                            ></textarea>
                          </div>
                          <div className="form-group mt-3">
                            <div className="preview-images mt-3">
                              {previewImages?.map((src, index) => (
                                <img
                                  key={index}
                                  src={src}
                                  alt="Preview"
                                  className="img-thumbnail mr-2"
                                  style={{ width: "100px", height: "100px" }}
                                />
                              ))}
                            </div>
                          </div>
                          {!isLogIn ? (
                            <button
                              className="btn btn-primary mt-2 text-white"
                              onClick={() => redirect()}
                            >
                              LogIn First To Submit Review
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary mt-2 text-white"
                            >
                              Submit Review
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div
                      className="card"
                      style={{ height: "310px", overflowY: "auto" }}
                    >
                      <div className="card-header">
                        <h5 className="mb-0">Reviews:</h5>
                      </div>
                      {Array.isArray(reviews.data) &&
                      reviews.data.length > 0 ? (
                        reviews?.data?.map((review) => (
                          <div
                            key={review._id}
                            style={{ position: "relative" }}
                          >
                            <div className="card-body align-item-center">
                              {userData._id === review.userId && (
                                <FaTrash
                                  className="text-danger"
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: "30px",
                                    right: "10px",
                                  }}
                                  onClick={() => handleDelete(review._id)}
                                />
                              )}
                              <div className="d-flex align-items-center mb-3">
                                <img
                                  src={review.user_img}
                                  alt="User"
                                  className="rounded-circle mr-3"
                                  style={{ width: "50px", height: "50px" }}
                                />
                                <div
                                  className="user-profileinfo d-flex"
                                  style={{ marginLeft: "10px" }}
                                >
                                  <h6 style={{ marginRight: "10px" }}>
                                    {review.user_name}
                                  </h6>
                                  <div className="rating">
                                    {[...Array(5)]?.map((star, index) => (
                                      <i
                                        key={index}
                                        className={`fas fa-star ${
                                          index < review.rating
                                            ? "text-warning"
                                            : "text-secondary"
                                        }`}
                                      ></i>
                                    ))}
                                  </div>
                                </div>
                              </div>
                              <p className="mt-3">
                                {review.review}.. <strong>Review on</strong> :{" "}
                                <small className="text-muted">
                                  {new Date(
                                    review.createdAt
                                  ).toLocaleDateString()}
                                </small>{" "}
                              </p>
                              <div className="mt-3">
                                {review.attachments?.map((src, index) => (
                                  <img
                                    key={index}
                                    src={src}
                                    alt="Review attachment"
                                    className="img-thumbnail mr-2 mb-2"
                                    onClick={() => openLightbox(index)}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      cursor: "pointer",
                                    }}
                                  />
                                ))}
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))
                      ) : (
                        <div className="card-body text-center">
                          <p>No reviews found</p>
                        </div>
                      )}
                    </div>

                    {openIndex !== null && (
                      <Lightbox
                        plugins={[Download, Fullscreen, Zoom, Thumbnails]}
                        open={true}
                        close={closeLightbox}
                        slides={reviews.data.flatMap((review) =>
                          review.attachments?.map((src) => ({ src }))
                        )}
                        index={openIndex}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h5 className="mt-3">Related Field</h5>
                    <hr className="mt0" />
                  </div>
                  {/* item start */}
                  {Array.isArray(reletedList) &&
                    reletedList?.map((item) => {
                      return (
                        <div className="col-md-6" key={item._id}>
                          <div className="card mb-3" style={{ maxWidth: 540 }}>
                            <div className="row g-0">
                              <Link
                                to={`/product/related-product/${item._id}`}
                                className="col-md-4"
                              >
                                <img
                                  src={item.image}
                                  className="img-fluid rounded-start"
                                  style={{minHeight:'100%'}}
                                  alt="..."
                                />
                              </Link>
                              <div className="col-md-8">
                                <div className="card-body">
                                  <h5 className="card-title">{item.title}</h5>
                                  <p
                                    className="mt5 mb5"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: 3,
                                      WebkitBoxOrient: "vertical",
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  />
                                  <Link to={`/product/related-product/${item._id}`} className="btn btn-primary btn-sm fs-6 text-light">View Details</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* item end */}
                </div>
                {/* Related part end */}
              </div>
              //************* main area end *************//
            )}

            <Ads />
          </article>
        </article>
      </section>
      {/* page body work end */}
    </>
  );
};

export default RealstateDetail;
