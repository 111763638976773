import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { toast } from "react-toastify";

const CallAction = ({ peerUser, realestateId, details, productId }) => {
  const { isLogIn, getCallDetail, userData, sellerinquiry } = useAppContext();
  const [reqDetails, setReqDetails] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        let segments = path.split("/");
        segments.shift();
        let newPathname = segments.join("/");
        navigate(`/login?redirect=${newPathname}`);
      }, 1000);
    }
  };

  const handleClick = () => {
    if (userData && reqDetails) {
      const dataToSend = {
        productId: productId,
        sellerId: details.userId,
        requirements_details: reqDetails,
      };
      sellerinquiry(dataToSend);
      setReqDetails("");
    } else {
    }
  };

  const onRoom = () => {
    const room_type = "video";
    const members = [peerUser];
    getCallDetail(members, room_type);
  };

  const onRoom1 = () => {
    const room_type = "audio";
    const members = [peerUser];
    getCallDetail(members, room_type);
  };

  return (
    <>
      <div className="">
        <div className="">
          <div className="">
            <button
              onClick={() => redirect(`/message?peerUser=${peerUser}`)}
              className="btn btn-secondary btn-sm mb5 mt5"
            >
              <i className="fa fa-comments" /> Text Chat
            </button>
            <button
              onClick={onRoom1}
              className="btn btn-success btn-sm  mb5 mt5 mx-1"
            >
              <i className="fa fa-phone" /> Voice Call
            </button>
            <button className="btn btn-danger btn-sm  mb5 mt5" onClick={onRoom}>
              <i className="fa fa-video-camera" /> Video Call
            </button>
            <button
              onClick={() => redirect(location.pathname)}
              className="btn btn-primary btn-sm mb5 mt5 mx-1 text-white"
              data-bs-target="#supplierModal"
              data-bs-toggle={isLogIn ? "modal" : ""}
            >
              <i className="fa fa-user-o" /> Contact To Supplier
            </button>
          </div>
        </div>
        {/* Supplier model start */}
        <div
          className="modal fade supplierModal"
          id="supplierModal"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-8">
                    <h1 className="modal-title fs-6">
                      <b>Get Latest Price</b> by adding Link few details of your
                      requirement
                    </h1>
                    <hr className="mt-2" />
                    {!realestateId === 1 ? (
                      <>
                        <label>
                          <b>Quantity</b>
                        </label>
                        <div
                          className="input-group mt-1 mb-4"
                          style={{ maxWidth: 200 }}
                        >
                          <input type="number" className="form-control" />
                          <span
                            className="input-group-text bg-theme"
                            id="basic-addon2"
                            style={{ background: "var(--theme-color)" }}
                          >
                            Pieces
                          </span>
                        </div>
                      </>
                    ) : null}
                    <label>
                      <b>Requirement Details</b>
                    </label>
                    <div
                      className="input-group mt-1 mb-4"
                      style={{ maxWidth: 400 }}
                    >
                      <textarea
                        className="form-control"
                        name=""
                        id=""
                        cols={30}
                        rows={3}
                        placeholder="Additional Details About Your Requirement"
                        defaultValue={""}
                        value={reqDetails}
                        onChange={(e) => setReqDetails(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn btnTheme btn-sm"
                      type="button"
                      style={{
                        minWidth: 150,
                        background: "var(--theme-color)",
                      }}
                      onClick={handleClick}
                    >
                      Next
                    </button>
                    <hr className="mt-4" />
                    <div className="mt-3">
                      <h5>Your contact information</h5>
                      <h6>
                        <i className="fa fa-user-o" /> <b>Mr.</b>{" "}
                        {userData.name}
                      </h6>
                      <h6>
                        <i className="fa fa-phone" /> {userData.mobile}
                        &nbsp; | &nbsp; <i className="fa fa-envelope-o" />{" "}
                        {userData.email}
                      </h6>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src="assets/images/iPhone-1.jpg"
                      alt=""
                      style={{ maxWidth: "100%" }}
                    />
                    <div className="">
                      <h5 className="fs-5">{details?.title}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Supplier model end */}
      </div>
    </>
  );
};

export default CallAction;
