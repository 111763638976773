import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { MdArrowOutward, MdOutlineWorkspacePremium } from "react-icons/md";

import profile from "../../assets/assets/images/profile_image.jpg";
import userProfilIcon from "../../assets/assets/images/user-Profil-icon.svg";
import chatCommunityIcon from "../../assets/assets/images/chat-community-icon.svg";
import newsBlogIcon from "../../assets/assets/images/news-blog-icon.svg";
import myNetwork from "../../assets/assets/images/my-network-icon.svg";
import myOrderIcon from "../../assets/assets/images/my-order.svg";
import jobappliedIcon from "../../assets/assets/images/job-applied.svg";
import subscriptionIcon from "../../assets/assets/images/subscription.svg";
import shopIcon from "../../assets/assets/images/shop-icon.svg";
import lockIcon from "../../assets/assets/images/lock-icon.svg";

const AccountSideBar = () => {
  const {
    userData,
    LogOut,
    connectionList,
    receivedList,
    getConnectionsList,
    getreceivedList,
  } = useAppContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const show_profile = searchParams.get("show_profile");
  const { image, name } = userData || {};

  // console.log(userData.membership_details)

  const goldenMembership = userData.membership_details.find(
    (item) => item.type
  );

  useEffect(() => {
    connectionList();
    receivedList();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="profile-sidebar w-full sticky-top"
      style={{ top: "20%", zIndex: "0" }}
    >
      {show_profile && (
        <>
          <div className="profile-userpic d-flex justify-content-center align-items-center activeUser">
            <img
              src={image || profile}
              className="img-fluid rounded-circle"
              alt="dzital"
              style={{
                maxHeight: "120px",
                minHeight: "110px",
                maxWidth: "120px",
              }}
            />
          </div>
          <div className="profile-usertitle">
            <div className="profile-usertitle-name d-flex align-items-center justify-content-center">
              <span className="mt5">{name}</span>
              <span className="me-2" style={{ marginLeft: "10px" }}>
                {goldenMembership?.membership_status === "Active" && (
                  <MdOutlineWorkspacePremium />
                )}
              </span>
            </div>
          </div>

          <div className="mt-3" style={{ marginLeft: "40px" }}>
            <Link to="/user-connectionlist" style={{ textDecoration: "none" }}>
              <strong>
                {" "}
                {getConnectionsList?.total_connections} {""} Connections{" "}
                <MdArrowOutward />
              </strong>
            </Link>
            <Link to="/user-invitionlist" style={{ textDecoration: "none" }}>
              {" "}
              <strong>
                {" "}
                {getreceivedList?.total_pending_requests} {""} invitations{" "}
                <MdArrowOutward />
              </strong>
            </Link>
          </div>
        </>
      )}
      <div className="profile-userbuttons ">
        <Link
          to="/packages?show_profile=true"
          className="btn btn-warning small-text text-center"
        >
          {goldenMembership?.membership_status === "Active"
            ? "Premium Member"
            : "Upgrade to Premium "}
        </Link>
      </div>
      <div className="profile-usermenu">
        <ul className="nav">
          {userData?.user_type === "seller" && (
            <li>
              <Link to="/seller-pannel">
                <img src={userProfilIcon} style={{ width: "22px" }} alt="" />{" "}
                Seller Pannel
              </Link>
            </li>
          )}
          <li>
            <Link to="/add-post">
              <img src={newsBlogIcon} style={{ width: "22px" }} alt="" /> Add
              Post
            </Link>
          </li>
          <li>
            <Link to="/feed">
              <img src={myNetwork} style={{ width: "22px" }} alt="" /> My
              Network
            </Link>
          </li>
          <li>
            <Link to="/message_list?show_profile=true">
              <img src={chatCommunityIcon} style={{ width: "22px" }} alt="" />{" "}
              Message
            </Link>
          </li>
          <li>
            <Link to="/my-post?show_profile=true">
              <img src={newsBlogIcon} style={{ width: "22px" }} alt="" /> My
              Post
            </Link>
          </li>
          <li>
            <Link to="/wishlist?show_profile=true">
              <i className="fa fa-heart-o" style={{ color: "#001120" }} />{" "}
              Wishlist
            </Link>
          </li>
          <li>
            <Link to="/cart">
              <img src={shopIcon} style={{ width: "22px" }} alt="" /> Cart
            </Link>
          </li>
          <li>
            <Link to="/my-Order?show_profile=true">
              <img src={myOrderIcon} style={{ width: "22px" }} alt="" /> My
              Order
            </Link>
          </li>
          <li>
            <Link to="/my-jobs?show_profile=true">
              <img src={jobappliedIcon} style={{ width: "22px" }} alt="" /> Job
              Applied
            </Link>
          </li>
          <li>
            <Link to="/my-subscription?show_profile=true">
              <img src={subscriptionIcon} style={{ width: "22px" }} alt="" /> My
              Subscription
            </Link>
          </li>
          <li>
            <Link to="/change-password?show_profile=true">
              <img src={lockIcon} style={{ width: "22px" }} alt="" /> Password
              &amp; Security
            </Link>
          </li>

          <li>
            <Link to="#" onClick={() => LogOut()}>
              <i className="fa fa-power-off" style={{ color: "#001120" }} />{" "}
              Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AccountSideBar;
