import React, { useEffect, useState } from "react";
import Ads from "../../../components/comon/Ads";
import AccountSideBar from "../AccountSideBar";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../contextApi/AppContext";
import { Pagination } from "@mui/material";
import { Skeleton3 } from "../../../components/loading/Skeleton";
import NoData from "../../noData/NoData";

const Wishlist = () => {
  const { wishList, getWishList, wishListRemove, listLoading } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const itemsPerPage = 5;
  const pageCount = Math.ceil(wishList.length / itemsPerPage);

  const handleDelete = (id) => {
    wishListRemove(id);
  };

  const displayedItems = wishList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    getWishList();
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>Wishlist</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Wishlist</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            <div className="col-md-1">
              <Ads />
            </div>
            <div className="col-md-3">
              <AccountSideBar />
            </div>
            <div className="col-md-7">
              <div className="card border-0 mt-0">
                <div className="card-body border-0">
                  <h2 className="text24 mb-1">Wishlist</h2>
                  <p className="text-muted mb-1">
                    Here you can see your wishlist items.
                  </p>
                  <hr className="mt0" />
                  {listLoading.WishList ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <Skeleton3 key={index} />
                    ))
                  ) : wishList.length === 0 ? (
                    <NoData des={"Add a post, to See your Post Thank you!."} />
                  ) : (
                    displayedItems.map((item, index) => (
                      <div className="card mb-3 myPostItem" key={index}>
                        <div className="row g-0">
                          <div className="col-md-4">
                            <Link
                              to={`/product/wishlist/${item._id}`}
                              className="imgBx"
                            >
                              <img
                                src={item.image}
                                className="img-fluid rounded-start"
                                alt={item.title}
                                style={{ height:'200px',width:'100%' }}
                              />
                            </Link>
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-9">
                                  <h5 className="card-title mb-1">
                                    {item.title}
                                  </h5>
                                </div>
                              </div>
                              <p className="card-text mb-0">
                                {item.description}
                              </p>
                              <hr className="mb-2 mt-1" />
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="fa-sharp fa-solid fa-trash mx-2" />
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}

                  <div align="center">
                    <Pagination
                      count={pageCount}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                      className="mt-3"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1">
              <Ads />
            </div>
          </article>
        </article>
      </section>
    </>
  );
};

export default Wishlist;
