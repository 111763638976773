import React, { useEffect} from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { Button, Badge } from "react-bootstrap";
import { FaStar } from "react-icons/fa";
import { MdLocationPin } from "react-icons/md";
import { BsBriefcaseFill } from "react-icons/bs";
import { TbReport } from "react-icons/tb";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

const FreeLancer = ({ item }) => {
  const {
    freelancerList = [], // Default to an empty array to handle undefined
    getFreelancer,
    sitelocation,
    globalCurrency,
    currency_symbol,
  } = useAppContext();

  useEffect(() => {
    const dataToSend = {
      addressName: sitelocation,
      currency: globalCurrency,
    };
    getFreelancer("", "", "", dataToSend);
    // eslint-disable-next-line
  }, [sitelocation, globalCurrency, currency_symbol]);


  return (
    <section className="py-4" style={{ background: "#ebfff4" }}>
      <article className="container">
        <div className="d-flex justify-content-between align-items-center mb-4 titelBx">
          <h3 className="mb-0">{item.title}</h3>
          <Link
            className="btn btn-primary rounded-pill px-4 text-white"
            to={`/${item.slug}?category_id=${item._id}`}
            onMouseEnter={(e) => (e.target.style.color = "var(--theme-color)")}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            style={{
              background: "linear-gradient(45deg, #007bff, #6610f2)",
              border: "none",
              fontSize: "0.95rem",
              fontWeight: "600",
            }}
          >
            View All
          </Link>
        </div>
        <div className="row">
          <Swiper
            spaceBetween={20}
            navigation={true}
            mousewheel={false}
            keyboard={true}
            loop={true}
            pagination={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
            className="mySwiper homeCategory"
          >
            {Array.isArray(freelancerList) &&
              freelancerList?.map((item) => (
                <SwiperSlide key={item._id}>
                  <div>
                    <div className="card card-custom shadow-sm border-0 rounded-4 p-2">
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={
                            item.seller_image ||
                            "https://via.placeholder.com/50"
                          }
                          alt="Profile"
                          style={{
                            borderRadius: "50%",
                            width: "50px",
                            height: "50px",
                          }}
                        />
                        <div className="ms-3">
                          <h6 className="mb-0">{item.seller_name} </h6>
                          <p className="text-muted mb-0 h-6">
                            {item.sub_category_name}{" "}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-top  mb-2">
                        <div className="col-md-4">
                          <Badge>
                            {" "}
                            <FaStar
                              className="mb-1"
                              style={{ color: "#fff" }}
                            />{" "}
                            <span className="ms-1">5.0</span>{" "}
                          </Badge>
                        </div>
                        <div className="col-md-8">
                          <p className="text-muted mb-2">
                            <MdLocationPin color="#e31414cf" />{" "}
                            <span style={{ fontSize: "14px" }}>
                              {" "}
                              {item.address.length > 10
                                ? `${item.address.substring(0, 10)}...`
                                : item.address}
                            </span>
                          </p>
                          <p className="text-muted mb-2">
                            <BsBriefcaseFill color="#56c3c3e3" />{" "}
                            <span style={{ fontSize: "14px" }}>
                              {item?.experience} years of exp{" "}
                            </span>
                          </p>
                          <p className="text-muted mb-2">
                            {" "}
                            <TbReport color="#077807de" />{" "}
                            <span style={{ fontSize: "14px" }}>
                              {item.projects} Projects{" "}
                            </span>{" "}
                          </p>
                        </div>
                      </div>

                      <div
                        className="d-flex-inline flex-wrap"
                        style={{ height: "57px", overflow: "hidden" }}
                      >
                        {Array.isArray(item.skills) &&
                          item.skills.map((skill, index) => (
                            <Badge key={index} className="me-2 p-2 rounded-pill bg-light text-dark">
                              {skill}
                            </Badge>
                          ))}
                      </div>
                      <hr />
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h5>
                            {currency_symbol ? currency_symbol : "$"}{" "}
                            {item.converted_currency_price}/hr
                          </h5>
                        </div>
                        <Link to={`/freelancer-detail/${item._id}`}>
                          <Button variant="success">More Info</Button>{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </article>
    </section>
  );
};

export default FreeLancer;
