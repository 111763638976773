import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../contextApi/AppContext";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { FaRegTrashCan, FaX } from "react-icons/fa6";
import { toast } from "react-toastify";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51PRWS708i8J7Bqoukp7NUitKQ5wmhOokDfktkMWnRc57EICz4HyYIp8n8soyRDmwACcflugDI91nAGEazLs3QOQ7008GpqmAmj"
);

const CartList = () => {
  const {
    cartList,
    getCartList,
    cartListRemove,
    cartListUpdate,
    totalprice,
    getPromoCodeList,
    promoCode,
    // orderCheckOut,
    baseURL,
    cartListClear,
    token,
  } = useAppContext();
  const [quantities, setQuantities] = useState({});
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponData, setCouponData] = useState({});
  const [couponValue, setCouponValue] = useState("");
  const [subTotal, setSubTotal] = useState(0);
  const [loader, setLoader] = useState(false);

  // console.log(promoCode, "promoCode");

  useEffect(() => {
    const fetchCartList = async () => {
      await getCartList();
    };
    fetchCartList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const initialQuantities = cartList.reduce((acc, item) => {
      acc[item.item_details._id] = item.quantity;
      return acc;
    }, {});
    setQuantities(initialQuantities);
  }, [cartList]);

  useEffect(() => {
    if (Array.isArray(cartList) && cartList && cartList.length > 0) {
      const allSameCategory = cartList.every(
        (obj) =>
          obj?.item_details?.category_id ===
          cartList[0]?.item_details?.category_id
      );
      if (allSameCategory) {
        getPromoCodeList({
          category_id: cartList[0]?.item_details?.category_id,
          limit: 100,
          status: "Active",
          expiry_date: true,
        });
      }
    }
  }, [cartList]);

  useEffect(() => {
    if (totalprice && couponApplied && couponData && couponData._id) {
      if (couponData?.type === "Flat") {
        setSubTotal(totalprice - couponData.value);
      } else {
        const price = (totalprice * couponData.value) / 100;
        setSubTotal(price);
      }
    } else {
      setSubTotal(totalprice || 0);
    }
  }, [totalprice, couponApplied, couponData]);

  const increaseQuantity = (id) => {
    const updatedQuantity = (quantities[id] || 0) + 1;
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [id]: updatedQuantity,
    }));
    cartListUpdate(id, updatedQuantity);
  };

  const decreaseQuantity = (id) => {
    if (quantities[id] > 0) {
      const updatedQuantity = quantities[id] - 1;
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [id]: updatedQuantity,
      }));
      cartListUpdate(id, updatedQuantity);
    }
  };

  const handleDelete = (id) => {
    cartListRemove(id);
  };

  const handleCheckOut = async () => {
    if (!cartList || cartList.length === 0) return;
    const body = {};
    // const body = {redirect: `http://localhost:3000`}
    if (couponApplied && couponData && couponData._id) {
      body.coupon = couponData._id;
    }
    try {
      setLoader(true);
      if (!token) {
        toast.info("Login first");
        return;
      }
      const { data } = await axios.post(
        `${baseURL}/api/checkout/session/create `,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        // console.log(data, "dmkdsnkldsa");
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.data?.id,
        });
        if (error) {
          console.error("Error redirecting to checkout", error);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    } finally {
      setLoader(false);
    }
  };

  const applyCoupon = (e) => {
    e.preventDefault();
    const cpdata = promoCode?.list?.find((item) => item.title === couponValue);
    if (cpdata && cpdata._id) {
      setCouponData(cpdata);
      setCouponApplied(true);
      toast.success("Promo code applied successfully");
    } else {
      toast.warn("Promo code can not be applied");
    }
  };

  return (
    <Container className="my-5">
      <Row>
        <Col md={8}>
          <Card>
            <Card.Header>
              <Row>
                <Col>
                  <h5>Cart : ({cartList?.length || ""} products)</h5>
                </Col>
                <Col className="text-end">
                  <Button
                    className="badge text-bg-danger"
                    onClick={cartListClear}
                  >
                    Clear Cart <FaRegTrashCan />
                  </Button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {cartList.length === 0 ? (
                <div>No Data found</div>
              ) : (
                cartList.map((item, index) => (
                  <Row className="mb-3 align-items-center" key={index}>
                    <Col xs={2}>
                      <img
                        src={item.item_details.image}
                        alt={item.item_details.title}
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs={4}>
                      <h6 className="text-truncate">
                        {" "}
                        {item.item_details.title}{" "}
                      </h6>
                      <p className="text-muted">
                        {" "}
                        <strong>Price:</strong> {item.item_details.base_price}{" "}
                      </p>
                    </Col>
                    <Col xs={3} className="text-center">
                      <Button
                        variant="light"
                        className="px-3"
                        onClick={() => decreaseQuantity(item.item_details._id)}
                      >
                        -
                      </Button>
                      <span className="mx-2">
                        {quantities[item.item_details._id] || ""}{" "}
                      </span>
                      <Button
                        variant="light"
                        className="px-3"
                        onClick={() => increaseQuantity(item.item_details._id)}
                      >
                        +
                      </Button>
                    </Col>
                    <Col xs={2} className="text-end">
                      <h6 className="m-0">{item.total_price.toFixed(2)}</h6>
                    </Col>
                    <Col xs={1} className="text-end">
                      <Button
                        variant="link"
                        className="text-danger"
                        onClick={() => handleDelete(item.item_details._id)}
                      >
                        <FaRegTrashCan />
                      </Button>
                    </Col>
                  </Row>
                ))
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          {cartList.length > 0 ? (
            <Card>
              <Card.Body>
                <Form onSubmit={applyCoupon}>
                  <div className="d-flex align-items-end gap-2 mb-3">
                    <Form.Group controlId="promoCode" style={{ flex: 1 }}>
                      <p className="mb-2 text-black">Promo code</p>
                      <Form.Control
                        type="text"
                        placeholder="Type here..."
                        disabled={couponApplied}
                        value={couponValue}
                        onChange={(e) => setCouponValue(e.target.value)}
                      />
                    </Form.Group>
                    {couponApplied && (
                      <Button
                        type="button"
                        variant="danger"
                        onClick={() => {
                          setCouponApplied(false);
                          setCouponValue("");
                          setCouponData({});
                        }}
                      >
                        <FaX />
                      </Button>
                    )}
                  </div>
                  <Button
                    style={{ background: "#77ced9" }}
                    type="submit"
                    className="w-100 text-dark"
                  >
                    <strong>
                      {" "}
                      {couponApplied
                        ? "Promo Code Applied"
                        : "Apply Promo Code"}{" "}
                    </strong>
                  </Button>
                </Form>
                <hr />
                <Row>
                  <Col>Subtotal</Col>
                  <Col className="text-end">${totalprice}</Col>
                </Row>
                <Row>
                  <Col>Discount</Col>
                  <Col className="text-end">
                    $
                    {couponData && couponApplied
                      ? ((totalprice * couponData?.value) / 100).toFixed(2)
                      : 0}
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>Total</Col>
                  <Col className="text-end">
                    {" "}
                    $
                    {totalprice -
                      (couponData && couponApplied
                        ? (totalprice * couponData?.value) / 100
                        : 0
                      ).toFixed(2)}
                  </Col>
                </Row>
                <Button
                  style={{ background: "#77ced9" }}
                  className="w-100 mt-3 text-dark"
                  onClick={handleCheckOut}
                  disabled={!cartList || cartList.length === 0 || loader}
                >
                  <strong> Continue to checkout </strong>
                </Button>
              </Card.Body>
            </Card>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default CartList;
