import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const GallerySlider = ({ img, onImageClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
    if (onImageClick) {
      onImageClick(index); // Call the passed function with the current index
    }
  };

  return (
    <div
      id="fashionGalleryCarousel"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {Array.isArray(img) &&
          img.map((item, index) => (
            <div
              className={`carousel-item ${
                index === currentIndex ? "active" : ""
              }`}
              key={index}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  maxHeight: "600px",
                  maxWidth: "600px",
                  overflow: "hidden",
                  margin: "auto",
                }}
              >
                <img
                  src={item}
                  width="100%"
                  style={{ objectFit: "cover" }}
                  alt={`Slide ${index}`}
                />
              </div>
            </div>
          ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#fashionGalleryCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#fashionGalleryCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
      <div className="d-inline-flex justify-content-center mt-3">
        {Array.isArray(img) &&
          img.slice(0, 4).map((item, index) => (
            <div
              key={index}
              className="mx-1"
              onClick={() => handleThumbnailClick(index)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={item}
                height="64"
                width="64"
                style={{
                  objectFit: "cover",
                  border:
                    currentIndex === index
                      ? "2px solid gray"
                      : "1px solid gray",
                }}
                alt={`Thumbnail ${index}`}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default GallerySlider;