import React, { useEffect, useState } from "react";
import Ads from "./../components/comon/Ads";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import { Modal, Form } from "react-bootstrap";
import { Card, Button, Row, Col, ListGroup, Collapse } from "react-bootstrap";
import {
  FaHome,
  FaRupeeSign,
  FaShareAlt,
  FaChevronDown,
  FaChevronUp,
  FaUserTie,
  FaTrash,
} from "react-icons/fa";
import { IoGlobeOutline } from "react-icons/io5";
import { MdCall } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { HiCheck } from "react-icons/hi";
import Lightbox from "yet-another-react-lightbox";
import {
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { toast } from "react-toastify";

const FreelancerDetail = ({ initialDetail }) => {
  const { id } = useParams();
  const {
    getFreelancerDetails,
    freelancerDetail,
    getReletedProduct,
    setFreelancerDetail,
    reletedList,
    sendProposal,
    productrating,
    getreviews,
    reviews,
    userData,
    reviewDelete,
    isLogIn,
    globalCurrency,
    currency_symbol,
  } = useAppContext();

  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [visibleItems, setVisibleItems] = useState(2);
  const [isExpanded, setIsExpanded] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const location1 = useLocation();
  const navigate = useNavigate();

  // Function to handle opening the lightbox with the selected image
  const openLightbox = (index) => setOpenIndex(index);

  // Function to handle closing the lightbox
  const closeLightbox = () => setOpenIndex(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateDescription = (description, wordLimit) => {
    const words = description?.split(" ");
    if (words?.length <= wordLimit) {
      return description;
    }
    return words?.slice(0, wordLimit).join(" ") + "...";
  };

  const fullDescription = freelancerDetail.data.description;
  const shortDescription = truncateDescription(fullDescription, 20);
  const isLongDescription = fullDescription?.split(" ")?.length > 20;

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 2);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
    // console.log('New rating set:', rating);
  };

  const handleReviewChange = (event) => {
    setReview(event.target.value);
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
    setPreviewImages(files.map((file) => URL.createObjectURL(file)));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("productId", product_id);
    formData.append("rating", rating);
    formData.append("review", review);
    images.forEach((image, index) => {
      formData.append(`attachments[${index}]`, image);
    });

    productrating(formData);
    setRating(0);
    setReview("");
    setImages([]);
    setPreviewImages([]);
  };

  const handleDelete = (id) => {
    const deleteConfirm = window.confirm(
      "Are you sure you want to delete this Review?"
    );
    if (deleteConfirm) {
      const dataToSend = {
        product_id: product_id,
        reviewId: id,
      };
      reviewDelete(dataToSend);
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmitpropsal = (event) => {
    event.preventDefault();
    // console.log("Amount:", amount);
    // console.log("Message:", message);
    const dataToSend = {
      FreelancerProductId: freelancerDetail.data._id,
      budget: amount,
      message: message,
    };
    sendProposal(dataToSend);
    handleClose();
    setMessage("");
    setAmount("");
  };

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        id: id,
        currency: globalCurrency,
      };
      await getFreelancerDetails(dataToSend);
    };
    if (!initialDetail && id) {
      fetchData();
    }
    if (!!initialDetail && !!initialDetail.data && !!initialDetail.data._id) {
      setFreelancerDetail(initialDetail);
    }
    // eslint-disable-next-line
  }, [id, initialDetail, globalCurrency, currency_symbol]);

  const {
    title,
    // description,
    attribute_values,
    product_id,
    // createdAt,
    sub_category_id,
    category_name,
    category_id,
    category_slug,
    userId,
    seller_details,
    address,
    converted_currency_price,
    per_hour_charges,
  } = freelancerDetail.data || {};

  useEffect(() => {
    if (product_id) {
      getreviews(product_id);
    }
    // eslint-disable-next-line
  }, [product_id]);

  useEffect(() => {
    if (product_id && category_id) {
      getReletedProduct(category_id, sub_category_id, product_id);
    }
    // eslint-disable-next-line
  }, [product_id, category_id, sub_category_id]);

  const sharePost = async () => {
    const shareData = {
      title: "Dzital",
      url: window.location.href,
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.log("Error sharing the post:", err.message);
    }
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-12">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to={`/${category_slug}?category_id=${category_id}`}>
                    {category_name}
                  </Link>
                </li>
                <li className="active"> Detail Page of {title}</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>

      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            <Ads />

            <Col md={6}>
              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <Row className="align-items-center">
                    <Col xs={2} className="text-center">
                      <img
                        src={freelancerDetail.data?.seller_details?.image}
                        alt="Company Logo"
                        className="img-fluid rounded"
                        style={{
                          width: "100%",
                          height: "80px",
                          objectFit: "cover",
                        }}
                      />
                    </Col>
                    <Col xs={10}>
                      <h5 className="mb-1">{title} </h5>
                      <p className="text-muted mb-1">
                        {freelancerDetail.data.seller_details?.name}{" "}
                      </p>
                      <div className="d-flex align-items-center mb-1">
                        <FaHome className="me-2 text-muted" />
                        <p className="text-muted mb-0"> {address} </p>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <p className="fw-bold mb-0">
                          {" "}
                          {currency_symbol ? currency_symbol : "$"}{" "}
                          {converted_currency_price}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <div className="description-collapse">
                    <p
                      className="mt10"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                      }}
                      dangerouslySetInnerHTML={{ __html: shortDescription }}
                    />
                    <Collapse in={isExpanded}>
                      <div>
                        <small className="text-muted">
                          {isExpanded ? (
                            <p
                              className="mt10"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: fullDescription,
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </small>
                      </div>
                    </Collapse>
                    {isLongDescription && (
                      <Button
                        variant="link"
                        className="p-0 mt-1"
                        onClick={toggleExpand}
                        aria-controls="example-collapse-text"
                        aria-expanded={isExpanded}
                      >
                        {isExpanded ? (
                          <>
                            <FaChevronUp className="me-2" />
                            Show less
                          </>
                        ) : (
                          <>
                            <FaChevronDown className="me-2" />
                            Show more
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                  <hr />

                  {userData._id !== userId ? (
                    <Row className="mt-3">
                      <Col xs={8}>
                        {!isLogIn ? (
                          <Button
                            variant="success"
                            className="w-100"
                            onClick={() => redirect("/message")}
                          >
                            <i
                              className="fa fa-envelope "
                              style={{ marginRight: "7px" }}
                            />
                            LogIn First To Send Proposal
                          </Button>
                        ) : (
                          <Button
                            variant="success"
                            className="w-100"
                            onClick={handleShow}
                          >
                            {" "}
                            <i
                              className="fa fa-envelope "
                              style={{ marginRight: "7px" }}
                            />
                            Send Proposal
                          </Button>
                        )}
                      </Col>
                      <Col xs={4} className="text-end">
                        <Button variant="light" onClick={sharePost}>
                          Share <FaShareAlt />
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                </Card.Body>
              </Card>

              <Card className="mb-4" style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <div className="accordion mt-4">
                    {/* item start */}
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button py-2"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          <h5 className="card-title">Full Details</h5>
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                      >
                        <div className="accordion-body">
                          <div className="row clothDtl">
                            {Array.isArray(attribute_values) &&
                              attribute_values.map((item) => (
                                <>
                                  <p
                                    key={item._id}
                                    className="d-flex"
                                    style={{ alignItems: "center", gap: "5px" }}
                                  >
                                    <strong style={{ color: "#1564db" }}>
                                      <HiCheck />{" "}
                                    </strong>{" "}
                                    <b style={{ color: "#1564db" }}>
                                      {item.attribute}{" "}
                                    </b>{" "}
                                    :<span> {item.value}</span>
                                  </p>
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* item end */}
                  </div>
                </Card.Body>
              </Card>
              <Card style={{ borderRadius: "15px" }}>
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <h4 className="card-title mb-0">About The Freelancer</h4>
                    <Link
                      type="button"
                      className="btn btn-primary btn-sm text-white ms-auto"
                      to={`/seller-info/${seller_details?._id}`}
                    >
                      See Profile
                    </Link>
                  </div>

                  <div
                    style={{ width: "350px", border: "1px solid , #00000085" }}
                  ></div>
                  <Row className="mt-2">
                    <Col md={2}>
                      <i className="bi bi-building fs-1 text-primary"></i>
                    </Col>
                    <Col md={12}>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <strong>
                          <FaUserTie />{" "}
                        </strong>{" "}
                        <b style={{ color: "#1564db" }}>Name </b> :
                        <span> {seller_details?.name}</span>
                      </p>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <strong>
                          <IoLocationSharp />{" "}
                        </strong>{" "}
                        <b style={{ color: "#1564db" }}>Location </b> :
                        <span> Kolkata</span>
                      </p>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <strong>
                          <IoGlobeOutline />{" "}
                        </strong>{" "}
                        <b style={{ color: "#1564db" }}>Email </b> :
                        <span> {seller_details?.email}</span>
                      </p>
                      <p
                        className="d-flex"
                        style={{ alignItems: "center", gap: "5px" }}
                      >
                        <strong>
                          <MdCall />{" "}
                        </strong>{" "}
                        <b style={{ color: "#1564db" }}>Contact </b> :
                        <span> {seller_details?.mobile}</span>
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">Rate this product:</h5>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit} className="mt-4">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="rating mt-4">
                            {[...Array(5)].map((star, index) => (
                              <button
                                key={index}
                                type="button"
                                className={`btn btn-link ${
                                  index < rating ? "text-warning" : ""
                                }`}
                                onClick={() => handleRatingChange(index + 1)}
                              >
                                <i className="fas fa-star"></i>
                              </button>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <label htmlFor="images">Upload Images:</label>
                          <input
                            type="file"
                            id="images"
                            className="form-control"
                            multiple
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="review">Review:</label>
                        <textarea
                          id="review"
                          className="form-control"
                          value={review}
                          onChange={handleReviewChange}
                        ></textarea>
                      </div>
                      <div className="form-group mt-3">
                        <div className="preview-images mt-3">
                          {previewImages.map((src, index) => (
                            <img
                              key={index}
                              src={src}
                              alt="Preview"
                              className="img-thumbnail mr-2"
                              style={{ width: "100px", height: "100px" }}
                            />
                          ))}
                        </div>
                      </div>
                      {!isLogIn ? (
                        <button
                          className="btn btn-primary mt-2 text-white"
                          onClick={() => redirect("/message")}
                        >
                          LogIn To Submit Review
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary mt-2 text-white"
                        >
                          Submit Review
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="accordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed py-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                      >
                        <h5 className="mb-0">Review List</h5>
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                    >
                      <div
                        className="accordion-body"
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        {Array.isArray(reviews.data) &&
                        reviews.data.length > 0 ? (
                          reviews.data.map((review) => (
                            <div
                              key={review._id}
                              style={{ position: "relative" }}
                            >
                              <div className="card-body align-item-center">
                                {userData._id === review.userId && (
                                  <FaTrash
                                    className="text-danger"
                                    style={{
                                      cursor: "pointer",
                                      position: "absolute",
                                      top: "30px",
                                      right: "10px",
                                    }}
                                    onClick={() => handleDelete(review._id)}
                                  />
                                )}
                                <div className="d-flex align-items-center mb-3">
                                  <img
                                    src={review.user_img}
                                    alt="User"
                                    className="rounded-circle mr-3"
                                    style={{ width: "50px", height: "50px" }}
                                  />
                                  <div
                                    className="user-profileinfo d-flex"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <h6 style={{ marginRight: "10px" }}>
                                      {review.user_name}
                                    </h6>
                                    <div className="rating">
                                      {[...Array(5)].map((star, index) => (
                                        <i
                                          key={index}
                                          className={`fas fa-star ${
                                            index < review.rating
                                              ? "text-warning"
                                              : "text-secondary"
                                          }`}
                                        ></i>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                                <p className="mt-3">
                                  {review.review}.. <strong>Review on</strong> :{" "}
                                  <small className="text-muted">
                                    {new Date(
                                      review.createdAt
                                    ).toLocaleDateString()}
                                  </small>{" "}
                                </p>
                                <div className="mt-3">
                                  {review.attachments.map((src, index) => (
                                    <img
                                      key={index}
                                      src={src}
                                      alt="Review attachment"
                                      className="img-thumbnail mr-2 mb-2"
                                      onClick={() => openLightbox(index)}
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ))}
                                </div>
                              </div>
                              <hr />
                            </div>
                          ))
                        ) : (
                          <div className="card-body text-center">
                            <p>No reviews found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {openIndex !== null && (
                  <Lightbox
                    plugins={[Download, Fullscreen, Zoom, Thumbnails]}
                    open={true}
                    close={closeLightbox}
                    slides={reviews.data.flatMap((review) =>
                      review.attachments.map((src) => ({ src }))
                    )}
                    index={openIndex}
                  />
                )}
              </div>
            </Col>

            <Col
              md={4}
              className="sticky-top"
              style={{ height: "100%", zIndex: "1" }}
            >
              <Card className="mb-4">
                <img
                  src={freelancerDetail.data?.image}
                  alt="Company Logo"
                  className="img-fluid rounded"
                  style={{ height: "40vh", objectFit: "fill" }}
                />
              </Card>

              <Card className="mb-4 shadow border-0 rounded">
                <Card.Body>
                  <h6 className="card-title mb-4 text-center fw-bold text-uppercase">
                    Similar Freelancers
                  </h6>
                  <ListGroup variant="flush">
                    {Array.isArray(reletedList) &&
                      reletedList.slice(0, visibleItems).map((item, index) => (
                        <Link
                          className="text-decoration-none"
                          to={`/product/related-product/${item._id}`}
                          key={index}
                        >
                          <ListGroup.Item className="p-4 rounded shadow-sm mb-3 job-box">
                            <div className="d-flex align-items-center">
                              <img
                                src={item.image}
                                alt="Freelancer Logo"
                                className="me-3"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                  border: "2px solid #dee2e6",
                                }}
                              />
                              <div className="flex-grow-1">
                                <h6 className="mb-1 fw-bold text-primary">
                                  {item.title}
                                </h6>
                                <div className="d-flex justify-content-between mb-2">
                                  <span className="badge bg-primary p-2 text-white">
                                    {item.jobType || "190/hr"}
                                  </span>
                                  <span className="badge bg-info p-2 text-white">
                                    Exp: {item.salary || "2 Yrs"}
                                  </span>
                                </div>
                                {/* Additional Details */}
                                <div className="row">
                                  <div className="col">
                                    <small className="text-muted">
                                      {item.rating || "4.5"} ⭐
                                    </small>
                                  </div>
                                  <div className="col-auto">
                                    <small className="text-muted">
                                      Projects Completed:{" "}
                                      {item.projectsCompleted || 0}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      ))}
                  </ListGroup>
                  {visibleItems < reletedList.length && (
                    <Button
                      variant="outline-primary"
                      className="mt-3 w-100"
                      onClick={showMoreItems}
                    >
                      Show more
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Ads />
          </article>
        </article>
      </section>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Proposal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitpropsal}>
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={3}
                placeholder="Enter message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Group>

            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "10px" }}
              className="text-white"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FreelancerDetail;
