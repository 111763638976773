import React, { useState } from "react";
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaPhoneSlash } from "react-icons/fa";
import useAgora from "./Hook";
import { useAppContext } from "../../contextApi/AppContext";

const Controls = (props) => {
  const {navigate} = useAppContext()
  const [useClient] = useAgora();
  const client = useClient();
  const { tracks, setStart } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState((ps) => {
        return { ...ps, audio: !ps.audio };
      });
    } else if (type === "video") {
      await tracks[1].setEnabled(!trackState.video);
      setTrackState((ps) => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    navigate(-1)
  };

  const buttonStyle = {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  };

  return (
    <div
      className="controls"
      style={{
        display: "flex",
        gap: "10px",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "10px",
        borderRadius: "10px",
        color: "white",
      }}
    >
      <div
        onClick={() => mute("audio")}
        style={buttonStyle}
      >
        {trackState.audio ? (
          <FaMicrophone style={{ color: "white" }} />
        ) : (
          <FaMicrophoneSlash style={{ color: "red" }} />
        )}
      </div>
      <div
        onClick={() => mute("video")}
        style={buttonStyle}
      >
        {trackState.video ? (
          <FaVideo style={{ color: "white" }} />
        ) : (
          <FaVideoSlash style={{ color: "red" }} />
        )}
      </div>
      <div
        onClick={() => leaveChannel()}
        style={{
          ...buttonStyle,
          backgroundColor: "red",
          padding: "5px 10px",
          borderRadius: "5px",
        }}
      >
        <FaPhoneSlash style={{ color: "white" }} />
      </div>
    </div>
  );
};

export default Controls;
