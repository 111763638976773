import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import logo from "../../assets/assets/images/dark-logo.svg";
import userIcon from "../../assets/assets/images/user-Profil-icon.svg";
import mapMarker from "../../assets/assets/images/map-marker-icon.svg";
import userImage from "../../assets/assets/images/freelancer.jpg";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import NotificationModal from "./NotificationModal";
import { StandaloneSearchBox } from "@react-google-maps/api";
import "./Header.css";
import { Modal, Button, Form } from "react-bootstrap";
import Select from "react-select";
import { BsCurrencyExchange } from "react-icons/bs";
import chatCommunityIcon from "../../assets/assets/images/chat-community-icon.svg";
import newsBlogIcon from "../../assets/assets/images/news-blog-icon.svg";
import myNetwork from "../../assets/assets/images/my-network-icon.svg";
import myOrderIcon from "../../assets/assets/images/my-order.svg";
import jobappliedIcon from "../../assets/assets/images/job-applied.svg";
import subscriptionIcon from "../../assets/assets/images/subscription.svg";
import shopIcon from "../../assets/assets/images/shop-icon.svg";
import lockIcon from "../../assets/assets/images/lock-icon.svg";


function Header() {
  const {
    userData,
    serviceList,
    getSubServiceList,
    subServiceList,
    isLogIn,
    setSiteLocation,
    sitelocation,
    searchRecord,
    getTopCityList,
    getCurrencyList,
    currency,
    setCurrency,
    setGlobalCurrency,
    setCurrency_symbol,
    currency_symbol,
  } = useAppContext();

  const [location, setLocation] = useState(null);
  const [countryName, setCountryName] = useState("Loading...");
  const [locLoading, setLocLoading] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("Choose Category");
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [slugValue, setSlugValue] = useState();
  const [searchDisabled, setSearchDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [show, setShow] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [currencyChange, setCurrencyChange] = useState(false);
  // List of currency options

  // console.log(selectedCurrency.value.name, 'currency_symbol')

  useEffect(() => {
    getCurrencyList();
  }, []);

  // console.log(currency, "currency");

  useEffect(() => {
    if (currency.data && currency.data.length) {
      const options = currency.data?.map((item) => ({
        value: { name: item.currency_name, symbol: item.currency_symbol },
        label: `${item.currency_name} (${
          item.currency_symbol || item.code
        }) - ${item.country}`,
      }));
      setCurrencyOptions(options);
    }
  }, [currency]);

  // Handle select change
  const handleCurrencyChange = (selectedOption) => {
    setSelectedCurrency(selectedOption);
    setGlobalCurrency(selectedOption.value.name);
    setCurrency_symbol(selectedOption.value.symbol);
    setCurrencyChange(true);
    // console.log("Selected currency:", selectedOption);
  };

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const navigate = useNavigate();

  const [places, setPlaces] = useState([]);
  const [center, setCenter] = useState();
  const [address1, setAddress1] = useState();
  const searchBoxRef = useRef(null);
  const closeRef = useRef(null);

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    // console.log("places", places);
    if (places && places.length > 0) {
      const place = places[0];
      const newCenter = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      const formatted_address = place.formatted_address;
      setAddress1(formatted_address);
      setCenter(newCenter);
      setPlaces(places);
      place.address_components?.forEach((element) => {
        if (element?.types?.includes("country")) {
          setCountryName(element?.long_name);
          getTopCityList(element?.long_name);
        }
      });
      setSiteLocation(formatted_address);
    }
  };

  const locationClick = () => {
    setSiteLocation(address1);
    closeRef.current?.click();
  };
  const handleChange = (e) => {
    if (!e.target.value || e.target.value === "") {
      setAddress1("");
      setSiteLocation("");
    } else {
      // setAddress1(e.target.value);
      // setSiteLocation(e.target.value);
    }
  };

  const getLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            localStorage.setItem("lat", JSON.stringify(latitude));
            localStorage.setItem("lon", JSON.stringify(longitude));
            setLocLoading(false);
            resolve({ latitude, longitude });
          },
          (error) => {
            setLocLoading(false);
            reject(error);
          }
        );
      } else {
        setLocLoading(false);
        reject(new Error("Geolocation is not supported"));
      }
    });
  };

  const getCountryInfo = async () => {
    try {
      await getLocation();
      const latitude = location.latitude;
      const longitude = location.longitude;
      const apiKey = "8d1d16020bfb4d1994ea0f1ce0ca60ca";

      const response = await fetch(
        `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`
      );
      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const country = data.results[0].components.country;
        setCountryName(country);
        getTopCityList(country);
      } else {
        setCountryName("NA");
      }
    } catch (error) {
      setCountryName("NA");
    }
  };

  useEffect(() => {
    getCountryInfo();
    // eslint-disable-next-line
  }, [locLoading]);

  const handleDropdownEnter = (itemId) => {
    setActiveDropdown(itemId);
    getSubServiceList(itemId);
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null);
  };

  const handleSubServiceItemClick = () => {
    setActiveDropdown(null);
  };

  const handleSubItemClick = () => {
    setOffcanvasOpen(false);
  };

  const handleDropdownSelect = (item) => {
    setSelectedTitle(item.title);
    setSlugValue(item.slug);
    setSelectedItemId(item._id);
    setSearchDisabled(false);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleClick = () => {
    window.open("https://dzital.ch", "_blank");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!searchValue) return;

    const dataToSend = {
      address: sitelocation ? sitelocation : countryName,
      search_text: searchValue,
      categoryId: selectedItemId,
    };
    searchRecord(dataToSend);
    navigate(
      `/${slugValue}?category_id=${selectedItemId}&search=${searchValue || ""}`
    );
    setSearchValue("");
    setOffcanvasOpen(false);
  };

  return (
    <>
      <section className="sticky-top">
        <Navbar expand="lg" bg="light" className="border-bottom border-1">
          <Container>
            <Navbar.Brand className="d-flex gap-2">
              <Link to="/">
                <img src={logo} alt="logo" style={{ width: "120px" }} />
              </Link>

              <Link to="/feed" style={{ marginLeft: "200px" }}>
                <img
                  src={myNetwork}
                  alt="Communication"
                  style={{ width: "60px", paddingLeft: "10px" }}
                />
              </Link>
              <div style={{ marginLeft: "150px" }}>
                <Button
                  className=""
                  onClick={handleShow}
                  style={{ backgroundColor: "#87e6db" }}
                >
                  {currencyChange ? (
                    <h5 className="mt-1" style={{ fontSize: "12px" }}>
                      {selectedCurrency?.value?.name} (
                      <span>{selectedCurrency?.value?.symbol}</span>)
                    </h5>
                  ) : (
                    <BsCurrencyExchange size={23} />
                  )}
                </Button>
              </div>
            </Navbar.Brand>
            {/* desktop search box start */}
            {/* <div className="newSearchBx d-none d-lg-block">
              <form
                className="input-group width100 custom-form"
                onSubmit={onSubmit}
              >
                <div className="input-group-prepend">
                  <DropdownButton
                    as={ButtonGroup}
                    variant="outline-primary"
                    title={selectedTitle}
                    id="dropdown-menu-align-right"
                    className="custom-dropdown"
                    style={{ width: "170px" }}
                  >
                    {Array.isArray(serviceList) &&
                      serviceList.map((item) => (
                        <Dropdown.Item
                          eventKey={item._id}
                          key={item._id}
                          onClick={() => handleDropdownSelect(item)}
                          active={selectedItemId === item._id}
                          style={{ width: "170px" }}
                        >
                          {item.title}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                </div>
                <input
                  type="text"
                  onChange={handleSearch}
                  value={searchValue}
                  disabled={searchDisabled}
                  className="form-control custom-input"
                  placeholder="Search..."
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-primary custom-search-button"
                    type="submit"
                    id="button-addon2"
                  >
                    <img src={searchIcon} alt="" style={{ width: "22px" }} />
                  </button>
                </div>
              </form>
            </div> */}

            <div>
              {/* Modal containing the currency dropdown */}
              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Select Currency</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="currencySelect">
                      <Form.Label>Search and select currency</Form.Label>
                      <Select
                        value={selectedCurrency}
                        onChange={handleCurrencyChange}
                        options={currencyOptions}
                        isSearchable={true}
                        placeholder="Select a currency..."
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    variant="primary"
                    className="text-white"
                    onClick={handleClose}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

            {/* desktop search box end */}
            <ul className="navListInline">
              {isLogIn && <NotificationModal />}
              <li className="nav-link">
                <strong
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img
                    src={mapMarker}
                    alt=""
                    style={{ marginTop: "-4px", width: "18px" }}
                  />{" "}
                  {countryName} &nbsp;
                </strong>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabIndex={-1}
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5">Location</h1>
                        <button
                          type="button"
                          ref={closeRef}
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        />
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col">
                            <div className="locationSearcBx">
                              <StandaloneSearchBox
                                onLoad={(ref) => (searchBoxRef.current = ref)}
                                onPlacesChanged={onPlacesChanged}
                              >
                                <input
                                  type="text"
                                  placeholder="Search for places"
                                  className="form-control"
                                  onChange={handleChange}
                                />
                              </StandaloneSearchBox>
                            </div>
                          </div>
                          <div className="col">
                            <button
                              className="btn btn-warning"
                              onClick={locationClick}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* modal part end here */}

              {/* language dropdown start */}
              {/* <li className="nav-link dropdown languageBx">
                <div
                  className="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-language"></i> <span>English</span>
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item">Germany</a>
                  </li>
                  <li>
                    <a className="dropdown-item">Russian</a>
                  </li>
                  <li>
                    <a className="dropdown-item">Chinese</a>
                  </li>
                </ul>
              </li> */}
              {/* language dropdown end */}

              {/* <li>
                <Link to="" className="nav-link">
                  &nbsp;&nbsp;
                  <img
                    src={userIcon}
                    alt=""
                    style={{ marginTop: "-4px", width: "18px" }}
                  />{" "}
                  <span>{userData._id ? `${userData.name}` : "Log In"}</span>
                </Link>
              </li> */}

              {/********* user dropdown part start here *********/}
              <li>
              <div className="btn-group userDropBx">                
                <Link className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static">
                <img src={userIcon} alt="" style={{ marginTop: "-4px", width: "18px" }} />&nbsp;Me
                </Link>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                  <li>
                    <div className="userBx pt-1 mb-1">
                      <img src={userImage} alt="" />
                      <div>
                        <h5>Friedrich Fischer</h5>
                        <p>Deutschlehrerin</p>
                      </div>
                    </div>
                    <Link className="btn" to="/account">View Profile</Link>
                  </li>
                  <li className="mt-2">
                    <Link to="/add-post">
                      <img src={newsBlogIcon} style={{ width: "22px" }} alt="" /> Add
                      Post
                    </Link>
                  </li>
                  <li>
                    <Link to="/feed">
                      <img src={myNetwork} style={{ width: "22px" }} alt="" /> My
                      Network
                    </Link>
                  </li>
                  <li>
                    <Link to="/message_list?show_profile=true">
                      <img src={chatCommunityIcon} style={{ width: "22px" }} alt="" />{" "}
                      Message
                    </Link>
                  </li>
                  <li>
                    <Link to="/my-post?show_profile=true">
                      <img src={newsBlogIcon} style={{ width: "22px" }} alt="" /> My
                      Post
                    </Link>
                  </li>
                  <li>
                    <Link to="/wishlist?show_profile=true">
                      <i className="fa fa-heart-o" style={{ color: "#001120" }} />{" "}
                      Wishlist
                    </Link>
                  </li>
                  <li>
                    <Link to="/cart">
                      <img src={shopIcon} style={{ width: "22px" }} alt="" /> Cart
                    </Link>
                  </li>
                  <li>
                    <Link to="/my-Order?show_profile=true">
                      <img src={myOrderIcon} style={{ width: "22px" }} alt="" /> My
                      Order
                    </Link>
                  </li>
                  <li>
                    <Link to="/my-jobs?show_profile=true">
                      <img src={jobappliedIcon} style={{ width: "22px" }} alt="" /> Job
                      Applied
                    </Link>
                  </li>
                  <li>
                    <Link to="/my-subscription?show_profile=true">
                      <img src={subscriptionIcon} style={{ width: "22px" }} alt="" /> My
                      Subscription
                    </Link>
                  </li>
                  <li>
                    <Link to="/change-password?show_profile=true">
                      <img src={lockIcon} style={{ width: "22px" }} alt="" /> Password
                      &amp; Security
                    </Link>
                  </li>
                </ul>
              </div>
              </li>
              {/********* user dropdown part end here *********/}
            </ul>
          </Container>
        </Navbar>
        <Navbar expand="lg" className="bg-theme mainNav">
          <Container>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto mb-2 mb-lg-16">
                {Array.isArray(serviceList) &&
                  serviceList?.map((item) => (
                    <>
                      <li
                        className="nav-item dropdown mega-dropdown"
                        key={item._id}
                        onMouseEnter={() => handleDropdownEnter(item._id)}
                        onMouseLeave={handleDropdownLeave}
                      >
                        <Link
                          className="navList dropdown-toggle"
                          style={{
                            color:
                              item._id === activeDropdown ? "#fff" : "#000",
                          }}
                          role="button"
                          onClick={() =>
                            navigate(`/${item.slug}?category_id=${item._id}`)
                          }
                          data-bs-toggle="dropdown"
                          aria-expanded={activeDropdown === item._id}
                        >
                          {item.title}
                        </Link>
                        <div
                          className={`dropdown-menu mega-dropdown-menu container ${
                            activeDropdown === item._id ? "show" : ""
                          }`}
                          onMouseEnter={() => handleDropdownEnter(item._id)}
                          onMouseLeave={handleDropdownLeave}
                        >
                          <div className="row jobLink">
                            <div className="col-12">
                              {item.title?.toLowerCase() === "jobs" && (
                                <>
                                  <Link to="/applicant-list">
                                    <i className="fa-solid fa-user"></i>{" "}
                                    <b className="">Job seeker</b>{" "}
                                  </Link>
                                  <hr />
                                </>
                              )}
                              {item.title?.toLowerCase() === "jobs" && (
                                <>
                                  <Link>
                                    <i className="fa-solid fa-user"></i>{" "}
                                    <b className="">Job Offer</b>{" "}
                                  </Link>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="popover-content">
                            {subServiceList &&
                              Array.isArray(subServiceList) &&
                              subServiceList?.map((menu, index) => (
                                <div key={index} className="popover-category">
                                  <h6>
                                    <Link
                                      to={`/${item.slug}?sub_category=${menu._id}`}
                                      style={{
                                        all: "unset",
                                        cursor: "pointer",
                                      }}
                                      // onClick={handleSubServiceItemClick}
                                    >
                                      {menu.title}
                                    </Link>
                                  </h6>
                                  <ul>
                                    {menu?.child_category?.map(
                                      (items, subIndex) => (
                                        <li key={subIndex}>
                                          <Link
                                            onClick={handleSubServiceItemClick}
                                            to={`/${item.slug}?child_category=${items._id}`}
                                          >
                                            {items?.title}
                                          </Link>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              ))}
                          </div>
                        </div>
                      </li>
                    </>
                  ))}
                <li className="navList">
                  <Nav onClick={handleClick}>Build Your Website</Nav>
                </li>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* mobile nav trigger box start */}
        <section className="p-2 bg-theme d-block d-lg-none">
          <Container>
            <button
              className="btn btn-primary text-white"
              onClick={() => setOffcanvasOpen(!isOffcanvasOpen)}
              aria-controls="offCanvasSidebar"
              aria-expanded={isOffcanvasOpen ? "true" : "false"}
            >
              <i className="fas fa-bars"></i> Menu
            </button>
          </Container>
        </section>
        {/* mobile nav trigger box end */}
      </section>
      {/********* mobile menu work start here *********/}
      <div
        className={`offcanvas offcanvas-start ${isOffcanvasOpen ? "show" : ""}`}
        data-bs-scroll="true"
        tabIndex={-1}
        id="offCanvasSidebar"
        aria-labelledby="offCanvasSidebarLabel"
      >
        <div className="offcanvas-header">
          {/* search box start */}
          <form className="input-group width100" onSubmit={onSubmit}>
            <div className="input-group-prepend">
              <DropdownButton
                as={ButtonGroup}
                variant="outline-primary"
                title={selectedTitle}
                id="dropdown-menu-align-right"
                className="custom-dropdown"
                style={{ width: "170px" }}
              >
                {Array.isArray(serviceList) &&
                  serviceList.map((item) => (
                    <Dropdown.Item
                      eventKey={item._id}
                      key={item._id}
                      onClick={() => handleDropdownSelect(item)}
                      active={selectedItemId === item._id}
                      style={{ width: "170px" }}
                    >
                      {item.title}
                    </Dropdown.Item>
                  ))}
              </DropdownButton>
            </div>
            <input
              type="text"
              onChange={handleSearch}
              value={searchValue}
              disabled={searchDisabled}
              className="form-control custom-input"
              placeholder="Search..."
            />
            <div className="input-group-append">
              <button
                className="btn btn-primary custom-search-button"
                type="submit"
                id="button-addon2"
              >
                <i className="fa fa-search"></i>
              </button>
            </div>
          </form>
          {/* search box end */}
          <button
            type="button"
            onClick={() => setOffcanvasOpen(false)}
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <hr className="m0" />
        <div className="offcanvas-body">
          {Array.isArray(serviceList) &&
            serviceList?.map((item) => (
              <div
                key={`mobile_${item._id}`}
                className="accordion accordion-flush mobileMegaMenuBx"
                id="megaMenuPart"
              >
                {/* category item 1 start */}
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      onClick={() => {
                        getSubServiceList(item?._id);
                        setOffcanvasOpen(true);
                      }}
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#categoryItem1${item._id}`}
                      aria-expanded="false"
                      aria-controls="categoryItem1"
                    >
                      {item.title}
                    </button>
                  </h2>
                  <div
                    id={`categoryItem1${item._id}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#megaMenuPart"
                  >
                    <div>
                      <div className="row">
                        <div className="col-md-3">
                          {Array.isArray(subServiceList) &&
                            subServiceList?.map((subItem) => (
                              <ul
                                className="linkList"
                                key={`mobile_${subItem._id}`}
                              >
                                <li>
                                  <Link
                                    to={`/${item.slug}?sub_category=${subItem._id}`}
                                    onClick={handleSubItemClick}
                                  >
                                    {subItem.title}
                                  </Link>
                                </li>
                              </ul>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* category item 1 end */}
              </div>
            ))}
        </div>
      </div>
      {/********* mobile menu work end here *********/}
    </>
  );
}

export default Header;
