import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useAppContext } from "../contextApi/AppContext";

const InvitationList = () => {
  const {
    receivedList,
    getreceivedList,
    ConnectionRequest,
    mySendList,
    mysendreq,
    ConnectionRequestWithdraw,
  } = useAppContext();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (tabValue === 0) {
      receivedList();
    }
    if (tabValue === 1) {
      mySendList();
    }
    // eslint-disable-next-line
  }, [tabValue]);

  const handleAccept = (connectionId) => {
    const dataToSend = {
      id: connectionId,
      status: "Accepted",
    };
    ConnectionRequest(dataToSend);
  };

  const handleDecline = (connectionId) => {
    const dataToSend = {
      id: connectionId,
      status: "Rejected",
    };
    ConnectionRequest(dataToSend);
  };

  const handleWithdraw = (connectionId) => {
    const dataToSend = {
      id: connectionId,
    };
    ConnectionRequestWithdraw(dataToSend);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      <Container sx={{ p: 3 }}>
        <Paper elevation={3}>
          <Box p={2}>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="Received" />
              <Tab label="Sent" />
            </Tabs>

            {tabValue === 0 && (
              <Box>
                <Typography variant="h6">
                  {getreceivedList?.data?.length} Invitations Received
                </Typography>
                {getreceivedList?.data?.map((connection, index) => (
                  <React.Fragment key={index}>
                    <Box display="flex" alignItems="center" p={2}>
                      <Avatar
                        alt={connection?.name}
                        src={connection?.user_image || connection.avatar}
                        sx={{ width: 56, height: 56 }}
                      />
                      <Box ml={2} flexGrow={1}>
                        <Typography variant="subtitle1">
                          {connection?.user_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {connection?.user_address}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Date :{" "}
                          {new Date(connection.createdAt).toLocaleDateString()}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ marginRight: "10px" }}
                        onClick={() => handleAccept(connection._id)}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleDecline(connection._id)}
                      >
                        Reject
                      </Button>
                    </Box>
                    {index < getreceivedList?.data?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Box>
            )}

            {tabValue === 1 && (
              <Box>
                <Typography variant="h6">
                  {mysendreq?.data?.length} Invitations Send
                </Typography>
                {mysendreq?.data?.map((connection, index) => (
                  <React.Fragment key={index}>
                    <Box display="flex" alignItems="center" p={2}>
                      <Avatar
                        alt={connection?.name}
                        src={connection?.user_image || connection.avatar}
                        sx={{ width: 56, height: 56 }}
                      />
                      <Box ml={2} flexGrow={1}>
                        <Typography variant="subtitle1">
                          {connection?.user_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {connection?.user_address}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Date :{" "}
                          {new Date(connection.createdAt).toLocaleDateString()}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        color="success"
                        sx={{ marginRight: "10px" }}
                        onClick={() => handleWithdraw(connection._id)}
                      >
                        Withdraw
                      </Button>
                    </Box>
                    {index < getreceivedList?.data?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default InvitationList;
