import React from 'react'
import "./BannerAnimation.css";
import visitingcard from '../../assets/visiting-car.jpg'

const VcardDzital = () => {

  const handleClick = () => {
    window.open("https://dzital.ch", "_blank");
  };

  return (
    <div className="container my-5 p-5" style={{ backgroundColor: '#b9efd5', borderRadius: '10px' }}>
    <div className="row align-items-center">
      <div className="col-md-7">
        <h5 className="fw-bold mb-4">Dzital <span style={{ fontWeight: 'normal' }}>V-Card maker</span></h5>
        <h1 className="fw-bold mb-3">Get your digital  <span style={{ color: '#055036' }}>V-Card</span> Now</h1>
        <p className="mb-4">Pre-designed by top talent. Just add your touch.</p>
        <button className="btn btn-dark btn-lg" onClick={handleClick}>Try Dzital.ch </button>
      </div>
      <div className="col-md-5">
      <img src={visitingcard} alt="Logo Example 1" className="me-2" style={{maxWidth:'100%', borderRadius: '10px' }} />
      </div>
    </div>
  </div>
  )
}

export default VcardDzital