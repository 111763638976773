import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contextApi/AppContext";

const WelcomeCourse = () => {
  const { getLessonDetail, lessonDetail, userData, subsId } = useAppContext();
  const  navigate  = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const courseId = searchParams.get("courseId");

  const { description, title, image, category_id, category_slug } =
    lessonDetail?.data || {};

    useEffect(() => {
      if(!courseId){
        return navigate(-1);
      }
    }, [courseId, navigate])

  useEffect(() => {
    const fetchData = async () => {
      await getLessonDetail(courseId);
    };
    fetchData();
    // eslint-disable-next-line
  }, [courseId]);

  return (
    <div className="bg-light py-5">
      <div className="container">
        <div className="text-center mb-5">
          <h1 className="display-4 font-weight-bold text-primary">
            Hi, {userData.name} 👋
          </h1>
          <h2 className="font-weight-bold">Congratulations!</h2>
          <h5 className="text-muted">
            You've successfully enrolled in our course. Get ready for a journey
            of happy learning ahead!
          </h5>
        </div>
        <div className="row justify-content-center align-items-center mb-5">
          <div className="col-md-4">
            <img src={image} className="img-fluid rounded-lg" alt="title" />
          </div>
          <div className="col-md-8">
            <div className="card border-0 shadow-sm rounded-lg h-100">
              <div className="card-body d-flex flex-column justify-content-center">
                <h3 className="card-title font-weight-bold mb-3">{title}</h3>
                <p
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {description}
                </p>
                <div className="mt-auto d-flex gap-4">
                  {subsId && (
                    <Link
                      to={`/my-subscription/detail/${subsId}`}
                      className="btn btn-primary btn-lg mr-3 text-white"
                    >
                      START LEARNING
                    </Link>
                  )}
                  <Link
                    to={`/${category_slug}?category_id=${category_id}`}
                    className="btn btn-outline-primary btn-lg"
                  >
                    EXPLORE MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeCourse;
