import React, { useEffect } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import { useAppContext } from "../../contextApi/AppContext";
import { useLocation } from "react-router-dom";

const PaymentSuccess = () => {
  const { navigate } = useAppContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const order_id = searchParams.get("order_id");
  // const data = serializedData ? JSON.parse(decodeURIComponent(serializedData)) : null;

  // console.log(order_id);
  useEffect(() => {
    if (!order_id) {
      navigate(-1);
    }
  }, [order_id, navigate]);

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
      style={{
        minHeight: "100vh",
        background: "linear-gradient(to right, #e0eafc, #cfdef3)",
        padding: "0",
      }}
    >
      <Row className="justify-content-center w-100">
        <Col
          xs={12}
          md={10}
          lg={8}
          xl={6}
          className="d-flex justify-content-center"
        >
          <Card
            className="shadow-lg border-0"
            style={{ borderRadius: "20px", width: "100%" }}
          >
            <Card.Header
              className="bg-success text-white text-center"
              style={{ borderRadius: "20px 20px 0 0" }}
            >
              <h3 className="my-2">Payment Successful!</h3>
            </Card.Header>
            <Card.Body className="p-4">
              <div className="d-flex flex-column align-items-center text-center">
                <FaCheckCircle size={100} color="green" className="my-4" />
                <Card.Title
                  className="mb-3"
                  style={{ fontSize: "1.5rem", fontWeight: "bold" }}
                >
                  Thank you for your purchase!
                </Card.Title>
                <Card.Text
                  className="mb-3 d-flex"
                  style={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  Your Order Id is = <Card.Title> {order_id} </Card.Title>
                </Card.Text>
                <Card.Text
                  className="text-muted mb-4"
                  style={{ fontSize: "1.1rem" }}
                >
                  Your payment has been successfully processed. We appreciate
                  your business.
                </Card.Text>
                <Button
                  variant="success"
                  onClick={() => navigate("/")}
                  style={{
                    padding: "0.5rem 2rem",
                    fontSize: "1.1rem",
                    borderRadius: "50px",
                  }}
                >
                  Go to Homepage
                </Button>
              </div>
            </Card.Body>
            <Card.Footer
              className="text-muted text-center"
              style={{ borderRadius: "0 0 20px 20px" }}
            >
              <small>
                If you have any questions, please contact our support team.
              </small>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
