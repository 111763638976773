import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import { FaPlusCircle } from "react-icons/fa";
import { PostModal } from "./post_modal";

const CreatePost = () => {
  const { userData } = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [content, setContent] = useState("");
  const onHide = () => {
    setModalOpen(false);
  };
  return (
    <Card className="create-post bg-theme">
      <Card.Body className="px-2">
        <div className="d-flex align-items-center gap-3">
          <Image src={userData?.image} roundedCircle className="profile-img" />
          <form
            style={{ width: "100%" }}
            onSubmit={(e) => {
              e.preventDefault();
              setModalOpen(true);
            }}
          >
            <input
              placeholder="Write something.."
              className="post-input form-control"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </form>
          <FaPlusCircle
            style={{ fontSize: 32, cursor: "pointer" }}
            onClick={() => setModalOpen(true)}
          />
        </div>
        <PostModal
          onHide={onHide}
          showModal={modalOpen}
          initialData={{ title: content }}
          clearContent={setContent}
        />
      </Card.Body>
    </Card>
  );
};

export default CreatePost;
