import React, { useEffect, useState } from "react";
import Ads from "../../../components/comon/Ads";
import AccountSideBar from "../AccountSideBar";
import BreadCrum from "../../../components/comon/BreadCrum";
import { useAppContext } from "../../../contextApi/AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";

const stripePromise = loadStripe(
  "pk_test_51PRWS708i8J7Bqoukp7NUitKQ5wmhOokDfktkMWnRc57EICz4HyYIp8n8soyRDmwACcflugDI91nAGEazLs3QOQ7008GpqmAmj"
);

const Packages = () => {
  const { getUserplan, packagesList, token, baseURL, userData } =
    useAppContext();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const createMemberShipPayment = async (membership_id) => {
    try {
      if (!token) {
        toast.info("Login first");
        return;
      }
      const { data } = await axios.post(
        `${baseURL}/api/membership/session/create`,
        { membership_id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.data,
        });
        if (error) {
          console.error("Error redirecting to checkout", error);
        }
      }
    } catch (error) {
      console.error(error?.response?.data?.message || "server error");
    }
  };

  const isSubscribed = (membership_id) => {
    return userData?.membership_details?.find(
      (detail) => detail.membership_id === membership_id
    );
  };

  const handleUpgrade = (membership_id) => {
    createMemberShipPayment(membership_id);
  };

  const fetchdata = async () => {
    setLoading(true);
    try {
      await getUserplan();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchdata();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BreadCrum title={"Packages"} />
      <section className="midBody">
        <div className="container-fluid">
          <div className="row">
            <Ads />
            <div className="col-md-3">
              <AccountSideBar />
            </div>
            <div className="col-md-7">
              <div className="card border-0 shadow-sm mb-4">
                <div className="card-body">
                  <h2 className="titleText pb-2">
                    Upgrade your membership to premium
                  </h2>
                  {!loading ? (
                    <div className="priceTabBx">
                      <div className="innerBx row">
                        <div
                          className="nav flex-column nav-pills col-md-4 mb-4"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          {Array.isArray(packagesList) &&
                            packagesList.map((item, index) => (
                              <button
                                className={`nav-link ${
                                  index === activeTab ? "active" : ""
                                }`}
                                id={`${item.title}-tab`}
                                onClick={() => setActiveTab(index)}
                                key={index}
                              >
                                <b>{item.title}</b>
                                <br />
                                <small>
                                  {item.no_of_months} Months' Access from Date
                                  Of Upgrade
                                </small>
                              </button>
                            ))}
                        </div>
                        <div
                          className="tab-content col-md-8"
                          id="v-pills-tabContent"
                        >
                          {Array.isArray(packagesList) &&
                            packagesList.map((item, index) => (
                              <div
                                className={`tab-pane fade ${
                                  index === activeTab ? "show active" : ""
                                }`}
                                id={`${item.title}`}
                                role="tabpanel"
                                aria-labelledby={`${item.title}-tab`}
                                key={index}
                              >
                                <div className="card mb-4">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-9">
                                        <h2 className="text-primary">
                                          {item.title}
                                        </h2>
                                        <p className="text-muted m-0">
                                          <b>
                                            {item.no_of_months} Months' Access
                                            from Date Of Upgrade
                                          </b>
                                          <br />
                                          You thrive on interactive learning and
                                          personalised feedback from Link
                                          teacher.
                                        </p>
                                      </div>
                                      <div className="col-md-3 text-center">
                                        <h1 className="text-primary">
                                          ${item.amount}
                                        </h1>
                                      </div>
                                    </div>
                                    <hr className="mt-2 mb-2" />
                                    <ul>
                                      {Array.isArray(item.benefit) &&
                                        item.benefit.map((i, index) => (
                                          <li key={index}>{i}</li>
                                        ))}
                                    </ul>
                                    {item.title === "Free Plan" ? (
                                      ""
                                    ) : isSubscribed(item._id) ? (
                                      <div className="d-flex flex-column align-items-center text-center p-3 border rounded">
                                        <button
                                          className="btn btn-secondary mb-2"
                                          disabled
                                        >
                                          Already Purchased
                                        </button>
                                        <p className="text-muted mb-0">
                                          Membership ends on:{" "}
                                          {new Date(
                                            isSubscribed(
                                              item._id
                                            ).membership_end_date
                                          ).toLocaleDateString()}
                                        </p>
                                      </div>
                                    ) : (
                                      <button
                                        className="btn btn-primary text-white"
                                        onClick={() => handleUpgrade(item._id)}
                                      >
                                        Upgrade to {item.title}
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center my-4">
                      <div
                        className="spinner-grow text-info"
                        role="status"
                      ></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Ads />
          </div>
        </div>
      </section>
    </>
  );
};

export default Packages;
