import React from "react";
import CustomSlick from "../components/sliders/Slider";
import GoodsOfAllKind from "../components/home/GoodsOfAllKind";
import Fashion from "../components/home/Fashion";
import Lesson from "../components/home/Lesson";
import Jobs from "../components/home/Jobs";
import TopCity from "../components/home/TopCity";
import { useAppContext } from "../contextApi/AppContext";
import FreeLancer from "../components/home/FreeLancer";
import RealEstate from "../components/home/RealEstate";
import ElectronicsBanner from "../components/home/ElectronicsBanner";
import CategoryBanner from "./home/CategoryBanner";
import BannerAnimation from "./home/BannerAnimation";
import DzitalChad from "./home/DzitalChad";
import VcardDzital from "./home/VcardDzital";
import SwiperComponent from "./test";
import image1 from "../assets/assets/images/model-1.jpg";
import image2 from "../assets/assets/images/model-2.jpg";
import image3 from "../assets/assets/images/model-3.jpg";
import image4 from "../assets/assets/images/model-4.jpg";
import freelancer1 from "../assets/assets/images/freelancer1.jpg";
import freelancer2 from "../assets/assets/images/freelancer2.jpg";
import freelancer3 from "../assets/assets/images/freelancer3.jpg";
import freelancer4 from "../assets/assets/images/freelancer4.jpg";
import job1 from "../assets/assets/images/job1.jpg";
import job2 from "../assets/assets/images/job2.jpg";
import job3 from "../assets/assets/images/job3.jpg";
import job4 from "../assets/assets/images/job4.jpg";

const Home = () => {
  const { serviceList } = useAppContext();

  const topEducator = [
    {
      "name": "Frau Anna Müller",
      "designation": "Lehrerin (Teacher)",
      "image": image1
    },
    {
      "name": "Johannes Kramer",
      "designation": "Deutschlehrerin (German Teacher)",
      "image": image2
    },
    {
      "name": "Frau Lena Marx",
      "designation": "Fachlehrerin (Subject Teacher)",
      "image": image3
    },
    {
      "name": "Friedrich Fischer",
      "designation": "Oberstudienrätin (Senior Teacher)",
      "image": image4
    }
  ];

  const topFreelancer = [
    {
      "name": "Johannes Schmidt",
      "designation": "React Developer ( Freelancer )",
      "image": freelancer1
    },
    {
      "name": "Friedrich Müller",
      "designation": "Web Developer ( Freelancer)",
      "image": freelancer2
    },
    {
      "name": "Lukas Schneider",
      "designation": "Seo ( Freelancer)",
      "image": freelancer3
    },
    {
      "name": "Sophia Wagner",
      "designation": "Content Writer ( Freelancer)",
      "image": freelancer4
    }
  ];

  const topseeker = [
    {
      "name": "Maximilian Hoffmann",
      "designation": "Graduated in BCA",
      "image": job1
    },
    {
      "name": "Lea Hofmann",
      "designation": "MBA in Marketing",
      "image": job2
    },
    {
      "name": "Marie Krause",
      "designation": "Financial Analyst ",
      "image": job3
    },
    {
      "name": "Hannah Fuchs",
      "designation": " Law in Business ",
      "image": job4
    }
  ];
  
  return (
    <>
      <CustomSlick />
      <SwiperComponent title={'Top Educators'} data={topEducator} />
      <CategoryBanner />
      <div className="bgtheme">
        {Array.isArray(serviceList) &&
          serviceList.map((item, index) => {
            const SectionComponent = getSectionComponent(item?.slug);
            return (
              <div key={index}>
                <SectionComponent item={item} index={index} />
                {index === 0 && <SwiperComponent title={'Premium Job Seekers'} data={topseeker} />}
                {index === 1 && <SwiperComponent title={'Top Freelancers'} data={topFreelancer} />}
                {index === 2 && <DzitalChad />}
                {index === 4 && <VcardDzital />}
              </div>
            );
          })}
        <div>
          <TopCity />
        </div>

      </div>
      <BannerAnimation />
    </>
  );
};

const getSectionComponent = (slug) => {
  switch (slug) {
    case "goods_of_all_kinds":
      return GoodsOfAllKind;
    case "fashion_beauty":
      return Fashion;
    case "lesson_courses":
      return Lesson;
    case "jobs":
      return Jobs;
    case "freelancer":
      return FreeLancer;
    case "real_estate":
      return RealEstate;
    case "electronics":
      return ElectronicsBanner;
    default:
      return () => null;
  }
};

export default Home;