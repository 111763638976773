import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";

const JobItem = ({ item }) => {
  const { wishListAdd } = useAppContext();

  const postWishlist = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    wishListAdd(item.product_id);
    // console.log(item._id, "item");
  };

  const formatDuration = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diff = now - createdDate;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 1) {
      return `${days} days`;
    } else {
      return `${hours} hours, ${minutes} minutes`;
    }
  };
  const formattedDuration = formatDuration(item.createdAt);

  const getAttributeValue = (attributeName) => {
    if (!Array.isArray(item.attribute_values)) return null;
    const attribute = item.attribute_values.find(
      (item) => item.attribute === attributeName
    );
    if (attribute) {
      return attribute.value;
    } else {
      return null;
    }
  };

  const experience = getAttributeValue("Experience");
  const salary = getAttributeValue("Salary");

  return (
    <>
      <div className="col-md-6">
        <Link className="jobeItem" to={`/job-detail/${item._id}`}>
          <div className="card mb10">
            <div className="card-body" style={{ height: "auto" }}>
              <div className="row">
                <div className="col-md-9 col-sm-12">
                  <h5 className="text-truncate">{item.title}</h5>
                  <p>
                    {" "}
                    posted by : <strong>
                      {item?.seller_details?.name}{" "}
                    </strong>{" "}
                  </p>
                </div>
                <div className="col-md-3 col-sm-12 logoBx">
                  <img
                    src={
                      item?.image ||
                      item?.company_logo ||
                      "assets/images/g-logo.png"
                    }
                    width={44}
                    className="mt5"
                    alt=""
                  />
                </div>
              </div>
              <ul className="list-inline divider mb5 text-truncate">
                <li>
                  <i className="fa fa-briefcase" /> {experience || ""}
                </li>
                <li>
                  <i className="fa fa-inr" /> {salary || "Not disclosed"}
                </li>
                <li>
                  <i className="fa fa-map-marker text-truncate " />{" "}
                  {item.address || ""}
                </li>
              </ul>
              <p className="m0 mb10 mt10">
                <i className="fa fa-file-text-o" /> {item.description}
              </p>
              <div className="row footBx mt20">
                <div className="col-6">
                  <span className="text-muted">
                    <i className="fa-regular fa-calendar-days"></i>{" "}
                    {formattedDuration} Ago
                  </span>
                </div>
                <div className="col-6" align="right">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm text-white btn-block"
                    onClick={postWishlist}
                    style={{ textDecoration: "none" }}
                  >
                    <i className="fa fa-bookmark-o" /> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default JobItem;
