import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ZoomSubs from "../components/modal/ZoomSubs";
import { useAppContext } from "../contextApi/AppContext";

const ProductPostedSuccess = () => {
  const { userData, packagesList, getUserplan } = useAppContext();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const serializedData = searchParams.get("data");
  const data = serializedData
    ? JSON.parse(decodeURIComponent(serializedData))
    : null;
  const [showModal, setShowModal] = useState(false);
  const [modalShown, setModalShown] = useState(false);

  const {title, description, category_slug, price} = data || data.data || {};
  // console.log(title)
  // console.log(data)

  const filteredPackages = packagesList?.filter((item) => item.type === "Zoom");

  const membership_id = userData?.membership_details?.find(
    (data) => data.membership_id === filteredPackages[0]?._id
  );

  useEffect(() => {
    getUserplan();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data === null) {
      navigate(-1);
    }
  }, [data, navigate]);

  useEffect(() => {
    if (
      data?.category_slug === "lesson_courses" &&
      !membership_id?.membership_status &&
      !modalShown
    ) {
      const timer = setTimeout(() => {
        setShowModal(true);
        setModalShown(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [data, membership_id, modalShown]);

  const handleModal = () => {
    setShowModal(false);
  };

  return (
    <div
      className="container d-flex justify-content-center mt-1"
      style={{ minHeight: "100vh" }}
    >
      <div
        className="card text-center border-0 shadow-lg"
        style={{ borderRadius: "15px", width: "100%", maxWidth: "600px" }}
      >
        <div className="card-body p-5">
          <FaCheckCircle className="text-success mb-2" size={80} />
          <h1 className="card-title text-success fw-bold">Success!</h1>
          <p className="card-text lead text-muted">
            Your {category_slug === 'jobs'  ? 'Job' : 'Product'} has been successfully posted.
          </p>
          {data.title && data.description && (
          <div className="product-summary mt-2">
            <h4 className="fw-bold">{category_slug === 'jobs'  ? 'Job' : 'Product'} Summary</h4>
            <p className="text-muted">
              <strong>Name:</strong> {title}
            </p>
            {price && (
            <p className="text-muted">
              <strong>Price:</strong> {price}
            </p>
            )}
            <p className="text-muted">
              <strong>Description:</strong>{" "}
              {description?.split(" ").slice(0, 10).join(" ")}
              {description?.split(" ").length > 10 ? "..." : ""}
            </p>
          </div>
          )}
          <div className="d-flex justify-content-center mt-4">
            {data?.category_slug === "lesson_courses" &&
            !membership_id?.membership_status ? (
              <button
                onClick={() => setShowModal(true)}
                className="btn btn-outline-success btn-lg mx-2"
                style={{ borderRadius: "30px" }}
              >
                Subscribe to Zoom
              </button>
            ) : (
              <button
                onClick={() => navigate("/my-post")}
                className="btn btn-outline-success btn-lg mx-2"
                style={{ borderRadius: "30px" }}
              >
                Check Your Post
              </button>
            )}
            <Link
              to="/"
              className="btn btn-outline-secondary btn-lg mx-2"
              style={{ borderRadius: "30px" }}
            >
              Back to Homepage
            </Link>
          </div>
        </div>
      </div>

      <ZoomSubs showModal={showModal} handleModal={handleModal} />
    </div>
  );
};

export default ProductPostedSuccess;
