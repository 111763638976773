import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { LuClock8 } from "react-icons/lu";
import { useAppContext } from "../../contextApi/AppContext";
import { MdLocationPin } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

const Lesson = ({ item }) => {
  const { lesson, wishListAdd, wishListRemove, userData, getLesson } =
    useAppContext();
  const [colorMap, setColorMap] = useState([]);

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };

  useEffect(() => {
    if (lesson && Array.isArray(lesson)) {
      let check = {};
      lesson.forEach((list) => {
        check[list.product_id] = list.isWishlist;
      });
      setColorMap(check);
    }
  }, [lesson]);

  useEffect(() => {
    getLesson({});
    // eslint-disable-next-line
  }, []);

  return (
    <section className=" py-4">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-3 titelBx">
          <h3 className="mb-0">{item.title}</h3>
          <Link
            className="btn btn-primary rounded-pill px-4 text-white"
            to={`/${item.slug}?category_id=${item._id}`}
            onMouseEnter={(e) => (e.target.style.color = "var(--theme-color)")}
            onMouseLeave={(e) => (e.target.style.color = "black")}
            style={{
              background: "linear-gradient(45deg, #007bff, #6610f2)",
              border: "none",
              fontSize: "0.95rem",
              fontWeight: "600",
            }}
          >
            View All
          </Link>
        </div>
        <div className="row">
          <Swiper
            spaceBetween={20}
            navigation={true}
            mousewheel={false}
            keyboard={true}
            loop={true}
            pagination={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
            className="mySwiper homeCategory"
          >
            {Array.isArray(lesson) &&
              lesson?.map((item) => {
                const CourseDuration = item?.attribute_values?.find(
                  (item) => item.attribute === "Course Duration"
                );

                return (
                  <SwiperSlide key={item._id}>
                    <div>
                      <div className="card card-custom shadow-sm border-0 rounded-3">
                        <div className="position-relative">
                          <Link to={`/lessons-courses-detail/${item._id}`}>
                            <img
                              src={item.image}
                              className="card-img-top rounded-top"
                              alt={item.title}
                              style={{ height: "180px", objectFit: "cover" }}
                            />
                          </Link>
                          <div
                            className="position-absolute top-0 end-0 m-2 bg-white p-1 rounded-circle"
                            onClick={
                              userData._id !== item._id
                                ? () => addWhishlist(item.product_id)
                                : null
                            }
                            style={{
                              cursor:
                                userData._id === item._id
                                  ? "not-allowed"
                                  : "pointer",
                              opacity: userData._id === item._id ? 0.5 : 1,
                            }}
                          >
                            <i
                              className={
                                colorMap[item.product_id]
                                  ? "fa fa-heart text-danger fs-4"
                                  : "fa fa-heart-o fs-4"
                              }
                            />
                          </div>
                        </div>
                        <div className="card-body">
                          <h5 className="card-title text-truncate ">
                            <Link
                              to={`/lessons-courses-detail/${item._id}`}
                              className="text-decoration-none text-dark"
                            >
                              {item.title}
                            </Link>
                          </h5>
                          <p className="text-muted mb-2">
                            <MdLocationPin color="#e31414cf" />{" "}
                            <span style={{ fontSize: "14px" }}>
                              {" "}
                              {item.address.length > 30
                                ? `${item.address.substring(0, 30)}...`
                                : item.address}
                            </span>
                          </p>
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <small className="text-muted m-0">
                              <LuClock8
                                style={{
                                  marginTop: "-5px",
                                  color: "var(--bs-info)",
                                }}
                                className="me-1"
                              />{" "}
                              {CourseDuration?.value}
                            </small>
                            <small className="text-muted">
                              <FaUsers style={{ color: "var(--bs-info)" }} />{" "}
                              {item?.total_learners === 1
                                ? "1 Learner"
                                : `${item?.total_learners || 0} Learners`}
                            </small>
                          </div>
                          <div className="mt-2">
                            <small className="text-muted d-flex align-items-center">
                              <i
                                className="bi bi-person me-1"
                                style={{ color: "var(--bs-primary)" }}
                              ></i>
                              <span>
                                <strong>Posted By:</strong>{" "}
                                {item.seller_details?.name}
                              </span>
                            </small>
                          </div>
                        </div>
                        <div className="card-footer bg-transparent border-0 d-flex justify-content-between p-2">
                          <Link
                            to={`/lessons-courses-detail/${item._id}`}
                            className="btn btn-outline-primary btn-sm px-3 py-2 rounded"
                          >
                            More Info
                          </Link>
                          <Link
                            to={`/lessons-courses-detail/${item._id}`}
                            className="btn btn-success btn-sm px-3 py-2 rounded"
                          >
                            Start Learning
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Lesson;
