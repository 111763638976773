import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LuClock8 } from "react-icons/lu";
import { useAppContext } from "../../contextApi/AppContext";
import { MdLocationPin } from "react-icons/md";
const LessonItem = ({ item }) => {
  const { wishListAdd, wishListRemove, userData } = useAppContext();
  const [colorMap, setColorMap] = useState([]);

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };
  const CourseDuration = item?.attribute_values?.find(
    (item) => item.attribute === "Course Duration"
  );
  return (
    <>
      <div className="col-md-4" key={item._id}>
        <div className="card card-custom shadow-sm border-0 rounded-3">
          <div className="position-relative">
            <Link to={`/lessons-courses-detail/${item._id}`}>
              <img
                src={item.image}
                className="card-img-top rounded-top"
                alt={item.title}
                style={{ height: "180px", objectFit: "cover" }}
              />
            </Link>
            <div
              className="position-absolute top-0 end-0 m-2 bg-white p-1 rounded-circle"
              onClick={
                userData._id !== item._id
                  ? () => addWhishlist(item.product_id)
                  : null
              }
              style={{
                cursor: userData._id === item._id ? "not-allowed" : "pointer",
                opacity: userData._id === item._id ? 0.5 : 1,
              }}
            >
              <i
                className={
                  colorMap[item.product_id]
                    ? "fa fa-heart text-danger fs-4"
                    : "fa fa-heart-o fs-4"
                }
              />
            </div>
          </div>
          <div className="card-body">
            <h5 className="card-title text-truncate ">
              <Link
                to={`/lessons-courses-detail/${item._id}`}
                className="text-decoration-none text-dark"
              >
                {item.title}
              </Link>
            </h5>
            <p className="text-muted mb-2">
              <MdLocationPin color="#e31414cf" />{" "}
              <span style={{ fontSize: "14px" }}>
                {" "}
                {item.address.length > 30
                  ? `${item.address.substring(0, 30)}...`
                  : item.address}
              </span>
            </p>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <small className="text-muted m-0">
                <LuClock8
                  style={{
                    marginTop: "-5px",
                    color: "var(--bs-info)",
                  }}
                  className="me-1"
                />{" "}
                {CourseDuration?.value}
              </small>
              <small className="text-muted">
                <i className="bi bi-people"></i>{" "}
                {item?.total_learners === 1
                  ? "1 Learner"
                  : `${item?.total_learners || 0} Learners`}
              </small>
            </div>
            <div className="mt-2">
              <small className="text-muted d-flex align-items-center">
                <i
                  className="bi bi-person me-1"
                  style={{ color: "var(--bs-primary)" }}
                ></i>
                <span>
                  <strong>Posted By:</strong> {item?.seller_details?.name}
                </span>
              </small>
            </div>
          </div>
          <div className="card-footer bg-transparent border-0 d-flex justify-content-between p-2">
            <Link
              to={`/lessons-courses-detail/${item._id}`}
              className="btn btn-outline-primary btn-sm px-3 py-2 rounded"
            >
              More Info
            </Link>
            <Link
              to={`/lessons-courses-detail/${item._id}`}
              className="btn btn-success btn-sm px-3 py-2 rounded"
            >
              Start Learning
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LessonItem;
