import React, { useEffect, useState } from "react";
import { Badge, Button, Form, Modal, Table } from "react-bootstrap";
import "./OrderTable.css";
import { FaEye } from "react-icons/fa";
import { IoArrowBackCircle } from "react-icons/io5";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../contextApi/AppContext";
import { Pagination, Stack } from "@mui/material";

const LessonDetailTable = ({ Detail, onBack }) => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("");
  const { courseLearnerList, courseLearner } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("q");

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (Detail && Detail._id) {
      const body = {
        // page: currentPage,
        // limit: 10,
        // keyword_search: search || "",
        product_id: Detail.product_id,
      };
      courseLearnerList(body);
    }
  }, [Detail, currentPage, search]);

  // console.log(courseLearner.data, "learner list");

  useEffect(() => {
    if (data && data._id) {
      setStatus(data?.status || "");
    }
  }, [data]);

  const onSubmit = async (e) => {
    // e.preventDefault();
    // if (status === data.status) {
    //   toast.info("Choose different status");
    //   return;
    // }
    // const resp = await updateJobApplicant(data._id, { status });
    // if (resp?.status === 200) {
    //   toast.success(resp.message);
    // }
  };
  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Application</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Select status</Form.Label>
              <Form.Select
                name="status"
                value={status || ""}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="">Select...</option>
                <option value="Pending">Pending</option>
                <option value="shortlisted">Shortlist</option>
                <option value="On_Progress">On Progress</option>
                <option value="Hire">Hire</option>
                <option value="Reject">Reject</option>
              </Form.Select>
            </Form.Group>
            <Modal.Footer
              className="px-0 border-0"
              style={{ paddingBottom: 0 }}
            >
              <Button
                type="button"
                variant="secondary"
                className="text-light"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button type="submit" variant="primary" className="text-light">
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <div className="d-flex align-items-center gap-2 mb-3">
        <IoArrowBackCircle
          onClick={() => onBack({ open: false, data: null })}
          style={{ fontSize: 24, cursor: "pointer" }}
        />
        <h6 className="m-0">
          {Detail.title} - {Detail?.total_job_applicants} applicant
        </h6>
      </div>
      <Table bordered hover responsive className="order-table">
        <thead>
          <tr>
            <th>Learner Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Purchase At</th>
            <th>Status</th>
            {/* <th>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(courseLearner?.data?.data) &&
            courseLearner?.data?.data?.map((doc, index) => {
              return (
                <tr key={index}>
                  <td className="customer-details">
                    {/* <img src={order.customer.image} alt={order.customer.name} className="customer-image" /> */}
                    <Link to={doc?.user_id} className="customer-name">
                      {doc?.user_name}
                    </Link>
                  </td>
                  <td className="text-muted">{doc?.user_email}</td>
                  <td className="text-muted">{doc?.user_mobile_number}</td>
                  <td>{new Date(doc?.createdAt).toLocaleDateString()}</td>
                  <td>
                    <Badge bg="primary">{doc?.status}</Badge>
                  </td>
                  {/* <td>
                  <FaEdit
                    style={{
                      fontSize: "20px",
                      marginRight: "12px",
                      color: "#00000090",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShow(true);
                      setData(doc);
                    }}
                  />
                  <a href={doc?.cv} target="_blank">
                    <FaDownload
                      style={{
                        fontSize: "20px",
                        marginRight: "12px",
                        color: "#168f9f",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </td> */}
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div align="center">
        <Stack spacing={2}>
          <Pagination
            count={courseLearner?.totalPages}
            page={currentPage}
            onChange={(value, page) => {
              setCurrentPage(page);
            }}
            color="primary"
            variant="outlined"
            shape="rounded"
            boundaryCount={2}
            siblingCount={1}
          />
        </Stack>
      </div>
    </div>
  );
};

const LessonTable = () => {
  const [Detail, setDetail] = useState({ open: false, data: null });
  const { courseOrderList, courseList } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const search = params.get("q");
  const navigate = useNavigate();

  useEffect(() => {
    courseList();
  }, []);

  // console.log(courseOrderList, "courseOrderList");

  //   useEffect(() => {
  //     if (Detail.open === false) {
  //       const searchData = {
  //         page: currentPage,
  //         limit: 20,
  //         keyword_search: search || "",
  //       };
  //       getSellerJobList(searchData);
  //     }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [currentPage, search, Detail]);

  return (
    <>
      {!Detail.open && (
        <div>
          <Table bordered hover responsive className="order-table">
            <thead>
              <tr>
                <th>Lesson List</th>
                <th>Category</th>
                <th>Total Learner</th>
                <th>Posted At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(courseOrderList?.data.data) &&
                courseOrderList?.data?.data?.map((doc, index) => {
                  return (
                    <tr key={index}>
                      <td>{doc?.title}</td>
                      <td className="text-muted">{doc?.sub_category_name}</td>
                      <td> {doc?.total_learners} </td>
                      <td>{new Date(doc?.createdAt).toLocaleDateString()}</td>
                      <td>
                        <FaEye
                          style={{
                            fontSize: "20px",
                            color: "#808080",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setDetail({ open: true, data: doc });
                            navigate("/seller-pannel");
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
          <div align="center">
            <Stack spacing={2}>
              <Pagination
                count={courseOrderList?.totalPages}
                page={currentPage}
                onChange={(value, page) => {
                  setCurrentPage(page);
                }}
                color="primary"
                variant="outlined"
                shape="rounded"
                boundaryCount={2}
                siblingCount={1}
              />
            </Stack>
          </div>
        </div>
      )}
      {Detail.open && (
        <LessonDetailTable Detail={Detail.data} onBack={setDetail} />
      )}
    </>
  );
};

export default LessonTable;
