import { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Form,
  Button,
  ListGroup,
  Spinner,
} from "react-bootstrap";
import { IoIosSend } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import PostCard from "./component/post_card";
import { CommentCard } from "./component/comment-card";
import LeftSidebar from "./component/left_sidebar";
import { RightSidebar, TrendingPostsSkeleton } from "./component/right_sidebar";
import { toast } from "react-toastify";
import RecentConnections from "./component/recent-connections";
import InfiniteScroll from "react-infinite-scroll-component";
import { PostModal } from "./component/post_modal";
import { CommentModal } from "./component/comment_modal";

export const PostDetail = () => {
  const params = useParams();
  const {
    postDetail,
    getPostDetail,
    postNewComment,
    getCommentList,
    commentList,
    commentData,
    getTrendingPost,
    trendingPosts,
    recentConnectionList,
    recentConnections,
  } = useAppContext();

  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false)
  const [modalOpen1, setModalOpen1] = useState(false)
  const [modalType, setModalType] = useState({})
  const [initialData, setInitialData] = useState(null)

  useEffect(() => {
    if (params.id) {
      getPostDetail(params.id);
      getCommentList({ post_id: params.id, page: 1, limit: 5 });
    }
  }, [params.id]);

  useEffect(() => {
    if (trendingPosts.isLoading === false && trendingPosts.data === null) {
      getTrendingPost();
    }
  }, [trendingPosts]);

  useEffect(() => {
    if (
      recentConnections.isLoading === false &&
      recentConnections.data === null
    ) {
      recentConnectionList();
    }
  }, [recentConnections]);

  const fetchData = async () => {
    const page = commentData.currentPage + 1
    if (commentData.currentPage >= commentData.totalPage) {
      return
    }
    await getCommentList({ page, limit: 5, post_id: params.id })
  }

  const onComment = async (e, type, id, setRepCount) => {
    e.preventDefault();
    const formdata = new FormData(e.target);
    let obj = Object.fromEntries(formdata);
    obj.post_id = params.id;
    if (type === "Reply" && id) {
      obj.comment_id = id;
    }
    obj.type = type;
    const res = await postNewComment(obj);
    if (res && res?.status === 200) {
      toast.success(res.message);
      e.target.reset();
      if (type === "Reply") {
        setRepCount((prev) => prev + 1);
      } else {
        getPostDetail(params.id);
        getCommentList({ post_id: params.id });
      }
    }
  };

  const onHide = () => {
    setModalOpen(false)
  }


  const onOpen = () => {
    setModalOpen(true)
  }

  const onCommentHide = () => {
    setModalOpen1(false)
    setInitialData(null)
  }

  const onCommentOpen = (data, type, setReplyList) => {
    setInitialData(data)
    setModalOpen1(true)
    setModalType({type, reply_list: type === "Reply" ? setReplyList : null})
  }

  if (!postDetail?.data && !postDetail?.data?._id && !postDetail.isLoading) {
    return (
      <div className="text-center py-5">
        <img
          src="https://img1a.flixcart.com/www/linchpin/fk-cp-zion/img/error-500_f9bbb4.png"
          alt="Error 500"
          className="img-fluid"
          style={{ maxWidth: "450px" }}
        />
        <div className="mt-3 mb-4" style={{ fontSize: "1.3em" }}>
          Unfortunately, the page you are looking for has been moved or deleted
        </div>
        <Link to="/" className="btn btn-primary text-white">
          Go To Home Page
        </Link>
      </div>
    );
  }

  return (
    <Container fluid className="feed-container">
      <PostModal onHide={onHide} showModal={modalOpen} initialData={postDetail?.data} isEdit={true} />
      <CommentModal initialData={initialData} onHide={onCommentHide} showModal={modalOpen1} type={modalType?.type} setReplyList={modalType?.reply_list} />
      <Row>
        <Col md={3} className="sidebar">
          {/* Sidebar content */}
          <LeftSidebar />
        </Col>
        <Col md={6} className="px-0">
          <div className="d-flex justify-content-between align-items-center mt-3 px-2">
            <button
              style={{ all: "unset", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <IoArrowBack style={{ fontSize: 24 }} />
            </button>
            {/* <button style={{ all: 'unset', cursor: 'pointer' }}><IoIosMore style={{ fontSize: 24 }} /></button> */}
          </div>
          <hr />
          {postDetail?.data && postDetail?.data?._id && (
            <PostCard post={postDetail?.data} onOpen={onOpen} />
          )}
          <Card className="comment-panel">
            <Card.Body className="px-2">
              <Form
                className="mb-3 d-flex gap-2 align-items-center"
                onSubmit={(e) => {
                  onComment(e, "Comment");
                }}
              >
                <Form.Group controlId="commentInput" style={{ flex: 1 }}>
                  <Form.Control
                    type="text"
                    placeholder="Add a comment..."
                    name="content"
                    required
                  />
                </Form.Group>
                <Button
                  type="submit"
                  className="text-light"
                  size="sm"
                  style={{ aspectRatio: 1, borderRadius: "50%" }}
                >
                  <IoIosSend style={{ fontSize: 20 }} />
                </Button>
              </Form>
              <ListGroup>
                <InfiniteScroll
                  dataLength={commentList.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={commentData.currentPage < commentData.totalPage}
                  loader={(
                    <div className='d-grid' style={{ placeItems: 'center' }}>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  {commentList.map((comment, i) => (
                    <CommentCard
                      comment={comment}
                      key={i}
                      onComment={onComment}
                      onCommentOpen={onCommentOpen}
                    />
                  ))}
                </InfiniteScroll>
                {commentList.length === 0 && <p>No comment found</p>}
              </ListGroup>
              {commentData?.isLoading && commentList.length === 0 && (
                <div className='d-grid' style={{ placeItems: 'center' }}>
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="sidebar">
          {/* Sidebar content */}
          {recentConnections.isLoading ? (
            <TrendingPostsSkeleton />
          ) : (
            <RecentConnections />
          )}
          {trendingPosts.isLoading ? (
            <TrendingPostsSkeleton />
          ) : (
            <RightSidebar />
          )}
        </Col>
      </Row>
    </Container>
  );
};