import React, { useEffect, useState } from "react";
import Ads from "../../../components/comon/Ads";
import { Link } from "react-router-dom";
import AccountSideBar from "../AccountSideBar";
import { useAppContext } from "../../../contextApi/AppContext";
import Stack from "@mui/material/Stack";
import { Pagination } from "@mui/material";
import { Skeleton3 } from "../../../components/loading/Skeleton";
import NoData from "../../noData/NoData";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const MyPost = () => {
  const { mypost, getMyPost, totalItem, myPostRemove, listLoading } =
    useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const pageCount = Math.ceil(totalItem?.post / itemsPerPage);

  const searchData = {
    page: currentPage,
    limit: itemsPerPage,
  };

  useEffect(() => {
    const fetchData = async () => {
      await getMyPost(searchData);
    };
    fetchData();
    // eslint-disable-next-line
  }, [currentPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>My Post</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">My Post</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bg-light py-4">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* sidebar start */}
            <div className="col-md-3">
              <AccountSideBar />
            </div>
            {/* sidebar end */}
            {/* right part start */}
            <div className="col-md-7">
              <div className="card border-0 mt-0 shadow-sm">
                <div className="card-body">
                  <h2 className="h4 mb-1">My Post</h2>
                  <p className="text-muted mb-3">
                    Here you can see your post and edit them easily.
                  </p>
                  <hr className="mt0 mb-4" />
                  {/* item start */}
                  {listLoading.Post ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <Skeleton3 key={index} />
                    ))
                  ) : mypost.length === 0 ? (
                    <NoData des={"Add a post, to See your Post Thank you!."} />
                  ) : (
                    Array.isArray(mypost) &&
                    mypost?.map((item) => (
                      <div
                        className="card mb-4 border-0 shadow-sm"
                        key={item._id}
                      >
                        <div className="row g-0">
                          <div className="col-md-4">
                            <Link
                              to={`/product/my-post/${item._id}`}
                              className="d-block h-100"
                            >
                              <img
                                src={item.image}
                                className="img-fluid rounded-start"
                                alt="Dzital"
                                style={{ objectFit: "cover", height: "180px" }}
                                height={180}
                                width={200}
                              />
                            </Link>
                          </div>
                          <div className="col-md-8">
                            <div className="card-body">
                              <div className="row mb-2">
                                <div className="col-md-9">
                                  <h5 className="card-title mb-0">
                                    {item.title}
                                  </h5>
                                </div>
                                <div className="col-md-3 text-end">
                                  {item.status === "Rejected" && (
                                    <OverlayTrigger
                                      placement={"top"}
                                      overlay={
                                        <Tooltip id={`tooltip-top}`}>
                                          {item.reject_reason}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className={`badge ${
                                          item.status === "Approved"
                                            ? "bg-success"
                                            : item.status === "Unapproved"
                                            ? "bg-danger"
                                            : "bg-secondary"
                                        }`}
                                      >
                                        {item.status}
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                  {item.status !== "Rejected" && (
                                    <span
                                      className={`badge ${
                                        item.status === "Approved"
                                          ? "bg-success"
                                          : item.status === "Unapproved"
                                          ? "bg-danger"
                                          : "bg-secondary"
                                      }`}
                                    >
                                      {item.status}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <p className="card-text text-truncate">
                                {item.description}
                              </p>
                              <hr className="mb-2 mt-1" />
                              <div className="d-flex flex-wrap">
                                <Link
                                  to={`/updateProduct/${item?._id}`}
                                  className="btn btn-outline-dark btn-sm me-2 mb-2"
                                >
                                  <i className="fa-regular fa-pen-to-square " />{" "}
                                  Edit
                                </Link>
                                <button
                                  className="btn btn-outline-danger btn-sm me-2 mb-2"
                                  onClick={() => myPostRemove(item._id)}
                                >
                                  <i className="fa-sharp fa-solid fa-trash" />{" "}
                                  Delete
                                </button>
                                <Link
                                  to={`/my-post-inquiry/${item._id}`}
                                  className="btn btn-outline-primary btn-sm mb-2"
                                >
                                  <i className="fa-solid fa-info"></i> Inquiry
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  {Array.isArray(mypost) && mypost.length > 0 && (
                    <div className="d-flex justify-content-center mt-4">
                      <Stack spacing={2}>
                        <Pagination
                          count={pageCount}
                          page={currentPage}
                          onChange={handlePageChange}
                          color="primary"
                          variant="outlined"
                          shape="rounded"
                          boundaryCount={2}
                          siblingCount={1}
                        />
                      </Stack>
                    </div>
                  )}
                  {/* item end */}
                </div>
              </div>
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default MyPost;
