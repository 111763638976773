import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Ads from '../../components/comon/Ads';
import { useAppContext } from '../../contextApi/AppContext';

const PrivacyPolicy = () => {
  const { getCmsList, mainData, loading } = useAppContext();
  const privacy = 'privacy-policy';

  useEffect(() => {
    getCmsList(privacy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>Privacy Policy</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">Privacy Policy</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      {/* page header part end here */}
      {/* page body part start */}
      <section className="midBody" style={{ backgroundColor: "#fff" }}>
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/**************** Body part start ****************/}
            {/* about content part start */}
            {loading  ? (
              <div className="col-md-10 d-flex justify-content-center ">
                <div className="spinner-grow text-info my-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-md-10">
                <div>
                  <h3>Privacy Policy</h3>
                  <div dangerouslySetInnerHTML={{ __html: mainData?.aboutUs?.description }} />
                </div>
              </div>
            )}
            {/* about content part end */}

            {/**************** Body part end ****************/}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>

  )
}

export default PrivacyPolicy