import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { FaBookmark, FaRegBookmark } from "react-icons/fa";
import { Card, Button, Badge } from "react-bootstrap";
import { IoBriefcaseOutline } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import defaultLogo from "../../assets/assets/images/g-logo.png";
import JobApply from "../modal/JobApply";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

const Jobs = ({ item }) => {
  const {
    jobList,
    wishListAdd,
    wishListRemove,
    userData,
    getJobList,
    sitelocation,
    isLogIn,
    navigate,
    applicantList,
    getApplicantList,
  } = useAppContext();
  const [colorMap, setColorMap] = useState({});
  const [showModal, setShowModal] = useState(false);
  const location1 = useLocation();
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    const dataToSend = {
      addressName: sitelocation,
    };
    getJobList("", "", "", dataToSend);
    applicantList();
    // eslint-disable-next-line
  }, [sitelocation]);

  const handleShowModal = (data) => {
    setInitialData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setInitialData(null);
  };

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  useEffect(() => {
    if (jobList && Array.isArray(jobList)) {
      let check = {};
      jobList.forEach((list) => {
        check[list.product_id] = list.isWishlist;
      });
      setColorMap(check);
    }
  }, [jobList]);

  return (
    <>
      <JobApply
        showModal={showModal}
        title={initialData?.title}
        handleCloseModal={handleCloseModal}
        id={initialData?._id}
      />
      <section className="bg-light">
        <article className="container pt-4 pb-4">
          <div className="d-flex justify-content-between align-items-center titelBx">
            <h3 className="mb-0">{item.title}</h3>
            <Link
              className="btn btn-primary rounded-pill px-4 text-white"
              to={`/${item.slug}?category_id=${item._id}`}
              onMouseEnter={(e) =>
                (e.target.style.color = "var(--theme-color)")
              }
              onMouseLeave={(e) => (e.target.style.color = "black")}
              style={{
                background: "linear-gradient(45deg, #007bff, #6610f2)",
                border: "none",
                fontSize: "0.95rem",
                fontWeight: "600",
              }}
            >
              View All
            </Link>
          </div>
          <aside className="row pt-2">
            {/* item start */}
            <Swiper
              spaceBetween={20}
              navigation={true}
              mousewheel={false}
              keyboard={true}
              loop={true}
              pagination={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              className="mySwiper homeCategory"
            >
              {/* jobList rendering */}
              {Array.isArray(jobList) &&
                jobList.map((jobItem) => {
                  const createdAtDate = new Date(jobItem?.createdAt);
                  const differenceInTime = new Date() - createdAtDate;
                  const differenceInDays = Math.floor(
                    differenceInTime / (1000 * 3600 * 24)
                  );
                  const Experience = jobItem?.attribute_values?.find(
                    (item) => item.attribute === "Experience"
                  );
                  const Skills = jobItem?.attribute_values?.find(
                    (item) => item.attribute === "Skills"
                  );
                  const Employment_Type = jobItem?.attribute_values?.find(
                    (item) => item.attribute === "Employment Type"
                  );
                  const Work_mode = jobItem?.attribute_values?.find(
                    (item) => item.attribute === "Work mode"
                  );
                  const Company_Name = jobItem?.attribute_values?.find(
                    (item) => item.attribute === "Company Name"
                  );
                  const logo =
                    jobItem?.image || jobItem?.company_logo || defaultLogo;

                  return (
                    <SwiperSlide key={jobItem._id}>
                      <Card
                        style={{
                          background: "#fff",
                          borderRadius: "10px",
                          padding: "15px",
                        }}
                        className="card-custom"
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className="border-1 p-1"
                            style={{
                              width: "60px",
                              height: 60,
                              borderRadius: "50%",
                              border: "1px solid #80808050",
                            }}
                          >
                            <img
                              src={logo}
                              alt="company logo"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                aspectRatio: "1",
                              }}
                              className="rounded-circle"
                            />
                          </div>
                          <div>
                            <Button
                              variant="light"
                              size="sm"
                              disabled={jobItem.userId === userData._id}
                              onClick={() => addWhishlist(jobItem.product_id)}
                            >
                              {colorMap[jobItem.product_id] ? (
                                <FaBookmark className="text-danger" />
                              ) : (
                                <FaRegBookmark />
                              )}
                            </Button>
                          </div>
                        </div>
                        <div className="mt-3" style={{ padding: 0 }}>
                          <Link
                            className="text-decoration-none"
                            to={`/job-detail/${jobItem._id}`}
                          >
                            <div className="d-flex gap-2 align-items-center mb-1">
                              <h4
                                className="m-0 p-0 text-truncate"
                                style={{ fontSize: 14 }}
                              >
                                {Company_Name?.value}
                              </h4>
                            </div>
                            <Card.Title
                              className="text-truncate"
                              style={{ fontSize: 16, height: "auto" }}
                            >
                              {jobItem?.title}
                            </Card.Title>
                            <div className="d-flex gap-1 mt-2">
                              <Badge pill variant="light">
                                {Work_mode?.value}
                              </Badge>
                              <Badge pill variant="light">
                                {Employment_Type?.value}
                              </Badge>
                            </div>
                            <div
                              className="d-flex align-items-center mt-2 text-muted gap-2"
                              style={{ fontSize: 14 }}
                            >
                              <IoBriefcaseOutline />
                              <span>{Experience?.value}</span>
                              <span
                                style={{
                                  fontSize: 12,
                                  color: "#00000050",
                                  fontWeight: 500,
                                }}
                              >
                                {differenceInDays} days ago
                              </span>
                            </div>
                            <div
                              className="d-flex align-items-center text-muted gap-2"
                              style={{ fontSize: 14 }}
                            >
                              <Card.Text className="text-nowrap text-truncate">
                                {Skills?.value}
                              </Card.Text>
                            </div>
                          </Link>
                          <hr />
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              className="d-flex align-items-center gap-1 text-truncate"
                              style={{
                                fontSize: 12,
                                color: "#00000080",
                                fontWeight: 500,
                              }}
                            >
                              {" "}
                              <FaLocationDot />{" "}
                              <span className="text-truncate">
                                {jobItem?.address || "Not mentioned"}
                              </span>
                            </div>
                            {isLogIn ? (
                              <Button
                                variant="primary"
                                size="sm"
                                style={{
                                  color: "#fff",
                                  minWidth: "fit-content",
                                }}
                                onClick={() => handleShowModal(jobItem)}
                                disabled={
                                  jobItem.userId === userData._id ||
                                  jobItem?.JobApplied
                                }
                              >
                                {jobItem?.JobApplied ? "Applied" : "Apply now"}
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                size="sm"
                                style={{
                                  color: "#fff",
                                  minWidth: "fit-content",
                                }}
                                onClick={() => redirect("/message")}
                              >
                                Login to Apply
                              </Button>
                            )}
                          </div>
                        </div>
                      </Card>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
            {/* item end */}
          </aside>
        </article>
      </section>

      <section className="">
        <article className="container pt-4 pb-4">
          <div className="d-flex justify-content-between align-items-center titelBx">
            <h3 className="mb-0"> Job Seekers </h3>
            <Link
              className="btn btn-primary rounded-pill px-4 text-white"
              to={"/applicant-list"}
              onMouseEnter={(e) =>
                (e.target.style.color = "var(--theme-color)")
              }
              onMouseLeave={(e) => (e.target.style.color = "black")}
              style={{
                background: "linear-gradient(45deg, #007bff, #6610f2)",
                border: "none",
                fontSize: "0.95rem",
                fontWeight: "600",
              }}
            >
              View All
            </Link>
          </div>
          <aside className="row pt-2">
          <Swiper
            spaceBetween={20}
            navigation={true}
            mousewheel={false}
            keyboard={true}
            loop={true}
            pagination={false}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              360: {
                slidesPerView: 2,
                spaceBetween: 15,
              },
              640: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 15,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
            className="mySwiper homeCategory"
          >

      
            {/* item start */}
            {Array.isArray(getApplicantList.data) &&
              getApplicantList.data?.map((jobItem) => {
                return (
                  <SwiperSlide key={jobItem._id}>

                  <Link to={`/seller-info/${jobItem.user_id}`} style={{textDecoration:'none'}} >
                    <Card
                      style={{
                        background: "#fff",
                        borderRadius: "10px",
                        padding: "15px",
                      }}
                      className="card-custom"
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          className="border-1 p-1"
                          style={{
                            width: "60px",
                            height: 60,
                            borderRadius: "50%",
                            border: "1px solid #80808050",
                          }}
                        >
                          <img
                            src={jobItem.job_applicant_image || defaultLogo}
                            alt="company logo"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              aspectRatio: "1",
                            }}
                            className="rounded-circle"
                          />
                        </div>
                      </div>
                      <div className="mt-3" style={{ padding: 0 }}>
                        <div
                          className="text-decoration-none"
                         
                        >
                          <div className="d-flex gap-2 align-items-center mb-1">
                            <h4
                              className="m-0 p-0 text-truncate"
                              style={{ fontSize: 18 }}
                            >
                              {jobItem?.job_applicant}
                            </h4>
                          </div>
                          <Card.Title
                            className="text-truncate"
                            style={{ fontSize: 14, height: "auto" }}
                          >
                            {jobItem?.job_applicant_email}
                          </Card.Title>
                          <div className="d-flex gap-1 mt-2">
                            {Array.isArray(jobItem.job_applicant_skills) && (
                              <>
                                {jobItem.job_applicant_skills
                                  .slice(0, 3)
                                  .map((jobDetail, idx) => (
                                    <Badge
                                      key={idx}
                                      pill
                                      variant="light"
                                      className="me-2 p-2 rounded-pill"
                                    >
                                      {jobDetail}
                                    </Badge>
                                  ))}
                                {jobItem.job_applicant_skills.length > 2 && (
                                  <Badge className="me-2 p-2 rounded-pill bg-light text-dark">
                                    {jobItem.job_applicant_skills.length - 2}+
                                  </Badge>
                                )}
                              </>
                            )}
                          </div>
                          <div
                            className="d-flex align-items-center mt-2 text-muted gap-2"
                            style={{ fontSize: 14 }}
                          >
                            <IoBriefcaseOutline />
                            <span>
                              {jobItem.job_applicant_year_of_experience} years
                              of exp
                            </span>
                          </div>
                          <div
                            className="d-flex align-items-center text-muted gap-2"
                            style={{ fontSize: 14 }}
                          >
                            <Card.Text className="text-nowrap text-truncate"></Card.Text>
                          </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                          <div
                            className="d-flex align-items-center gap-1 text-truncate"
                            style={{
                              fontSize: 12,
                              color: "#00000080",
                              fontWeight: 500,
                            }}
                          >
                            {" "}
                            <FaLocationDot />{" "}
                            <span className="text-truncate">
                              {jobItem?.job_applicant_address ||
                                "Not mentioned"}
                            </span>
                          </div>
                          <Button
                            variant="primary"
                            size="sm"
                            style={{ color: "#fff", minWidth: "fit-content", textDecoration:'none' }}
                            onClick={() => redirect(`/seller-info/${jobItem.user_id}`)}
                          >
                            More Info
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Link>
                  </SwiperSlide>
                );
              })}
            {/* item end */}
            </Swiper>
          </aside>
        </article>
      </section>
    </>
  );
};

export default Jobs;