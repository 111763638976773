import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useAppContext } from "../../contextApi/AppContext";
import { toast } from "react-toastify";

function SubscriptionPrice({ show, handleClose, product_id, category_id }) {
  const { getPlanCreate } = useAppContext();
  const [planName, setPlanName] = useState("");
  const [price, setPrice] = useState("");
  const [interval, setInterval] = useState("");
  const [intervalCount, setIntervalCount] = useState("");

  const handleSubmit = () => {
    if (
      product_id &&
      category_id &&
      planName &&
      price &&
      interval &&
      intervalCount
    ) {
      getPlanCreate({
        product_id,
        category_id,
        plan_name: planName,
        plan_price: price,
        plan_interval: interval,
        plan_interval_count: intervalCount,
      }, product_id);
    } else toast.error("Something went wrong");
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Subscription Plan Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formPlanName" className="mb-3">
            <Form.Label>Plan Name</Form.Label>
            <Form.Control
              type="text"
              value={planName}
              onChange={(e) => setPlanName(e.target.value)}
              placeholder="Enter plan name"
              required
            />
          </Form.Group>
          <Form.Group controlId="formPrice" className="mb-3">
            <Form.Label>Price</Form.Label>
            <Form.Control
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Enter price"
              required
            />
          </Form.Group>
          <Form.Group controlId="formInterval" className="mb-3">
            <Form.Label>Plan Interval</Form.Label>
            <Form.Control
              as="select"
              value={interval}
              onChange={(e) => setInterval(e.target.value)}
              required
            >
              <option value="">Select Interval</option>
              <option value="month">Month</option>
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="year">Year</option>
            </Form.Control>
          </Form.Group>
          {interval && (
            <Form.Group controlId="formIntervalCount" className="mb-3">
              <Form.Label>Interval Count (number of {interval}s)</Form.Label>
              <Form.Control
                type="number"
                value={intervalCount}
                onChange={(e) => setIntervalCount(e.target.value)}
                placeholder={`Enter number of ${interval}s`}
                required
              />
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SubscriptionPrice;
