import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import { Card } from "react-bootstrap";

const NotificationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef(null);
  const { getNotificationList, notificationList, timeAgo } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    getNotificationList({ page: 1, limit: 10 });
    // eslint-disable-next-line
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li className="dropdown" ref={modalRef}>
      <Link
        className="me-3 dropdown-toggle hidden-arrow"
        id="navbarDropdownMenuLink"
        role="button"
        onClick={toggleDropdown}
        aria-expanded={isOpen ? "true" : "false"}
      >
        <i className="fas fa-bell"></i>
        <span className="badgeBx badge rounded-pill bg-danger">1</span>
      </Link>
      <div
        style={{ minWidth: "350px", borderRadius: "8px" }}
        className={`notification-list-content pb-2 ${isOpen ? "show" : ""}`}
      >
        {notificationList?.map((item, index) => {
          return (
            <div className="notification-list-item mb-2" key={index}>
              <div
                className="notification-list-img d-flex justify-content-center align-items-center"
                style={{ background: "#80808030" }}
                onClick={() =>
                  item?.sender
                    ? navigate(`/seller-info/${item.sender._id}`)
                    : {}
                }
              >
                {item?.sender?.image ? (
                  <img src={item?.sender?.image} alt="user" />
                ) : (
                  <h5 className="m-0" style={{ fontSize: 22 }}>
                    {item?.sender?.name?.slice(0, 1) || "A"}
                  </h5>
                )}
              </div>
              <div className="notification-list-detail position-relative">
                <Link
                  to={`${item?.redirect_path}`}
                  className="m-0 p-0"
                  style={{
                    color: "#000",
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                >
                  {item?.sender && <b>{item?.sender?.name}</b>}{" "}
                  <small>{item?.message}</small>
                </Link>
                <p className="mb-0 text-secondary">
                  <small
                    style={{
                      fontSize: 12,
                      textAlign: "end",
                      lineHeight: "12px",
                    }}
                  >
                    {timeAgo(item?.createdAt)} ago
                  </small>
                </p>
                {/* <FaDotCircle className="position-absolute" style={{color: '#77ced9', top: 4, right: 4, zIndex: 10}} /> */}
              </div>
            </div>
          );
        })}
        <hr />
        {notificationList?.length > 0 ? (
          <Card.Footer>
            <Link to="/user-connectionlist" className="right-sidebar-link pt-0">
              View all notification
            </Link>
          </Card.Footer>
        ) : (
          <p className="my-3" style={{ textAlign: "center" }}>
            No activity here.
          </p>
        )}
      </div>
    </li>
  );
};

export default NotificationModal;
