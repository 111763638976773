import React from "react";
import { Card, ListGroup, Placeholder } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";
import { Link } from "react-router-dom";

export const RightSidebar = () => {
  const { trendingPosts, timeAgo } = useAppContext();
  
  return (
    <Card className="mb-3 position-sticky" style={{ top: 128 }}>
      <Card.Header className="right-sidebar-header">Trending Posts</Card.Header>
      <ListGroup variant="flush" className="right-sidebar-list">
        {trendingPosts?.data?.map((post, index) => (
          <ListGroup.Item
            key={index}
            className="right-sidebar-list-item d-flex flex-column"
          >
            <Link
              to={`/post-detail/${post._id}`}
              style={{ all: "unset", fontWeight: 600 }}
              className="right-sidebar-news-title"
            >
              {post?.title?.slice(0, 60)} {post?.title?.length > 60 && "..."}
            </Link>
            <small className="right-sidebar-news-details text-muted">
              {post.total_reactions} Reactions •{" "}
              {timeAgo(post.createdAt)} ago
            </small>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {/* <Card.Footer className="right-sidebar-footer">
        <Card.Link href="#" className="right-sidebar-link">Show more</Card.Link>
      </Card.Footer> */}
    </Card>
  );
};

export const TrendingPostsSkeleton = () => {
  return (
    <Card className="mb-3 position-sticky" style={{ top: 128 }}>
      <Card.Header className="right-sidebar-header">
        <Placeholder as="div" animation="wave">
          <Placeholder xs={6} style={{ borderRadius: "16px" }} />
        </Placeholder>
      </Card.Header>
      <ListGroup variant="flush" className="right-sidebar-list">
        {[...Array(5)].map((_, index) => (
          <ListGroup.Item key={index} className="right-sidebar-list-item">
            <Placeholder
              as="div"
              animation="wave"
              className="right-sidebar-news-title"
            >
              <Placeholder xs={8} style={{ borderRadius: "16px" }} />
            </Placeholder>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Card.Footer className="right-sidebar-footer">
        <Placeholder
          as={Card.Link}
          animation="wave"
          className="right-sidebar-link"
        >
          <Placeholder xs={4} style={{ borderRadius: "16px" }} />
        </Placeholder>
      </Card.Footer>
    </Card>
  );
};