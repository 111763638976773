import React, { useState, useEffect, useRef } from "react";
import RealstateProduct from "../components/comon/RealstateProduct";
import Ads from "../components/comon/Ads";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../contextApi/AppContext";
import { Pagination } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Skeleton3 } from "../components/loading/Skeleton";
import NoData from "./noData/NoData";
import BreadCrum from "../components/comon/BreadCrum";
import FashionFilter from "../components/fashion-beauty/FashionFilter";

const Realstate = () => {
  const {
    property,
    getAttributeFilterList,
    totalItem,
    getProperty,
    listLoading,
    globalCurrency,
    currency_symbol,
  } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(totalItem?.RealEstate / itemsPerPage);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sub_id = searchParams.get("sub_category");
  const child_id = searchParams.get("child_category");
  const cat_id = searchParams.get("category_id");
  const search_value = searchParams.get("search");
  const [filterData, setFilterData] = useState([]);
  const [propertyType, setPropertyType] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  const propertyRefs = useRef([]);

  const searchData = {
    address: locationSearch,
    page: currentPage,
    limit: itemsPerPage,
    property_type: propertyType,
    minPrice: minPrice,
    maxPrice: maxPrice,
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = { addressName: "", currency: globalCurrency };
      if (sub_id || child_id) {
        await getProperty(
          searchData,
          sub_id || child_id,
          filterData,
          dataToSend
        );
      } else {
        await getProperty(searchData, "", filterData, dataToSend);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [
    search_value,
    currentPage,
    sub_id,
    filterData,
    propertyType,
    locationSearch,
    minPrice,
    maxPrice,
    child_id,
    globalCurrency,
    currency_symbol,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (sub_id) {
        await getAttributeFilterList({ sub: sub_id });
      }
      if (cat_id) {
        await getAttributeFilterList({ main: cat_id });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [search_value, sub_id, cat_id]);

  const updateFilter = (e, attr, value) => {
    const checked = e.target.checked;
    if (checked === true) {
      const existingFilterIndex = filterData.findIndex(
        (item) => item.attribute === attr
      );

      if (existingFilterIndex !== -1) {
        const updatedFilterData = [...filterData];
        updatedFilterData[existingFilterIndex].value.push(value);
        setFilterData(updatedFilterData);
      } else {
        const updatedFilterData = [
          ...filterData,
          {
            attribute: attr,
            value: [value],
          },
        ];
        setFilterData(updatedFilterData);
      }
    } else {
      const updatedFilterData = filterData
        .map((item) => {
          if (item.attribute === attr) {
            item.value = item.value.filter((v) => v !== value);
          }
          return item;
        })
        .filter((item) => item.value.length > 0);
      setFilterData(updatedFilterData);
    }
  };

  const handlePropertyTypeChange = (e) => {
    setPropertyType(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocationSearch(e.target.value);
  };

  return (
    <>
      <BreadCrum title={"Real Estate List Page"} param={"Real Estate"} />

      {/* new work start here */}
      <div className="container mb-3">
        <div className="row">
          <div className="col-md-5 col-sm-5 col-12">
            <div className="card mt-2 mb-2" style={{ background: "#f7f7f7" }}>
              <div className="card-body" style={{ padding: "6px" }}>
                {["Buy", "Rent", "Lease", "Home Stay"].map((type, index) => (
                  <div
                    className="form-check form-check-inline custom-radio"
                    key={index}
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id={`inlineRadio${index + 1}`}
                      value={type}
                      onChange={handlePropertyTypeChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`inlineRadio${index + 1}`}
                    >
                      <strong>{type}</strong>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-7 col-sm-7 col-12">
            <div>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fas fa-map-marker-alt text-primary"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Location"
                  value={locationSearch}
                  onChange={handleLocationChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* new work end here */}

      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* left part start */}
            <FashionFilter
              onFilter={updateFilter}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
            />

            {/* left part end */}
            {/* right part start */}
            <div className="col-md-8 mb-3 mb-md-0">
              {/* product item start */}
              {listLoading.RealEstate ? (
                Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton3 key={index} />
                ))
              ) : property.length === 0 ? (
                <NoData
                  des={
                    "Please check the spelling or try searching for something else. Thank you!."
                  }
                />
              ) : (
                property.map((item, index) => (
                  <div
                    key={index}
                    ref={(el) => (propertyRefs.current[index] = el)}
                  >
                    <RealstateProduct
                      item={item}
                      currency_symbol={currency_symbol}
                    />
                  </div>
                ))
              )}
              {/* product item end */}
              {/* pagination */}
              <div className="d-flex justify-content-center">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={2}
                    siblingCount={1}
                  />
                </Stack>
              </div>
              {/* pagination end */}
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default Realstate;
