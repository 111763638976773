import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { EffectFade, Autoplay } from 'swiper/modules';
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";

const CustomSlick = () => {
  const { getBannerList, bannerList } = useAppContext();
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const swiperRef = useRef(null);
  // console.log(bannerList)

  const onInit = (swiper) => {
    swiperRef.current = swiper;
  };

  const handleMouseEnter = () => {
    if (swiperRef.current) swiperRef.current.autoplay.stop();
  };

  const handleMouseLeave = () => {
    if (swiperRef.current) swiperRef.current.autoplay.start();
  };

  const handleSearch = (event, item) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleNavigate = (item, value) => {
    // console.log(value)
    navigate(`/${item.category_details.slug}?sub_category=${value._id}`)
  }

  const onSubmit = (e, item) => {
    e.preventDefault();
    if (!searchValue) return
    navigate(`/${item.category_details.slug}?category_id=${item.category_id}&search=${searchValue || ''}`)
    setSearchValue('')
  }


  useEffect(() => {
    const fetchBannerList = async () => {
      await getBannerList();
    };
    fetchBannerList();
    // eslint-disable-next-line
  }, [])
  return (
    <section className="fsMainSlider">
      <div>
        <div >
          <div align='center' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            <Swiper
              onInit={onInit}
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[EffectFade, Autoplay]}
              effect="fade"
              onSlideChange={() => ''}
              onSwiper={(swiper) => ''}
            >
              {Array.isArray(bannerList) && bannerList.map((item) => (
                <SwiperSlide style={{ backgroundColor: `${item.primary_color}` }} key={item._id} >
                  <div className="slide-content">
                    <div className="container">
                      <div className="row justify-content-center" >
                        <div className="col-md-6 centerBx fade-in">
                          <div>
                            <h3 className="mb-4" align="left">{item.title}</h3>
                            <form className="input-group width100" onSubmit={(e) => onSubmit(e, item)}>
                              <input
                                type="text"
                                onChange={(e) => handleSearch(e, item)}
                                value={searchValue}
                                placeholder="Search for services..."
                                className="form-control"
                              />
                              <button type="submit" id="button-addon2" className="btn btn-primary text-white">
                                <i className="fa fa-search text-white" aria-hidden="true"></i>
                              </button>
                            </form>
                            <div align="left">
                              <ul className="bannerPopularList">
                                <li><b>Popular : </b></li>
                                {Array.isArray(item.sub_category) && item.sub_category.map((value) => (
                                  <li onClick={() => handleNavigate(item, value)} key={value._id}><Link>{value.title}</Link></li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <img src={item.image} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))} 
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomSlick;
