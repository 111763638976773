import React, { useEffect } from "react";
import { useAppContext } from "../contextApi/AppContext";
import { useParams } from "react-router-dom";
import RealstateDetail from "./RealstateDetail";
import JobDetail from "./JobDetail";
import FreelancerDetail from "./FreelancerDetail";
import LessonCoursesDetail from "./LessonCoursesDetail";
import FashionBeautyDetails from "../components/fashion-beauty/FashionBeautyDetails";
import ElectronicsDetail from "./ElectronicsDetail";
import ProductDetail from "./ProductDetail";

const AllProductDetails = () => {
  const {
    getAllProductDetails,
    getAllUnAuthProductDetails,
    allPDetails,
    isLogIn,
    globalCurrency,
    currency_symbol,
  } = useAppContext();
  const params = useParams();

  console.log(allPDetails.data?.category_name)

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = {
        id: params.id,
        currency: globalCurrency,
      };
      if (isLogIn) await getAllProductDetails(dataToSend);
      else await getAllUnAuthProductDetails(dataToSend);
    };
    if (params.id) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [params.id, currency_symbol]);

  if (!allPDetails || !allPDetails.data) {
    return null;
  }

  return (
    <div>
      {allPDetails.data?.category_name === "Real Estate" && (
        <RealstateDetail initialDetail={allPDetails} />
      )}
      {allPDetails.data?.category_name === "Jobs" && (
        <JobDetail initialDetail={allPDetails} />
      )}
      {allPDetails.data?.category_name === "Freelancer" && (
        <FreelancerDetail initialDetail={allPDetails} />
      )}
      {allPDetails.data?.category_name === "Lessons & Courses" && (
        <LessonCoursesDetail initialDetail={allPDetails} />
      )}
      {allPDetails.data?.category_name === "Fashion & Beauty" && (
        <FashionBeautyDetails initialDetail={allPDetails} />
      )}
      {allPDetails.data?.category_name === "Electronics" && (
        <ElectronicsDetail initialDetail={allPDetails} />
      )}
      {allPDetails.data?.category_name === "Goods of all kinds" && (
        <ProductDetail initialDetail={allPDetails} />
      )}
    </div>
  );
};

export default AllProductDetails;
