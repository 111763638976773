import React, { useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import {
  EffectCoverflow,
  Autoplay,
  Pagination,
  Navigation,
} from "swiper/modules";
const SwiperComponent = ({ title, data }) => {
  return (
    <>
      <div className="pt-5 pb-5">
        <div className="container pt-1 pb-4">
          <h3 class="mb-5 titelBx">{title}</h3>
          <div className="swipBannerBx">
            <Swiper
              slidesPerView={2}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              coverflowEffect={{
                rotate: 0,
                stretch: 40,
                depth: 300,
                modifier: 1,
                slideShadows: true,
              }}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 2,
                },
              }}
              modules={[EffectCoverflow, Autoplay, Pagination, Navigation]}
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              className="mySwiper"
            >
              {Array.isArray(data) &&
                data.map((item, i) => (
                  <SwiperSlide key={i}>
                    <img src={item.image} />
                    <div className="textBx">
                      <h2>{item.name}</h2>
                      <p>{item.designation}</p>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};
export default SwiperComponent;
