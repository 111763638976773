import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaStar, FaRegStar } from "react-icons/fa";
import { GoProjectSymlink } from "react-icons/go";
import { FiCheckCircle, FiUser } from "react-icons/fi";
import { MdWork } from "react-icons/md";

const FreelancerItem = ({ item, currency_symbol }) => {
  // const limitDescription = (description) => {
  //   const words = description.split(" ");
  //   if (words?.length > 20) {
  //     return words.slice(0, 20)?.join(" ") + "...";
  //   }
  //   return description;
  // };

  // const formatDuration = (createdAt) => {
  //   const now = new Date();
  //   const createdDate = new Date(createdAt);
  //   const diff = now - createdDate;

  //   const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  //   const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //   const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  //   if (days > 1) {
  //     return `${days} days`;
  //   } else {
  //     return `${hours} hours, ${minutes} minutes`;
  //   }
  // };
  // const formattedDuration = formatDuration(item.createdAt);

  return (
    <>
      <div className="card-body jobeItemBx">
        <div
          key={item.id}
          className="mb-4 shadow-sm"
          style={{
            borderRadius: "20px",
            border: "none",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            background: "linear-gradient(to right, #f8f9fa, #e9ecef)",
          }}
        >
          <div className="col-md-4 p-0" style={{ maxHeight: "250px" }}>
            <img
              src={item.image}
              className="img-fluid"
              alt={item.name}
              style={{
                height: "100%",
                objectFit: "cover",
                borderRadius: "20px 0 0 20px",
              }}
            />
          </div>
          <div className="col-md-8 p-4 d-flex flex-column justify-content-between">
            <div>
              <h5
                className="card-title mb-2"
                style={{ fontWeight: "700", fontSize: "1.25rem" }}
              >
                {item.seller_name}
                <FiUser
                  style={{
                    marginLeft: "10px",
                    color: "#6c757d",
                    fontSize: "1.1rem",
                  }}
                />
                <span
                  style={{
                    fontSize: "0.95rem",
                    marginLeft: "50px",
                    color: "#343a40",
                  }}
                >
                  <GoProjectSymlink
                    style={{ marginRight: "5px", color: "#007bff" }}
                  />
                  {item.projects} Projects Delivered
                </span>
              </h5>
              <p
                className="card-text text-muted mb-2"
                style={{
                  fontSize: "1rem",
                  color: "#6c757d",
                  fontWeight: "500",
                }}
              >
                <MdWork style={{ marginRight: "5px", color: "#6c757d" }} />{" "}
                {item.title}
              </p>
              <p
                className="card-text mb-2"
                style={{ fontSize: "0.9rem", color: "#495057" }}
              >
                <FaMapMarkerAlt
                  className="text-danger"
                  style={{ fontSize: "1rem" }}
                />{" "}
                {item.address}
              </p>
              <p
                className="card-text mb-2"
                style={{
                  fontSize: "1rem",
                  color: "#495057",
                  fontWeight: "700",
                }}
              >
                <span className="text-success" style={{ fontSize: "1rem" }}>
                  {currency_symbol ? currency_symbol : "$"}{" "}
                </span>{" "}
                {item.converted_currency_price}/hr
              </p>
              <p
                className="card-text mb-2"
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  color: "#495057",
                  fontWeight: "500",
                }}
              >
                <FiCheckCircle
                  style={{ marginRight: "8px", color: "#28a745" }}
                />
                <strong style={{ marginRight: "5px" }}>Skills:</strong>
                {item.skills?.join(", ")}
              </p>
              <div
                className="card-text"
                style={{
                  fontSize: "0.9rem",
                  color: "#495057",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.description &&
                  (/<\/?[a-z][\s\S]*>/i.test(item.description) ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  ) : (
                    <p>
                      <strong style={{ color: "#1564db" }}>
                        Job Description -
                      </strong>{" "}
                      {item.description}
                    </p>
                  ))}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {item.ratings && (
                <div style={{ fontSize: "0.9rem", color: "#ffc107" }}>
                  <span className="text-warning">
                    {Array(Math.floor(item.ratings))
                      .fill(<FaStar />)
                      .map((icon, index) => (
                        <React.Fragment key={index}>{icon}</React.Fragment>
                      ))}
                    {item.ratings % 1 !== 0 && <FaRegStar />}
                  </span>
                  <span className="text-muted ms-2">({item.ratings})</span>
                </div>
              )}
              <Link
                className="btn btn-primary rounded-pill px-4 text-white"
                to={`/freelancer-detail/${item._id}`}
                style={{
                  background: "linear-gradient(45deg, #007bff, #6610f2)",
                  border: "none",
                  fontSize: "0.95rem",
                  fontWeight: "600",
                }}
              >
                Hire Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreelancerItem;
