import React, { useEffect } from "react";
import Ads from "../../../components/comon/Ads";
import { Link } from "react-router-dom";
import AccountSideBar from "../AccountSideBar";
import { useAppContext } from "../../../contextApi/AppContext";
import { Skeleton3 } from "../../../components/loading/Skeleton";
import NoData from "../../noData/NoData";

const MyOrder = () => {
  const { orderList, getMyOrderList, listLoading, userData } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      await getMyOrderList();
    };
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>My order</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">My Order</li>
              </ul>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody bg-light">
        <article className="container-fluid">
          <article className="row">
            <Ads />
            <div className="col-md-3 mb-4">
              <AccountSideBar />
            </div>
            <div className="col-md-7 mb-4">
              <div className="card border-0 shadow-sm">
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center ">
                    <h2 className="text-primary"> My Order</h2>
                    {userData.user_type === "seller" && (
                      <div
                        className="card bg-light p-4"
                        style={{ width: "400px", borderRadius: "10px" }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ">
                            <p
                              className="mb-2"
                              style={{ fontSize: "25px", fontWeight: "bold" }}
                            >
                              Your Balance:{" "}
                              <span className="text-primary ">
                                {" "}
                                ${userData?.wallet_amount}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="d-flex gap-2">
                          <Link
                            to="/my-Order/payment_request"
                            className="btn btn-primary d-flex align-items-center justify-content-center text-white"
                            style={{ height: "40px", width: "100%" }}
                          >
                            Payout Request
                          </Link>
                          <Link
                            to="/my-Order/check_statement"
                            className="btn btn-secondary d-flex align-items-center justify-content-center"
                            style={{ height: "40px", width: "100%" }}
                          >
                            Statement
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                  <p className="text-muted">Here you can see your Orders.</p>
                  <hr />
                  {listLoading.orderList ? (
                    Array.from({ length: 3 }).map((_, index) => (
                      <Skeleton3 key={index} />
                    ))
                  ) : orderList === undefined ? (
                    <NoData
                      des={"Place an order to see your orders. Thank you!"}
                    />
                  ) : (
                    Array.isArray(orderList) &&
                    orderList.map((item, i) => (
                      <div className="myPostItem mb-3" key={i}>
                        {item?.items.map((it, j) => (
                          <div className="card mb-2 border-0 shadow-sm" key={j}>
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col-md-2">
                                  <div className="imgBx">
                                    <img
                                      src={it.image}
                                      className="img-fluid rounded"
                                      alt="Product"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-10">
                                  <h5 className="mb-3 text-dark">{it.title}</h5>
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <p className="mb-2">
                                        <strong>Quantity:</strong> {it.quantity}
                                      </p>
                                      <p className="mb-2">
                                        <strong>Total Price:</strong> $
                                        {it.total_price}
                                      </p>
                                    </div>
                                    <div className="col-sm-6">
                                      <p className="mb-2">
                                        <strong>Purchase Date:</strong>{" "}
                                        {item?.order_date
                                          ? new Date(
                                              item.order_date
                                            ).toLocaleDateString("en-GB")
                                          : "-"}
                                      </p>
                                      <p className="mb-2">
                                        <strong>Seller Name:</strong>{" "}
                                        {it.seller_name}
                                      </p>
                                      <p className="mb-2">
                                        <strong>Payment Mode:</strong>{" "}
                                        {item.payment_mode}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <Ads />
          </article>
        </article>
      </section>
    </>
  );
};

export default MyOrder;
