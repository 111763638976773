import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAppContext } from '../../contextApi/AppContext';
import moment from 'moment';

const ScheduleMeetingModal = ({ show, handleClose, product_id }) => {
  const [dateTime, setDateTime] = useState(new Date());
  const [topic, setTopic] = useState('');
  const [duration, setDuration] = useState('');
  const { getScheduledMeetingsList } = useAppContext();

  const handleSubmit = async () => {
    const formattedDateTime = moment(dateTime).toISOString();
    await getScheduledMeetingsList({ topic, start_time: formattedDateTime, duration: duration, product_id });
    handleClose();
  };

  const handleDateChange = (newDateTime) => {
    if (moment(newDateTime).isValid()) {
      setDateTime(newDateTime);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Class</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formDateTime">
            <Form.Label>Date & Time</Form.Label>
            <Datetime
              value={dateTime}
              onChange={handleDateChange}
              inputProps={{ className: 'form-control' }}
            />
          </Form.Group>
          <Form.Group controlId="formTopic">
            <Form.Label>Topic</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter meeting topic"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formDuration">
            <Form.Label>Class Duration (minutes)</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter class duration in minutes"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              required
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" className='text-white' onClick={handleSubmit}>
          Schedule
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScheduleMeetingModal;
