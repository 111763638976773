import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import noPostar from "../../assets/no-poster.png";
import { FaStar } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import Timer from "../comon/Timer";
import { toast } from "react-toastify";
const GoodsItems = ({ item, currency_symbol }) => {
  const { wishListAdd, wishListRemove, userData, cartAdd, isLogIn } =
    useAppContext();
  const [colorMap, setColorMap] = useState([]);
  const location1 = useLocation();
  const navigate = useNavigate();

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };

  const addToCart = () => {
    if (!item.price) {
      toast.warning("Price is not defined");
      return;
    }

    const dataToSend = {
      product_id: item.product_id,
      quantity: 1,
      price: item.price,
    };
    cartAdd(dataToSend);
  };

  const { attribute_values } = item;
  const brand = attribute_values?.find(
    (attribute) => attribute.attribute === "Brand"
  );

  return (
    <div className="col-md-4 col-12 mb-4" key={item._id}>
      <div className="card card-custom shadow-sm border-0 rounded-4 position-relative">
        <div className="position-relative" style={{ height: "200px" }}>
          <Link to={`/product-detail/${item._id}`}>
            <img
              src={item?.image[0] || noPostar}
              className="card-img-top rounded-top-4"
              alt=""
              style={{ height: "100%", objectFit: "cover" }}
            />
          </Link>
          {item?.bid_now && (
            <div className="timer_badge">
              <Timer
                endDate={item?.bid_end_date}
                startDate={item?.bid_start_date}
              />
            </div>
          )}
          {item.userId === userData._id ? (
            ""
          ) : (
            <div
              className="position-absolute top-0 end-0 m-2"
              onClick={
                !isLogIn ? redirect : () => addWhishlist(item.product_id)
              }
            >
              <span
                className="p-2 rounded"
                style={{
                  background: "#ffffffd1",
                  color: "#000",
                }}
              >
                {" "}
                <i
                  className={
                    colorMap[item.product_id]
                      ? "fa fa-heart text-danger"
                      : "fa fa-heart-o"
                  }
                />{" "}
              </span>
            </div>
          )}
          {item.ratings > 0 ? (
            <div
              className="position-absolute bottom-0 start-0 m-2 text-light px-2 rounded"
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
              }}
            >
              <strong className="text-black">{item.ratings} </strong>
              <strong>
                {" "}
                <FaStar color="#fdc701" style={{ marginTop: "-4px" }} />{" "}
              </strong>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="card-body d-flex flex-column">
          <span> {brand?.value} </span>
          <h6 className="card-title text-truncate">{item.title}</h6>
          <p className="card-text fw-bold text-primary m-0">
            Price: {currency_symbol ? currency_symbol : "$"}{" "}
            {item.converted_currency_price}
          </p>
          <p className="card-text text-muted small m-0 mt-1">
            <strong>Posted By:</strong> {item.seller_name}
          </p>
          <div className="d-flex mt-2">
            <button
              className="btn btn-primary me-2 text-white"
              style={{ width: "75%" }}
              onClick={!isLogIn ? redirect : addToCart}
            >
              {!isLogIn ? "Login To Buy" : "Buy Now"}
            </button>
            <button
              className="btn btn-warning"
              onClick={!isLogIn ? redirect : addToCart}
            >
              <FaCartArrowDown />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoodsItems;
