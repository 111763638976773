

export default function AgoraVoice() {

  return (
    <div className="App">
      {/* <button onClick={join}>JOIN CALL</button>
      <div id="local_stream"></div>
      <div id="remote_stream"></div> */}

      {/********* new work start here *********/}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 text-center">
            <div className="voiceCallWrap">
              <div className="userBx">
                <i className="fa fa-user"></i>
              </div>
              <h3 align="center">Mr. Rohit Modi</h3>
              <br />
              <div className="mt-5">
                <button className="btn btn-success rounded rounded-5" type="button"><i className="fa fa-phone"></i></button>&nbsp;&nbsp;
                <button className="btn btn-secondary rounded rounded-5" type="button"><i className="fa fa-volume-xmark"></i></button>&nbsp;&nbsp;
                <button className="btn btn-danger rounded rounded-5" type="button"><i className="fa-solid fa-phone-slash"></i></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/********* new work end here *********/}

    </div>
  );
}