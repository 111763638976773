import { io } from 'socket.io-client';

const socket = io('https://dzital.com'); // Adjust URL as needed

const connectSocket = () => {
    socket.on('connect', () => {
        console.log('Connected to server');
    });

    socket.on('disconnect', () => {
        console.log('Disconnected from server');
    });
};

const listenToEvent = (event, callback) => {
    socket.on(event, callback);
};

const emitEvent = (event, data) => {
    socket.emit(event, data);
};

export { connectSocket, emitEvent, listenToEvent, socket };