import React from "react";
import { Link } from "react-router-dom";
import HomeInformation from "../../screen/home/HomeInformation";
import { useAppContext } from "../../contextApi/AppContext";

const TopCity = () => {
  const { topCity } = useAppContext();

  // console.log(topCity, "top city");

  return (
    <>
      <section className="bg-light">
        <article className="container pt-4 pb-4">
          <h4>Top cities</h4>
          <div className="row cityLink mt-3">
            {topCity?.data?.map((item, index) => (
              <div className="col" key={index}>
                <ul className="list-unstyled">
                  <li>
                    <Link>{item?.city} </Link>
                  </li>
                </ul>
              </div>
            ))}
          </div>
          <HomeInformation />
        </article>
      </section>
    </>
  );
};

export default TopCity;