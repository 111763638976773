import React, { useState, useEffect } from "react";
import Ads from "../components/comon/Ads";
import { useLocation } from "react-router-dom";
import JobItem from "../components/job/JobItem";
import { useAppContext } from "../contextApi/AppContext";
import { Pagination } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Skeleton } from "../components/loading/Skeleton";
import NoData from "./noData/NoData";
import BreadCrum from "../components/comon/BreadCrum";
import FashionFilter from "../components/fashion-beauty/FashionFilter";

const JobList = () => {
  const {
    jobList,
    getJobList,
    totalItem,
    listLoading,
    getAttributeFilterList,
    sitelocation,
  } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sub_id = searchParams.get("sub_category");
  const cat_id = searchParams.get("category_id");
  const child_id = searchParams.get("child_category");
  const search_value = searchParams.get("search");
  const [filterData, setFilterData] = useState([]);
  const itemsPerPage = 12;
  const pageCount = Math.ceil(totalItem?.job / itemsPerPage);
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();
  // console.log('first,', cat_id)
  const searchData = {
    keyword_search: search_value,
    page: currentPage,
    limit: itemsPerPage,
    address: sitelocation,
  };

  useEffect(() => {
    const fetchData = async () => {
      const dataToSend = { addressName: "" };
      if (sub_id || child_id) {
        await getJobList(
          searchData,
          child_id || sub_id,
          filterData,
          dataToSend,
          minPrice,
          maxPrice
        );
      } else {
        await getJobList(
          searchData,
          "",
          filterData,
          dataToSend,
          minPrice,
          maxPrice
        );
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [
    search_value,
    currentPage,
    sub_id,
    filterData,
    sitelocation,
    minPrice,
    maxPrice,
    child_id
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (sub_id) {
        await getAttributeFilterList({ sub: sub_id });
      }
      if (cat_id) {
        await getAttributeFilterList({ main: cat_id });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [search_value, sub_id, cat_id]);

  const updateFilter = (e, attr, value) => {
    const checked = e.target.checked;
    if (checked === true) {
      const existingFilterIndex = filterData.findIndex(
        (item) => item.attribute === attr
      );

      if (existingFilterIndex !== -1) {
        const updatedFilterData = [...filterData];
        updatedFilterData[existingFilterIndex].value.push(value);
        setFilterData(updatedFilterData);
      } else {
        const updatedFilterData = [
          ...filterData,
          {
            attribute: attr,
            value: [value],
          },
        ];
        setFilterData(updatedFilterData);
      }
    } else {
      const updatedFilterData = filterData
        .map((item) => {
          if (item.attribute === attr) {
            item.value = item.value.filter((v) => v !== value);
          }
          return item;
        })
        .filter((item) => item.value.length > 0);
      setFilterData(updatedFilterData);
    }
  };

  // useEffect(() => {
  //   console.log(filterData)
  // }, [filterData])

  return (
    <>
      <BreadCrum title={"Jobs List Page"} param={"Jobs"} />
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/* left part start */}
            <FashionFilter
              onFilter={updateFilter}
              setMaxPrice={setMaxPrice}
              setMinPrice={setMinPrice}
              isJob={true}
            />
            {/* left part end */}
            {/* right part start */}
            <div className="col-md-8">
              <div className="row">
                {listLoading.job ? (
                  Array.from({ length: 4 }).map((item, index) => (
                    <Skeleton key={index} col={6} />
                  ))
                ) : jobList.length === 0 ? (
                  <NoData
                    des={
                      "Please check the spelling or try searching for something else. Thank you!."
                    }
                  />
                ) : (
                  Array.isArray(jobList) &&
                  jobList.map((item, index) => (
                    <JobItem key={index} item={item} />
                  ))
                )}
              </div>
              {/* pagenation start */}
              <div align="center">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(value) => setCurrentPage(value)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={2}
                    siblingCount={1}
                  />
                </Stack>
              </div>
              {/* pagenation end */}
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default JobList;
