import React, { useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import { useAppContext } from '../../../contextApi/AppContext';
import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';

const LeftSidebar = () => {
    const { userData, getConnectionsList, getreceivedList, connectionList, receivedList } = useAppContext()

    useEffect(() => {
        if(getConnectionsList.length === 0){
            connectionList();
        }
        if(getreceivedList.length === 0){
            receivedList();
        }
        // eslint-disable-next-line
      }, [getConnectionsList, getreceivedList]);

    return (
        <Card className="mb-3 position-sticky" style={{ top: 128 }}>
            <Card.Body className="text-center">
                <Image
                    src={userData?.image}
                    roundedCircle
                    style={{ width: 80, height: 80, objectFit: 'cover' }}
                    className="mb-2"
                />
                <Card.Title>{userData?.name}</Card.Title>
                <Card.Text>{userData?.bio || 'User prefer not to disclosed anything..'}</Card.Text>
                <div className="">
                    <Link
                        to="/user-connectionlist"
                        style={{ textDecoration: "none" }}
                    >
                        <strong>
                            {" "}
                            {getConnectionsList?.total_connections} {""}{" "}
                            Connections <MdArrowOutward />
                        </strong>
                    </Link>
                    <Link
                        to="/user-invitionlist"
                        style={{ textDecoration: "none" }}
                    >
                        {" "}
                        <strong>
                            {" "}
                            {getreceivedList?.total_pending_requests} {""}{" "}
                            invitations <MdArrowOutward />
                        </strong>
                    </Link>
                </div>
            </Card.Body>
            <Card.Footer className="text-center">
                <Card.Link as={Link} to='/packages?show_profile=true'>Upgrade to premium</Card.Link>
            </Card.Footer>
        </Card>
    );
};

export default LeftSidebar;