import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Ads from "../../components/comon/Ads";
import { useAppContext } from "../../contextApi/AppContext";

const AboutUs = () => { 
  const { getCmsList, mainData, loading } = useAppContext();
  const aboutus = 'about-us';

  useEffect(() => {
    getCmsList(aboutus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="pageBanner">
        <article className="container">
          <aside className="row">
            <div className="col-md-6">
              <h4>About Us</h4>
            </div>
            <div className="col-md-6">
              <ul className="navList">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="active">About Us</li>
              </ul>
              <div>{}</div>
            </div>
          </aside>
        </article>
      </section>
      <section className="midBody" style={{ backgroundColor: "#fff" }}>
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            {/**************** Body part start ****************/}
            {loading ? (
              <div className="col-md-10 d-flex justify-content-center ">
                <div className="spinner-grow text-info my-4" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="col-md-10">
                {/* about content part start */}
                <div className="row">
                  <div className="col-md-8">
                    <div className="aboutus-content sticky-top">
                      <h1>
                        Welcome to <span>Dzital</span>
                      </h1>
                      <div dangerouslySetInnerHTML={{ __html: mainData?.aboutUs?.description }} />
                      <div className="row">
                        {/* item start */}
                        {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="serviceBoxItem text-center">
                            <img src="./assets/images/vision.png" alt="" />
                            <div className="title">
                              <h4>Vision</h4>
                            </div>
                            <div className="text">
                              <span>
                                Lorem ipsum dolor sit amet, id quo eruditi
                                eloquentiam. Assum decore te sed. Elitr scripta
                                ocurreret qui ad.
                              </span>
                            </div>
                          </div>
                        </div> */}
                        {/* item end */}
                        {/* item start */}
                        {/* <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div className="serviceBoxItem text-center">
                            <img src="./assets/images/mission.png" alt="" />
                            <div className="title">
                              <h4>Mission</h4>
                            </div>
                            <div className="text">
                              <span>
                                Lorem ipsum dolor sit amet, id quo eruditi
                                eloquentiam. Assum decore te sed. Elitr scripta
                                ocurreret qui ad.
                              </span>
                            </div>
                          </div>
                        </div> */}
                        {/* item end */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img
                      src="./assets/images/home-search.jpg"
                      width="100%"
                      alt=""
                    />
                    <img
                      src="./assets/images/job-search.jpg"
                      width="100%"
                      alt=""
                    />
                    <img
                      src="./assets/images/ecommerce.jpg"
                      width="100%"
                      alt=""
                    />
                    <img
                      src="./assets/images/vehicle-search.jpg"
                      width="100%"
                      alt=""
                    />
                    <img
                      src="./assets/images/online-education.jpg"
                      width="100%"
                      alt=""
                    />
                  </div>
                </div>
                {/* about content part end */}
              </div>
            )}
            {/**************** Body part end ****************/}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default AboutUs;
