import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useAppContext } from "../../contextApi/AppContext";
import { toast } from "react-toastify";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PRWS708i8J7Bqoukp7NUitKQ5wmhOokDfktkMWnRc57EICz4HyYIp8n8soyRDmwACcflugDI91nAGEazLs3QOQ7008GpqmAmj"
);

const ZoomSubs = ({ showModal, handleModal }) => {
  const { getUserplan, packagesList, baseURL, token } = useAppContext();
  const [membership_id, setMembership_id] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchPackages = async () => {
      if (packagesList && packagesList.length > 0) {
        const filteredPackages = packagesList.filter(
          (item) => item.title === "Zoom Api Plan"
        );
        if (filteredPackages.length > 0) {
          setMembership_id(filteredPackages[0]._id);
        }
      }
    };
    fetchPackages();
  }, [packagesList]);

  useEffect(() => {
    getUserplan();
    // eslint-disable-next-line 
  }, []);

  const createMemberShipPayment = async (membership_id) => {
    try {
      setLoader(true);
      if (!token) {
        toast.info("Login first");
        return;
      }
      const { data } = await axios.post(
        `${baseURL}/api/membership/session/create`,
        { membership_id },
        {
          headers: {
            "Content-Type": "application/json",
            token: token,
          },
        }
      );
      if (data.status === 200) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.data,
        });
        if (error) {
          console.error("Error redirecting to checkout", error);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "server error");
    } finally {
      setLoader(false);
    }
  };

  const handleSubscribe = () => {
    createMemberShipPayment(membership_id);
    handleModal(); // Close the modal after initiating the subscription
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={handleModal}
      centered
      className="premium-modal"
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="w-100 text-center text-dark">
          Exclusive Zoom API Subscription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          <p className="lead">
            Unlock the full potential of Zoom with our premium API subscription.
            Elevate your experience with advanced features and seamless
            integration.
          </p>
          <p>Subscribe today to boost your Zoom experience!</p>
        </div>
        <hr />
        <div className="text-center">
          <h5>Key Benefits:</h5>
          <ul className="list-group list-group-flush text-left">
            <li className="list-group-item">Access to Zoom's enhanced API functionalities.</li>
            <li className="list-group-item">Seamless integration with your existing systems.</li>
            <li className="list-group-item">Priority support for API-related issues.</li>
            <li className="list-group-item">Regular updates and new feature releases.</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center bg-light">
        <Button
          variant="secondary"
          onClick={handleSubscribe}
          className="btn-lg mx-2"
          disabled={!membership_id || loader}
        >
          {loader ? "Wait" : "Yes, Subscribe Now"}
        </Button>
        <Button
          variant="primary"
          onClick={handleModal}
          className="btn-lg text-white mx-2"
        >
          No, Maybe Later
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ZoomSubs;
