import React, { useState, useEffect } from "react";
import Ads from "./../components/comon/Ads";
import LessonItem from "../components/lessons-courses/LessonItem";
import { useAppContext } from "../contextApi/AppContext";
import { Pagination } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Skeleton } from "../components/loading/Skeleton";
import { useLocation } from "react-router-dom";
import NoData from "./noData/NoData";
import BreadCrum from "../components/comon/BreadCrum";
import FashionFilter from "../components/fashion-beauty/FashionFilter";


const LessonCourses = () => {
  const { lesson, getAttributeFilterList, getLesson, totalItem, listLoading } = useAppContext();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const pageCount = Math.ceil(totalItem?.Lessons / itemsPerPage);
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const sub_id = searchParams.get('sub_category');
  const child_id = searchParams.get('child_category');
  const cat_id = searchParams.get('category_id');
  const search_value = searchParams.get('search');
  const [filterData, setFilterData] = useState([])
  const [minPrice, setMinPrice] = useState();
  const [maxPrice, setMaxPrice] = useState();


  const searchData = {
    keyword_search: search_value,
    page: currentPage,
    limit: itemsPerPage,
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sub_id || child_id) {
        await getLesson(searchData, sub_id || child_id, filterData, minPrice, maxPrice);
      } else {
        await getLesson(searchData, '', filterData, minPrice, maxPrice);
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [search_value, currentPage, sub_id, child_id, filterData, minPrice, maxPrice]);

  useEffect(() => {
    const fetchData = async () => {
      if (sub_id) {
        await getAttributeFilterList({ sub: sub_id })
      }
      if (cat_id) {
        await getAttributeFilterList({ main: cat_id })
      }
    };
    fetchData()
    // eslint-disable-next-line
  }, [search_value, sub_id, cat_id]);

  const updateFilter = (e, attr, value) => {
    const checked = e.target.checked;
    if (checked === true) {
      const existingFilterIndex = filterData.findIndex(item => item.attribute === attr);

      if (existingFilterIndex !== -1) {
        const updatedFilterData = [...filterData];
        updatedFilterData[existingFilterIndex].value.push(value);
        setFilterData(updatedFilterData);
      } else {
        const updatedFilterData = [
          ...filterData,
          {
            attribute: attr,
            value: [value]
          }
        ];
        setFilterData(updatedFilterData);
      }
    } else {
      const updatedFilterData = filterData.map(item => {
        if (item.attribute === attr) {
          item.value = item.value.filter(v => v !== value);
        }
        return item;
      }).filter(item => item.value.length > 0);
      setFilterData(updatedFilterData);
    }
  };


  return (
    <>
      <BreadCrum title={"Lessons & Courses List Page"} param={'Lessons & Courses'} />
      <section className="midBody bgtheme">
        <article className="container-fluid">
          <article className="row">
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
            <FashionFilter onFilter={updateFilter} setMaxPrice={setMaxPrice} setMinPrice={setMinPrice} />

            {/* right part start */}
            <div className="col-md-8">
              <div className="row">
                {/* item start */}
                {listLoading.Lessons ? Array.from({ length: 4 }).map((item, index) => (
                  <Skeleton key={index} col={6} />
                )) : lesson.length === 0 ? (
                  <NoData des={'Please check the spelling or try searching for something else. Thank you!'} />
                ) : (
                  Array.isArray(lesson) && lesson.map((item, index) => (
                    <LessonItem key={index} item={item} />
                  ))
                )}
              </div>
              {/* pagination start here */}
              <div align="center">
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    page={currentPage}
                    onChange={(event, value) => setCurrentPage(value)}
                    color="primary"
                    variant="outlined"
                    shape="rounded"
                    boundaryCount={2}
                    siblingCount={1}
                  />
                </Stack>
              </div>
              {/* pagination end here */}
            </div>
            {/* right part end */}
            {/* ad box start */}
            <Ads />
            {/* ad box end */}
          </article>
        </article>
      </section>
    </>
  );
};

export default LessonCourses;
