import React, { useState, useEffect } from "react";
import { useAppContext } from "../../contextApi/AppContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Slider,
} from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";

const FashionFilter = ({ onFilter, setMaxPrice, setMinPrice, isJob }) => {
  const { filterAttributeList } = useAppContext();
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [debouncedPriceRange, setDebouncedPriceRange] = useState(priceRange);

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedPriceRange(priceRange);
    }, 300); // Debounce time of 300ms

    // Cleanup the timeout if priceRange changes within the debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [priceRange]);

  useEffect(() => {
    setMinPrice(debouncedPriceRange[0]);
    setMaxPrice(debouncedPriceRange[1]);
  }, [debouncedPriceRange, setMinPrice, setMaxPrice]);

  return (
    <div className="col-md-2">
      <nav className="bg-white shadow-sm rounded p-2 mb-4 sticky-top" style={{top:'140px',zIndex:'0'}}>
       <h5 className="titelBx mb-3">Filter By</h5>
        <div>
          {/* Price Filter */}
          <div className="mb-3">
            <Accordion
              className="rounded-0"
              sx={{
                boxShadow: "none",
                border: "1px solid #e0e0e0",
                "&:hover": { borderColor: "#e0e0e0" },
              }}
              defaultExpanded 
            >
              <AccordionSummary
                expandIcon={<IoMdArrowDropdown />}
                aria-controls="panel-price-content"
                id="panel-price-header"
                className="bg-light rounded-0"
                style={{minHeight:'auto',height:'44px',overflow:'hidden'}}
              >
                <Typography className="fw-semibold">{isJob? 'Sallary' : 'Price'}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" className="mb-1">
                  {`$${priceRange[0]} - $${priceRange[1]}`}
                </Typography>
                <Slider
                  value={priceRange}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10000}
                  step={1}
                />
              </AccordionDetails>
            </Accordion>
          </div>

          {/* Other Filters */}
          {Array.isArray(filterAttributeList) &&
            filterAttributeList.map((item) => {
              const value =
                Array.isArray(item.options) && item.options.length > 0
                  ? item.options
                  : Array.isArray(item.attribute_values) &&
                    item.attribute_values.length > 0
                  ? item.attribute_values
                  : [];

              return (
                <div key={item._id} className="mb-3">
                  {value.length > 0 && (
                    <Accordion
                      className="rounded-0"
                      sx={{
                        boxShadow: "none",
                        border: "1px solid #e0e0e0",
                        "&:hover": { borderColor: "#e0e0e0" },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<IoMdArrowDropdown />}
                        aria-controls={`panel-${item._id}-content`}
                        id={`panel-${item._id}-header`}
                        className="bg-light rounded-0"
                        style={{minHeight:'auto',height:'44px',overflow:'hidden'}}
                      >
                        <Typography className="fw-semibold">
                          {item.attribute}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ul className="list-group list-group-flush">
                          {value.map((attribute) => (
                            <li
                              key={attribute._id}
                              className="list-group-item d-flex justify-content-between align-items-start border-0 ps-0"
                            >
                              <label className="form-check-label">
                                <input
                                  type="checkbox"
                                  className="form-check-input me-2"
                                  onChange={(e) =>
                                    onFilter(e, item.attribute, attribute.value)
                                  }
                                />
                                {attribute.value}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </div>
              );
            })}
        </div>
      </nav>
    </div>
  );
};

export default FashionFilter;
