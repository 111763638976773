import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppContext } from "../../../contextApi/AppContext";

export const CommentModal = ({
  showModal,
  onHide,
  type,
  initialData,
  setReplyList,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [comment, setComment] = useState("");
  const { commentUpdate, setCommentList } = useAppContext();

  useEffect(() => {
    // console.log(initialData);
    if (initialData && initialData?.content) {
      setComment(initialData?.content || "");
    }
  }, [initialData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (initialData === comment) {
      onHide();
      return;
    }
    setIsSubmitting(true);
    const res = await commentUpdate(initialData._id, { content: comment });
    if (res) {
      if (type === "Reply") {
        setReplyList((prev) =>
          prev.map((item) => {
            if (item._id === initialData._id) {
              return { ...item, content: comment };
            }
            return item;
          })
        );
      } else {
        setCommentList((prev) =>
          prev.map((item) => {
            if (item._id === initialData._id) {
              return { ...item, content: comment };
            }
            return item;
          })
        );
      }
      setIsSubmitting(false);
      onHide();
    }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onHide={onHide}
      centered
      className="premium-modal"
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="w-100 text-center text-dark">
          Edit Your {type}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formText" className="mb-2">
            <Form.Label>{type}</Form.Label>
            <textarea
              className="form-control"
              name="title"
              placeholder="Type here.."
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>
          <hr />
          <div className="d-flex justify-content-end gap-3">
            <Button
              variant="secondary"
              type="button"
              onClick={onHide}
              disabled={isSubmitting}
            >
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="text-light"
              disabled={isSubmitting}
            >
              Update {type}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
