import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../../contextApi/AppContext";
import noPostar from "../../assets/no-poster.png";
import { FaStar } from "react-icons/fa";
import { FaCartArrowDown } from "react-icons/fa";
import { toast } from "react-toastify";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";

const ElectronicsBanner = ({ item }) => {
  const {
    electronicsList,
    wishListAdd,
    wishListRemove,
    userData,
    sitelocation,
    getElectronicsProduct,
    cartAdd,
    globalCurrency,
    currency_symbol,
    isLogIn,
  } = useAppContext();
  const [colorMap, setColorMap] = useState([]);
  const location1 = useLocation();
  const navigate = useNavigate();

  const redirect = (path) => {
    if (isLogIn) {
      navigate(path);
    } else {
      toast("please Login first");
      setTimeout(() => {
        navigate(`/login?redirect=${location1.pathname}`);
      }, 1000);
    }
  };

  useEffect(() => {
    const dataToSend = {
      addressName: sitelocation,
      currency: globalCurrency,
    };
    getElectronicsProduct("", "", "", dataToSend);
    // eslint-disable-next-line
  }, [sitelocation, globalCurrency, currency_symbol]);

  const addWhishlist = (id) => {
    if (colorMap[id]) {
      wishListRemove(id);
      const filtered = { ...colorMap };
      filtered[id] = false;
      setColorMap(filtered);
    } else {
      wishListAdd(id);
      setColorMap((prevColorMap) => ({
        ...prevColorMap,
        [id]: !prevColorMap[id],
      }));
    }
  };

  const addToCart = (item) => {
    if (!item.price) {
      toast.warning("Price is not defined");
      return;
    }

    const dataToSend = {
      product_id: item.product_id,
      quantity: 1,
      price: item.price,
    };
    cartAdd(dataToSend);
  };

  useEffect(() => {
    if (electronicsList && Array.isArray(electronicsList)) {
      let check = {};
      electronicsList.forEach((list) => {
        check[list.product_id] = list.isWishlist;
      });
      setColorMap(check);
    }
  }, [electronicsList]);

  // console.log(electronicsList, "electronicsList");

  return (
    <>
      <section className="">
        <article className="container pt-4 pb-4">
          <div className="d-flex justify-content-between align-items-center titelBx">
            <h3 className="mb-0">{item.title}</h3>
            <Link
              className="btn btn-primary rounded-pill px-4 text-white"
              to={`/${item.slug}?category_id=${item._id}`}
              onMouseEnter={(e) =>
                (e.target.style.color = "var(--theme-color)")
              }
              onMouseLeave={(e) => (e.target.style.color = "black")}
              style={{
                background: "linear-gradient(45deg, #007bff, #6610f2)",
                border: "none",
                fontSize: "0.95rem",
                fontWeight: "600",
              }}
            >
              View All
            </Link>
          </div>
          <aside className="row pt-2 ">
            {/* item start */}
            <Swiper
              spaceBetween={20}
              navigation={true}
              mousewheel={false}
              keyboard={true}
              loop={true}
              pagination={false}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                360: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 15,
                },
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              className="mySwiper homeCategory"
            >
              {Array.isArray(electronicsList) &&
                electronicsList?.map((item, index) => {
                  const { attribute_values, image } = item;
                  const brand = attribute_values?.find(
                    (attribute) => attribute.attribute === "Brand"
                  );

                  return (
                    <>
                      <SwiperSlide key={item._id}>
                        <div className="card card-custom shadow-sm border-0 rounded-4">
                          <div
                            className="position-relative"
                            style={{ height: "200px" }}
                          >
                            <Link to={`/Electronics-detail/${item._id}`}>
                              <img
                                src={image[0] || noPostar}
                                className="card-img-top rounded-top-4"
                                alt=""
                                style={{ height: "100%", objectFit: "cover" }}
                              />
                            </Link>
                            {item.userId === userData._id ? (
                              ""
                            ) : (
                              <div
                                className="position-absolute top-0 end-0 m-2"
                                onClick={
                                  !isLogIn
                                    ? redirect
                                    : () => addWhishlist(item.product_id)
                                }
                              >
                                <span
                                  className="p-2 rounded"
                                  style={{
                                    background: "#ffffffd1",
                                    color: "#000",
                                  }}
                                >
                                  {" "}
                                  <i
                                    className={
                                      colorMap[item.product_id]
                                        ? "fa fa-heart text-danger"
                                        : "fa fa-heart-o"
                                    }
                                  />{" "}
                                </span>
                              </div>
                            )}
                            {item.ratings > 0 ? (
                              <div
                                className="position-absolute bottom-0 start-0 m-2 text-light px-2 rounded"
                                style={{
                                  backgroundColor: "#fffbdf",
                                }}
                              >
                                <strong className="text-black">
                                  {item.ratings}{" "}
                                </strong>
                                <strong>
                                  {" "}
                                  <FaStar
                                    color="#fdc701"
                                    style={{ marginTop: "-4px" }}
                                  />{" "}
                                </strong>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="card-body d-flex flex-column">
                            <span> {brand?.value} </span>
                            <h6 className="card-title text-truncate">
                              {item.title}
                            </h6>
                            <p className="card-text fw-bold text-primary m-0">
                              Price: {currency_symbol ? currency_symbol : "$"}{" "}
                              {item.converted_currency_price}
                            </p>
                            <p className="card-text text-muted small m-0 mt-1">
                              <strong>Posted By:</strong> {item.seller_name}
                            </p>
                            <div className="d-flex mt-2">
                              <button
                                className="btn btn-primary me-2 text-white"
                                style={{ width: "75%" }}
                                onClick={
                                  !isLogIn
                                    ? redirect
                                    : () => {
                                        addToCart(item);
                                      }
                                }
                              >
                                Buy Now
                              </button>
                              <button
                                className="btn btn-success"
                                onClick={
                                  !isLogIn
                                    ? redirect
                                    : () => {
                                        addToCart(item);
                                      }
                                }
                              >
                                {" "}
                                <FaCartArrowDown />{" "}
                              </button>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
            </Swiper>
          </aside>
        </article>
      </section>
    </>
  );
};

export default ElectronicsBanner;
