import React, { useEffect, useState } from "react";
import "./applicant.css"; // Updated CSS file
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import Ads from "../components/comon/Ads";
import { useAppContext } from "../contextApi/AppContext";
import user from "../../src/assets/assets/images/user.jpg"; // Placeholder image
import BreadCrum from "../components/comon/BreadCrum";

const ApplicantList = () => {
  const { applicantList, getApplicantList, userData, sendConnection, sellerinfo } =
    useAppContext();
  const [page, setPage] = useState(1);
  const limit = 20;

  useEffect(() => {
    const dataToSend = {
      page,
      limit,
    };
    applicantList(dataToSend);
  }, [page]);

  const totalCount = getApplicantList.total_count || 0;
  const totalPages = Math.ceil(totalCount / limit);

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setPage(pageNumber);
  };

  const sendConnectionRequest = (sellerId) => {
    const dataToSend = {
      receiverId: sellerId,
    };
    sendConnection(dataToSend);
  };

  console.log(getApplicantList);
  return (
    <>
      {/* Page banner start */}
      <BreadCrum title={"Applicant List"} param={"Applicant List"} />
      {/* Page banner end */}

      {/* Page body start */}
      <section className="premium-mid-body bg-light">
        <article className="container-fluid">
          <article className="row">
            {/* Ad box start */}
            <Ads />
            {/* Ad box end */}

            <div className="col-md-10">
              <div className="row">
                {Array.isArray(getApplicantList.data) &&
                  getApplicantList.data.map((applicant, index) => (
                    <div className="col-md-4 mb-4" key={index}>
                      {/* Card Component */}
                      <div className="premium-card border-0 shadow-lg text-center">
                        <div className="premium-card-body p-3 d-flex flex-column">
                          <div className="premium-image-wrapper position-relative">
                            <img
                              src={applicant?.job_applicant_image || user}
                              className="premium-applicant-image"
                              alt="applicant"
                            />
                          </div>

                          {/* Applicant Details */}
                          <div className="mt-3">
                            <h5 className="premium-applicant-name">
                              {applicant?.job_applicant || "Manoj K Singh"}
                            </h5>
                            <p className="premium-applicant-title mb-1">
                              {applicant?.job_applicant_title ||
                                "Director Skill at Madhya Pradesh Tourism Board"}
                            </p>
                            <p className="premium-applicant-institution small mb-2">
                              <i className="fa fa-university me-1"></i>
                              {applicant?.job_applicant_institution ||
                                "Sikkim Manipal Institute of Technology - SMU"}
                            </p>
                          </div>

                          {/* Connect Button */}
                          <div className="mt-3 mb-2">
                            {/* <button
                              className="premium-btn-connect btn"
                              
                            > */}
                              {sellerinfo?.isConnected === true &&
                              sellerinfo.isPending === false ? (
                                <button className="premium-btn-connect btn">
                                  Connected
                                </button>
                              ) : sellerinfo?.isConnected === false &&
                                sellerinfo.isPending === false ? (
                                <button
                                  className="premium-btn-connect btn"
                                  onClick={() =>
                                    sendConnectionRequest(
                                      applicant?.user_id,
                                    )}
                                >
                                 <i className="fa fa-user-plus"></i> Connect
                                </button>
                              ) : (
                                <button className="premium-btn-connect btn">
                                  Request Sent
                                </button>
                              )}
                              
                            {/* </button> */}
                          </div>
                        </div>
                      </div>
                      {/* Card Component End */}
                    </div>
                  ))}
              </div>

              {/* Pagination */}
              {/* <Pagination className=" justify-content-center mt-4">
                <Pagination.Prev onClick={handlePrevPage} disabled={page === 1} />
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === page}
                    onClick={() => handlePageClick(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={handleNextPage} disabled={page === totalPages} />
              </Pagination> */}
            </div>

            {/* Ad box start */}
            <Ads />
            {/* Ad box end */}
          </article>
        </article>
      </section>
      {/* Page body end */}
    </>
  );
};

export default ApplicantList;
